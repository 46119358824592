/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  faTrashAlt,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { deleteMultiplePrimexThreatAlertsAsync, getAllPrimexThreatAlertsAsync } from '../../../store/primexAlertsSlice';
import EditPrimexAlert from './modals/editPrimexAlert';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';

interface Props { }

const DraftThreats: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    IncomingAlerts: state.incomingAlerts,
    primexAlerts: state.primexAlerts,
    cyberThreatAssignments: state.cyberThreatAssignments,
    user: state.user,
  }));

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [sortState, setSortState] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');

  const dispatch = useAppDispatch();

  const onCheckboxChange = (checkboxId: number) => {
    if (selectedRows.includes(checkboxId)) {
      const newArray = selectedRows.filter((item) => item !== checkboxId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([checkboxId, ...selectedRows]);
    }
  };

  const deleteSelected = async () => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultiplePrimexThreatAlertsAsync(selectedRows));
      setSelectedRows([]);
      dispatch(getAllPrimexThreatAlertsAsync());
    }
  };

  const returnSortIcon = () => {
    switch (sortState) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onSortClick = () => {
    switch (sortState) {
      case 'noSort':
        setSortState('sortAsc');
        return;
      case 'sortAsc':
        setSortState('sortDesc');
        return;
      case 'sortDesc':
        setSortState('noSort');
        return;
      default:
        setSortState('noSort');
    }
  };

  return (
    <>
      <div className="drafts-container">
        <div className="my-3">
          <button type="button" className="ml-2 button-link" onClick={deleteSelected}>
            <FontAwesomeIcon icon={faTrashAlt} />
            <span className="ml-2">Delete Selected</span>
          </button>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th className="check-box">
                <input type="checkbox" />
              </th>
              <th>
                <span>Issued </span>
                <Button className="no-button" onClick={onSortClick}>
                  <FontAwesomeIcon icon={returnSortIcon()} />
                </Button>
              </th>
              <th>Alert</th>
              <th>Source</th>
              <th>Created</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentState.primexAlerts.primexSecurityAlerts
              && currentState.primexAlerts.primexSecurityAlerts
                .slice()
                .filter((item) => item.draft)
                .sort((a, b) => {
                  const aVar = a.createdAt;
                  const bVar = b.createdAt;
                  switch (sortState) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((threat) => {
                  const {
                    id,
                    title,
                    createdAt,
                    draft,
                    sourceTitle,
                  } = threat;
                  if (draft) {
                    return (
                      <tr key={id}>
                        <td className="check-box">
                          <input type="checkbox" data-threatid={id} onChange={() => onCheckboxChange(id)} />
                        </td>
                        <td>
                          {dateOnly(createdAt)}
                        </td>
                        <td>
                          <Link to={`/threat-alerts/view/${id}`}>
                            {title}
                          </Link>
                        </td>
                        <td>{sourceTitle}</td>
                        <td>
                          Author
                          {' '}
                          {dateOnly(createdAt)}
                        </td>
                        <td>
                          <EditPrimexAlert
                            currentId={id}
                            securityAlertDraft={
                              currentState.primexAlerts.selectedPrimexSecurityAlert
                            }
                            dateConversion={dateOnly}
                          >
                            Edit
                          </EditPrimexAlert>
                        </td>
                      </tr>
                    );
                  }
                })
            }
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default DraftThreats;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ConvertToPdf, { Question } from '../../../service/pdfConverter';
import UploadQuestionnaire from './modals/UploadQuestionnaire';
import UpdateQuestionnaire from './modals/UpdateQuestionnaire';
import { useAppDispatch } from '../../../store/hooks';
import {
  deleteQuestionnaireAsync,
  // downloadQuestionnaireAsync,
  getAllQuestionnairesAsync,
  getQuestionnaireAsync,
  updateQuestionnaireAsync,
} from '../../../store/questionnaireSlice';
import QuestionnaireFullPreviewModal from './modals/PreviewModal';
import { createQuestionnaireAssignmentsAsync, getAssignmentsForQuestionnaireAsync } from '../../../store/questionnaireAssignmentsSlice';
import QuestionnaireDistribution from './modals/modalComponents/questionnaireDistribution';
import { DistributionUniversal, QuestionnaireDistributionModel } from '../../../models/distributionModels';
import { Member } from '../../../store/memberSlice';
import { User } from '../../../store/userSlice';
import { downloadQuestionnaire } from '../../../api/questionnaireEngineAPI';
import { dateOnly } from '../../../service/timeAndDate';

interface Answer {
  id: number;
  answer: string;
  question: Question;
}

interface QuestionnaireSession {
  answers: Answer[];
}
interface QuestionnaireAssignments {
  id: number;
  member: Member;
  users: User[];
  createdAt: Date;
  questionnaireSession: QuestionnaireSession[];
}
interface Questionnaire {
  questionnaireAssignmentsCount?: number;
  questionsCount?: number;
  id: number;
  name: string;
  dueDate: Date | null;
  questionnaireEngineTemplateId: number;
  questionnaireEngineName: string;
  questionnaireEngineVideo: string;
  questionnaireEngineExplanation: string;
  questions: Question[];
  questionnaireSessions: QuestionnaireSession[];
  questionnaireAssignments: QuestionnaireAssignments[];
  author: User;
  createdAt: Date;
}

interface Props {
  data: Questionnaire[];
  currentQuestionnaire: Questionnaire | null;
}

const QuestionnaireAdmin: React.FC<Props> = (props: Props) => {
  const { data, currentQuestionnaire } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [addRespondentsModalId, setAddRespondentsModalId] = useState<number | null>(null);
  const [model, setModel] = useState<DistributionUniversal>();

  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortDueDate, setSortDueDate] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortQuestion, setSortQuestion] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const onClickDelete = async (id: Questionnaire['id']) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteQuestionnaireAsync(id));
      dispatch(getAllQuestionnairesAsync());
    }
  };

  const viewDetails = async (questionnaireId: number) => {
    await dispatch(getQuestionnaireAsync(questionnaireId));
    await dispatch(getAssignmentsForQuestionnaireAsync(questionnaireId));
    history.push(`/questionnaires/admin/detail/${questionnaireId}`);
  };

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const handleClose = () => {
    setAddRespondentsModalId(null);
  };
  // eslint-disable-next-line no-self-compare
  const dueDateCheck: number = model?.dueDate?.getTime() || 0;
  const submitDistribution = async () => {
    if (model) {
      const distModel: QuestionnaireDistributionModel = {
        ...model,
        questionnaireId: addRespondentsModalId!,
      };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        // eslint-disable-next-line no-self-compare
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        }
      } else {
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        distModel.allMembers = false;
        distModel.specificMemberIds = null;
        distModel.specificMemberTypes = null;
        distModel.specificMemberMaturityLevels = null;
        distModel.specificMemberSubTypes = null;
      }
      await dispatch(updateQuestionnaireAsync({
        id: addRespondentsModalId!,
        questionnaireBody: {
          dueDate: distModel.dueDate,
        },
      }));
      await dispatch(createQuestionnaireAssignmentsAsync(distModel));
      await dispatch(getAllQuestionnairesAsync());
      handleClose();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const downloadQuestionnaireTemplate = async (engineId: number, templateName: string) => {
    const res = await downloadQuestionnaire(engineId);
    // eslint-disable-next-line max-len
    const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    saveAs(blob, `${templateName}.xlsx`);
  };

  const returnDueDate = (questionnaireForDate: Questionnaire) => {
    let dueDate = '';
    if (questionnaireForDate && questionnaireForDate.dueDate) {
      dueDate = dateOnly(questionnaireForDate.dueDate);
    }
    return dueDate;
  };

  const returnStatus = (questionnaireForStatus: Questionnaire) => {
    let status = 'Inactive';
    if (
      questionnaireForStatus.questionnaireAssignments
      && questionnaireForStatus.questionnaireAssignments.length > 0
    ) {
      status = 'Active';
    }
    const dueDate = returnDueDate(questionnaireForStatus);
    if (
      dueDate
      && new Date(dueDate).valueOf() < new Date().valueOf()
    ) {
      status = 'Done';
    }
    return status;
  };

  const returnDownloadLink = async (
    incomingQuestionnaireId: number,
    retry: boolean = false,
  ) => {
    try {
      const incomingQuestionnaire = await dispatch(getQuestionnaireAsync(incomingQuestionnaireId));
      if (incomingQuestionnaire.payload) {
        const blob = await pdf((
          <ConvertToPdf questionnaire={incomingQuestionnaire.payload} />
        )).toBlob();
        saveAs(blob, incomingQuestionnaire.payload.name);
      }
    } catch (e) {
      if (!retry) {
        returnDownloadLink(incomingQuestionnaireId, true);
      }
    }
  };

  return (
    <>
      <div className="admin-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <div className="d-flex justify-content-end">
              <Button href="/files/MasterTemplate.xlsx" className="ml-4 mb-4" variant="secondary">Download Blank Template</Button>
              <UploadQuestionnaire
                questionnaireList={data}
                currentQuestionnaire={currentQuestionnaire}
              />
            </div>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Table bordered>
              <thead>
                <tr>
                  <th>
                    <span>Title</span>
                    <Button
                      className="no-button text-left"
                      onClick={() => {
                        switch (sortTitle) {
                          case 'noSort':
                            setSortTitle('sortAsc');
                            return;
                          case 'sortAsc':
                            setSortTitle('sortDesc');
                            return;
                          case 'sortDesc':
                            setSortTitle('noSort');
                            return;
                          default:
                            setSortTitle('noSort');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
                    </Button>
                  </th>
                  <th>
                    <span>Questions </span>
                    <Button
                      className="no-button text-left"
                      onClick={() => {
                        switch (sortQuestion) {
                          case 'noSort':
                            setSortQuestion('sortAsc');
                            return;
                          case 'sortAsc':
                            setSortQuestion('sortDesc');
                            return;
                          case 'sortDesc':
                            setSortQuestion('noSort');
                            return;
                          default:
                            setSortQuestion('noSort');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={returnSortIcon(sortQuestion)} />
                    </Button>
                  </th>
                  <th>
                    <span>Status </span>
                    <Button
                      className="no-button text-left"
                      onClick={() => {
                        switch (sortStatus) {
                          case 'noSort':
                            setSortStatus('sortAsc');
                            return;
                          case 'sortAsc':
                            setSortStatus('sortDesc');
                            return;
                          case 'sortDesc':
                            setSortStatus('noSort');
                            return;
                          default:
                            setSortStatus('noSort');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
                    </Button>
                  </th>
                  <th>Responses</th>
                  <th>
                    <span>Due Date </span>
                    <Button
                      className="no-button text-left"
                      onClick={() => {
                        switch (sortDueDate) {
                          case 'noSort':
                            setSortDueDate('sortAsc');
                            return;
                          case 'sortAsc':
                            setSortDueDate('sortDesc');
                            return;
                          case 'sortDesc':
                            setSortDueDate('noSort');
                            return;
                          default:
                            setSortDueDate('noSort');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={returnSortIcon(sortDueDate)} />
                    </Button>

                  </th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data
                  && data
                    .slice()
                    .sort((a, b) => {
                      const aVar = a.createdAt || 0;
                      const bVar = b.createdAt || 0;
                      if (
                        sortTitle === 'noSort'
                        && sortDueDate === 'noSort'
                        && sortQuestion === 'noSort'
                        && sortStatus === 'noSort'
                      ) {
                        return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                      }
                      return 0;
                    })
                    .sort((a, b) => {
                      const aVar = a.name ? a.name.toLowerCase() : 'z';
                      const bVar = b.name ? b.name.toLowerCase() : 'z';
                      switch (sortTitle) {
                        case 'noSort':
                          return 0;
                        case 'sortAsc':
                          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                        case 'sortDesc':
                          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                        default:
                          return 0;
                      }
                    })
                    .sort((a, b) => {
                      const aVar = (a.questions || []).length;
                      const bVar = (b.questions || []).length;
                      switch (sortQuestion) {
                        case 'noSort':
                          return 0;
                        case 'sortAsc':
                          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                        case 'sortDesc':
                          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                        default:
                          return 0;
                      }
                    })
                    .sort((a, b) => {
                      const aVar = returnStatus(a);
                      const bVar = returnStatus(b);
                      switch (sortStatus) {
                        case 'noSort':
                          return 0;
                        case 'sortAsc':
                          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                        case 'sortDesc':
                          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                        default:
                          return 0;
                      }
                    })
                    .sort((a, b) => {
                      const aVar = a.dueDate || 0;
                      const bVar = b.dueDate || 0;
                      switch (sortDueDate) {
                        case 'noSort':
                          return 0;
                        case 'sortAsc':
                          if (aVar === 0) {
                            return 1;
                          }
                          if (bVar === 0) {
                            return -1;
                          }
                          if (aVar === bVar) {
                            return 0;
                          }
                          return aVar > bVar ? 1 : -1;
                        case 'sortDesc':
                          if (aVar === 0) {
                            return 1;
                          }
                          if (bVar === 0) {
                            return -1;
                          }
                          if (aVar === bVar) {
                            return 0;
                          }
                          return aVar > bVar ? -1 : 1;
                        default:
                          return 0;
                      }
                    })
                    .map((questionnaire) => (
                      <tr key={questionnaire.id}>
                        <td>
                          <QuestionnaireFullPreviewModal
                            incomingQuestionnaireId={questionnaire.id}
                            data={currentQuestionnaire}
                          >
                            {questionnaire.name}
                          </QuestionnaireFullPreviewModal>
                        </td>
                        <td>{questionnaire.questionsCount}</td>
                        <td>{returnStatus(questionnaire)}</td>
                        <td>
                          {(questionnaire?.questionnaireAssignments || []).length ? (
                            <Button className="button-link p-0" data-id={`detailLink${questionnaire.id}`} onClick={() => viewDetails(questionnaire.id)}>
                              <span className="text-underline">
                                {`${(questionnaire?.questionnaireAssignments || [])
                                  .filter(
                                    (assignment: any) => assignment.questionnaireSession
                                      && assignment.questionnaireSession.answers
                                      && assignment.questionnaireSession.answers.length > 0,
                                  ).length} of ${questionnaire.questionnaireAssignmentsCount}`}
                              </span>
                            </Button>
                          ) : (
                            <Button className="button-link p-0" data-id={`detailLink${questionnaire.id}`} onClick={() => setAddRespondentsModalId(questionnaire.id)}>
                              <span className="text-underline">
                                Collect Responses
                              </span>
                            </Button>
                          )}
                        </td>
                        <td>{returnDueDate(questionnaire) || 'N/A'}</td>
                        <td className="text-center">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <UpdateQuestionnaire
                                data-questionnaire-id={questionnaire.id}
                                currentQuestionnaire={questionnaire}
                              />
                              <Dropdown.Item
                                data-questionnaire-id={questionnaire.id}
                                // eslint-disable-next-line max-len
                                onClick={() => downloadQuestionnaireTemplate(questionnaire.questionnaireEngineTemplateId, questionnaire.name)}
                              >
                                Download Template
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => returnDownloadLink(questionnaire.id)}>
                                Download Questionnaire
                              </Dropdown.Item>
                              <Dropdown.Item
                                data-questionnaire-id={questionnaire.id}
                                onClick={(e: any) => onClickDelete(e.target.getAttribute('data-questionnaire-id'))}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal show={!!addRespondentsModalId} size="lg" onHide={handleClose}>
        <Modal.Header />
        <Modal.Body>
          <QuestionnaireDistribution
            distModel={{ model, setModel }}
          />
        </Modal.Body>
        <Modal.Footer>
          {model?.userOnly
            && (
              <>
                {model.specificUserId
                  && model.dueDate
                  ? (
                    <Button variant="primary" onClick={submitDistribution}>
                      Update
                    </Button>
                  ) : (
                    <Button disabled variant="primary">
                      Update
                    </Button>
                  )}
              </>
            )}
          {!model?.userOnly
            && (
              <Button variant="primary" onClick={submitDistribution}>
                Update
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionnaireAdmin;

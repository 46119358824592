import { ChecklistResponse } from '../store/checklistAssignmentSlice';
import { Answer } from '../store/questionnaireSlice';

export const dateOnly = (date: Date) => {
  const d = new Date(date);
  return d.toLocaleDateString();
};
export const dateAndTime = (date: Date) => {
  const d = new Date(date);
  const timeDate = `${d.toLocaleDateString()} ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  return timeDate;
};

export const dateYearMonthDay = (date: Date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (`0${(d.getMonth() + 1)}`).slice(-2);
  const day = (`0${d.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

export const lastActiveDateString = (date: Date) => {
  const d = new Date(date);
  return d.toLocaleString('en-US', { hour12: true });
};

export const getQuestionnaireSessionUpdatedDate = (answers: Answer[]) => {
  if (answers.length === 0) {
    return '';
  }

  const mostRecentAnswerUpdatedAtDate = [...answers]
    .sort((a: Answer, b: Answer) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    })[0].updatedAt;

  const date = new Date(mostRecentAnswerUpdatedAtDate);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getChecklistSessionUpdatedDate = (responses: ChecklistResponse[]) => {
  if (responses.length === 0) {
    return '';
  }

  const mostRecentAnswerUpdatedAtDate = [...responses]
    .sort((a: ChecklistResponse, b: ChecklistResponse) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    })[0].updatedAt;

  const date = new Date(mostRecentAnswerUpdatedAtDate);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

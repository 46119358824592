import { AxiosPromise } from 'axios';
import api from './api';

export function getAllIncomingAlerts() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/incoming-threat-alerts')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneIncomingAlert(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/incoming-threat-alerts/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleAlerts(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete('/incoming-threat-alerts/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentQuestionnaireAssignmentsAsync, QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import QuestionnaireCard from '../../shared/questionnaire/QuestionnaireCard';

interface Props { }

const getQuestionnaireAssignmentGroups = (
  currentQuestionnaireAssignments: QuestionnaireAssignment[],
) => {
  const result: QuestionnaireAssignment[][] = [];

  for (let i = 0; i < currentQuestionnaireAssignments.length; i += 1) {
    const currentQuestionnaireAssignment = currentQuestionnaireAssignments[i];

    // if the result doesn't contain an array of questionnaire
    // assignments for the current questionnaire
    const resultIndex = result
      .map((questionnaireAssignmentGroup) => questionnaireAssignmentGroup[0].questionnaire.id)
      .indexOf(currentQuestionnaireAssignment.questionnaire.id);

    if (resultIndex !== -1) {
      result[resultIndex].push(currentQuestionnaireAssignment);
    } else {
      result.push([currentQuestionnaireAssignment]);
    }
  }

  return result;
};

const MemberQuestionnairesList: React.FC<Props> = () => {
  const title = 'Questionnaires';
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    questionnaireAssignments: state.questionnaireAssignments,
  }));
  useEffect(() => {
    dispatch(getCurrentQuestionnaireAssignmentsAsync());
  }, []);
  const { currentQuestionnaireAssignments } = currentState.questionnaireAssignments;

  return (
    <div className="dashboard-container">
      <div className="row row-no-gutters m-0">
        <div className="col-12 dashboard-title">
          <h2>
            {title}
          </h2>
        </div>
      </div>
      <div className="row row-no-gutters m-0">
        <div className="col-12">
          <Card className="questionnaire-container">
            <div className="row row-no-gutters m-0">
              {
                (currentQuestionnaireAssignments || [])
                  .length > 0 ? (
                    <>
                      {
                        getQuestionnaireAssignmentGroups(currentQuestionnaireAssignments
                          .slice()
                          .reverse())
                          .map((questionnaireAssignmentGroup: QuestionnaireAssignment[]) => (
                            <div
                              key={questionnaireAssignmentGroup[0].questionnaire.id}
                              className="col-12 col-lg-6 col-xl-4"
                            >
                              <QuestionnaireCard data={questionnaireAssignmentGroup} />
                            </div>
                          ))
                      }
                    </>
                  ) : null
              }
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MemberQuestionnairesList;

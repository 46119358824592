import React, { useState } from 'react';
import {
  Form,
  Accordion,
  Card,
  Button,
} from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  IrContact,
  IrClaim,
  updateClaimContactAsync,
  getOneClaimAsync,
  createContactAsync,
  deleteContactAsync,
} from '../../../../store/irClaimSlice';
import { User } from '../../../../store/userSlice';

interface Props {
  users: User[],
  contact: IrContact | undefined,
  addContact: IrContact | undefined,
  claim: IrClaim;
}

const ITContact: React.FC<Props> = ({
  users,
  contact,
  claim,
  addContact,
}) => {
  const [open, setOpen] = useState(true);
  const [member, setMember] = useState(true);
  const [backupContact, setBackupContact] = useState(!!addContact);
  const [secondMember, setSecondMember] = useState(true);
  const [debounceHandle, setDebounceHandle] = useState<any>();
  const dispatch = useDispatch();
  const { id: claimId } = claim;

  const memberSelect = () => {
    const userArray: { value: number, label: string }[] = [];
    const userMap = users.map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));
    userArray.push(...userMap);
    return userArray;
  };

  const submitMember = async (id: number) => {
    const userCheck = users.find((user) => user.id === id);
    if (userCheck && contact?.id) {
      const {
        lastName,
        firstName,
        email,
        phone,
        jobTitle,
        jobCategory,
      } = userCheck;
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          firstName,
          lastName,
          email,
          cellPhone: phone,
          jobTitle,
          category: jobCategory,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };
  const submitAddMember = async (id: number) => {
    const userCheck = users.find((user) => user.id === id);
    if (userCheck && addContact?.id) {
      const {
        lastName,
        firstName,
        email,
        phone,
        jobTitle,
        jobCategory,
      } = userCheck;
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          firstName,
          lastName,
          email,
          cellPhone: phone,
          jobTitle,
          category: jobCategory,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };
  const updateContactInfo = async (info: any, body: string) => {
    if (info && contact?.id && body === 'firstName') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          firstName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'lastName') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          lastName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'email') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          email: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'officePhone') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          officePhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'cellPhone') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          cellPhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'jobTitle') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          jobTitle: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'category') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          category: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'notes') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          notes: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateAddContactInfo = async (info: any, body: string) => {
    if (info && addContact?.id && body === 'firstName') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          firstName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'lastName') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          lastName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'email') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          email: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'officePhone') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          officePhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'cellPhone') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          cellPhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'jobTitle') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          jobTitle: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'category') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          category: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && addContact?.id && body === 'notes') {
      await dispatch(updateClaimContactAsync({
        id: addContact.id,
        updateBody: {
          notes: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="mt-4 p-2">
      <Card>
        <Accordion.Toggle className="p-2 text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
          IT Contact
          <div>
            <Form.Check
              inline
              type="checkbox"
              defaultChecked={contact?.sameAsReportingPart}
              id="it-same-reporting"
              label="Same as Reporting Party"
              onClick={async (e) => {
                const { currentTarget } = e;
                if (contact) {
                  if (currentTarget.checked) {
                    const sameAsReport = await claim.irContacts
                      .find((reportContact: IrContact) => reportContact.contactType === 'reporting-party');
                    if (sameAsReport) {
                      const {
                        firstName,
                        lastName,
                        email,
                        officePhone,
                        cellPhone,
                        jobTitle,
                        category,
                      } = sameAsReport;
                      await dispatch(updateClaimContactAsync({
                        id: contact.id,
                        updateBody: {
                          sameAsReportingPart: true,
                          firstName,
                          lastName,
                          email,
                          officePhone,
                          cellPhone,
                          jobTitle,
                          category,
                        },
                      }));
                      await dispatch(getOneClaimAsync(claimId));
                    }
                  } else {
                    await dispatch(updateClaimContactAsync({
                      id: contact.id,
                      updateBody: {
                        sameAsReportingPart: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        officePhone: '',
                        cellPhone: '',
                        jobTitle: '',
                        category: '',
                      },
                    }));
                    await dispatch(getOneClaimAsync(claimId));
                  }
                }
              }}
            />
            <FontAwesomeIcon className="ml-auto" icon={open ? faAngleUp : faAngleDown} />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form>
              <div className="d-flex col-8 p-0">
                <Form.Check
                  inline
                  label="Member User"
                  type="radio"
                  name="member"
                  id="it-member-check"
                  onClick={() => setMember(false)}
                />
                <Select
                  isDisabled={member}
                  className="col-6 mr-3"
                  options={memberSelect()}
                  onChange={(newValue: any) => {
                    submitMember(newValue.value);
                  }}
                />
                <Form.Check
                  inline
                  label="Other"
                  type="radio"
                  name="member"
                  id="it-other-check"
                  onClick={() => setMember(true)}
                />
                <Form.Check
                  inline
                  defaultChecked={contact?.msp}
                  label="MSP"
                  type="checkbox"
                  id="it-other-check"
                  onClick={async (e) => {
                    const { currentTarget } = e;
                    if (contact) {
                      if (currentTarget.checked) {
                        await dispatch(updateClaimContactAsync({
                          id: contact.id,
                          updateBody: {
                            msp: true,
                          },
                        }));
                        await dispatch(getOneClaimAsync(claimId));
                      } else {
                        await dispatch(updateClaimContactAsync({
                          id: contact.id,
                          updateBody: {
                            msp: false,
                          },
                        }));
                        await dispatch(getOneClaimAsync(claimId));
                      }
                    }
                  }}
                />
              </div>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="p-0 col-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="it-first-name"
                    type="text"
                    defaultValue={contact?.firstName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'firstName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="it-last-name"
                    type="text"
                    defaultValue={contact?.lastName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'lastName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="it-email"
                    type="email"
                    defaultValue={contact?.email || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'email'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Office Phone</Form.Label>
                  <Form.Control
                    id="it-office-phone"
                    type="text"
                    defaultValue={contact?.officePhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'officePhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Cell Phone</Form.Label>
                  <Form.Control
                    id="it-cell-phone"
                    type="text"
                    defaultValue={contact?.cellPhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'cellPhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="pl-0 col-4">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Control
                    id="it-contact-type"
                    type="text"
                    defaultValue={contact?.contactType || ''}
                    disabled
                  />
                </div>
                <div className="p-0 col-4">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    id="it-job-title"
                    type="text"
                    defaultValue={contact?.jobTitle || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'jobTitle'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="pr-0 col-4">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    id="it-category"
                    type="text"
                    defaultValue={contact?.category || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'category'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              {backupContact
                && (
                  <>
                    <h5 className="bold mt-4">Secondary Contact</h5>
                    <div className="d-flex col-8 p-0">
                      <Form.Check
                        inline
                        label="Member User"
                        type="radio"
                        name="it-second-Member"
                        id="it-second-member-check"
                        onClick={() => setSecondMember(false)}
                      />
                      <Select
                        isDisabled={secondMember}
                        className="col-6 mr-3"
                        options={memberSelect()}
                        onChange={(newValue: any) => {
                          submitAddMember(newValue.value);
                        }}
                      />
                      <Form.Check
                        inline
                        label="Other"
                        type="radio"
                        name="it-second-Member"
                        id="it-second-other-check"
                        onClick={() => setSecondMember(true)}
                      />
                    </div>
                    <div className="d-flex mt-4 ml-0 justify-content-between">
                      <div className="p-0 col-2">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          id="it-second-first-name"
                          type="text"
                          defaultValue={addContact?.firstName || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'firstName'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="p-0 col-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          id="it-second-last-name"
                          type="text"
                          defaultValue={addContact?.lastName || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'lastName'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="p-0 col-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          id="it-second-email"
                          type="email"
                          defaultValue={addContact?.email || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'email'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="p-0">
                        <Form.Label>Office Phone</Form.Label>
                        <Form.Control
                          id="it-second-office-phone"
                          type="text"
                          defaultValue={addContact?.officePhone || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'officPhone'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="p-0">
                        <Form.Label>Cell Phone</Form.Label>
                        <Form.Control
                          id="it-second-cell-phone"
                          type="text"
                          defaultValue={addContact?.cellPhone || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'cellPhone'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-4 ml-0 justify-content-between">
                      <div className="pl-0 col-4">
                        <Form.Label>Contact Type</Form.Label>
                        <Form.Control
                          id="it-second-contact-type"
                          type="text"
                          disabled
                          defaultValue={addContact?.contactType || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'contactType'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="p-0 col-4">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          id="it-second-job-title"
                          type="text"
                          defaultValue={addContact?.jobTitle || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'jobTitle'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                      <div className="pr-0 col-4">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                          id="it-second-category"
                          type="text"
                          defaultValue={addContact?.category || ''}
                          onChange={(e) => {
                            if (debounceHandle) clearTimeout(debounceHandle);
                            const { currentTarget: { value } } = e;
                            const handle = setTimeout(() => updateAddContactInfo(value, 'category'), 750);
                            setDebounceHandle(handle);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              <Button
                onClick={async () => {
                  if (backupContact) {
                    setBackupContact(false);
                    if (addContact) {
                      await dispatch(deleteContactAsync(addContact.id));
                      await dispatch(getOneClaimAsync(claimId));
                    }
                  } else {
                    setBackupContact(true);
                    if (!addContact) {
                      await dispatch(createContactAsync({ contactType: 'backup-it-contact', irClaim: claim }));
                      await dispatch(getOneClaimAsync(claimId));
                    }
                  }
                }}
                className="no-button my-3"
              >
                {backupContact ? '- Remove backup contact' : '+ Add backup contact'}
              </Button>
              <div>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  id="it-notes"
                  as="textarea"
                  rows={3}
                  defaultValue={contact?.notes || ''}
                  onChange={(e) => {
                    if (debounceHandle) clearTimeout(debounceHandle);
                    const { currentTarget: { value } } = e;
                    const handle = setTimeout(() => updateContactInfo(value, 'notes'), 750);
                    setDebounceHandle(handle);
                  }}
                />
              </div>
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ITContact;

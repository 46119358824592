import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useHistory } from 'react-router-dom';
import ArticleIcon from '../../../../assets/icons/Posts-dash.svg';
import { useAppDispatch } from '../../../../store/hooks';
import { nullSelectedPostArticle } from '../../../../store/postArticleSlice';

interface Props { }

const PostArticleCard: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createBillboard = async () => {
    await dispatch(nullSelectedPostArticle());
    history.push('/content/create/postarticle');
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h2 className="text-center">
            Post / Article
          </h2>
        </Card.Title>
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className="m-3">
            <img src={ArticleIcon} alt="Article Icon" />
          </div>
          <div className="m-3">
            <p>Create content to appear in the summary newsletter, dashboard widget or carousel.</p>
          </div>
        </div>
        <div className="d-flex flex-column button-box">
          <Button className="m-2" variant="primary" onClick={createBillboard}>Create</Button>
          <Link to="/content/manage/postarticle">
            <Button className="m-2" variant="secondary">Manage</Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PostArticleCard;

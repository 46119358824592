import { AxiosPromise } from 'axios';
import { CreatePrimexSecurityAlert, CreatePrimexSecurityAlertDraft } from '../store/primexAlertsSlice';
import api from './api';

export function getAllPrimexThreatAlerts() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/cyber-threats')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOnePrimexThreatAlert(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/cyber-threats/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultiplePrimexThreatAlerts(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete('/cyber-threats/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createPrimexThreatAlert(
  data:
  CreatePrimexSecurityAlert | CreatePrimexSecurityAlertDraft | Partial<CreatePrimexSecurityAlert>,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/cyber-threats', data)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updatePrimexThreatAlert(id: number, body: Partial<CreatePrimexSecurityAlert>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/cyber-threats/${id}`, body)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useHistory } from 'react-router-dom';
import ChecklistIcon from '../../../../assets/icons/Checklist-dash.svg';
import { nullSelectedChecklist } from '../../../../store/checklistSlice';
import { useAppDispatch } from '../../../../store/hooks';

interface Props { }

const ChecklistCard: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createChecklist = () => {
    dispatch(nullSelectedChecklist());
    history.push('/content/create/checklist');
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h2 className="text-center">
            Checklist
          </h2>
        </Card.Title>
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className="m-3">
            <img src={ChecklistIcon} alt="Checklist Icon" />
          </div>
          <div className="m-3">
            <p>
              Create checklists to assist members with implementing policies and best practices.
            </p>
          </div>
        </div>
        <div className="d-flex flex-column button-box">
          <Button className="m-2" variant="primary" onClick={createChecklist}>Create</Button>
          <Link to="/content/manage/checklist">
            <Button className="m-2" variant="secondary">Manage</Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChecklistCard;

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import { Link } from 'react-router-dom';
import TrainingIcon from '../../../../assets/icons/training-tile.svg';

interface Props { }

const TrainingCard: React.FC<Props> = () => (
  <Card>
    <Card.Body>
      <Card.Title>
        <h2 className="text-center">
          Training
        </h2>
      </Card.Title>
      <div className="d-flex justify-content-between align-items-center flex-column">
        <div className="m-3">
          <img src={TrainingIcon} alt="How-To Icon" />
        </div>
        <div className="m-3">
          <p>
            Go to the Training portal to find or upload and
            assign training content and videos to member users.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column button-box">
        <Button className="m-2" href="https://nhprimex.org/account/view/training/" variant="primary">Go to Training</Button>
      </div>
    </Card.Body>
  </Card>
);

export default TrainingCard;

import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import pdfBackgroundLogo from '../../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../../assets/bkg-diamonds.png';
import { IrClaim } from '../../../../../store/irClaimSlice';

interface Props {
  irClaim: IrClaim;
}

const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 50,
    fontFamily: 'lato',
    fontSize: 12,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  fontBody: {
    fontFamily: 'Courier',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleFonts: {
    fontSize: 16,
  },
  subTitleFonts: {
    fontSize: 14,
  },
  contentBlock: {
    marginRight: 25,
  },
  contentBlockParent: {
    marginBottom: 10,
  },
  contentTitle: {
    textDecoration: 'underline',
  },
});

const ClaimContactsPdf: React.FC<Props> = (props: Props) => {
  const { irClaim } = props;
  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.fontBody]} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Contacts
            </Text>
          </View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Claim Title:
            </Text>
            <Text>
              { irClaim.title || 'No Title' }
            </Text>
          </View>
          <View>
            {
              irClaim
              && irClaim.irContacts
              && irClaim.irContacts.length > 0
              && irClaim.irContacts.map((contact) => (
                <View key={`${contact.id}-pdf`} style={[{ marginBottom: 15 }]}>
                  <View style={[styles.inline, styles.subTitleFonts, { marginBottom: 5 }]}>
                    <Text style={[{ textDecoration: 'underline' }]}>
                      {`${contact.contactType.charAt(0).toUpperCase()}${contact.contactType.replace('-', ' ').slice(1)}`}
                    </Text>
                  </View>
                  {
                    contact.irContactMemberUser
                      ? (
                        <View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Name
                              </Text>
                              <Text>
                                {
                                  `${contact.irContactMemberUser.firstName || ''} ${contact.irContactMemberUser.lastName || ''}`
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Email
                              </Text>
                              <Text>
                                {
                                  contact.irContactMemberUser.email || ''
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Office Phone
                              </Text>
                              <Text>
                                {
                                  contact.irContactMemberUser.phone || ''
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Cell Phone
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Job Title
                              </Text>
                              <Text>
                                {
                                  contact.irContactMemberUser.jobTitle
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Job Category
                              </Text>
                              <Text>
                                {
                                  contact.irContactMemberUser.jobCategory || ''
                                }
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Notes
                              </Text>
                              <Text>
                                {
                                  contact.notes
                                }
                              </Text>
                            </View>
                          </View>
                        </View>
                      ) : (
                        <View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Name
                              </Text>
                              <Text>
                                {
                                  `${contact.firstName || ''} ${contact.lastName || ''}`
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Email
                              </Text>
                              <Text>
                                {
                                  contact.email || ''
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Office Phone
                              </Text>
                              <Text>
                                {
                                  contact.officePhone || ''
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Cell Phone
                              </Text>
                              <Text>
                                {
                                  contact.cellPhone || ''
                                }
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Job Title
                              </Text>
                              <Text>
                                {
                                  contact.jobTitle
                                }
                              </Text>
                            </View>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Job Category
                              </Text>
                              <Text>
                                {
                                  contact.category || ''
                                }
                              </Text>
                            </View>
                          </View>
                          <View style={[styles.inline, styles.contentBlockParent]}>
                            <View style={[styles.column, styles.contentBlock]}>
                              <Text style={[styles.contentTitle]}>
                                Notes
                              </Text>
                              <Text>
                                {
                                  contact.notes
                                }
                              </Text>
                            </View>
                          </View>
                        </View>
                      )
                  }
                </View>
              ))
            }
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};
export default ClaimContactsPdf;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBillboardAssignments, getBillboardAssignments } from '../api/billboardAssignmentAPI';
import { BillboardDistributionModel } from '../models/distributionModels';
import { Billboard } from './billboardSlice';
import { User } from './userSlice';

export interface BillboardAssignment {
  id: number;
  billboard: Billboard;
  user: User;
}

interface BillboardAssignmentState {
  currentBillboardAssignments: BillboardAssignment[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: BillboardAssignmentState = {
  currentBillboardAssignments: null,
  status: 'idle',
  error: null,
};

const getBillboardAssignmentsAsync = createAsyncThunk(
  'billboardAssignments/getCurrent',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getBillboardAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createBillboardAssignmentsAsync = createAsyncThunk(
  'billboardAssignments/create',
  async (arg: BillboardDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createBillboardAssignments(arg);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const billboardAssignmentsSlice = createSlice({
  name: 'billboardAssignments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillboardAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBillboardAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentBillboardAssignments = action.payload;
        state.error = null;
      })
      .addCase(getBillboardAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentBillboardAssignments = [];
        state.error = action.payload;
      })
      .addCase(createBillboardAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBillboardAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createBillboardAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default billboardAssignmentsSlice.reducer;

export {
  getBillboardAssignmentsAsync,
  createBillboardAssignmentsAsync,
};

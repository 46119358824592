import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ContentCreation from './components/views/ContentCreation';
import CyberThreatAlerts from './components/views/CyberThreatAlerts';
import Dashboard from './components/views/Dashboard';
import HowTo from './components/views/HowTo';
import Members from './components/views/Members';
import NotificationsList from './components/views/Notifications';
import PageNotFound from './components/views/PageNotFound';
import Profile from './components/views/Profile';
import Questionnaires from './components/views/Questionnaires';
import Trainings from './components/views/Training';
import Users from './components/views/Users';
import CyberClaims from './components/views/CyberClaims';
import { getCurrentNotificationsAsync } from './store/notificationSlice';
import { getTrainingRecordsAsync } from './store/trainingSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getCurrentUserAsync } from './store/userSlice';
import PostsAndArticles from './components/views/PostsAndArticles';
import { getBillboardAssignmentsAsync } from './store/billboardAssignmentSlice';
import { getCurrentTodoAsync } from './store/todoSlice';
import { getCurrentPostArticleAssignmentsAsync } from './store/postArticleAssignmentSlice';
import { getCurrentQuestionnaireAssignmentsAsync } from './store/questionnaireAssignmentsSlice';
import NoAccessPermitted from './components/views/NoAccessPermitted';
import Documents from './components/views/Documents';
import Checklists from './components/views/Checklists';

interface Props {}

const AppRouter: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  // make sure to fetch the current user whenever loading the app
  // the router is the base component to load whenever user logs in
  const getTrainingThenNotificationsAsync = async () => {
    await dispatch(getTrainingRecordsAsync());
    await dispatch(getCurrentQuestionnaireAssignmentsAsync());
    dispatch(getCurrentNotificationsAsync());
  };
  useEffect(() => {
    dispatch(getCurrentUserAsync());
    getTrainingThenNotificationsAsync();
    dispatch(getBillboardAssignmentsAsync());
    dispatch(getCurrentPostArticleAssignmentsAsync());
    dispatch(getCurrentTodoAsync());
  }, []);

  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  const { currentUser } = currentState.user;
  return (
    <>
      {
        currentUser
        && !currentUser.cyberNone
          ? (
            <BrowserRouter>
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/profile" component={Profile} />
                <Route path="/questionnaires" component={Questionnaires} />
                <Route path="/threat-alerts" component={CyberThreatAlerts} />
                <Route path={['/member', '/members']} component={Members} />
                <Route path="/users" component={Users} />
                <Route path="/training" component={Trainings} />
                <Route path="/how-to" component={HowTo} />
                <Route path="/notifications" component={NotificationsList} />
                <Route path="/posts-articles" component={PostsAndArticles} />
                <Route path="/checklists" component={Checklists} />
                <Route path="/documents" component={Documents} />
                <Route path="/cyber-claims" component={CyberClaims} />
                {currentUser
                && currentUser.role
                && superAdminRole
                && (currentUser.role
                >= superAdminRole) ? (
                  <Route path="/content" component={ContentCreation} />
                  ) : null}
                <Route component={PageNotFound} />
              </Switch>
            </BrowserRouter>
          ) : null
      }
      {
        currentUser
        && currentUser.cyberNone
        && (
          <NoAccessPermitted />
        )
      }
    </>
  );
};

export default AppRouter;

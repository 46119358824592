import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMembersAutosuggestAsync } from '../../../store/memberSlice';

interface Member {
  id: number;
  name: string;
}

interface MemberAutosuggestProps {
  memberIds: number[];
    // eslint-disable-next-line no-unused-vars
  onSelect: (selectedMembers: number[]) => void;
}

const MemberAutosuggest: React.FC<MemberAutosuggestProps> = ({ memberIds, onSelect }) => {
  const [, setSelectedMembers] = useState<Member[]>([]);
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => ({
    member: state.member.memberAutosuggest,
  }));

  useEffect(() => {
    dispatch(getMembersAutosuggestAsync());
  }, []);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedMembers(selectedOptions);
    if (onSelect) {
      onSelect(selectedOptions.map((option:any) => option.value));
    }
  };

  const defaultSelectedOptions = memberIds.map((id) => currentState.member
    .find((option) => +option.value! === +id))
    .filter(Boolean);

  return (
    <Select
      options={currentState.member}
      value={defaultSelectedOptions}
      onChange={handleSelectChange}
      placeholder="Select members..."
      isMulti
    />
  );
};

export default MemberAutosuggest;

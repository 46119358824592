/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getLogout } from './auth';
// import { getLogout } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// add a request interceptor with bearer token
api.interceptors.request.use((config) => {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  return config;
});

api.interceptors.response.use((response) => response, async (error) => {
  const { response: errorResponse, config: originalRequest } = error;
  try {
    if (errorResponse.status === 401 && originalRequest && !originalRequest._retry && originalRequest.url !== '/auth/refresh') {
      originalRequest._retry = true;
      await api.post('/auth/refresh', {
        refreshToken: localStorage.getItem('refreshToken'),
      }).then((response) => {
        if (response.status === 201 && response.data.refreshToken) {
          localStorage.setItem('accessToken', response.data.refreshToken);
          api.defaults.headers!.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          return api.request(originalRequest);
        }
      }).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('email');
          window.location.href = '/';
        }
      });
    } else if (originalRequest.url === '/auth/refresh' && originalRequest._retry) {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      window.location.href = '/';
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } catch (authError) {
    return Promise.reject(error);
  }
  originalRequest._retry = true;
  return api.request(originalRequest);
});

const formUploadApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// add a request interceptor with bearer token
formUploadApi.interceptors.request.use((config) => {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  return config;
});

formUploadApi.interceptors.response.use((response) => response, async (error) => {
  const { response: errorResponse, config: originalRequest } = error;
  try {
    if (errorResponse.status === 401 && originalRequest && !originalRequest._retry && originalRequest.url !== '/auth/refresh') {
      originalRequest._retry = true;
      await formUploadApi.post('/auth/refresh', {
        refreshToken: localStorage.getItem('refreshToken'),
      }).then((response) => {
        if (response.status === 201 && response.data.refreshToken) {
          localStorage.setItem('accessToken', response.data.refreshToken);
          formUploadApi.defaults.headers!.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          return formUploadApi.request(originalRequest);
        }
      }).catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('email');
          window.location.href = '/';
        }
      });
    } else if (originalRequest.url === '/auth/refresh' && originalRequest._retry) {
      const logout = await getLogout();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      window.location.href = logout.data.url;
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  } catch (authError) {
    return Promise.reject(error);
  }
  originalRequest._retry = true;
  return formUploadApi.request(originalRequest);
});

const getPaginatedCountFromResponse = (response: any) => {
  const totalCountHeader = response.headers['x-total-count'];
  const totalCount = totalCountHeader ? parseInt(totalCountHeader, 10) : null;
  return totalCount;
};

export default api;

export {
  formUploadApi,
  getPaginatedCountFromResponse,
};

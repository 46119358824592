import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import DetailsNav from './detailTabs/detailsNav';
import ClaimInfo from './detailTabs/claimInfo';
import Contact from './detailTabs/contact';
import Technology from './detailTabs/technology';
import Files from './detailTabs/files';
import Updates from './detailTabs/updates';
import PrivateComments from './detailTabs/privateComments';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOneClaimAsync } from '../../../store/irClaimSlice';
import { getAllUsersWithMembersAsync, User } from '../../../store/userSlice';

interface Props { }

const ClaimDetails: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string
  }>();
  const currentState = useAppSelector((state: any) => ({
    claims: state.irClaim,
    users: state.user,
  }));
  // eslint-disable-next-line max-len
  const AtomIRRole = process.env.REACT_APP_ATOM_IR_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_ATOM_IR_ROLE_ACCESS_LEVEL, 10);

  const dispatch = useAppDispatch();
  const {
    claims: { selectedClaim },
    users: {
      users,
      currentUser,
    },
  } = currentState;

  const atomIrUser = currentUser.role === AtomIRRole;
  const atomLead = users?.filter((user: User) => user.role === 400);

  useEffect(() => {
    dispatch(getOneClaimAsync(parseInt(id, 10)));
    dispatch(getAllUsersWithMembersAsync());
  }, []);
  return (
    <>
      {selectedClaim && (
        <Card className="cyber-claims-container">
          <DetailsNav claimId={selectedClaim.id}>
            <Switch>
              <Route path="/cyber-claims/details/:id/info">
                <ClaimInfo
                  claim={selectedClaim}
                  atomLead={atomLead}
                  currentUser={currentUser}
                />
              </Route>
              <Route path="/cyber-claims/details/:id/contacts">
                <Contact claim={selectedClaim} atomIrUser={atomIrUser} />
              </Route>
              <Route path="/cyber-claims/details/:id/technology">
                <Technology
                  technologyInfo={selectedClaim.technology}
                  atomIrUser={atomIrUser}
                />
              </Route>
              <Route path="/cyber-claims/details/:id/files">
                <Files atomIrUser={atomIrUser} claim={selectedClaim} />
              </Route>
              <Route path="/cyber-claims/details/:id/updates">
                <Updates claim={selectedClaim} updates={selectedClaim.updates} />
              </Route>
              <Route path="/cyber-claims/details/:id/private-comments">
                <PrivateComments
                  claim={selectedClaim}
                  privateComm={selectedClaim.privateCommunications}
                />
              </Route>
            </Switch>
          </DetailsNav>
        </Card>
      )}
    </>
  );
};

export default ClaimDetails;

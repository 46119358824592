/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import TagManager from 'react-gtm-module';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import HowToIcon from '../../assets/icons/How-to-headeri-icon.svg';
import Logo from '../../assets/primex-logo.svg';
import { getLogout } from '../../api/auth';
import { useAppSelector } from '../../store/hooks';
import MemberSelectionModal from '../shared/MemberSelectionModal';
import NotificationDropdown from './headerComponents/NotificationDropdown';
import { reactiveAdmin } from '../../api/userAPI';

interface Props { }

const Header: React.FC<Props> = () => {
  const [showSelectMemberModal, setShowSelectMemberModal] = useState<boolean>(false);

  const history = useHistory();

  const currentState = useAppSelector((state) => ({
    user: state.user,
    notification: state.notification,
  }));

  const { currentUser } = currentState.user;
  const { notifications } = currentState.notification;

  // eslint-disable-next-line max-len
  const primexMemberNumber: number | null = process.env.REACT_APP_PRIMEX_MEMBER_NUMBER ? parseInt(process.env.REACT_APP_PRIMEX_MEMBER_NUMBER, 10) : null;

  const returnNotificationNumber = () => {
    if (notifications) {
      // eslint-disable-next-line prefer-destructuring
      const length = notifications
        .slice()
        .filter((item) => (
          item.cyberThreatAssignment
          || item.postArticleAssignment
          || item.questionnaireAssignment
          || item.document
          || item.trainingId
          || item.checklistAssignment
          || item.irClaim
          || item.irFile
        ))
        .filter((item) => !item.viewed).length;
      if (length < 1) {
        return 0;
      } if (length >= 10) {
        return 10;
      }
      return length;
    }
    return 0;
  };

  // eslint-disable-next-line no-unused-vars
  const trackClick = (e: boolean, location: string) => {
    if (e) {
      let tagManagerArgs = {};
      if (location === 'notification') {
        tagManagerArgs = {
          dataLayer: {
            event: 'cyberPortal',
            action: 'view',
            category: 'notifications',
            label: 'View Notification in Header',
            value: 1,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      } else if (location === 'how-to') {
        tagManagerArgs = {
          dataLayer: {
            event: 'cyberPortal',
            action: 'view',
            category: 'how-to',
            label: 'View How-To Page',
            value: 1,
          },
        };
      } else if (location === 'memberProfile') {
        tagManagerArgs = {
          dataLayer: {
            event: 'cyberPortal',
            action: 'view',
            category: 'member profile',
            label: 'View Member Profile via Header Bar',
            value: 1,
          },
        };
      } else if (location === 'userProfile') {
        tagManagerArgs = {
          dataLayer: {
            event: 'cyberPortal',
            action: 'view',
            category: 'user profile',
            label: 'View User Profile via Header Bar',
            value: 1,
          },
        };
        history.push('/profile');
      }
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const returnChildMembers = () => {
    if (currentUser && currentUser.members) {
      if (currentUser.members.length > 0) {
        if (currentUser.members[0].childMembers) {
          return currentUser.members[0].childMembers;
        }
        return [];
      }
      return [];
    }
    return [];
  };

  const checkForPrimaryItorPrimaryContact = () => {
    if (currentUser && currentUser.members && currentUser.members.length > 0) {
      const allMembers = currentUser.members;
      if (currentUser.primaryContactForMembers && currentUser.primaryContactForMembers.length > 0) {
        const pCMembers = currentUser.primaryContactForMembers;
        allMembers.forEach((member) => {
          if (!pCMembers.find((pcMember) => pcMember.id === member.id)) {
            return false;
          }
        });
        return true;
      }
      if (currentUser.primaryITForMembers && currentUser.primaryITForMembers.length > 0) {
        const pCMembers = currentUser.primaryITForMembers;
        allMembers.forEach((member) => {
          if (!pCMembers.find((pcMember) => pcMember.id === member.id)) {
            return false;
          }
        });
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <div className="d-flex justify-content-between global-header">
        <div className="d-flex align-items-center">
          <div className="px-3">
            <img src={Logo} alt="Primex Logo" />
          </div>
          <div className="px-3">
            <h2 className="mb-0">
              Primex
              <sup>3 </sup>
              Cybersecurity Portal
            </h2>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="px-3 alertDropdownContainer d-flex align-items-center">
            <Dropdown as={ButtonGroup} onToggle={(e) => trackClick(e, 'notification')}>
              <Dropdown.Toggle
                className="alertDropdown d-flex"
                variant="clear"
              >
                <FontAwesomeIcon icon={faBell} className="bell-icon" />
                {
                  notifications
                  && returnNotificationNumber() > 0
                  && (
                    <Badge variant="danger">
                      {returnNotificationNumber()}
                    </Badge>
                  )
                }
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 dropdown-menu-center">
                <div className="dropdown-header">
                  <span>Notifications </span>
                  <span>
                    {
                      notifications
                      && returnNotificationNumber() > 0
                      && (
                        <span>
                          (
                          {returnNotificationNumber()}
                          )
                        </span>
                      )
                    }
                  </span>
                </div>
                {
                  notifications
                  && notifications.length > 0
                  && notifications
                    .slice()
                    .filter((item) => !item.viewed)
                    .sort((a, b) => (a.createdAt.valueOf() < b.createdAt.valueOf() ? 1 : -1))
                    .map((notification, index) => {
                      if (notification && index < 10) {
                        return (
                          <NotificationDropdown
                            key={`${notification.id}-notification`}
                            notification={notification}
                            index={index}
                          />
                        );
                      }
                    })
                }
                {
                  returnNotificationNumber() === 0
                  && (
                    <Dropdown.Item>
                      <div className="py-2 no-notifications">
                        <h6>No new notifications.</h6>
                      </div>
                    </Dropdown.Item>
                  )
                }
                <div className="dropdown-footer">
                  <Link to="/notifications">
                    view all
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="px-3 how-to-link-container d-flex align-items-center">
            <Link to="/how-to" onClick={() => trackClick(true, 'how-to')}>
              <img src={HowToIcon} alt="How-to icon link" />
            </Link>
          </div>
          <div className="p-3">
            <p className="mb-0">{`${currentUser?.firstName} ${currentUser?.lastName}`}</p>
            <DropdownButton
              id="dropdown-basic-button"
              title={(currentUser?.members || []).length
                ? currentUser?.members[0].memberName
                : 'Member'}
              variant="clear"
              className="member-button"
            >
              <Dropdown.Item onClick={() => trackClick(true, 'userProfile')}>
                My Profile
              </Dropdown.Item>
              {/* user has 1 member with no child members */}
              {
                currentUser
                && (currentUser.members || []).length === 1
                && currentUser.members[0].memberNumber !== primexMemberNumber
                && (returnChildMembers()).length === 0
                && (currentUser.primaryContactForMembers || currentUser.primaryITForMembers)
                && currentUser.restrictedDataAccess
                && checkForPrimaryItorPrimaryContact()
                  ? (
                    <Dropdown.Item onClick={() => trackClick(true, 'memberProfile')}>
                      <Link className="no-underline" to={`/members/profile/${currentUser?.members[0].id}/info`}>
                        Member Profile
                      </Link>
                    </Dropdown.Item>
                  ) : null
                }
              {/* current user has more than 1 member, or a member with child members */}
              {
              ((currentUser?.members || []).length > 1
                || (returnChildMembers()).length > 0)
                && currentUser?.restrictedDataAccess
                && checkForPrimaryItorPrimaryContact()
                ? (
                  <Dropdown.Item
                    onClick={() => {
                      trackClick(true, 'memberProfile');
                      setShowSelectMemberModal(true);
                    }}
                  >
                    Member Profile
                  </Dropdown.Item>
                ) : null
              }
              {localStorage.getItem('impersonate') === 'admin' && (
                <Dropdown.Item
                  onClick={async () => {
                    await reactiveAdmin();
                  }}
                >
                  Restore Previous User
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={async () => {
                  const res = await getLogout();
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('email');
                  localStorage.removeItem('refreshToken');
                  window.location.href = res.data.url;
                }}
              >
                Logout
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      <MemberSelectionModal
        show={showSelectMemberModal}
        onClose={() => setShowSelectMemberModal(false)}
      />
    </>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getCurrentQuestionnaireSessionAsync,
  postStartQuestionnaireSessionAsync,
  getQuestionnaireAsync,
  Questionnaire,
  updateQuestionnaireSessionAsync,
  Answer,
} from '../../../../store/questionnaireSlice';
import { getCurrentNotificationsAsync, updateNotificationAsync } from '../../../../store/notificationSlice';
import { QuestionnaireAssignment } from '../../../../store/questionnaireAssignmentsSlice';
import PreviewSessionAnswers from './PreviewSessionAnswers';
import { getQuestionnaireSessionUpdatedDate } from '../../../../service/timeAndDate';
import InUseModal from './InUseModal';
import Unlocked from '../../../../assets/icons/LockGray.svg';
import Locked from '../../../../assets/icons/LockBlue.svg';

const getStatusText = (assignment: QuestionnaireAssignment) => {
  if (!assignment.questionnaireSession) {
    return 'Not Started';
  }
  // all answers are complete
  if (assignment.questionnaireSession.answers
    .filter((answer: Answer) => answer.answer).length
    === assignment.questionnaireSession.answers.length) {
    return 'Complete';
  }
  return 'In Progress';
};

const getCompleteQuestionnaireAssignments = (assignments: QuestionnaireAssignment[]) => {
  const count = assignments.filter((assignment) => assignment.questionnaireSession
    && (assignment.questionnaireSession.answers || []).length
    === assignment.questionnaireSession.answers
      .filter((answer: Answer) => answer.answer.length > 0).length)
    .length;
  return count;
};

interface Props {
  id?: number | null;
  questionnaire: Questionnaire;
  newTitle?: string | null;
  viewedNotificationId?: number | null;
  linkStyle?: string;
  questionnaireAssignments: QuestionnaireAssignment[];
}

const QuestionnaireIntroModal: React.FC<Props> = (props: Props) => {
  const {
    // id,
    questionnaire,
    newTitle,
    viewedNotificationId,
    questionnaireAssignments,
    linkStyle,
  } = props;
  const currentState = useAppSelector((state) => ({
    questionnaire: state.questionnaire,
    questionnaireAssignments: state.questionnaireAssignments,
    user: state.user,
  }));
  const [show, setShow] = useState<boolean>(false);
  const [showMultipleMembers, setShowMultipleMembers] = useState<boolean>(false);
  const [showInUse, setShowInUse] = useState<boolean>(false);
  const [showInaccessibleMember, setShowInaccessibleMember] = useState<boolean>(false);
  const [
    questionnaireAssignmentGroups,
    setQuestionnaireAssignmentGroups,
  ] = useState<QuestionnaireAssignment[][]>([]);
  const [checked, setChecked] = useState<QuestionnaireAssignment[]>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowMultipleMembers = () => setShowMultipleMembers(true);
  const handleMultipleMembersClose = () => setShowMultipleMembers(false);
  const navigateToQuestionnaire = (id: number) => {
    history.push(`/questionnaires/begin/${id}`);
  };
  const continueToQuestionnaire = async (
    startQuestionnaireAssignments: QuestionnaireAssignment[],
  ) => {
    if (viewedNotificationId) {
      await dispatch(updateNotificationAsync({
        id: viewedNotificationId,
        body: {
          viewed: true,
        },
      }));
      dispatch(getCurrentNotificationsAsync());
    }

    // error handling if selecting questionnaires with more than 1 session
    const questionnaireSessionIds = new Set(
      startQuestionnaireAssignments.map((assignment) => assignment.questionnaireSession?.id),
    );
    if (questionnaireSessionIds.size > 1) {
      return;
    }
    if (!startQuestionnaireAssignments[0].questionnaireSession) {
      const startResponse = await dispatch(
        postStartQuestionnaireSessionAsync(
          startQuestionnaireAssignments.map((assignment) => assignment.id),
        ),
      );
      await dispatch(getQuestionnaireAsync(questionnaire.id));
      history.push(`/questionnaires/begin/${startResponse.payload.id}`);
    } else {
      // show an error if trying to jump into a questionnaire session
      // with members you are not assigned to
      const startQuestionAssignmentIds = startQuestionnaireAssignments[0]
        .questionnaireSession.questionnaireAssignments
        .map((questionnaireSessionQa: any) => questionnaireSessionQa.id);
      const currentQuestionnaireAssignmentIds = currentState.questionnaireAssignments
        .currentQuestionnaireAssignments
        .map((userQa: any) => userQa.id);
      if (
        !startQuestionAssignmentIds
          .every(
            (questionnaireSessionQa: any) => currentQuestionnaireAssignmentIds
              .indexOf(questionnaireSessionQa) !== -1,
          )
      ) {
        setShowInaccessibleMember(true);
        return;
      }

      const currentQuestionnaireSession = await dispatch(getCurrentQuestionnaireSessionAsync(
        startQuestionnaireAssignments[0].questionnaireSession.id,
      ));
      await dispatch(getQuestionnaireAsync(questionnaire.id));
      // if current questionnaire doesn't have a lockedByUser value
      if (!currentQuestionnaireSession.payload?.lockedByUser) {
        await dispatch(updateQuestionnaireSessionAsync(
          startQuestionnaireAssignments[0].questionnaireSession.id,
        ));
        navigateToQuestionnaire(startQuestionnaireAssignments[0].questionnaireSession.id);
      } else if (currentQuestionnaireSession.payload?.lockedByUser?.id
        !== currentState.user?.currentUser?.id) {
        // if current questionnaire is locked by a different user
        setShowInUse(true);
      } else {
        // current questionnaire is locked by the current user so just continue to the session
        navigateToQuestionnaire(startQuestionnaireAssignments[0].questionnaireSession.id);
      }
    }
  };

  useEffect(() => {
    const groups = questionnaireAssignments
      .reduce((prev: any, curr: any) => {
        const questionnaireSessionId: string = (curr.questionnaireSession?.id || 0).toString(10);
        (prev[questionnaireSessionId] = prev[questionnaireSessionId] || []).push(curr);
        return prev;
      }, {});
    const groupArrays = Object.keys(groups).map((key: string) => groups[key]);
    setQuestionnaireAssignmentGroups(groupArrays);
  }, []);

  return (
    <>
      <Button
        className={`${viewedNotificationId && 'button-link px-0'}${linkStyle === 'link' && 'no-button button-link text-left p-0'}`}
        variant="primary"
        onClick={questionnaireAssignments.length > 1 ? handleShowMultipleMembers : handleShow}
      >
        { newTitle || 'Continue to Questionnaire' }
      </Button>

      <Modal show={showMultipleMembers} onHide={handleMultipleMembersClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Select Members</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`This questionnaire applies to ${
              questionnaireAssignments.length
            } of the members you are associated with.`}
          </p>
          <p>
            {`You have complete ${
              getCompleteQuestionnaireAssignments(questionnaireAssignments)
            } of the ${
              questionnaireAssignments.length
            } member questionnaires.`}
          </p>
          <p>
            Members can be grouped to fill out a single questionnaire for all
            selected members. Grouping will apply to this questionnaire only
            and cannot be changed once the questionnaire has been started
          </p>
          <h4>Which Member(s) do you want to enter answers for?</h4>
          <Table bordered className="member-select-table">
            <thead>
              <tr>
                <th> </th>
                <th>Member</th>
                <th>Status</th>
                <th>Last Update</th>
                <th>In Use</th>
              </tr>
            </thead>
            <tbody>
              {questionnaireAssignmentGroups.map((assignmentGroup) => (
                <>
                  {
                    assignmentGroup.map((assignment) => {
                      let rowClass = '';
                      if (assignment.questionnaireSession) {
                        // somewhere in a group
                        rowClass = 'group-row';
                        // start of group
                        if (assignmentGroup.indexOf(assignment) === 0) {
                          rowClass += ' group-top';
                        }
                        // end of group
                        if (assignmentGroup.indexOf(assignment) === assignmentGroup.length - 1) {
                          rowClass += ' group-bottom';
                        }
                      }

                      return (
                        <tr
                          key={assignment.id}
                          className={`${
                            rowClass
                          }${
                            getStatusText(assignment) === 'Complete' ? ' text-muted' : ''
                          }`}
                        >
                          <td>
                            <Form.Check
                              checked={
                                checked.map(
                                  (checkedAssignment) => checkedAssignment.id,
                                ).indexOf(assignment.id) !== -1
                              }
                              disabled={
                                (assignmentGroup.length !== 1
                                && assignmentGroup[0].questionnaireSession
                                && assignmentGroup.indexOf(assignment) !== 0)
                                || getStatusText(assignment) === 'Complete'
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setChecked([...checked, assignment]);
                                } else {
                                  const existingCheckedIndex = checked
                                    .map((checkedAssigment) => checkedAssigment.id)
                                    .indexOf(assignment.id);
                                  const newChecked = checked.slice();
                                  newChecked.splice(existingCheckedIndex, 1);
                                  setChecked(newChecked);
                                }
                              }}
                            />
                          </td>
                          <td>{assignment.member.memberName}</td>
                          <td>{getStatusText(assignment)}</td>
                          <td>
                            {`${
                              assignment.questionnaireSession?.lockedByUser?.firstName || ''
                            } ${
                              assignment.questionnaireSession?.lockedByUser?.lastName || ''
                            } ${
                              getQuestionnaireSessionUpdatedDate(
                                assignment.questionnaireSession?.answers || [],
                              )
                            }`}
                          </td>
                          <td>
                            {assignment.questionnaireSession?.lockedByUser
                              ? <img src={Locked} alt="Locked" />
                              : <img src={Unlocked} alt="Unlocked" />}
                          </td>
                        </tr>
                      );
                    })
                  }
                </>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <PreviewSessionAnswers
            data={questionnaire}
            assignment={null}
            linkStyle="secondary-button"
          >
            Preview Questionnaire
          </PreviewSessionAnswers>
          <Button variant="primary" onClick={() => continueToQuestionnaire(checked)}>
            Continue to Questionnaire
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Introduction</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {questionnaire.questionnaireEngineExplanation}
          </p>
          {
            questionnaire.questionnaireEngineVideo
            && (
              <div className="responsive-iframe_wrapper">
                <iframe src={questionnaire.questionnaireEngineVideo.includes('https://www.youtube.com/embed/') ? questionnaire.questionnaireEngineVideo : `https://www.youtube.com/embed/${questionnaire.questionnaireEngineVideo}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => continueToQuestionnaire([questionnaireAssignments[0]])}
          >
            Continue to Questionnaire
          </Button>
        </Modal.Footer>
      </Modal>
      <InUseModal
        show={showInUse}
        id={currentState.questionnaire?.currentQuestionnaireSession?.id}
        name={`${
          currentState.questionnaire?.currentQuestionnaireSession?.lockedByUser?.firstName
        } ${
          currentState.questionnaire?.currentQuestionnaireSession?.lockedByUser?.lastName
        }`}
        handleClose={() => setShowInUse(false)}
        handleAccept={navigateToQuestionnaire}
      />
      <Modal show={showInaccessibleMember} onHide={() => setShowInaccessibleMember(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Introduction</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{'This questionnaire session contains an assignmnet that you don\'t have acces to.'}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuestionnaireIntroModal;

QuestionnaireIntroModal.defaultProps = {
  id: null,
  newTitle: null,
  viewedNotificationId: null,
  linkStyle: undefined,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteMultipleAlerts,
  getAllIncomingAlerts,
  getOneIncomingAlert,
} from '../api/incomingAlertAPI';

export interface IncomingSecurityAlert {
  id: number;
  guid: string;
  title: string;
  content: string;
  source: string;
  publishDate?: Date;
  link: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface IncomingAlertState {
  incomingSecurityAlerts: IncomingSecurityAlert[] | null;
  viewIncomingAlert: IncomingSecurityAlert | null;
  securityAlertDraft: IncomingSecurityAlert | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: IncomingAlertState = {
  incomingSecurityAlerts: [],
  viewIncomingAlert: null,
  securityAlertDraft: null,
  status: 'idle',
  error: null,
};

const getAllIncomingAlertsAsync = createAsyncThunk(
  'incomingAlerts/getAllIncomingAlerts',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await getAllIncomingAlerts();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneIncomingAlertAsync = createAsyncThunk(
  'incomingAlerts/GetOneIncomingAlert',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneIncomingAlert(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const viewIncomingAlertAsync = createAsyncThunk(
  'incomingAlerts/ViewIncomingAlert',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneIncomingAlert(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleAlertsAsync = createAsyncThunk(
  'incomingAlerts/deleteMultipleAlerts',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleAlerts(ids);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const setSelectedIncomingAlertNull = createAsyncThunk(
  'incomingAlerts/nullDraftAlert',
  () => null,
);

// const nullSelectedAlert = () => (() => { type: 'NULL_ALERT' });

export const incomingAlertsSlice = createSlice({
  name: 'incomingAlerts',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllIncomingAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllIncomingAlertsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.incomingSecurityAlerts = action.payload;
        state.error = null;
      })
      .addCase(getAllIncomingAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.incomingSecurityAlerts = [];
        state.error = action.payload;
      })
      .addCase(getOneIncomingAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneIncomingAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.securityAlertDraft = action.payload;
        state.error = null;
      })
      .addCase(getOneIncomingAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.securityAlertDraft = null;
        state.error = action.payload;
      })
      .addCase(deleteMultipleAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleAlertsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultipleAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(setSelectedIncomingAlertNull.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setSelectedIncomingAlertNull.fulfilled, (state) => {
        state.status = 'idle';
        state.securityAlertDraft = null;
        state.error = null;
      })
      .addCase(viewIncomingAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(viewIncomingAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.viewIncomingAlert = action.payload;
        state.error = null;
      })
      .addCase(viewIncomingAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.viewIncomingAlert = null;
        state.error = action.payload;
      });
  },
});

export default incomingAlertsSlice.reducer;

export {
  getAllIncomingAlertsAsync,
  getOneIncomingAlertAsync,
  deleteMultipleAlertsAsync,
  setSelectedIncomingAlertNull,
  viewIncomingAlertAsync,
};

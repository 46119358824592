import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import cyberThreat from '../../../../assets/icons/cyberthreat.svg';

interface Props { }

const CyberThreatCard: React.FC<Props> = () => (
  <Card>
    <Card.Body>
      <Card.Title>
        <h2 className="text-center">
          Cyber Threat Alert
        </h2>
      </Card.Title>
      <div className="d-flex justify-content-between align-items-center flex-column">
        <div className="m-3">
          <img src={cyberThreat} alt="How-To Icon" />
        </div>
        <div className="m-3">
          <p>
            Customize and distribute information about current cybersecurity
            threats based on incoming feeds from trusted sources.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column button-box">
        <Link to="/threat-alerts/admin">
          <Button className="m-2" variant="primary"> Create &amp; Manage</Button>
        </Link>
      </div>
    </Card.Body>
  </Card>
);

export default CyberThreatCard;

import React from 'react';
import {
  Page,
  Text,
  Image,
  Font,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import DOMPurify from 'dompurify';
import pdfBackgroundLogo from '../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../assets/bkg-diamonds.png';
import { PrimexSecurityAlert } from '../../../../store/primexAlertsSlice';

interface Props {
 threatAlert: PrimexSecurityAlert;
}

Font.register({ family: 'lato', src: 'https://fonts.googleapis.com/css2?family=Lato&display=swap' });

const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 75,
    fontFamily: 'lato',
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontSize: '29px',
    color: '#00263E',
  },
  h3: {
    fontSize: '22px',
    color: '#00263E',
  },
  boldText: {
    fontWeight: 600,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    right: 0,
    width: '110%',
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  fontBody: {
    fontFamily: 'lato',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  lineHeight: {
    marginTop: 5,
    marginBottom: 5,
  },
});

const PdfCreator: React.FC<Props> = ({ threatAlert }) => {
  const {
    content,
    recommendations,
    title,
    threatLevel,
    source,
    technologiesImpacted,
  } = threatAlert;
  const cleanDescription = DOMPurify.sanitize(content);
  const cleanRecommendations = DOMPurify.sanitize(recommendations);

  const stripHtml = (contentBlock: string) => {
    const regOpenPTag = new RegExp(/<p[^>]*>/g);
    const allTags = new RegExp(/<[^>]+>/g);
    const formattedBlock = contentBlock.split(regOpenPTag);
    return (
      <View style={[styles.column]}>
        {
          formattedBlock.map((string) => {
            const cleanedString = string.replaceAll(allTags, '').replaceAll('&nbsp;', ' ');
            return (
              <Text style={[styles.lineHeight]}>{cleanedString}</Text>
            );
          })
        }
      </View>
    );
  };
  return (
    <Document>
      <Page size="A4" style={styles.page} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View style={[styles.fontBody]}>
          <View style={[styles.inline, styles.section]}>
            <Text>
              Severity Level:
            </Text>
            <Text style={[styles.boldText, { marginLeft: 10 }]}>{threatLevel || ''}</Text>
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.title]}>{title || ''}</Text>
          </View>
          {
            source
              ? (
                <View style={[styles.section]}>
                  <Text style={[styles.h3]}>Original Source</Text>
                  <Text>{source || ''}</Text>
                </View>
              ) : null
          }
          {
            technologiesImpacted
              ? (
                <View style={[styles.section]}>
                  <Text style={[styles.h3]}>Technologies Impacted</Text>
                  <Text>{technologiesImpacted || ''}</Text>
                </View>
              ) : null
          }
          <View style={[styles.section]}>
            <Text style={[styles.h3]}>Description</Text>
            {
              cleanDescription
                ? stripHtml(cleanDescription) : null
            }
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.h3]}>Recommendations</Text>
            {
              cleanRecommendations
                ? stripHtml(cleanRecommendations) : null
            }
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};

export default PdfCreator;

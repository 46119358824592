import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  loggedIn: boolean;
  email: string;
  accessToken: string;
}

interface LoginPayload {
  email: string;
  accessToken: string;
}

const initialState = {
  loggedIn: false,
  email: '',
  accessToken: '',
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.loggedIn = true;
      state.email = action.payload.email;
      state.accessToken = action.payload.accessToken;
    },
    logout(state) {
      state.loggedIn = false;
      state.email = '';
      state.accessToken = '';
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Alert,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import deleteIcon from '../../../../assets/icons/Delete.svg';
import upload from '../../../../assets/icons/Upload.svg';
import checkCircle from '../../../../assets/icons/check-circle-fill.svg';
import { useAppDispatch } from '../../../../store/hooks';
import { getAllQuestionnairesAsync, postQuestionnaireAsync, updateQuestionnaireAsync } from '../../../../store/questionnaireSlice';
import QuestionnairePreview from './modalComponents/questionnairePreview';
import QuestionnaireDistribution from './modalComponents/questionnaireDistribution';
import { createQuestionnaireAssignmentsAsync } from '../../../../store/questionnaireAssignmentsSlice';
import { DistributionUniversal, QuestionnaireDistributionModel } from '../../../../models/distributionModels';
import { QuestionnaireErrors } from '../../../../models/questionnaireErrorModel';
import QuestionnaireErrorDetails from '../QuestionnaireErrorDetails';

interface File {
  name: string;
  size: number;
  lastModified: number;
  webkitRelativePath: string;
  type: string;
  arrayBuffer: any;
  slice: any;
  stream: any;
  text: any;
}

interface Questionnaire {
  id: number;
  name: string;
  questionnaireEngineTemplateId: number;
  questionnaireEngineName: string;
  questionnaireEngineVideo: string;
  questionnaireEngineExplanation: string;
  questionnaireSessions: any[];
  questions: any[];
}

interface Props {
  questionnaireList: Questionnaire[];
  currentQuestionnaire: Questionnaire | null;
}

const UploadQuestionnaire: React.FC<Props> = (props: Props) => {
  const { questionnaireList, currentQuestionnaire } = props;
  const [show, setShow] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [title, setTitle] = useState<string | null>('');
  const [titleError, setTitleError] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [distributionState, setDistributionState] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [uploadErrorDetails, setUploadErrorDetails] = useState<QuestionnaireErrors>();
  const [loading, setLoading] = useState(false);

  const [model, setModel] = useState<DistributionUniversal>();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setFile(undefined);
    setTitle(null);
    setSuccessfulUpload(false);
    setPreview(false);
    setDistributionState(false);
    setModel(undefined);
    setUploadErrorDetails(undefined);
    setUploadErrorMessage('');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const submitUpload = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (file && title) {
      questionnaireList.forEach((questionnaire) => {
        if (questionnaire.name.toLowerCase().replace(/\s/g, '') === title.toLowerCase().replace(/\s/g, '')) {
          setTitleError(true);
        } else {
          setTitleError(false);
        }
      });
      if (!titleError) {
        const response = await dispatch(
          postQuestionnaireAsync({ formUpload: file, formTitle: title }),
        );
        if (response) {
          if (response.meta.requestStatus === 'rejected') {
            setUploadErrorMessage(response.payload.data || '');
          } else if (response.payload.error) {
            setUploadErrorDetails(response.payload);
          } else {
            setUploadErrorMessage('');
            setSuccessfulUpload(true);
          }
          setLoading(false);
        }
        dispatch(getAllQuestionnairesAsync());
      }
    } else if (file && !title) {
      setTitleError(true);
      setFileError(false);
      setLoading(false);
    } else if (!file && title) {
      setFileError(true);
      setTitleError(false);
      setLoading(false);
    } else {
      setTitleError(true);
      setFileError(true);
      setLoading(false);
    }
  };

  const submitDistribution = async () => {
    if (model && currentQuestionnaire) {
      const dueDateCheck: number = model?.dueDate?.getTime() || 0;
      const distModel: QuestionnaireDistributionModel = {
        ...model, questionnaireId: currentQuestionnaire.id,
      };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        // eslint-disable-next-line no-self-compare
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberSubTypes = null;
          distModel.specificMemberMaturityLevels = null;
        }
      } else {
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        distModel.allMembers = false;
        distModel.specificMemberIds = null;
        distModel.specificMemberTypes = null;
        distModel.specificMemberSubTypes = null;
        distModel.specificMemberMaturityLevels = null;
      }
      await dispatch(updateQuestionnaireAsync({
        id: currentQuestionnaire.id,
        questionnaireBody: {
          dueDate: distModel.dueDate,
        },
      }));
      await dispatch(createQuestionnaireAssignmentsAsync(distModel));
      dispatch(getAllQuestionnairesAsync());
      handleClose();
    }
  };

  return (
    <>
      <Button className="ml-4 mb-4" variant="primary" onClick={handleShow}>
        Upload Questionnaire
      </Button>

      <Modal show={show} onHide={handleClose} size={preview ? 'xl' : undefined}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="mb-0">
              {!successfulUpload && !preview && 'Upload Questionnaire'}
              {successfulUpload && !preview && 'Questionnaire Uploaded'}
              {successfulUpload && preview && 'Preview Questionnaire'}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !successfulUpload
            && !preview
            && !distributionState
            && (
              <>
                <Form id="file-upload-form" className="d-flex flex-column" onSubmit={submitUpload}>
                  <Form.Group controlId="fileTitle">
                    <Form.Label>Title</Form.Label>
                    <InputGroup>
                      <Form.Control type="text" placeholder="Enter a descriptive title for this questionnaire" required isInvalid={titleError} onChange={(e: any) => setTitle(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        Please enter a unique title.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formFile" className="input-container">
                    {
                      !file
                        ? (
                          <>
                            <img src={upload} alt="Upload icon" className="mb-3" />
                            <span>
                              Drag and drop file here or
                              <span className="browse"> browse</span>
                              .
                            </span>
                            <Form.File
                              className="file-upload"
                              multiple={false}
                              type="file"
                              feedback="Please attach a valid .xlsx file."
                              isInvalid={fileError}
                              onChange={(e: any) => {
                                setUploadErrorMessage('');
                                setUploadErrorDetails(undefined);
                                setFile(e.target.files[0]);
                                if (file) {
                                  setFileError(false);
                                }
                              }}
                              accept=".xlsx"
                            />
                          </>
                        )
                        : (
                          <div className="d-flex align-items-center">
                            <Button className="delete-file" onClick={() => setFile(undefined)}>
                              <img src={deleteIcon} alt="Delete uploaded Excel file Icon" />
                            </Button>
                            <div className="d-flex flex-column ml-3">
                              <span>{file.name}</span>
                              <span>
                                {file.size}
                                {' '}
                                KB
                              </span>
                            </div>
                          </div>
                        )
                    }
                  </Form.Group>
                </Form>
              </>
            )
          }
          {
            successfulUpload
            && currentQuestionnaire
            && !preview
            && !distributionState
            && (
              <div className="uploaded-modal p-2">
                <div>
                  <img className="uploaded-icon" src={checkCircle} alt="Check circle icon" />
                  <span className="ml-3">Your questionnaire was successfully uploaded.</span>
                </div>
                <div className="mt-3">
                  <h2>{currentQuestionnaire.name}</h2>
                  <p>
                    {currentQuestionnaire.questions.length}
                    {' '}
                    Questions
                  </p>
                </div>
              </div>
            )
          }
          {
            successfulUpload
            && currentQuestionnaire
            && preview
            && !distributionState
            && (
              <div>
                <QuestionnairePreview
                  data={currentQuestionnaire}
                />
              </div>
            )
          }
          {
            successfulUpload
            && preview
            && distributionState
            && (
              <div>
                <QuestionnaireDistribution
                  distModel={{ model, setModel }}
                />
              </div>
            )
          }
          {(uploadErrorMessage || '').length > 0 ? (
            <Alert variant="danger">
              {uploadErrorMessage}
            </Alert>
          ) : null}
          {
            uploadErrorDetails
            && <QuestionnaireErrorDetails errorDetails={uploadErrorDetails} />
          }
        </Modal.Body>
        <Modal.Footer>
          {
            !successfulUpload
            && !preview
            && !distributionState
            && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button disabled={loading} variant="primary" type="submit" form="file-upload-form">
                  Begin Upload
                  { loading && <Spinner animation="border" size="sm" variant="light" className="ml-1" /> }
                </Button>
              </>
            )
          }
          {
            successfulUpload
            && !preview
            && !distributionState
            && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={() => setPreview(true)}>
                  Preview Questionnaire
                </Button>
              </>
            )
          }
          {
            successfulUpload
            && preview
            && !distributionState
            && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={() => setDistributionState(true)}>
                  Collect Responses
                </Button>
              </>
            )
          }
          {
            successfulUpload
            && preview
            && distributionState
            && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                {model?.userOnly
                  && (
                    <>
                      {model.specificUserId
                       && model.dueDate
                        ? (
                          <Button variant="primary" onClick={submitDistribution}>
                            Assign
                          </Button>
                        ) : (
                          <Button disabled variant="primary">
                            Assign
                          </Button>
                        )}
                    </>
                  )}
                {!model?.userOnly
                  && (
                    <Button disabled={!model?.dueDate} variant="primary" onClick={submitDistribution}>
                      Assign
                    </Button>
                  )}
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadQuestionnaire;

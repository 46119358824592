import React, { useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import InfoModal from './infoModal';
import {
  createUpdateAsync,
  getOneClaimAsync,
  IrClaim,
  IrUpdateComment,
} from '../../../../store/irClaimSlice';
import { dateAndTime } from '../../../../service/timeAndDate';
// import { User } from '../../../../store/userSlice';

interface Props {
  claim: IrClaim;
  updates: IrUpdateComment[];
}

const Updates: React.FC<Props> = ({ claim, updates }) => {
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState('');
  const { id: claimId } = claim;
  const dispatch = useDispatch();
  const submitComment = async () => {
    const commentObj = ({
      comment,
      irClaimId: claimId,
    });
    await dispatch(createUpdateAsync(commentObj));
    await dispatch(getOneClaimAsync(claimId));
    await setComment('');
  };
  return (
    <div className="mt-4">
      {/* TODO: number of updates */}
      <div className="updates-attention d-flex mb-3">
        <FontAwesomeIcon className="mr-3" size="lg" icon={faExclamationCircle} />
        <p>
          <span>Attention: &nbsp;</span>
          Comments will be visible to all users associated with this claim.
          Do not use this page to write or paste any private containing
          restricted information that should not be shared with the member or external parties.
          <br />
          This area of the cyber portal contains confidential information and is
          exempt from NH RSA 91-A.
          <FontAwesomeIcon className="ml-2 info-icon" onClick={() => setShow(true)} size="sm" icon={faInfoCircle} />
        </p>
      </div>
      <h3>
        Updates&nbsp;
        <span className="number-font">
          (
          {updates.length}
          )
        </span>
      </h3>
      <div className="mt-4">
        <Form.Control
          as="textarea"
          value={comment}
          rows={2}
          placeholder="Add a comment"
          onChange={(e) => setComment(e.currentTarget.value)}
        />
        <div className="mt-3 d-flex justify-content-end">
          <Button
            variant="secondary"
            type="button"
            onClick={() => submitComment()}
            disabled={!comment}
          >
            Submit
          </Button>
        </div>
      </div>
      <div>
        <div className="updates-comments">
          {(updates.length > 0)
            && updates.map((update) => (
              <Table bordered className="col-12 mt-5">
                <thead className="updates-comment-title">
                  <tr>
                    <th className="d-flex justify-content-between">
                      <span>
                        {update.author.role === 400
                          ? (`${update.author.firstName} ${update.author.lastName} (#${update.author.id}) - ATOM`)
                          : (`${update.author.firstName} ${update.author.lastName} - Primex`)}
                      </span>
                      <span>{dateAndTime(update.createdAt)}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {update.comment}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))}
        </div>
      </div>
      <InfoModal show={show} setShow={setShow} />
    </div>
  );
};

export default Updates;

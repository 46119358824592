/* eslint-disable array-callback-return */
import React from 'react';
import {
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Complete from '../../../assets/icons/check-circle-fill.svg';
import InProgress from '../../../assets/icons/InProgressCheck.svg';
import NotStarted from '../../../assets/icons/notStartedCheck.svg';
import { dateOnly } from '../../../service/timeAndDate';
import { ChecklistAssignment } from '../../../store/checklistAssignmentSlice';
import { Checklist } from '../../../store/checklistSlice';
import MultiMemberChecklistModal from '../checklists/modals/MultiMemberChecklistModal';

interface Props {
  LatestChecklists: ChecklistAssignment[];
}

interface GroupedChecklistAssignment {
  checklistAssignments: ChecklistAssignment[];
  checklist: Checklist;
  checklistTitle: string;
  createdAt?: Date;
}

const ChecklistCard: React.FC<Props> = ({ LatestChecklists }) => {
  const consolidateChecklists = () => {
    const sortedChecklistArray: GroupedChecklistAssignment[] = [];
    if (LatestChecklists) {
      const newChecklistArray = LatestChecklists.slice();
      newChecklistArray.map((checklistAssignment) => {
        if (!sortedChecklistArray.find(
          (sortedAssignment) => sortedAssignment.checklist.id === checklistAssignment.checklist.id,
        )
        ) {
          const newStructure: GroupedChecklistAssignment = {
            checklistAssignments: newChecklistArray.filter(
              (assign) => assign.checklist.id === checklistAssignment.checklist.id,
            ),
            checklist: checklistAssignment.checklist,
            checklistTitle: checklistAssignment.checklist.title,
          };
          if (newStructure.checklistAssignments.length > 0) {
            newStructure.createdAt = newStructure
              .checklistAssignments
              .slice()
              .sort(
                (a, b) => (a.createdAt.valueOf() < b.createdAt.valueOf() ? -1 : 1),
              )[0].createdAt;
            sortedChecklistArray.push(newStructure);
          }
        }
      });
    }
    return sortedChecklistArray;
  };

  const consolidatedChecklists = consolidateChecklists();

  const returnLevelOfComplete = (assignment: ChecklistAssignment) => {
    if (assignment.completed && assignment.completedDate) {
      return (
        <>
          <img className="mr-2" src={Complete} alt="Complete Icon" />
          <p className="m-0">Complete</p>
        </>
      );
    }
    if (
      assignment.responses
      && assignment.responses.length > 0
      && assignment.responses.every((response) => !response.itemCompleted)
    ) {
      return (
        <>
          <img className="mr-2" src={NotStarted} alt="Complete Icon" />
          <p className="m-0">Not Started</p>
        </>
      );
    }
    return (
      <>
        <img className="mr-2" src={InProgress} alt="Complete Icon" />
        <p className="m-0">In Progress</p>
      </>
    );
  };

  if (consolidatedChecklists && consolidatedChecklists.length > 0) {
    const mostRecentChecklist = consolidatedChecklists[0];
    if (
      mostRecentChecklist.checklistAssignments
      && mostRecentChecklist.checklistAssignments.length === 1
    ) {
      const LatestChecklist = mostRecentChecklist.checklistAssignments[0];
      return (
        <div className="card-section checklist-dash-card">
          <Card>
            <Card.Body>
              <Card.Title>
                <h2 className="mb-0">Latest Checklist</h2>
                {LatestChecklist.checklist.dueDate
                  && (
                    <h5 className="bold mt-0">
                      {`Due Date: ${dateOnly(LatestChecklist.checklist.dueDate)}`}
                    </h5>
                  )}
              </Card.Title>
              <Card.Subtitle>
                <h4 className="bold">
                  {LatestChecklist.checklist.title}
                </h4>
              </Card.Subtitle>
              <p>
                {LatestChecklist.checklist.summary}
              </p>
              <div className="d-flex flex-row">
                <Link to={`/checklists/${LatestChecklist.id}`}>
                  <Button variant="primary"> Continue </Button>
                </Link>
                <Link className="mt-2 ml-2" to="/checklists">
                  View all &gt;&gt;
                </Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
    if (
      mostRecentChecklist.checklistAssignments
      && mostRecentChecklist.checklistAssignments.length > 1
    ) {
      return (
        <div className="card-section checklist-dash-card">
          <Card>
            <Card.Body>
              <Card.Title>
                <h2 className="mb-0">Latest Checklist</h2>
                {mostRecentChecklist.checklist.dueDate
                  && (
                    <h5 className="bold mt-0">
                      {`Due Date: ${dateOnly(mostRecentChecklist.checklist.dueDate)}`}
                    </h5>
                  )}
              </Card.Title>
              <Card.Subtitle>
                <h4 className="bold">
                  {mostRecentChecklist.checklist.title}
                </h4>
              </Card.Subtitle>
              <div className="d-flex flow-row justify-content-between">
                <p>
                  {mostRecentChecklist.checklist.summary}
                </p>
              </div>
              <div className="d-flex flex-row">
                <div>
                  <MultiMemberChecklistModal
                    dashboard
                    checklistAssignments={mostRecentChecklist.checklistAssignments}
                    checklist={mostRecentChecklist.checklist}
                  >
                    Continue
                  </MultiMemberChecklistModal>
                  <Link className="mt-2 ml-2" to="/checklists">
                    View all &gt;&gt;
                  </Link>
                </div>
                <Dropdown className="questionnaire-multi-dropdown align-self-center flex-grow-1 text-right">
                  <Dropdown.Toggle className="p-0 no-button">
                    <div className="d-flex">
                      <p className="m-0 mr-3 d-flex">
                        Multiple
                      </p>
                      <FontAwesomeIcon icon={faSortDown} />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      mostRecentChecklist.checklistAssignments.map((assignment) => (
                        <Dropdown.Item bsPrefix="custom-dropdown" as="div" className="no-underline d-flex justify-content-between">
                          <div className="col-7 p-0">
                            {assignment.member.memberName}
                          </div>
                          <div className="d-flex col-5 ml-3">
                            {
                              returnLevelOfComplete(assignment)
                            }
                          </div>
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  }
  return (
    <div />
  );
};

export default ChecklistCard;

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { QuestionnaireAssignment, QuestionnaireAssignmentUser } from '../../../../store/questionnaireAssignmentsSlice';
import { Questionnaire } from '../../../../store/questionnaireSlice';
import QuestionInfoModal from './QuestionInfoModal';

interface Props {
  data: Questionnaire,
  assignment: QuestionnaireAssignment | QuestionnaireAssignmentUser | null;
  children: string;
  linkStyle: 'link' | 'secondary-button';
}

interface Question {
  id: number;
  question: string;
  questionType: string;
}

interface Answer {
  id: number;
  answer: string;
  question: Question;
}

const PreviewSessionAnswers: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    data,
    children,
    assignment,
    linkStyle,
  } = props;
  const [show, setShow] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const handleClose = () => {
    setQuestionNumber(0);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const returnCurrentAnswer = () => {
    if (assignment
      && assignment.questionnaireSession
      && assignment.questionnaireSession.answers
      && assignment.questionnaireSession.answers.length > 0
    ) {
      const userAnswer: Answer = assignment.questionnaireSession.answers.find(
        (answer: Answer) => answer.question.id === data.questions[questionNumber].id,
      );
      if (userAnswer) {
        if (userAnswer.question.questionType === 'Select one') {
          return userAnswer.answer;
        }
      }
      return null;
    }
    return null;
  };

  const multiAnswer = () => {
    if (assignment
      && assignment.questionnaireSession
      && assignment.questionnaireSession.answers
      && assignment.questionnaireSession.answers.length > 0
    ) {
      const userAnswer: Answer = assignment.questionnaireSession.answers.find(
        (answer: Answer) => answer.question.id === data.questions[questionNumber].id,
      );
      if (userAnswer) {
        if (userAnswer.question.questionType === 'Select multiple') {
          const numberArray: number[] = userAnswer.answer ? JSON.parse(userAnswer.answer) : [];
          return numberArray;
        }
      }
      return [];
    }
    return [];
  };

  const categoryArray: any[] = [];
  return (
    <>
      {linkStyle === 'link' ? (
        <Button className="no-button button-link p-0" onClick={handleShow}>
          {children}
        </Button>
      ) : null}
      {linkStyle === 'secondary-button' ? (
        <Button variant="secondary" onClick={handleShow}>
          {children}
        </Button>
      ) : null}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Preview Responses</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="p-3 questionnaire-session-card">
            <div className="d-flex justify-content-between">
              <h3>{data.name}</h3>
            </div>
            <div className="d-flex justify-content-between">
              <p>
                {questionNumber}
                <span> of </span>
                {data.questions.length}
                <span> Questions Complete</span>
              </p>
            </div>
            <div className="row row-no-gutters m-0">
              <div className="col-2">
                <ul className="questionnaire-categories">
                  {
                    data.questions.map((item) => {
                      if (!categoryArray.includes(item.category)) {
                        categoryArray.push(item.category);
                        // eslint-disable-next-line max-len
                        const active = data.questions[questionNumber].category === item.category;
                        return (
                          <li className={`category-item ${active ? 'active' : ''}`}>
                            <p>{item.category}</p>
                          </li>
                        );
                      }
                      return null;
                    })
                  }
                </ul>
              </div>
              <div className="col-10 question-section">
                <div className="p-4">
                  <h5>{data.questions[questionNumber].category}</h5>
                  {
                    data.questions[questionNumber].categoryExplanation
                    && (
                      <p>{data.questions[questionNumber].categoryExplanation}</p>
                    )
                  }
                  <p>
                    <span>Question </span>
                    {data.questions
                      .filter(
                        (item) => item.category === data.questions[questionNumber].category,
                      )
                      .indexOf(data.questions[questionNumber]) + 1}
                    <span> of </span>
                    {data.questions
                      .filter(
                        (item) => item.category === data.questions[questionNumber].category,
                      ).length}
                  </p>
                  <Card className="p-4">
                    <div>
                      <h4 className="bold">{data.questions[questionNumber].question}</h4>
                      {
                        (data.questions[questionNumber].explanation
                          || data.questions[questionNumber].videoExplanation)
                        && (
                          <QuestionInfoModal data={data.questions[questionNumber]} />
                        )
                      }
                    </div>
                    <Form id="form-answer">
                      <Form.Group controlId="form.answer">
                        {
                          data.questions[questionNumber].questionType === 'Select multiple'
                            ? (
                              <Form.Group controlId="form.answer">
                                {data.questions[questionNumber].answerOption1 && <Form.Check checked={multiAnswer().includes(1)} disabled={!multiAnswer().includes(1)} type="checkbox" id="answer1" label={data.questions[questionNumber].answerOption1} />}
                                {data.questions[questionNumber].answerOption2 && <Form.Check checked={multiAnswer().includes(2)} disabled={!multiAnswer().includes(2)} type="checkbox" id="answer2" label={data.questions[questionNumber].answerOption2} />}
                                {data.questions[questionNumber].answerOption3 && <Form.Check checked={multiAnswer().includes(3)} disabled={!multiAnswer().includes(3)} type="checkbox" id="answer3" label={data.questions[questionNumber].answerOption3} />}
                                {data.questions[questionNumber].answerOption4 && <Form.Check checked={multiAnswer().includes(4)} disabled={!multiAnswer().includes(4)} type="checkbox" id="answer4" label={data.questions[questionNumber].answerOption4} />}
                              </Form.Group>
                            ) : (
                              <Form.Group controlId="form.answer">
                                {data.questions[questionNumber].answerOption1 && <Form.Check checked={returnCurrentAnswer() === data.questions[questionNumber].answerOption1} disabled={returnCurrentAnswer() !== data.questions[questionNumber].answerOption1} type="radio" id="answer1" label={data.questions[questionNumber].answerOption1} name="question 1" />}
                                {data.questions[questionNumber].answerOption2 && <Form.Check checked={returnCurrentAnswer() === data.questions[questionNumber].answerOption2} disabled={returnCurrentAnswer() !== data.questions[questionNumber].answerOption2} type="radio" id="answer2" label={data.questions[questionNumber].answerOption2} name="question 1" />}
                                {data.questions[questionNumber].answerOption3 && <Form.Check checked={returnCurrentAnswer() === data.questions[questionNumber].answerOption3} disabled={returnCurrentAnswer() !== data.questions[questionNumber].answerOption3} type="radio" id="answer3" label={data.questions[questionNumber].answerOption3} name="question 1" />}
                                {data.questions[questionNumber].answerOption4 && <Form.Check checked={returnCurrentAnswer() === data.questions[questionNumber].answerOption4} disabled={returnCurrentAnswer() !== data.questions[questionNumber].answerOption4} type="radio" id="answer4" label={data.questions[questionNumber].answerOption4} name="question 1" />}
                              </Form.Group>
                            )
                        }
                      </Form.Group>
                    </Form>
                  </Card>
                  <div className="d-flex justify-content-end">
                    <div className="questionnaire-button-group">
                      <Button
                        variant="primary"
                        className="m-4"
                        disabled={questionNumber <= 0}
                        onClick={() => {
                          setQuestionNumber(questionNumber > 0 ? questionNumber - 1 : 0);
                        }}
                      >
                        <span className="buttonArrow">&lt;</span>
                        <span className="ml-2">Previous Question</span>
                      </Button>
                      {
                        questionNumber + 1 === data.questions.length
                          ? (
                            <Button variant="primary" className="m-4">
                              <span className="mr-2">Complete</span>
                              <span className="buttonArrow">&gt;</span>
                            </Button>
                          )
                          : (
                            <Button variant="primary" onClick={() => setQuestionNumber(questionNumber + 1)} className="m-4">
                              <span className="mr-2">Next Question</span>
                              <span className="buttonArrow">&gt;</span>
                            </Button>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PreviewSessionAnswers;

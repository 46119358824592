/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {
  createSystemAlertAsync,
  getOneSystemAlertAsync,
  getSystemAlertsAsync,
  SystemAlert,
} from '../../../../store/systemAlertSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dateYearMonthDay } from '../../../../service/timeAndDate';
import { getMembersAsync } from '../../../../store/memberSlice';
import { DistributionUniversal, SystemAlertDistributionModel } from '../../../../models/distributionModels';
import { createSystemAlertAssignmentsAsync } from '../../../../store/systemAlertAssignmentSlice';
import { populateMemberMaturityFilter, populateMemberSubTypeFilter } from '../../../../service/distributionHelpers';

interface Props { }

interface MemberDropdown {
  value: number;
  name: string;
}

interface SubtypeDropdown {
  value: string;
  name: string;
}

const CreateAlertContent: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const currentState = useAppSelector((state) => ({
    systemAlerts: state.systemAlerts,
    user: state.user,
    member: state.member,
  }));

  const { members } = currentState.member;
  const { users } = currentState.user;

  const [systemAlert, setSystemAlert] = useState<Partial<SystemAlert>>();
  const [distModel, setDistModel] = useState<DistributionUniversal>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getCurrentSystemAlert = async (reqId: number) => {
    const currentSystemAlert = await dispatch(getOneSystemAlertAsync(reqId));
    setSystemAlert(currentSystemAlert.payload);
    const model = currentSystemAlert.payload as SystemAlertDistributionModel;
    let memberSelection = 'allMembers';
    if (model.allMembers) {
      memberSelection = 'allMembers';
    } else if (model.specificMemberIds && model.specificMemberIds.length > 0) {
      memberSelection = 'specificMembers';
    } else if (model.specificMemberTypes && model.specificMemberTypes.length > 0) {
      memberSelection = 'selectedCriteria';
    }
    console.log({ ...distModel!, ...model, memberSelection });
    setDistModel({ ...distModel!, ...model, memberSelection });
  };

  useEffect(() => {
    if (id && !systemAlert) {
      const parseId = parseInt(id, 10);
      getCurrentSystemAlert(parseId);
    } else if (!systemAlert) {
      setSystemAlert({
        title: '',
        message: '',
        dismissible: false,
      });
    }

    dispatch(getMembersAsync());
    if (!distModel) {
      setDistModel({
        allMembers: false,
        userOnly: false,
        specificMemberIds: [],
        specificMemberTypes: null,
        specificMemberMaturityLevels: [],
        specificMemberSubTypes: [],
        primaryProgramContact: false,
        primaryIt: false,
        basicUser: false,
        specificUserJobCategories: [],
        dueDate: null,
        memberSelection: '',
        userError: false,
        dateError: false,
        memberError: false,
        selectedUserMember: null,
      });
    }
  }, []);

  const submitDistribution = (alertId: number) => {
    if (distModel && alertId) {
      const sysAlertDistDto: SystemAlertDistributionModel = {
        systemAlertId: alertId,
        ...distModel,
      };
      if (distModel.memberSelection === 'specificMembers') {
        sysAlertDistDto.allMembers = false;
        sysAlertDistDto.specificMemberTypes = null;
        sysAlertDistDto.specificMemberSubTypes = null;
      } else if (distModel.memberSelection === 'selectedCriteria') {
        sysAlertDistDto.allMembers = false;
        sysAlertDistDto.specificMemberIds = null;
      } else if (distModel.memberSelection === 'allMembers') {
        sysAlertDistDto.allMembers = true;
        sysAlertDistDto.specificMemberIds = null;
        sysAlertDistDto.specificMemberTypes = null;
        sysAlertDistDto.specificMemberSubTypes = null;
      }
      // TODO add assignment API
      dispatch(createSystemAlertAssignmentsAsync(sysAlertDistDto));
    }
  };

  const submitSystemAlert = async (publishAlert: boolean) => {
    if (publishAlert) {
      if (systemAlert
        && systemAlert.title
        && systemAlert.message
        && systemAlert.startDate
        && systemAlert.expirationDate) {
        const alertBody: Partial<SystemAlert> = {
          id: systemAlert ? systemAlert.id : undefined,
          title: systemAlert.title,
          message: DOMPurify.sanitize(systemAlert?.message),
          dismissible: systemAlert?.dismissible,
          startDate: systemAlert?.startDate,
          expirationDate: systemAlert?.expirationDate,
          draft: !publishAlert,
          publishedDate: publishAlert ? new Date() : undefined,
        };
        const submitAlert = await dispatch(createSystemAlertAsync(alertBody));
        if (submitAlert.payload.id) {
          submitDistribution(submitAlert.payload.id);
          dispatch(getSystemAlertsAsync());
          history.push('/content/manage/system-alerts');
        }
      }
    } else if (systemAlert) {
      const alertBody: Partial<SystemAlert> = {
        id: systemAlert ? systemAlert.id : undefined,
        title: systemAlert.title,
        message: systemAlert.message ? DOMPurify.sanitize(systemAlert.message) : '',
        dismissible: systemAlert.dismissible,
        startDate: systemAlert.startDate,
        expirationDate: systemAlert.expirationDate,
        draft: !publishAlert,
        publishedDate: publishAlert ? new Date() : undefined,
      };
      const submitAlert = await dispatch(createSystemAlertAsync(alertBody));
      if (submitAlert) {
        submitDistribution(submitAlert.payload.id);
        dispatch(getSystemAlertsAsync());
        history.push('/content/manage/system-alerts');
      }
    }
  };

  const setTitle = (title: string) => {
    setSystemAlert({
      ...systemAlert,
      title,
    });
  };

  const setMessage = (message: string) => {
    setSystemAlert({
      ...systemAlert,
      message,
    });
  };

  const setDismissible = (dismissible: boolean) => {
    setSystemAlert({
      ...systemAlert,
      dismissible,
    });
  };

  const setStartDate = (startDate: Date) => {
    setSystemAlert({
      ...systemAlert,
      startDate,
    });
  };

  const setExpirationDate = (expirationDate: Date) => {
    setSystemAlert({
      ...systemAlert,
      expirationDate,
    });
  };

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberIds: memberIds });
    }
  };

  const populateMaturityState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberMaturityLevels: subtypes });
    }
  };

  const populateSubtypeState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberSubTypes: subtypes });
    }
  };

  const populateMemberTypeFilter = () => {
    const memberTypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      members
        .slice()
        .map((item) => item.entityType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull)
        // eslint-disable-next-line array-callback-return
        .map((type) => {
          const option = {
            label: type,
            value: type,
          };
          memberTypeOptions.push(option);
        });
    }
    return memberTypeOptions;
  };

  const setMemberType = (array: SubtypeDropdown[]) => {
    if (distModel) {
      const specificMemberTypes: string[] = [];
      if (array && array.length > 0) {
        // eslint-disable-next-line array-callback-return
        array.map((item) => {
          specificMemberTypes.push(item.value);
        });
      }
      setDistModel({ ...distModel, specificMemberTypes });
    }
  };

  const populateCategoryFilter = () => {
    const jobCategories: { label: string, value: string }[] = [];
    if (users) {
      const userList = users.slice();
      userList
        .filter((user) => user.jobCategory)
        .sort((a, b) => ((a.jobCategory ? a.jobCategory?.toLowerCase() : 'z') < (b.jobCategory ? b.jobCategory?.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-All`)) {
              jobCategories.push({
                label: `${user.jobCategory}-All`,
                value: `category=${user.jobCategory}&jobRole=All`,
              });
            }
          }
        });
      userList
        .filter((user) => user.jobCategory && user.jobRole)
        .sort((a, b) => ((a.jobRole ? a.jobRole.toLowerCase() : 'z') < (b.jobRole ? b.jobRole.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-${user.jobRole}`)) {
              jobCategories.push({
                label: `${user.jobCategory}-${user.jobRole}`,
                value: `category=${user.jobCategory}&jobRole=${user.jobRole}`,
              });
            }
          }
        });
      return jobCategories.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    }
    return [{
      label: 'All',
      value: 'All',
    }];
  };

  const setJobCategory = (dropDownArray: SubtypeDropdown[]) => {
    if (distModel) {
      const specificUserJobCategories: string[] = [];
      if (dropDownArray && dropDownArray.length > 0) {
        dropDownArray.map((arrayItem) => {
          specificUserJobCategories.push(arrayItem.value);
        });
      }
      setDistModel({ ...distModel, specificUserJobCategories });
    }
  };

  const memberListType = useMemo(() => currentState.member.members
    .slice()
    .filter((member) => member.memberNumber !== 573)
    // eslint-disable-next-line max-len
    .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
    .map((member) => (
      {
        label: member.memberName,
        value: member.id,
      }
    )), [currentState.member.members]);

  const selectMemberList = (ids:Array<number> | null = [], selectionOnly = false) => {
    if (selectionOnly) {
      if (ids && ids.length > 0) {
        return memberListType.filter((value) => ids!.map((v) => +v).includes(value.value));
      }
      return [];
    }
    return memberListType;
  };

  return (
    <div className="row">
      <div className="col-8">
        <Card className="p-3">
          <Card.Body>
            <h2>System Alert</h2>
            <Form>
              <Form.Group controlId="title">
                <Form.Label className="mt-2">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={systemAlert?.title}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="message" className="quill-box-content">
                <Form.Label className="mt-2">Message</Form.Label>
                {systemAlert ? (
                  <ReactQuill
                    className="quill-box"
                    theme="snow"
                    value={systemAlert?.message || ''}
                    // eslint-disable-next-line no-unused-vars
                    onChange={(html, _delta, _source, _editor) => {
                      setMessage(html);
                    }}
                  />
                ) : null}
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="col-4">
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex justify-content-between mb-2">
              <Button variant="secondary" onClick={() => submitSystemAlert(false)}>Save Draft</Button>
              <Button variant="primary" onClick={() => submitSystemAlert(true)}>Publish</Button>
            </div>
            <div>
              <Form.Group controlId="dismissable">
                <Form.Label className="my-2">Display</Form.Label>
                <Form.Check
                  className="my-2"
                  checked={systemAlert?.dismissible}
                  onChange={(e) => setDismissible(e.target.checked)}
                  type="checkbox"
                  id="dismissible"
                  label="Allow users to dismiss the alert"
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="MM/DD/YYYY"
                  defaultValue={
                    systemAlert?.startDate
                      ? dateYearMonthDay(systemAlert?.startDate)
                      : undefined
                  }
                  onChange={(e) => setStartDate(new Date((e.target.value).replace(/-/g, '/')))}
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="MM/DD/YYYY"
                  defaultValue={
                    systemAlert?.expirationDate
                      ? dateYearMonthDay(systemAlert?.expirationDate)
                      : undefined
                  }
                  onChange={(e) => setExpirationDate(new Date((e.target.value).replace(/-/g, '/')))}
                />
              </Form.Group>
            </div>
            {
              distModel
              && (
                <>
                  <div>
                    <Form.Group controlId="memberType">
                      <Form.Label>Audience</Form.Label>
                      <Form.Check checked={distModel.memberSelection === 'allMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="allMembers" label="Publish to all members" name="memberSelection" />
                      <Form.Check checked={distModel.memberSelection === 'specificMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="specificMembers" label="Publish to specific members" name="memberSelection" />
                      {
                        distModel.memberSelection === 'specificMembers'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberSelectInput">
                              <Form.Label>Select Member(s)</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={selectMemberList()}
                                defaultValue={selectMemberList(distModel.specificMemberIds, true)}
                                onChange={
                                  (selectedOption: any) => populateMemberIdState(selectedOption)
                                }
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                      <Form.Check checked={distModel.memberSelection === 'selectedCriteria'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="selectedCriteria" label="Publish to members based on selected criteria" name="memberSelection" />
                      {
                        distModel.memberSelection === 'selectedCriteria'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberTypeDropdown">
                              <Form.Label>Member Type</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                defaultValue={populateMemberTypeFilter()
                                  .filter((x) => distModel.specificMemberTypes?.includes(x.value))}
                                options={populateMemberTypeFilter()}
                                onChange={(newValue: any) => setMemberType(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberMaturityDropdown">
                              <Form.Label>Member Maturity Level</Form.Label>
                              <Select
                                isDisabled={
                                  !distModel.specificMemberTypes
                                  || distModel.specificMemberTypes.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                defaultValue={
                                  populateMemberMaturityFilter(members, distModel)
                                    .filter((x) => distModel
                                      .specificMemberMaturityLevels?.includes(x.value))
                                    }
                                options={populateMemberMaturityFilter(members, distModel)}
                                onChange={(newValue: any) => populateMaturityState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>Sub-Type</Form.Label>
                              <Select
                                isDisabled={
                                  (!distModel.specificMemberTypes
                                    || distModel.specificMemberTypes.length <= 0)
                                  || !distModel.specificMemberMaturityLevels
                                  || distModel.specificMemberMaturityLevels.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                defaultValue={
                                  populateMemberSubTypeFilter(members, distModel)
                                    .filter((x) => distModel
                                      .specificMemberSubTypes?.includes(x.value))
                                    }
                                options={populateMemberSubTypeFilter(members, distModel)}
                                onChange={(newValue: any) => populateSubtypeState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>User Job Category</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                // defaultValue={distModel.specificUserJobCategories}
                                options={populateCategoryFilter()}
                                onChange={(newValue: any) => setJobCategory(newValue)}
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                    </Form.Group>
                  </div>
                  <div className="ml-4">
                    <Form.Group controlId="memberRole">
                      <Form.Label className="my-2">User Role</Form.Label>
                      <Form.Check className="my-2" checked={distModel.primaryProgramContact} onChange={(e) => setDistModel({ ...distModel, primaryProgramContact: e.target.checked })} type="checkbox" id="primaryProgramContact" label="Primary Program Contacts" />
                      <Form.Check className="my-2" checked={distModel.primaryIt} onChange={(e) => setDistModel({ ...distModel, primaryIt: e.target.checked })} type="checkbox" id="primaryIT" label="Primary IT Contacts" />
                      <Form.Check className="my-2" checked={distModel.basicUser} onChange={(e) => setDistModel({ ...distModel, basicUser: e.target.checked })} type="checkbox" id="basicUser" label="Basic Users" />
                    </Form.Group>
                  </div>
                </>
              )
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CreateAlertContent;

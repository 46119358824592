import React from 'react';
import { Notification } from '../../../../store/notificationSlice';
import Read from '../../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../../assets/icons/MessageClosed.svg';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import QuestionnaireIntroModal from '../../questionnaire/modals/QuestionnaireIntroModal';
import { useAppSelector } from '../../../../store/hooks';
import { QuestionnaireAssignment } from '../../../../store/questionnaireAssignmentsSlice';

interface Props {
  notification: Notification;
}

const QuestionnaireRow: React.FC<Props> = ({ notification }) => {
  const {
    id,
    viewed,
    questionnaireAssignment,
    title,
    type,
    dueDate,
    createdAt,
  } = notification;
  const currentState = useAppSelector((state) => ({
    questionnaireAssignments: state.questionnaireAssignments,
  }));

  if (questionnaireAssignment) {
    return (
      <tr key={`${id}-cyberAlertNotification`}>
        <td>
          <img src={viewed ? Read : Unread} alt={viewed ? 'Read icon' : 'Unread icon'} />
        </td>
        <td>
          {dateAndTime(createdAt)}
        </td>
        <td>{type}</td>
        <td>
          <QuestionnaireIntroModal
            newTitle={title}
            id={questionnaireAssignment.questionnaire.id}
            questionnaire={questionnaireAssignment.questionnaire}
            questionnaireAssignments={
              currentState.questionnaireAssignments.currentQuestionnaireAssignments
                .filter(
                  (assignment: QuestionnaireAssignment) => assignment.questionnaire.id
                      === questionnaireAssignment.questionnaire.id
                  ,
                )
            }
            viewedNotificationId={id}
          />
        </td>
        <td>{dateOnly(dueDate)}</td>
      </tr>
    );
  }
  return (
    <tr />
  );
};
export default QuestionnaireRow;

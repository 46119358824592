import React from 'react';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { IncomingSecurityAlert } from '../../../../../store/incomingAlertSlice';

interface Props {
  securityAlertDraft: IncomingSecurityAlert | null,
  dateConversion: Function,
  severity: {
    severity: string,
    setSeverity: Function,
  },
  headline: {
    headline: string,
    setHeadline: Function,
  },
  technologies: {
    technologies: string,
    setTechnologies: Function,
  },
  sourceTitle: {
    sourceTitle: string,
    setSourceTitle: Function,
  }
  originalSource: {
    originalSource: string,
    setOriginalSource: Function,
  },
  customDescription: {
    customDescription: string,
    setCustomDescription: React.Dispatch<React.SetStateAction<string>>,
  },
  customRecommendations: {
    customRecommendations: string,
    setCustomRecommendations: React.Dispatch<React.SetStateAction<string>>,
  },
}

const CreateAlertEditor: React.FC<Props> = ({
  securityAlertDraft,
  dateConversion,
  severity,
  headline,
  technologies,
  sourceTitle,
  originalSource,
  customDescription,
  customRecommendations,
}) => (
  <>
    {
      securityAlertDraft
      && (
        <div className="row">
          <div className="col-6">
            <div className="alert-box">
              {
                securityAlertDraft.source === 'NCAS'
                && (
                  <div>
                    <h1>
                      {securityAlertDraft.title}
                    </h1>
                    <div className="d-flex flex-column">
                      <h3 className="m-0">DATE(S) ISSUED:</h3>
                      <span>{dateConversion(securityAlertDraft.publishDate) || 'Unknown'}</span>
                    </div>
                  </div>
                )
              }
              <div className="parsed-content">
                {
                  parse(DOMPurify.sanitize(securityAlertDraft.content))
                }
              </div>
            </div>
          </div>
          <div className="col-6">
            <Form>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="headline">
                    <Form.Label>Headline</Form.Label>
                    <Form.Control type="text" placeholder="Enter headline" value={headline.headline} onChange={(e: any) => headline.setHeadline(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Form.Group controlId="severitySelect">
                    <Form.Label>Severity</Form.Label>
                    <Form.Control as="select" value={severity.severity} onChange={(e: any) => severity.setSeverity(e.target.value)}>
                      <option>Select</option>
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-8">
                  <Form.Group controlId="technologiesImpacted">
                    <Form.Label>Technologies Impacted</Form.Label>
                    <Form.Control type="text" placeholder="Enter technologies" value={technologies.technologies} onChange={(e: any) => technologies.setTechnologies(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="originalSource">
                    <Form.Label>Source Title</Form.Label>
                    <Form.Control type="text" placeholder="Enter source title" value={sourceTitle.sourceTitle || securityAlertDraft.source} onChange={(e: any) => sourceTitle.setSourceTitle(e.target.value)} />
                  </Form.Group>
                  <Form.Group controlId="originalSource">
                    <Form.Label>Source Link</Form.Label>
                    <Form.Control type="text" placeholder="Enter source URL" value={originalSource.originalSource || securityAlertDraft.link} onChange={(e: any) => originalSource.setOriginalSource(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <ReactQuill className="quill-box" theme="snow" value={customDescription.customDescription} onChange={customDescription.setCustomDescription} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="description">
                    <Form.Label>Recommendations</Form.Label>
                    <ReactQuill className="quill-box" theme="snow" value={customRecommendations.customRecommendations} onChange={customRecommendations.setCustomRecommendations} />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )
    }
    {
      !securityAlertDraft
      && (
        <div className="row">
          <div className="col-12">
            <Form>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="headline">
                    <Form.Label>Headline</Form.Label>
                    <Form.Control type="text" placeholder="Enter headline" value={headline.headline} onChange={(e: any) => headline.setHeadline(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Form.Group controlId="severitySelect">
                    <Form.Label>Severity</Form.Label>
                    <Form.Control as="select" value={severity.severity} onChange={(e: any) => severity.setSeverity(e.target.value)}>
                      <option>Select</option>
                      <option>Low</option>
                      <option>Medium</option>
                      <option>High</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-8">
                  <Form.Group controlId="technologiesImpacted">
                    <Form.Label>Technologies Impacted</Form.Label>
                    <Form.Control type="text" placeholder="Enter technologies" value={technologies.technologies} onChange={(e: any) => technologies.setTechnologies(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="originalSource">
                    <Form.Label>Source Title</Form.Label>
                    <Form.Control type="text" placeholder="Enter source title" value={sourceTitle.sourceTitle} onChange={(e: any) => sourceTitle.setSourceTitle(e.target.value)} />
                  </Form.Group>
                  <Form.Group controlId="originalSource">
                    <Form.Label>Source Link</Form.Label>
                    <Form.Control type="text" placeholder="Enter source URL" value={originalSource.originalSource} onChange={(e: any) => originalSource.setOriginalSource(e.target.value)} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <ReactQuill className="quill-box" theme="snow" value={customDescription.customDescription} onChange={customDescription.setCustomDescription} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="description">
                    <Form.Label>Recommendations</Form.Label>
                    <ReactQuill className="quill-box" theme="snow" value={customRecommendations.customRecommendations} onChange={customRecommendations.setCustomRecommendations} />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )
    }
  </>
);

export default CreateAlertEditor;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getChecklistAssignmentAsync } from '../../../store/checklistAssignmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ViewChecklist from '../content/Checklists/checklistComponents/ViewChecklist';

interface Props { }

const ChecklistDetail: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();

  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => ({
    checklistAssignments: state.checklistAssignments,
  }));

  useEffect(() => {
    dispatch(getChecklistAssignmentAsync(parseInt(id || '', 10)));
  }, []);

  if (!currentState.checklistAssignments.selectedAssignment) {
    return (<div />);
  }

  return (
    <>
      <ViewChecklist
        checklistAssignment={currentState.checklistAssignments.selectedAssignment}
        checklist={currentState.checklistAssignments.selectedAssignment.checklist}
      />
    </>
  );
};
export default ChecklistDetail;

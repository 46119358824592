import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';

import { HowToTopic } from '../../../../store/howToSlice';

interface Props {
  topic: HowToTopic[];
}
const HowToAutoDisplay: React.FC<Props> = ({ topic }) => {
  const [slidePosition, setSlidePosition] = useState(0);
  const [show, setShow] = useState(true);
  const [modalSelection, setModalSelection] = useState(topic);
  const selected = modalSelection[0];

  const handleNext = (id: number) => {
    setSlidePosition(0);
    setModalSelection(modalSelection.filter((select) => select.id !== id));
    localStorage.setItem(`howToId-${id}`, 'closed');
  };
  const handleClose = () => {
    modalSelection.map((selection) => localStorage.setItem(`howToId-${selection.id}`, 'closed'));
    setShow(false);
  };
  return (
    <>
      {
        selected
          && modalSelection.length ? (
            <Modal show={show} size="xl" onHide={() => handleClose()} className="how-to-modal">
              <Modal.Header closeButton className="modal-header">
                <h2 className="how-to-modal-title m-0">
                  How To:&nbsp;
                  {selected.topicTitle}
                </h2>
                {modalSelection.length > 1
                  ? (
                    <Button
                      className="d-flex align-items-center
             no-button button-link text-left p-0 ml-auto"
                      onClick={() => handleNext(selected.id)}
                    >
                      next category
                      <FontAwesomeIcon className="ml-1 next-cat-icon" icon={faAngleRight} />
                      <FontAwesomeIcon className="next-cat-icon" icon={faAngleRight} />
                    </Button>
                  ) : null}
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <h2>
                    {selected.slides[slidePosition].slideTitle}
                  </h2>
                </div>
                <div className="modal-info">
                  {
                  parse(DOMPurify.sanitize(selected.slides[slidePosition].content))
                }
                </div>
              </Modal.Body>
              <Modal.Footer className="modal-footer d-flex justify-content-between">
                <Button variant="primary" disabled={slidePosition <= 0} onClick={() => setSlidePosition(slidePosition - 1)}>
                  Previous
                </Button>
                <div>
                  {
                  selected.slides.map((item, index) => <FontAwesomeIcon className={`pagination-icon ${slidePosition === index && 'active'}`} icon={faCircle} />)
                }
                </div>
                <Button variant="primary" onClick={() => setSlidePosition(selected.slides.length - 1 <= slidePosition ? 0 : slidePosition + 1)}>
                  Next
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null
      }
    </>
  );
};

export default HowToAutoDisplay;

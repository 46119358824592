import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAllQuestionnairesAsync } from '../../../store/questionnaireSlice';
import QuestionnaireAdmin from '../../shared/questionnaire/QuestionnaireAdmin';

interface Props { }

const QuestionnairesAdmin: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    questionnaire: state.questionnaire,
    questionnaireAssignments: state.questionnaireAssignments,
  }));

  useEffect(() => {
    dispatch(getAllQuestionnairesAsync());
  }, []);

  return (
    <div className="dashboard-container">
      <div className="row row-no-gutters m-0">
        <div className="col-12 dashboard-title">
          <h2>
            Questionnaires
          </h2>
        </div>
      </div>
      <div className="row row-no-gutters m-0">
        <div className="col-12">
          {
            currentState.questionnaire.questionnaires
            && (
              <Card className="p-4">
                <QuestionnaireAdmin
                  data={currentState.questionnaire.questionnaires}
                  currentQuestionnaire={currentState.questionnaire.questionnaire}
                />
              </Card>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default QuestionnairesAdmin;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getUserRecord from '../api/trainingAPI';

export interface Training {
  id: number;
  name: string;
  trainingLink: string;
  trainingDescription: string;
  thumbnail: string;
  dueDate: Date;
  startDate: Date;
  status: string;
  courseType: string;
}

export interface PrimexTrainingState {
  trainings: Training[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: PrimexTrainingState = {
  trainings: [],
  status: 'idle',
  error: null,
};

const getTrainingRecordsAsync = createAsyncThunk(
  'primexTraining/getUserRecords',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await getUserRecord();

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const primexTrainingSlice = createSlice({
  name: 'primexTraining',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingRecordsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTrainingRecordsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.trainings = action.payload;
        state.error = null;
      })
      .addCase(getTrainingRecordsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.trainings = null;
        state.error = action.payload;
      });
  },
});

export default primexTrainingSlice.reducer;

export {
  getTrainingRecordsAsync,
};

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useHistory } from 'react-router-dom';
import SystemAlert from '../../../../assets/icons/SystemAlerts-dash.svg';
import { useAppDispatch } from '../../../../store/hooks';
import { nullSelectedSystemAlert } from '../../../../store/systemAlertSlice';

interface Props { }

const SystemAlertCard: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createAlert = async () => {
    await dispatch(nullSelectedSystemAlert());
    history.push('/content/create/system-alerts');
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <h2 className="text-center">
            System Alert
          </h2>
        </Card.Title>
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className="m-3">
            <img src={SystemAlert} alt="System Alert Icon" />
          </div>
          <div className="m-3">
            <p>Create an alert to display at the top of all pages in the portal.</p>
          </div>
        </div>
        <div className="d-flex flex-column button-box">
          <Button className="m-2" variant="primary" onClick={createAlert}>Create</Button>
          <Link to="/content/manage/system-alerts">
            <Button className="m-2" variant="secondary">Manage</Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SystemAlertCard;

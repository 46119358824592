import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  faSearch,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import ManageHowToTable from './HowTo/ManageHowToTable';
import ManagementSystemAlertTable from './SystemAlerts/ManageSystemAlertTable';
import {
  deleteMultipleSystemAlertsAsync,
  getSystemAlertsAsync,
  nullSelectedSystemAlert,
  SystemAlertState,
} from '../../../store/systemAlertSlice';
import { deleteMultipleHowToAsync, getHowToTopicsAsync, HowToTopic } from '../../../store/howToSlice';
import { useAppDispatch } from '../../../store/hooks';
import ManageBillboardTable from './Billboards/ManageBillboards';
import ManagePostArticleTable from './PostArticle/ManagePostArticle';
import {
  Billboard,
  deleteMultipleBillboardsAsync,
  getBillboardsAsync,
  nullSelectedBillboard,
} from '../../../store/billboardSlice';
import {
  deleteMultiplepostArticlesAsync,
  getpostArticlesAsync,
  nullSelectedPostArticle,
  PostArticle,
} from '../../../store/postArticleSlice';
import ManageChecklistTable from './Checklists/ManageChecklist';
import {
  Checklist,
  deleteMultipleChecklistsAsync,
  getAllChecklistsAsync,
  nullSelectedChecklist,
} from '../../../store/checklistSlice';

interface Props {
  systemAlerts: SystemAlertState;
  howToTopics: HowToTopic[] | null;
  billboards: Billboard[] | null;
  postsAndArticles: PostArticle[] | null;
  checklists: Checklist[] | null;
}

const ManageContent: React.FC<Props> = (props: Props) => {
  const {
    systemAlerts,
    howToTopics,
    billboards,
    postsAndArticles,
    checklists,
  } = props;
  const [showArchived, setShowArchived] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [searchString, setSearchString] = useState('');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const deleteSelected = async () => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      if (location.pathname.includes('how-to')) {
        await dispatch(deleteMultipleHowToAsync(selectedRows));
        dispatch(getHowToTopicsAsync());
        setSelectedRows([]);
      } else if (location.pathname.includes('system-alerts')) {
        await dispatch(deleteMultipleSystemAlertsAsync(selectedRows));
        dispatch(getSystemAlertsAsync());
        setSelectedRows([]);
      } else if (location.pathname.includes('billboards')) {
        await dispatch(deleteMultipleBillboardsAsync(selectedRows));
        dispatch(getBillboardsAsync());
        setSelectedRows([]);
      } else if (location.pathname.includes('postarticle')) {
        await dispatch(deleteMultiplepostArticlesAsync(selectedRows));
        dispatch(getpostArticlesAsync());
        setSelectedRows([]);
      } else if (location.pathname.includes('checklist')) {
        await dispatch(deleteMultipleChecklistsAsync(selectedRows));
        dispatch(getAllChecklistsAsync());
        setSelectedRows([]);
      }
    }
  };

  const handleCreate = async () => {
    if (location.pathname.includes('how-to')) {
      history.push('/content/create/how-to');
    } else if (location.pathname.includes('system-alerts')) {
      await dispatch(nullSelectedSystemAlert());
      history.push('/content/create/system-alerts');
    } else if (location.pathname.includes('billboards')) {
      await dispatch(nullSelectedBillboard());
      history.push('/content/create/billboards');
    } else if (location.pathname.includes('postarticle')) {
      await dispatch(nullSelectedPostArticle());
      history.push('/content/create/postarticle');
    } else if (location.pathname.includes('checklist')) {
      dispatch(nullSelectedChecklist());
      history.push('/content/create/checklist');
    }
  };
  return (
    <Card className="manager-container">
      <div className="d-flex justify-content-between align-content-center">
        <div className="align-self-center">
          {
            selectedRows.length > 0
            && (
            <button onClick={deleteSelected} type="button" className="ml-2 button-link">
              <FontAwesomeIcon size="lg" icon={faTrashAlt} />
              <span className="ml-2">Delete Selected</span>
            </button>
            )
          }
        </div>
        <div className="my-3 mx-2 d-flex justify-content-end align-items-center">
          <div className="mr-5">
            <input
              type="checkbox"
              checked={showArchived}
              onChange={() => (showArchived ? setShowArchived(false) : setShowArchived(true))}
            />
            <span className="ml-2">Show Archived</span>
          </div>
          <div className="searchbar mr-4">
            <InputGroup>
              <FormControl
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder="Search"
                aria-label="Enter search criteria"
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div>
            <Button onClick={handleCreate} variant="primary">Create</Button>
          </div>
        </div>
      </div>
      <div>
        <Switch>
          <Route exact path="/content/manage/how-to">
            <ManageHowToTable
              deleteRows={{ selectedRows, setSelectedRows }}
              howToTopics={howToTopics}
              showArchived={showArchived}
              searchString={searchString}
            />
          </Route>
          <Route exact path="/content/manage/system-alerts">
            <ManagementSystemAlertTable
              deleteRows={{ selectedRows, setSelectedRows }}
              systemAlerts={systemAlerts}
              showArchived={showArchived}
            />
          </Route>
          <Route exact path="/content/manage/billboards">
            <ManageBillboardTable
              deleteRows={{ selectedRows, setSelectedRows }}
              showArchived={showArchived}
              searchString={searchString}
              billboards={billboards}
            />
          </Route>
          <Route exact path="/content/manage/postarticle">
            <ManagePostArticleTable
              deleteRows={{ selectedRows, setSelectedRows }}
              showArchived={showArchived}
              searchString={searchString}
              postsAndArticles={postsAndArticles}
            />
          </Route>
          <Route exact path="/content/manage/checklist">
            <ManageChecklistTable
              deleteRows={{ selectedRows, setSelectedRows }}
              showArchived={showArchived}
              searchString={searchString}
              checklists={checklists}
            />
          </Route>
        </Switch>
      </div>
    </Card>
  );
};

export default ManageContent;

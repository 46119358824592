import React from 'react';
import { Card } from 'react-bootstrap';
import Layout from '../layouts/Layout';
import NotificationsTable from '../shared/notifications/NotificationsTable';

const NotificationsList: React.FC<void> = () => (
  <Layout>
    <div className="dashboard-container">
      <div className="row row-no-gutters m-0">
        <div className="col-12 dashboard-title">
          <h2>Notifications</h2>
        </div>
      </div>
      <div className="row row-no-gutters m-0">
        <div className="col-12 notifications-list-container">
          <Card>
            <Card.Body>
              <NotificationsTable />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  </Layout>
);
export default NotificationsList;

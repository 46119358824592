/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { saveAs } from 'file-saver';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import DownloadIcon from '../../../assets/icons/Download.svg';
import QuestionInfoModal from './modals/QuestionInfoModal';
import {
  getCurrentQuestionnaireSessionAsync,
  patchSubmitAnswer,
  submitQuestionnaireAsync,
  // eslint-disable-next-line no-unused-vars
  updateQuestionnaireSessionAsync,
} from '../../../store/questionnaireSlice';
import ConvertToPdf, { QuestionnaireAssignments } from '../../../service/pdfConverter';
import { User } from '../../../store/userSlice';
import { dateAndTime } from '../../../service/timeAndDate';
import { getCurrentQuestionnaireAssignmentsAsync } from '../../../store/questionnaireAssignmentsSlice';

interface Question {
  id: number;
  order: number;
  question: string;
  questionType: string;
  answerOption1: string;
  answerOption2: string;
  answerOption3: string;
  answerOption4: string;
}

interface Answer {
  id: number;
  answer: string;
  question: Question;
}

interface Props {
  data: {
    id: number;
    name: string;
    dueDate: Date | null;
    questionnaireEngineTemplateId: number;
    questionnaireEngineName: string;
    questionnaireEngineVideo: string;
    questionnaireEngineExplanation: string;
    questions: any[];
    questionnaireSessions: any[];
    questionnaireAssignments?: QuestionnaireAssignments[];
    author: User;
    createdAt: Date;
  },
  currentSession: {
    id: number;
    answers: Answer[];
    updatedAt: Date;
    questionnaireAssignments: QuestionnaireAssignments[];
  }
  toast: {
    setToastShow: Function;
  }
}

const QuestionnaireSession: React.FC<Props> = (props: Props) => {
  const { toast, data, currentSession } = props;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [multiAnswer, setMultiAnswer] = useState<number[]>([]);
  const isMounted = useRef(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));
  const { currentUser } = currentState.user;
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  useEffect(() => {
    const lastPosition: number[] = [];
    currentSession.answers.forEach((answer: any, index: number) => {
      if (answer.answer) {
        return;
      }
      lastPosition.push(index);
    });
    if (lastPosition.length > 0) {
      setQuestionNumber(lastPosition[0]);
      setCurrentAnswer('');
      setMultiAnswer([]);
    } else {
      isMounted.current = true;
    }
  }, []);
  useEffect(() => {
    if (isMounted.current) {
      const { answer } = currentSession.answers[questionNumber];
      if (data.questions[questionNumber].questionType === 'Select one') {
        switch (answer) {
          case '':
            break;
          case data.questions[questionNumber].answerOption1:
            setCurrentAnswer('answer1');
            break;
          case data.questions[questionNumber].answerOption2:
            setCurrentAnswer('answer2');
            break;
          case data.questions[questionNumber].answerOption3:
            setCurrentAnswer('answer3');
            break;
          case data.questions[questionNumber].answerOption4:
            setCurrentAnswer('answer4');
            break;
          default:
            break;
        }
      } else if (data.questions[questionNumber].questionType === 'Select multiple') {
        if (answer) {
          const answerArray: number[] = JSON.parse(answer);
          setMultiAnswer(answerArray);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [questionNumber]);

  const answerQuestion = async (event: any) => {
    event.preventDefault();
    if (currentAnswer || multiAnswer.length > 0) {
      let answer: string = '';
      if (data.questions[questionNumber].questionType === 'Select one') {
        switch (currentAnswer) {
          case 'answer1':
            answer = data.questions[questionNumber].answerOption1;
            break;
          case 'answer2':
            answer = data.questions[questionNumber].answerOption2;
            break;
          case 'answer3':
            answer = data.questions[questionNumber].answerOption3;
            break;
          case 'answer4':
            answer = data.questions[questionNumber].answerOption4;
            break;
          default:
            alert('Invalid input');
            return;
        }
      } else if (data.questions[questionNumber].questionType === 'Select multiple') {
        answer = JSON.stringify(multiAnswer);
      }
      // TODO: Currently the answers are recorded
      //  in the most recent session, we likely want better control on this.
      const answerId = currentSession
        .answers[questionNumber]
        .id;
      await dispatch(patchSubmitAnswer({ answerId, answer }));
      const newSession = await dispatch(getCurrentQuestionnaireSessionAsync(currentSession.id));
      if (questionNumber + 1 === data.questions.length) {
        // Submit questionnaire
        if (newSession.payload && newSession.payload.answers) {
          dispatch(submitQuestionnaireAsync(currentSession.id))
            .then(() => {
              dispatch(getCurrentQuestionnaireAssignmentsAsync());
              toast.setToastShow(true);
            });
        }
        if (
          currentUser
          && currentUser.role
          && superAdminRole
          && (currentUser.role >= superAdminRole)
        ) {
          history.push('/questionnaires/admin');
        } else {
          history.push('/questionnaires');
        }
      } else {
        setCurrentAnswer('');
        setMultiAnswer([]);
        setQuestionNumber(questionNumber + 1);
      }
    }
  };

  const clickHandler = (event: any) => {
    setCurrentAnswer(event.target.id);
  };

  const multiClickHandler = (answerNumber: number) => {
    if (multiAnswer.includes(answerNumber)) {
      const newAnswers = multiAnswer.filter((item) => item !== answerNumber);
      setMultiAnswer([...newAnswers]);
    } else {
      setMultiAnswer([answerNumber, ...multiAnswer]);
    }
  };

  const returnMemberNames = () => {
    let memberNameString = '';
    if (
      currentSession.questionnaireAssignments
      && currentSession.questionnaireAssignments.length > 0
    ) {
      currentSession.questionnaireAssignments.map((assignment, index) => {
        if (assignment && assignment.member) {
          if (index === 0) {
            memberNameString = memberNameString.concat('', assignment.member.memberName);
          } else {
            memberNameString = memberNameString.concat('& ', assignment.member.memberName);
          }
        }
      });
    }
    return memberNameString;
  };

  const returnDownloadLink = async (retry: boolean = false) => {
    try {
      const blob = await pdf((
        <ConvertToPdf questionnaire={data} userDownload currentSession={currentSession} />
      )).toBlob();
      saveAs(blob, data.name);
    } catch (e) {
      if (!retry) {
        returnDownloadLink(true);
      }
    }
  };

  const categoryArray: any[] = [];
  return (
    <>
      <Card className="p-3 questionnaire-session-card">
        <div className="d-flex justify-content-between">
          <h3>{data.name}</h3>
          <Button className="no-button button-link no-underline" onClick={() => returnDownloadLink()}>
            <img src={DownloadIcon} alt="download icon" />
            <span className="ml-2">
              Download
            </span>
          </Button>
        </div>
        <div className="d-flex justify-content-between">
          <p>{returnMemberNames()}</p>
          <p>
            <span className="numeric-font">
              {questionNumber}
            </span>
            <span> of </span>
            <span className="numeric-font">
              {data.questions.length}
            </span>
            <span> Questions Complete</span>
          </p>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-2">
            <ul className="questionnaire-categories">
              {

                data.questions.map((item) => {
                  if (!categoryArray.includes(item.category)) {
                    categoryArray.push(item.category);
                    const active = data.questions[questionNumber].category === item.category;
                    return (
                      <li className={`category-item ${active ? 'active' : ''}`}>
                        <p>{item.category}</p>
                      </li>
                    );
                  }
                  return null;
                })
              }
            </ul>
          </div>
          <div className="col-10 question-section">
            <div className="p-4">
              <h5>{data.questions[questionNumber].category}</h5>
              {
                data.questions[questionNumber].categoryExplanation
                && (
                  <p>{data.questions[questionNumber].categoryExplanation}</p>
                )
              }
              <p>
                <span>Question </span>
                <span className="numeric-font">
                  {data.questions
                    .filter(
                      (item) => item.category === data.questions[questionNumber].category,
                    )
                    .indexOf(data.questions[questionNumber]) + 1}
                </span>
                <span> of </span>
                <span className="numeric-font">
                  {data.questions
                    .filter(
                      (item) => item.category === data.questions[questionNumber].category,
                    ).length}
                </span>
              </p>
              <Card className="p-4">
                <div>
                  <h4 className="bold">{data.questions[questionNumber].question}</h4>
                  {
                    (data.questions[questionNumber].explanation
                      || data.questions[questionNumber].videoExplanation)
                    && (
                      <QuestionInfoModal data={data.questions[questionNumber]} />
                    )
                  }
                </div>
                <Form id="form-answer" onSubmit={(event) => answerQuestion(event)}>
                  {
                    data.questions[questionNumber].questionType === 'Select multiple'
                      ? (
                        <Form.Group controlId="form.answer">
                          {data.questions[questionNumber].answerOption1 && <Form.Check checked={multiAnswer.includes(1)} onChange={() => multiClickHandler(1)} type="checkbox" id="answer1" label={data.questions[questionNumber].answerOption1} />}
                          {data.questions[questionNumber].answerOption2 && <Form.Check checked={multiAnswer.includes(2)} onChange={() => multiClickHandler(2)} type="checkbox" id="answer2" label={data.questions[questionNumber].answerOption2} />}
                          {data.questions[questionNumber].answerOption3 && <Form.Check checked={multiAnswer.includes(3)} onChange={() => multiClickHandler(3)} type="checkbox" id="answer3" label={data.questions[questionNumber].answerOption3} />}
                          {data.questions[questionNumber].answerOption4 && <Form.Check checked={multiAnswer.includes(4)} onChange={() => multiClickHandler(4)} type="checkbox" id="answer4" label={data.questions[questionNumber].answerOption4} />}
                        </Form.Group>
                      ) : (
                        <Form.Group controlId="form.answer">
                          {data.questions[questionNumber].answerOption1 && <Form.Check checked={currentAnswer === 'answer1'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer1" label={data.questions[questionNumber].answerOption1} name="question 1" />}
                          {data.questions[questionNumber].answerOption2 && <Form.Check checked={currentAnswer === 'answer2'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer2" label={data.questions[questionNumber].answerOption2} name="question 1" />}
                          {data.questions[questionNumber].answerOption3 && <Form.Check checked={currentAnswer === 'answer3'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer3" label={data.questions[questionNumber].answerOption3} name="question 1" />}
                          {data.questions[questionNumber].answerOption4 && <Form.Check checked={currentAnswer === 'answer4'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer4" label={data.questions[questionNumber].answerOption4} name="question 1" />}
                        </Form.Group>
                      )
                  }
                </Form>
              </Card>
              <div className="d-flex justify-content-between">
                <div className="m-4">
                  {
                    currentSession
                    && currentSession.updatedAt
                    && (
                      <p>
                        Last updated:
                        {' '}
                        <span className="numeric-font">
                          {dateAndTime(currentSession.updatedAt)}
                        </span>
                      </p>
                    )
                  }
                </div>
                <div className="questionnaire-button-group">
                  <Button
                    variant="primary"
                    className="m-4"
                    disabled={questionNumber <= 0}
                    onClick={() => {
                      setCurrentAnswer('');
                      setQuestionNumber(questionNumber > 0 ? questionNumber - 1 : 0);
                    }}
                  >
                    <span className="buttonArrow">&lt;</span>
                    <span className="ml-2">Previous Question</span>
                  </Button>
                  {
                    questionNumber + 1 === data.questions.length
                      ? (
                        <Button variant="primary" form="form-answer" type="submit" className="m-4" disabled={!currentAnswer && multiAnswer.length === 0}>
                          <span className="mr-2">Complete</span>
                          <span className="buttonArrow">&gt;</span>
                        </Button>
                      )
                      : (
                        <Button variant="primary" form="form-answer" type="submit" className="m-4" disabled={!currentAnswer && multiAnswer.length === 0}>
                          <span className="mr-2">Next Question</span>
                          <span className="buttonArrow">&gt;</span>
                        </Button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default QuestionnaireSession;

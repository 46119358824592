/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useParams } from 'react-router-dom';
import { getOnePrimexThreatAlertAsync, PrimexSecurityAlert } from '../../../store/primexAlertsSlice';
import { dateAndTime, dateOnly } from '../../../service/timeAndDate';
import { CyberThreatAssignment } from '../../../store/cyberThreatAssignmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ViewProfileModal from '../questionnaire/modals/ViewProfileModal';

interface Props {}

const CyberThreatDetailAdmin: React.FC<Props> = (props) => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    primexAlerts: state.primexAlerts,
  }));
  const [sortStateMember, setSortStateMember] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateUser, setSortStateUser] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateViewed, setSortStateViewed] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [searchCriteria, setSearchCriteria] = useState('');

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  useEffect(() => {
    dispatch(getOnePrimexThreatAlertAsync(parseInt(id || '', 10)));
  }, []);

  // if there is not an active security alert, then just show an empty div
  if (!currentState.primexAlerts.selectedPrimexSecurityAlert) {
    return (<div />);
  }

  const {
    title,
    publishDate,
    dueDate,
    cyberThreatAssignments,
  } = currentState.primexAlerts.selectedPrimexSecurityAlert;

  return (
    <div className="threat-detail-admin-container">
      <div className="d-flex justify-content-between">
        <div>
          <h3>{title}</h3>
          <p>
            <span>Published: </span>
            <span className="numeric-font">{dateAndTime(publishDate)}</span>
          </p>
        </div>
        <div>
          <p>
            <span>Expires: </span>
            <span className="numeric-font">{dueDate ? dateAndTime(dueDate) : 'N/A'}</span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-content-center mb-2">
        <div className="d-flex flex-inline">
          <p className="m-0 d-flex align-items-center">
            <span>Published to: </span>
            <span className="d-flex ml-2">
              <p className="publish-tags ml-2">N/A</p>
            </span>
          </p>
        </div>
        <div className="searchbar">
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th>
              <span>Member </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateMember) {
                    case 'noSort':
                      setSortStateMember('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateMember('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateMember('noSort');
                      return;
                    default:
                      setSortStateMember('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateMember)} />
              </Button>
            </th>
            <th>
              <span>User </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateUser) {
                    case 'noSort':
                      setSortStateUser('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateUser('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateUser('noSort');
                      return;
                    default:
                      setSortStateUser('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateUser)} />
              </Button>
            </th>
            <th>
              <span>Viewed </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateViewed) {
                    case 'noSort':
                      setSortStateViewed('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateViewed('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateViewed('noSort');
                      return;
                    default:
                      setSortStateViewed('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateViewed)} />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            cyberThreatAssignments
            && (
              cyberThreatAssignments
                .slice()
                .filter((assignment) => assignment.user)
                .filter((assignment) => {
                  if (
                    (
                      assignment.user
                      && assignment.user.firstName
                      && assignment.user.firstName
                        .toLowerCase()
                        .includes(searchCriteria.toLowerCase())
                    )
                    // eslint-disable-next-line max-len
                    || (
                      assignment.user
                      && assignment.user.lastName
                      && assignment.user.lastName
                        .toLowerCase()
                        .includes(searchCriteria.toLowerCase())
                    )
                  ) {
                    return assignment;
                  }
                  if (assignment.user.members) {
                    let memberMatch = false;
                    assignment.user.members.forEach((member: any) => {
                      if (member.memberName.toLowerCase().includes(searchCriteria.toLowerCase())) {
                        memberMatch = true;
                      }
                    });
                    if (memberMatch) {
                      return assignment;
                    }
                  }
                })
                .sort((a, b) => {
                  let aVar = '';
                  let bVar = '';
                  if (a.user.members.length > 1) {
                    aVar = 'various';
                  } else if (a.user.members[0] && a.user.members[0].memberName) {
                    aVar = a.user.members[0].memberName.toLowerCase();
                  }
                  if (b.user.members.length > 1) {
                    bVar = 'various';
                  } else if (b.user.members[0] && b.user.members[0].memberName) {
                    bVar = b.user.members[0].memberName.toLowerCase();
                  }
                  switch (sortStateMember) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.user.lastName?.toLowerCase() || 'a';
                  const bVar = b.user.lastName?.toLowerCase() || 'a';
                  switch (sortStateUser) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((assignment) => (
                  <tr>
                    <td>
                      {
                        assignment.user.members.length > 1
                        && ('Various')
                      }
                      {
                        assignment.user.members.length === 1
                        && (
                          <Link to={`/members/profile/${assignment.user.members[0].id}/info`}>
                            {assignment.user.members[0].memberName}
                          </Link>
                        )
                      }
                      {
                        !assignment.user.members.length
                        && ('No member defined')
                      }
                    </td>
                    <td>
                      <ViewProfileModal profileId={assignment.user.id}>
                        {assignment.user.lastName && assignment.user.lastName}
                        <span>, </span>
                        {assignment.user.firstName && assignment.user.firstName}
                      </ViewProfileModal>
                    </td>
                    <td>
                      {assignment.viewed ? dateOnly(assignment.viewed) : ''}
                    </td>
                  </tr>
                ))
            )
          }
        </tbody>
      </Table>
    </div>
  );
};

export default CyberThreatDetailAdmin;

/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  Card,
  Modal,
  Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import ArrowSelectIcon from '../../assets/icons/ArrowSelect.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getOneMemberAsync } from '../../store/memberSlice';
// import { Member } from '../../store/memberSlice';

interface Props {
  show: boolean;
  onClose: () => void;
}

const MemberSelectionModal: React.FC<Props> = (props) => {
  const { show, onClose } = props;
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  const { id } = useParams<{
    id: string | undefined
  }>();
  const history = useHistory();

  const reload = async (incomingId: number) => {
    await dispatch(getOneMemberAsync(incomingId));
    history.push(`/members/profile/${incomingId}/info`);
    onClose();
  };

  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  const { currentUser } = currentState.user;

  const primexMemberNumber: number | null = process.env.REACT_APP_PRIMEX_MEMBER_NUMBER ? parseInt(process.env.REACT_APP_PRIMEX_MEMBER_NUMBER, 10) : null;

  return (
    <Modal show={show} size="lg" onHide={onClose} className="member-select-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="ml-2 mb-0 align-self-center">Select Member</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are associated with multiple Members.</p>
        <h4 className="mb-4">Which Member Profile would you like to view?</h4>
        {
          currentUser
          && currentUser.members
          && currentUser.members
            .slice()
            .filter((member) => member.memberNumber !== primexMemberNumber)
            .map((member) => {
              const newParentMemberNumber = member.id;
              if (!member.parentMember) {
                return (
                  <Accordion key={`${member.id}-memberSelectModal`} defaultActiveKey="0">
                    <Card className="member-select-card">
                      <Accordion.Toggle
                        as={Card.Header}
                        className="d-flex justify-content-between align-items-center"
                        eventKey="0"
                        onClick={() => {
                          if (open) {
                            setOpen(false);
                          } else {
                            setOpen(true);
                          }
                        }}
                      >
                        <h3 className="m-0 py-1">
                          {member.memberName}
                        </h3>
                        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Table bordered className="member-select">
                          <thead>
                            <tr>
                              <th>
                                <strong>Member</strong>
                              </th>
                              <th>
                                <strong>Maturity</strong>
                              </th>
                              <th>
                                <span />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              currentUser.members
                              && currentUser.members
                                .slice()
                                // .filter((childMember) => childMember.parentMember && (childMember.parentMember.id === member.id))
                                .filter((childMember) => childMember.memberNumber !== primexMemberNumber)
                                .filter((childMember) => {
                                  if (childMember.parentMember) {
                                    if (childMember.parentMember.id === newParentMemberNumber) {
                                      return childMember;
                                    }
                                  }
                                })
                                .map((childMember) => (
                                  <tr key={`${childMember.id}-childMember`}>
                                    <td>
                                      {childMember.memberName}
                                    </td>
                                    <td>
                                      N/A
                                      {/* TODO: need to add maturity */}
                                    </td>
                                    <td className="text-right">
                                      {
                                        id
                                          && parseInt(id, 10) === childMember.id
                                          ? (
                                            <Link to={`/members/profile/${childMember.id}/info`}>
                                              <img src={ArrowSelectIcon} alt="Select Icon" />
                                            </Link>
                                          ) : (
                                            <Button onClick={() => reload(childMember.id)} className="button-link p-0">
                                              <img src={ArrowSelectIcon} alt="Select Icon" />
                                            </Button>
                                          )
                                      }
                                    </td>
                                  </tr>
                                ))
                            }
                            <tr>
                              <td>
                                {member.memberName}
                              </td>
                              <td>
                                N/A
                                {/* TODO: need to add maturity */}
                              </td>
                              <td className="text-right">
                                {
                                  id
                                    && parseInt(id, 10) === member.id
                                    ? (
                                      <Link to={`/members/profile/${member.id}/info`}>
                                        <img src={ArrowSelectIcon} alt="Select Icon" />
                                      </Link>
                                    ) : (
                                      <Button onClick={() => reload(member.id)} className="button-link p-0">
                                        <img src={ArrowSelectIcon} alt="Select Icon" />
                                      </Button>
                                    )
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              }
            })
        }
      </Modal.Body>
    </Modal>
  );
};

export default MemberSelectionModal;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOnePostArticleAsync } from '../../../store/postArticleSlice';
import PostAndArticleBody from './shared/PostArticleBody';

interface Props { }

const PostAndArticleDetail: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();

  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => ({
    postArticles: state.postArticles,
  }));

  useEffect(() => {
    dispatch(getOnePostArticleAsync(parseInt(id || '', 10)));
  }, []);

  if (!currentState.postArticles.selectedpostArticle) {
    return (<div />);
  }

  return (
    <>
      <PostAndArticleBody postArticle={currentState.postArticles.selectedpostArticle} />
    </>
  );
};
export default PostAndArticleDetail;

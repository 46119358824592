import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface Props {
    show: boolean;
 }

const LoadingModal: React.FC<Props> = ({ show }) => (
  <Modal show={show} className="loading-modal">
    <Spinner variant="light" className="my-auto" animation="border" role="status" />
    <h4 className="my-auto ml-2 bold">Loading...</h4>
  </Modal>
);

export default LoadingModal;

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { checkIrPermission } from '../../../service/checkIrPermission';
import { dateAndTime, dateOnly } from '../../../service/timeAndDate';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentNotificationsAsync, Notification, updateNotificationAsync } from '../../../store/notificationSlice';
import { QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import ReadPrimexAlert from '../../shared/cyberThreats/modals/readPrimexAlert';
import DropdownIntroModal from '../../shared/questionnaire/modals/DropdownIntroModal';
import QuestionnaireIntroModal from '../../shared/questionnaire/modals/QuestionnaireIntroModal';

interface Props {
  notification: Notification;
  index: number;
}

const NotificationDropdown: React.FC<Props> = ({ notification, index }) => {
  const {
    id,
    type,
    title,
    cyberThreatAssignment,
    questionnaireAssignment,
    postArticleAssignment,
    document,
    checklistAssignment,
    irClaim,
    irFile,
    trainingId,
  } = notification;
  const dispatch = useAppDispatch();
  const [selectModal, setSelectModal] = useState<string>('');
  const currentState = useAppSelector((state) => ({
    questionnaireAssignments: state.questionnaireAssignments,
    user: state.user,
  }));
  const { currentUser } = currentState.user;
  const viewNotification = async (viewedId: number) => {
    await dispatch(updateNotificationAsync({
      id: viewedId,
      body: {
        viewed: true,
      },
    }));
    dispatch(getCurrentNotificationsAsync());
  };

  if (type.includes('Private Comments') && irClaim && currentUser && checkIrPermission(currentUser)) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <Link to={`/cyber-claims/details/${irClaim.id}/private-comments`}>
            {title}
          </Link>
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('New IR Claim') && irClaim) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          {
            currentUser
              && checkIrPermission(currentUser)
              ? (
                <Link to={`/cyber-claims/details/${irClaim.id}/info`}>
                  {title}
                </Link>
              ) : (
                <Link to={`/cyber-claims/info/${irClaim.id}`}>
                  {title}
                </Link>
              )
          }
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('Claim Update') && irClaim) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          {
            currentUser
              && checkIrPermission(currentUser)
              ? (
                <Link to={`/cyber-claims/details/${irClaim.id}/${notification.type.includes('Claim Update Message') ? 'updates' : 'info'}`}>
                  {title}
                </Link>
              ) : (
                <Link to={`/cyber-claims/info/${irClaim.id}`}>
                  {title}
                </Link>
              )
          }
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('New IR File') && irFile && irFile.irClaim) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          {
            currentUser
              && checkIrPermission(currentUser)
              ? (
                <Link to={`/cyber-claims/details/${irFile.irClaim.id}/files`}>
                  {title}
                </Link>
              ) : (
                <Link to={`/cyber-claims/info/${irFile.irClaim.id}`}>
                  {title}
                </Link>
              )
          }
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('Post') && postArticleAssignment && postArticleAssignment.postArticle) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <Link to={`/posts-articles/${postArticleAssignment.postArticle.id}`}>
            {title}
          </Link>
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('New Document') && document) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <Link to={`/documents/${document.s3Directory === 'memberDocs' ? 'member' : 'portal'}`} onClick={() => viewNotification(id)}>
            {title}
          </Link>
        </div>
      </Dropdown.Item>
    );
  }

  if (type.includes('Cyber Threat Alert') && cyberThreatAssignment) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <ReadPrimexAlert
            threatAssignment={cyberThreatAssignment}
            threatData={cyberThreatAssignment.cyberThreat}
            userView
            dateConversion={dateOnly}
            notificationId={id}
          >
            {title}
          </ReadPrimexAlert>
        </div>
      </Dropdown.Item>
    );
  }
  if (type.includes('Questionnaire') && questionnaireAssignment) {
    if (
      currentState.questionnaireAssignments.currentQuestionnaireAssignments
      && (currentState.questionnaireAssignments.currentQuestionnaireAssignments.filter(
        (assignment: QuestionnaireAssignment) => assignment.questionnaire.id
          === questionnaireAssignment.questionnaire.id,
      ) || []).length > 1
    ) {
      return (
        <>
          <Dropdown.Item eventKey={index}>
            <div className="py-2">
              <h6>{type}</h6>
              <Button className="no-button button-link text-left p-0" onClick={() => setSelectModal(`${questionnaireAssignment.id}-${index}`)}>
                {title}
              </Button>
              <p>
                <span>
                  Due:
                </span>
                {' '}
                <span className="numeric-font">
                  {dateAndTime(notification.dueDate)}
                </span>
              </p>
            </div>
          </Dropdown.Item>
          <DropdownIntroModal
            viewedNotificationId={id}
            modalKey={`${questionnaireAssignment.id}-${index}`}
            openModal={selectModal || null}
            questionnaire={questionnaireAssignment.questionnaire}
            questionnaireAssignments={
              currentState.questionnaireAssignments.currentQuestionnaireAssignments
                .filter(
                  (assignment: QuestionnaireAssignment) => assignment.questionnaire.id
                    === questionnaireAssignment.questionnaire.id,
                )
            }
          />
        </>
      );
    }
    return (
      <>
        <Dropdown.Item eventKey={index}>
          <div className="py-2">
            <h6>{type}</h6>
            <QuestionnaireIntroModal
              newTitle={title}
              id={questionnaireAssignment.questionnaire.id}
              questionnaire={questionnaireAssignment.questionnaire}
              questionnaireAssignments={
                currentState.questionnaireAssignments.currentQuestionnaireAssignments
                  .filter(
                    (assignment: QuestionnaireAssignment) => assignment.questionnaire.id
                      === questionnaireAssignment.questionnaire.id,
                  )
              }
              viewedNotificationId={id}
            />
            <p>
              <span>
                Due:
              </span>
              {' '}
              <span className="numeric-font">
                {dateAndTime(notification.dueDate)}
              </span>
            </p>
          </div>
        </Dropdown.Item>
      </>
    );
  }
  if (type.includes('Training') && trainingId) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <Link to="/training" onClick={() => viewNotification(id)}>
            {title}
          </Link>
          <p>
            <span>
              Due:
            </span>
            {' '}
            <span className="numeric-font">
              {dateAndTime(notification.dueDate)}
            </span>
          </p>
        </div>
      </Dropdown.Item>
    );
  }
  if (type.includes('Checklist') && checklistAssignment) {
    return (
      <Dropdown.Item eventKey={index}>
        <div className="py-2">
          <h6>{type}</h6>
          <Link to={`/checklists/${checklistAssignment.id}`} onClick={() => viewNotification(id)}>
            {
              checklistAssignment.checklist
                && checklistAssignment.checklist.title
                ? checklistAssignment.checklist.title
                : 'None'
            }
          </Link>
          <p>
            <span>
              Due:
            </span>
            {' '}
            <span className="numeric-font">
              {dateAndTime(notification.dueDate)}
            </span>
          </p>
        </div>
      </Dropdown.Item>
    );
  }
  return (<div />);
};
export default NotificationDropdown;

import { AxiosPromise } from 'axios';
import { CyberThreatDistributionModel } from '../models/distributionModels';
import api from './api';

export interface UpdateCyberThreatAssignmentModel {
  id: number;
  viewed: Date;
}

export function getCurrentCyberThreatAssignments() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/cyber-threat-assignments/current')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createCyberThreatAssignments(
  threatAssignmentCriteria: CyberThreatDistributionModel,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/cyber-threat-assignments', threatAssignmentCriteria)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateCyberThreatAssignment(
  params: UpdateCyberThreatAssignmentModel,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/cyber-threat-assignments/${params.id}`, params)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import QuestionnaireIcon from '../../../../assets/icons/questionnaire-dash.svg';

interface Props { }

const QuestionnareCard: React.FC<Props> = () => (
  <Card>
    <Card.Body>
      <Card.Title>
        <h2 className="text-center">
          Questionnaire
        </h2>
      </Card.Title>
      <div className="d-flex justify-content-between align-items-center flex-column">
        <div className="m-3">
          <img src={QuestionnaireIcon} alt="System Alert Icon" />
        </div>
        <div className="m-3">
          <p>
            Create and distribute questionnaires to assess
            the cybersecurity knowledge and maturity level for members.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column mt-auto button-box">
        <Link to="/questionnaires/admin">
          <Button className="m-2" variant="primary">Create &amp; Manage</Button>
        </Link>
      </div>
    </Card.Body>
  </Card>
);

export default QuestionnareCard;

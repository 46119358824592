import React, { useState } from 'react';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import { getAllClaimsAsync, getOneClaimAsync, IrClaim } from '../../../../store/irClaimSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { updateOneMemberAsync } from '../../../../store/memberSlice';

interface Props {
  claim: IrClaim;
  singleClaim: boolean;
}

const ToggleEmail: React.FC<Props> = ({ claim, singleClaim }) => {
  const [show, setShow] = useState(false);

  const dispatch = useAppDispatch();

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  // TODO: primary program contacts cannot be removed
  const handleSubmit = async () => {
    if (claim.member && claim.member.id) {
      await dispatch(updateOneMemberAsync({
        id: claim.member.id,
        memberData: {
          emailDeactivated: !claim.member.emailDeactivated,
        },
      }));
    }
    if (singleClaim) {
      dispatch(getOneClaimAsync(claim.id));
    } else {
      dispatch(getAllClaimsAsync(null));
    }
    handleClose();
  };

  return (
    <>
      <Button variant={singleClaim ? 'clear' : ''} className={`${singleClaim ? '' : 'no-button button-link'} p-0 text-left d-flex flex-row align-items-center`} onClick={handleShow}>
        <Form.Check
          readOnly
          type="switch"
          checked={!claim.member?.emailDeactivated}
        />
        {
          claim.member
            && claim.member.emailDeactivated
            ? (
              'Email Deactivated'
            ) : (
              'Email Active'
            )
        }
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="ml-2 mb-0 align-self-center">Toggle Member Email</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            claim.member
              && claim.member.emailDeactivated
              ? (
                <>
                  <h4>
                    The email for
                    {' '}
                    {claim.member.memberName}
                    {' '}
                    has been flagged for deactivation.
                  </h4>
                  <p>
                    Would you like to submit a request to reactivate
                    email service to Primex for this member?
                  </p>
                </>
              ) : (
                <>
                  <h4>
                    The email for
                    {' '}
                    {claim.member?.memberName}
                    {' '}
                    is active.
                  </h4>
                  <p>
                    Would you like to submit a request to deactivate
                    email service to Primex for this member?
                  </p>
                </>
              )
          }
          <div className="d-flex justify-content-end mt-3 member-access-buttons">
            <Button variant="clear" className="claims-outline-button mr-3" onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit Request</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ToggleEmail;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOneUserAsync } from '../../../store/userSlice';
import UserInfo from '../UserInfo';

interface Props { }

const UserProfile: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  useEffect(() => {
    dispatch(getOneUserAsync(parseInt(id || '', 10)));
  }, []);

  const { user: { viewUserProfile } } = currentState;

  if (!viewUserProfile) {
    return (<div />);
  }

  return (
    <UserInfo user={viewUserProfile} />
  );
};

export default UserProfile;

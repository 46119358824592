import React from 'react';
// import To-Do from '../../../assets/icons/to-do.svg';
// import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ToDo from '../../../assets/icons/to-do.svg';
import { Todo } from '../../../store/todoSlice';
import { useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';
import { QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import QuestionnaireIntroModal from '../questionnaire/modals/QuestionnaireIntroModal';
import MultiMemberChecklistModal from '../checklists/modals/MultiMemberChecklistModal';
// import { ChecklistAssignment } from '../../../store/checklistAssignmentSlice';

interface Props {
  List: Todo[];
}

const TodoList: React.FC<Props> = ({ List }) => {
  const currentState = useAppSelector((state) => ({
    questionnaireAssignments: state.questionnaireAssignments,
  }));
  const getListGroups = () => {
    const result: Todo[][] = [];
    // eslint-disable-next-line array-callback-return
    List.map((currentTodo) => {
      if (currentTodo.questionnaire) {
        const resultIndex = result
          .map((listGroup) => listGroup[0].questionnaire.id)
          .indexOf(currentTodo.questionnaire.id);

        if (resultIndex !== -1) {
          result[resultIndex].push(currentTodo);
        } else {
          result.push([currentTodo]);
        }
      } else if (currentTodo.checklist) {
        const resultIndex = result
          .map((listGroup) => listGroup[0].checklist?.id)
          .indexOf(currentTodo.checklist?.id);

        if (resultIndex !== -1) {
          result[resultIndex].push(currentTodo);
        } else {
          result.push([currentTodo]);
        }
      }
    });
    return result;
  };

  const multiAssignments = (assignment: any) => {
    const assignments: any[] = (assignment
      .map((item: any) => item.checklistAssignment));
    return assignments;
  };

  return (
    <div className="card-section todo-dash-card">
      <Card>
        <Card.Body>
          <Card.Title className="d-flex">
            <img src={ToDo} alt="to-do icon" />
            <h2 className="ml-2 mt-1">To-Do List</h2>
          </Card.Title>
          <Table bordered>
            <thead>
              <tr>
                <th>Title</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {List.length > 0
                && getListGroups()
                  .map((item) => {
                    if (item[0].type.includes('questionnaire')) {
                      return (
                        <tr>
                          <td>
                            <QuestionnaireIntroModal
                              newTitle={item[0].title}
                              id={item[0].questionnaire.id}
                              questionnaire={item[0].questionnaire}
                              linkStyle="link"
                              questionnaireAssignments={
                                currentState.questionnaireAssignments
                                  .currentQuestionnaireAssignments
                                  .filter(
                                    (assignment: QuestionnaireAssignment) => assignment
                                      .questionnaire.id
                                      === item[0].questionnaire.id,
                                  )
                              }
                            />
                          </td>
                          <td>
                            {dateOnly(item[0].dueDate)}
                          </td>
                        </tr>
                      );
                    } if (item[0].type.includes('checklist')) {
                      return (
                        <tr>
                          <td>
                            {
                              item.length === 1
                                ? (
                                  <Link to={`checklists/${item[0].id}`}>
                                    {item[0].title}
                                  </Link>
                                ) : (
                                  <MultiMemberChecklistModal
                                    checklistAssignments={multiAssignments(item)}
                                    checklist={item[0].checklist}
                                  >
                                    {item[0].checklist.title}
                                  </MultiMemberChecklistModal>
                                )
                            }
                          </td>
                          <td>
                            {dateOnly(item[0].dueDate)}
                          </td>
                        </tr>
                      );
                    } if (item[0].type.includes('training')) {
                      return (
                        <tr>
                          <td>
                            <Link to={`training/${item[0].trainingId}`}>
                              {item[0].title}
                            </Link>
                          </td>
                          <td>
                            {dateOnly(item[0].dueDate)}
                          </td>
                        </tr>
                      );
                    }
                    return false;
                  })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TodoList;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Card from 'react-bootstrap/Card';
import { Training } from '../../../store/trainingSlice';
import MicroTrainingIcon from '../../../assets/icons/microtraining-icon.svg';
import OtherTrainingIcon from '../../../assets/icons/webinars-icon.svg';

interface Props {
  data: Training[] | null;
}

const RecTrainingCard: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const dateConversion = (date: Date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  return (
    <div className="card-section recommended-training-card">
      <Card>
        <Card.Body>
          <Card.Title>
            <h2>Training</h2>
          </Card.Title>
          {(data
            && data.length > 0)
            ? (data
              .slice(0, 5)
              .map((training: Training) => {
                const purifiedContent = DOMPurify.sanitize(training.trainingDescription);
                const strippedHtml = purifiedContent.replace(/<[^>]+>/g, '');
                const briefDescription = strippedHtml.length > 100
                  ? strippedHtml.substring(0, 100)
                  : strippedHtml;
                return (
                  <div className="d-flex flex-row justify-content-between training-link">
                    <div className="d-flex col-2">
                      {
                          training.courseType
                          && training.courseType === 'micro-trainings'
                            ? (
                              <img className="w-100" src={MicroTrainingIcon} alt="training" />
                            ) : (
                              <img className="w-100" src={OtherTrainingIcon} alt="training" />
                            )
                        }
                    </div>
                    <div className="d-flex flex-row justify-content-between flex-grow-1">
                      <div>
                        <a target="_blank" rel="noreferrer" href={training.trainingLink}>
                          <p className="font-weight-bold">{training.name}</p>
                        </a>
                        <p className="no-underline">
                          {briefDescription}
                          {
                            briefDescription
                            && (
                              <span>...</span>
                            )
                          }
                        </p>
                      </div>
                      <div className="col-3">
                        <p>
                          <span>Due: </span>
                          <span className="numeric-font">{dateConversion(training.dueDate)}</span>
                        </p>
                        <p className="font-weight-bold">{training.status}</p>
                      </div>
                    </div>
                  </div>
                );
              })) : (
                <h5> You don&apos;t have any assigned trainings at this time.</h5>
            )}
          <div>
            <Link to="/training">View all training &gt;&gt;</Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default RecTrainingCard;

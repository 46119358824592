import { AxiosPromise } from 'axios';
import api from './api';

// eslint-disable-next-line import/prefer-default-export
export function downloadQuestionnaire(engineId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/questionnaire-api-client/${engineId}`, {
          responseType: 'blob',
        })
        .then((response: any) => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', 'testname.xlsx');
          // document.body.appendChild(link);
          // link.click();
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

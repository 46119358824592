/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';
import { CyberThreatDistributionModel, DistributionUniversal } from '../../../../models/distributionModels';
import { createCyberThreatAssignmentsAsync } from '../../../../store/cyberThreatAssignmentSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { getOneIncomingAlertAsync, IncomingSecurityAlert, setSelectedIncomingAlertNull } from '../../../../store/incomingAlertSlice';
import {
  CreatePrimexSecurityAlert,
  CreatePrimexSecurityAlertDraft,
  createPrimexThreatAlertAsync,
  getAllPrimexThreatAlertsAsync,
  nullSelectedPrimexAlert,
  PrimexSecurityAlert,
  updatePrimexThreatAlertAsync,
} from '../../../../store/primexAlertsSlice';
import QuestionnaireDistribution from '../../questionnaire/modals/modalComponents/questionnaireDistribution';
import CreateAlertEditor from './modalComponents/createAlertEditor';

interface Props {
  currentId: number | null,
  securityAlertDraft: IncomingSecurityAlert | null,
  primexAlertDraft: PrimexSecurityAlert | null,
  dateConversion: Function,
  children: string,
  regularButton?: boolean,
}

const CreateThreatAlert: React.FC<Props> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    currentId,
    securityAlertDraft,
    primexAlertDraft,
    dateConversion,
    children,
    regularButton,
  } = props;
  const [show, setShow] = useState(false);
  const [createAlertStep, setCreateAlertStep] = useState(1);

  const [headline, setHeadline] = useState('');
  const [severity, setSeverity] = useState<'Select' | 'Low' | 'Medium' | 'High'>('Select');
  const [technologies, setTechnologies] = useState('');
  const [sourceTitle, setSourceTitle] = useState('');
  const [originalSource, setOriginalSource] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customRecommendations, setCustomRecommendations] = useState('');
  const [model, setModel] = useState<DistributionUniversal>();

  const clearState = () => {
    setHeadline('');
    setSeverity('Select');
    setTechnologies('');
    setSourceTitle('');
    setOriginalSource('');
    setCustomDescription('');
    setCustomRecommendations('');
    setModel(undefined);
  };

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClose = () => {
    setCreateAlertStep(1);
    dispatch(setSelectedIncomingAlertNull());
    dispatch(nullSelectedPrimexAlert());
    clearState();
    setModel(undefined);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const createAlert = async () => {
    if (currentId) {
      await dispatch(getOneIncomingAlertAsync(currentId));
    }
    handleShow();
  };

  const submitDistribution = async () => {
    if (model && primexAlertDraft) {
      const distModel: CyberThreatDistributionModel = {
        ...model, cyberThreatId: primexAlertDraft.id,
      };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberSubTypes = null;
        }
      } else {
        distModel.allMembers = false;
        distModel.specificMemberTypes = null;
        distModel.specificMemberMaturityLevels = null;
        distModel.specificMemberSubTypes = null;
        distModel.specificMemberIds = null;
      }
      await dispatch(updatePrimexThreatAlertAsync({
        id: primexAlertDraft.id,
        body: {
          draft: false,
          publishDate: new Date(),
          dueDate: distModel.dueDate || undefined,
        },
      }));
      await dispatch(createCyberThreatAssignmentsAsync(distModel));
      history.push('/threat-alerts/drafts');
      await dispatch(getAllPrimexThreatAlertsAsync());
      dispatch(setSelectedIncomingAlertNull());
      dispatch(nullSelectedPrimexAlert());
      handleClose();
    }
  };

  const createPrimexAlertDraft = async () => {
    let originalSourceURL = originalSource || '';
    let originalSourceTitle = sourceTitle || '';
    if (securityAlertDraft) {
      originalSourceURL = originalSource || securityAlertDraft.link;
      originalSourceTitle = sourceTitle || securityAlertDraft.source;
    }
    const alertBody: CreatePrimexSecurityAlertDraft = {
      id: primexAlertDraft?.id || undefined,
      title: headline || 'draft alert',
      threatLevel: severity || null,
      technologiesImpacted: technologies || null,
      sourceTitle: originalSourceTitle,
      source: originalSourceURL,
      content: customDescription || null,
      recommendations: customRecommendations || null,
      incomingAlert: securityAlertDraft || undefined,
      draft: true,
    };
    await dispatch(createPrimexThreatAlertAsync(alertBody));
    history.push('/threat-alerts/drafts');
    await dispatch(getAllPrimexThreatAlertsAsync());
    dispatch(setSelectedIncomingAlertNull());
    dispatch(nullSelectedPrimexAlert());
    handleClose();
  };

  const createPrimexAlert = async (nextStep: boolean) => {
    let originalSourceURL = originalSource || '';
    let originalSourceTitle = sourceTitle || '';
    if (securityAlertDraft) {
      originalSourceURL = originalSource || securityAlertDraft.link;
      originalSourceTitle = sourceTitle || securityAlertDraft.source;
    }
    // eslint-disable-next-line max-len
    if (headline && severity && customDescription && customRecommendations) {
      const alertBody: CreatePrimexSecurityAlert = {
        id: primexAlertDraft?.id || undefined,
        title: headline,
        threatLevel: severity,
        technologiesImpacted: technologies || '',
        sourceTitle: originalSourceTitle,
        source: originalSourceURL,
        content: customDescription,
        recommendations: customRecommendations,
        incomingAlert: securityAlertDraft || undefined,
        draft: true,
      };
      const results = await dispatch(createPrimexThreatAlertAsync(alertBody));
      if (nextStep && results) {
        setCreateAlertStep(createAlertStep + 1);
      } else if (!nextStep && results) {
        history.push('/threat-alerts/drafts');
        await dispatch(getAllPrimexThreatAlertsAsync());
        dispatch(setSelectedIncomingAlertNull());
        dispatch(nullSelectedPrimexAlert());
        handleClose();
      }
    }
  };

  return (
    <>
      <Button className={regularButton ? '' : 'button-link px-0'} variant="primary" onClick={createAlert}>
        {children}
      </Button>

      <Modal className={createAlertStep === 1 && securityAlertDraft ? 'mega-modal' : 'not-mega-modal'} size="xl" show={show}>
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title>
            {
              createAlertStep === 1
              && (
                <h4 className="ml-2 mb-0">Create Cyber Threat Alert</h4>
              )
            }
            {
              createAlertStep === 2
              && (
                <h4 className="ml-2 mb-0">Distribution</h4>
              )
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            createAlertStep === 1
            && (
              <CreateAlertEditor
                dateConversion={dateConversion}
                securityAlertDraft={securityAlertDraft || null}
                severity={{ severity, setSeverity }}
                headline={{ headline, setHeadline }}
                technologies={{ technologies, setTechnologies }}
                sourceTitle={{ sourceTitle, setSourceTitle }}
                originalSource={{ originalSource, setOriginalSource }}
                customDescription={{ customDescription, setCustomDescription }}
                customRecommendations={{ customRecommendations, setCustomRecommendations }}
              />
            )
          }
          {
            createAlertStep === 2
            && (
              <QuestionnaireDistribution
                distModel={{ model, setModel }}
              />
            )
          }
        </Modal.Body>
        <Modal.Footer>
          {
            createAlertStep === 1
            && (
              <>
                <Button variant="secondary" onClick={createPrimexAlertDraft}>
                  Save for Later
                </Button>
                <Button variant="primary" onClick={() => createPrimexAlert(true)}>
                  Next
                </Button>
              </>
            )
          }
          {
            createAlertStep === 2
            && (
              <>
                <Button variant="secondary" onClick={() => setCreateAlertStep(createAlertStep - 1)}>
                  Back
                </Button>
                {model?.userOnly
                  ? (
                    <Button disabled={!model?.specificUserId} variant="primary" onClick={submitDistribution}>
                      Assign
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={submitDistribution}>
                      Assign
                    </Button>
                  )}
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

CreateThreatAlert.defaultProps = {
  regularButton: false,
};

export default CreateThreatAlert;

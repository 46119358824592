import { AxiosPromise } from 'axios';
import { SystemAlert } from '../store/systemAlertSlice';
import api from './api';

export function getSystemAlerts() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/system-alerts')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createSystemAlert(alert: Partial<SystemAlert>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/system-alerts', alert)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneSystemAlert(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/system-alerts/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateSystemAlert(id: number, updateBody: Partial<SystemAlert>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/system-alerts/${id}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleSystemAlerts(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/system-alerts/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

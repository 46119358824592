import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

interface Props {
  claimId: number;
}
const DetailsNav: React.FC<Props> = ({ claimId, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="row mx-0 pb-2 align-items-center nav-row">
        <div className="col-12">
          <Nav variant="tabs" className="m-0 justify-content-around">
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/info`}>Claim Information</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/contacts`}>Contacts</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/technology`}>Technology</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/files`}>Files</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/updates`}>Updates</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/cyber-claims/details/${claimId}/private-comments`}>Private Comments</NavLink>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div className="row row-no-gutters">
        <div className="col-12">
          {children}
        </div>
      </div>
    </>
  );
};

export default DetailsNav;

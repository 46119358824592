/* eslint-disable arrow-body-style */
import React from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import CyberThreatDetailAdmin from '../shared/cyberThreats/CyberThreatAdminDetail';
import CyberThreatNav from '../shared/cyberThreats/CyberThreatNav';
import DraftThreats from '../shared/cyberThreats/DraftThreats';
import IncomingThreats from '../shared/cyberThreats/IncomingThreats';
import PrintCyberThreat from '../shared/cyberThreats/PrintCyberThreat';
import PublishedThreats from '../shared/cyberThreats/PublishedThreats';
import UserThreatLanding from '../shared/cyberThreats/UserThreatLanding';

interface Props { }

const CyberThreatAlerts: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  const { currentUser } = currentState.user;
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <Switch>
              <Route exact path={['/threat-alerts', '/threat-alerts/view/:id', '/threat-alerts/admin', '/threat-alerts/drafts', '/threat-alerts/published']}>
                <h2>
                  Cyber Threat Alerts
                </h2>
              </Route>
              <Route exact path="/threat-alerts/published/*">
                <h2>
                  Cyber Threat Alert Details
                </h2>
              </Route>
            </Switch>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="cyberthreat-container">
              <Switch>
                <Route exact path="/threat-alerts">
                  <UserThreatLanding />
                </Route>
                <Route path="/threat-alerts/view/:id">
                  <PrintCyberThreat />
                </Route>
                {currentUser
                  && currentUser.role
                  && superAdminRole
                  && (currentUser.role
                    >= superAdminRole) ? (
                      <>
                        <Route exact path={['/threat-alerts/admin', '/threat-alerts/drafts', '/threat-alerts/published']}>
                          <CyberThreatNav>
                            <Switch>
                              <Route exact path="/threat-alerts/admin">
                                <IncomingThreats />
                              </Route>
                              <Route exact path="/threat-alerts/drafts">
                                <DraftThreats />
                              </Route>
                              <Route exact path="/threat-alerts/published">
                                <PublishedThreats />
                              </Route>
                            </Switch>
                          </CyberThreatNav>
                        </Route>
                        <Route path="/threat-alerts/published/:id">
                          <CyberThreatDetailAdmin />
                        </Route>
                      </>
                  ) : null}
              </Switch>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CyberThreatAlerts;

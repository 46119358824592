import React from 'react';
import { Link } from 'react-router-dom';
import { Notification, updateNotificationAsync } from '../../../../store/notificationSlice';
import Read from '../../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../../assets/icons/MessageClosed.svg';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  notification: Notification;
}

const TrainingRow: React.FC<Props> = ({ notification }) => {
  const {
    id,
    viewed,
    trainingId,
    title,
    type,
    dueDate,
    createdAt,
  } = notification;
  const dispatch = useAppDispatch();

  const viewTraining = () => {
    dispatch(updateNotificationAsync({
      id,
      body: {
        viewed: true,
      },
    }));
  };

  if (trainingId) {
    return (
      <tr key={`${id}-cyberAlertNotification`}>
        <td>
          <img src={viewed ? Read : Unread} alt={viewed ? 'Read icon' : 'Unread icon'} />
        </td>
        <td>
          {dateAndTime(createdAt)}
        </td>
        <td>{type}</td>
        <td>
          <Link to="/training" onClick={viewTraining}>
            {title}
          </Link>
        </td>
        <td>{dateOnly(dueDate)}</td>
      </tr>
    );
  }
  return (
    <tr />
  );
};
export default TrainingRow;

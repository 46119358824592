/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import pdfBackgroundLogo from '../../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../../assets/bkg-diamonds.png';
import { IrClaim } from '../../../../../store/irClaimSlice';
import { dateOnly } from '../../../../../service/timeAndDate';

interface Props {
  irClaim: IrClaim;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 50,
    fontFamily: 'lato',
    fontSize: 12,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  fontBody: {
    fontFamily: 'Courier',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  halfWidth: {
    maxWidth: '50%',
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleBlockMargin: {
    marginRight: 10,
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: 5,
    height: 5,
    marginTop: 5,
    marginRight: 5,
  },
  titleFonts: {
    fontSize: 16,
  },
  subTitleFonts: {
    fontSize: 14,
  },
  spaceAfterColon: {
    marginRight: 5,
  },
  questionTitle: {
    textDecoration: 'underline',
  },
});

const MemberUserClaimInfoPdf: React.FC<Props> = (props: Props) => {
  const { irClaim } = props;
  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.fontBody]} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.spaceAfterColon]}>
              Claim Title:
            </Text>
            <Text>
              { irClaim.title }
            </Text>
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inline]}>
              <Text style={[styles.spaceAfterColon]}>
                Loss Date:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                { irClaim.lossDate && dateOnly(irClaim.lossDate) }
              </Text>
              <Text style={[styles.spaceAfterColon]}>
                Date Reported:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                { irClaim.entryDate && dateOnly(irClaim.entryDate) }
              </Text>
              <Text style={[styles.spaceAfterColon]}>
                Primex Adjuster:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                {
                  irClaim.primexAdjuster
                  && irClaim.primexAdjuster.firstName
                  && irClaim.primexAdjuster.lastName
                  && `${irClaim.primexAdjuster.firstName} ${irClaim.primexAdjuster.lastName}`
                }
              </Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.questionTitle]}>
              Incident Description
            </Text>
            <Text>{ irClaim.descriptionOfIncident }</Text>
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inline]}>
              <Text style={[styles.spaceAfterColon]}>
                Atom Lead:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                {
                  irClaim.atomLead
                  && irClaim.atomLead.firstName
                  && irClaim.atomLead.lastName
                  && `${irClaim.atomLead.firstName} ${irClaim.atomLead.lastName}`
                }
              </Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.questionTitle]}>
              Resolution Details
            </Text>
            <Text>{ irClaim.resolutionDetails }</Text>
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};
export default MemberUserClaimInfoPdf;

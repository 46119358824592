import React from 'react';
import Card from 'react-bootstrap/Card';
import {
  Route,
  Switch,
} from 'react-router-dom';
import ChecklistDetailAdmin from './Checklists/ChecklistAdminDetail';
import PostArticleDetailAdmin from './PostArticle/PostArticleAdminDetail';

interface Props {
}

const DetailContent: React.FC<Props> = () => (
  <Card className="manager-container">
    <div>
      <Switch>
        <Route exact path={['/content/detail/postarticle/:id']}>
          <PostArticleDetailAdmin />
        </Route>
        <Route exact path={['/content/detail/checklist/:id']}>
          <ChecklistDetailAdmin />
        </Route>
      </Switch>
    </div>
  </Card>
);

export default DetailContent;

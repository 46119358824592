import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Billboard } from '../../../store/billboardSlice';

interface Props {
  selectedBillboard: Billboard;
}

const BillboardDisplay: React.FC<Props> = ({ selectedBillboard }) => {
  const buttonPosition = () => {
    switch (selectedBillboard.buttonPosition) {
      case 'bottom-left':
        return 'left';
      case 'bottom-center':
        return 'center';
      case 'bottom-right':
        return 'right';
      default:
        return 'left';
    }
  };
  if (!selectedBillboard) {
    return <div />;
  }

  const billboardLink = () => {
    if (selectedBillboard.buttonLink.startsWith('/')) {
      return (
        <Link to={selectedBillboard.buttonLink}>
          <Button variant="primary">{selectedBillboard.buttonText}</Button>
        </Link>
      );
    }
    if (selectedBillboard.buttonLink.startsWith('https://')) {
      return (
        <Button target="_blank" href={selectedBillboard.buttonLink} variant="primary">{selectedBillboard.buttonText}</Button>
      );
    }
    return (
      <Button target="_blank" href={`https://${selectedBillboard.buttonLink}`} variant="primary">{selectedBillboard.buttonText}</Button>
    );
  };

  return (
    <div className="billboard-card card-section">
      <Card>
        <Card.Body>
          <div className="billboard-image d-flex flex-column p-5 justify-content-between" style={{ backgroundImage: `url(${selectedBillboard.imageGetUrl})`, height: '300px' }}>
            <div>
              <h1 style={{ color: `${selectedBillboard.color}`, fontSize: '36px' }}>{selectedBillboard.headline}</h1>
            </div>
            <div style={{ textAlign: buttonPosition() }}>
              {
                selectedBillboard.buttonLink
                && selectedBillboard.buttonText
                && billboardLink()
              }
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BillboardDisplay;

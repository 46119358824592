import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import InfoIcon from '../../../../assets/icons/info.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  data: {
    id: number;
    order: number;
    category: string;
    explanation: string;
    videoExplanation: string;
    questionType: string;
    answerOption1: string;
    answerOption2: string;
    answerOption3: string;
    answerOption4: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
  }
}

const QuestionInfoModal: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="question-info-button link" onClick={handleShow}>
        <FontAwesomeIcon size="lg" icon={faInfoCircle} />
        <span className="ml-1">Information about this question</span>
      </Button>

      <Modal show={show} onHide={handleClose} className="question-info-modal" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Question Explanation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {data.explanation}
          </p>
          {
            data.videoExplanation
            && (
              <div className="responsive-iframe_wrapper">
                <iframe src={data.videoExplanation.includes('https://www.youtube.com/embed/') ? data.videoExplanation : `https://www.youtube.com/embed/${data.videoExplanation}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Return to Question
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionInfoModal;

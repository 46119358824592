import React, { useEffect, useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createSystemAlertAsync,
  deleteMultipleSystemAlertsAsync,
  getOneSystemAlertAsync,
  getSystemAlertsAsync,
  SystemAlert,
  SystemAlertState,
  updateSystemAlertAsync,
} from '../../../../store/systemAlertSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';

interface Props {
  systemAlerts: SystemAlertState;
  deleteRows: {
    selectedRows: number[],
    setSelectedRows: Function,
  };
  showArchived: boolean,
}

const ManagementSystemAlertTable: React.FC<Props> = (props: Props) => {
  const { deleteRows, systemAlerts, showArchived } = props;
  // eslint-disable-next-line no-unused-vars
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStart, setSortStart] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortEnd, setSortEnd] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCreated, setSortCreated] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSystemAlertsAsync());
  }, []);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (deleteRows.selectedRows.includes(checkboxId)) {
      const newArray = deleteRows.selectedRows.filter((item) => item !== checkboxId);
      deleteRows.setSelectedRows([...newArray]);
    } else {
      deleteRows.setSelectedRows([checkboxId, ...deleteRows.selectedRows]);
    }
  };

  const archiveSystemAlert = async (id: number) => {
    await dispatch(updateSystemAlertAsync({ id, updateBody: { archived: true } }));
    dispatch(getSystemAlertsAsync());
  };

  const deleteSystemAlert = async (id: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultipleSystemAlertsAsync([id]));
      dispatch(getSystemAlertsAsync());
    }
  };

  const copySystemAlert = async (id: number) => {
    const selectedalert = await dispatch(getOneSystemAlertAsync(id));
    if (selectedalert) {
      const newSystemAlert: Partial<SystemAlert> = { ...selectedalert.payload };
      newSystemAlert.id = undefined;
      newSystemAlert.publishedDate = undefined;
      newSystemAlert.archived = false;
      newSystemAlert.draft = true;
      newSystemAlert.title = `Copy of: ${newSystemAlert.title}`;
      await dispatch(createSystemAlertAsync(newSystemAlert));
      dispatch(getSystemAlertsAsync());
    }
  };
  return (
    <Table bordered>
      <thead>
        <tr>
          <th className="check-box">
            <input type="checkbox" />
          </th>
          <th>
            <span>Title </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortTitle) {
                  case 'noSort':
                    setSortTitle('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortTitle('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortTitle('noSort');
                    return;
                  default:
                    setSortTitle('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
            </Button>
          </th>
          <th>
            <span>Status </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStatus) {
                  case 'noSort':
                    setSortStatus('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStatus('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStatus('noSort');
                    return;
                  default:
                    setSortStatus('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
            </Button>
          </th>
          <th>
            <span>Start Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStart) {
                  case 'noSort':
                    setSortStart('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStart('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStart('noSort');
                    return;
                  default:
                    setSortStart('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStart)} />
            </Button>
          </th>
          <th>
            <span>End Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortEnd) {
                  case 'noSort':
                    setSortEnd('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortEnd('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortEnd('noSort');
                    return;
                  default:
                    setSortEnd('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortEnd)} />
            </Button>
          </th>
          <th>
            <span>Created </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortCreated) {
                  case 'noSort':
                    setSortCreated('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortCreated('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortCreated('noSort');
                    return;
                  default:
                    setSortCreated('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortCreated)} />
            </Button>
          </th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          systemAlerts.systemAlerts
          && (
            systemAlerts.systemAlerts
              .slice()
              .filter((item) => (showArchived ? item : !item.archived))
              .sort((a, b) => {
                const aVar = a.title.toLowerCase();
                const bVar = b.title.toLowerCase();
                switch (sortTitle) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const returnStatus = (topic: SystemAlert) => {
                  if (!topic.draft && !topic.archived) {
                    return 1;
                  } if (topic.draft && !topic.archived) {
                    return 2;
                  }
                  return 3;
                };
                const aVar = returnStatus(a);
                const bVar = returnStatus(b);
                switch (sortStatus) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.startDate;
                const bVar = b.startDate;
                switch (sortStart) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.expirationDate;
                const bVar = b.expirationDate;
                switch (sortEnd) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.createdAt;
                const bVar = b.createdAt;
                switch (sortCreated) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((alert) => (
                <tr>
                  <td className="check-box">
                    <input
                      type="checkbox"
                      data-threatid={alert.id}
                      onChange={() => onCheckboxChange(alert.id)}
                    />
                  </td>
                  <td>
                    {alert.title}
                  </td>
                  <td>
                    {alert.archived && 'Archived'}
                    {
                      !alert.archived
                      && !alert.draft
                      && 'Active'
                    }
                    {
                      !alert.archived
                      && alert.draft
                      && 'Draft'
                    }
                  </td>
                  <td>
                    {dateOnly(alert.startDate)}
                  </td>
                  <td>
                    {dateOnly(alert.expirationDate)}
                  </td>
                  <td>
                    <p className="m-0">{dateAndTime(alert.createdAt)}</p>
                    <p className="m-0">
                      {
                        alert.author
                        && alert.author.firstName
                        && alert.author.lastName
                        && (
                          <>
                            {alert.author.firstName}
                            {' '}
                            {alert.author.lastName}
                          </>

                        )
                      }
                    </p>
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to={`/content/create/system-alerts/${alert.id}`}>
                            Edit
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => copySystemAlert(alert.id)}>
                          Copy
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteSystemAlert(alert.id)}>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => archiveSystemAlert(alert.id)}>
                          Archive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
          )
        }
      </tbody>
    </Table>
  );
};
export default ManagementSystemAlertTable;

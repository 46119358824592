/* eslint-disable consistent-return */
import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';
import High from '../../../assets/icons/high-risk.svg';
import Medium from '../../../assets/icons/med-risk.svg';
import Low from '../../../assets/icons/low-risk.svg';

interface Props { }

const CyberThreatCard: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    cyberThreatAssignments: state.cyberThreatAssignments,
  }));

  const { currentCyberThreatAssignments } = currentState.cyberThreatAssignments;
  const returnAlertIcon = (level: string) => {
    switch (level) {
      case 'Low':
        return Low;
      case 'Medium':
        return Medium;
      case 'High':
        return High;
      default:
        return '';
    }
  };

  return (
    <div className="card-section cyber-threat-card">
      <Card>
        <Card.Body>
          <Card.Title>
            <div className="d-flex justify-content-between">
              <h2>Latest Cyber Threat Alerts</h2>
              <Link to="/threat-alerts">View all cyber threat alerts &gt;&gt;</Link>
            </div>
          </Card.Title>
          <Table bordered>
            <thead>
              <tr>
                <th>Threat Level</th>
                <th>Title</th>
                <th>Issued</th>
              </tr>
            </thead>
            <tbody>
              {currentCyberThreatAssignments
                && currentCyberThreatAssignments
                  .slice()
                  // eslint-disable-next-line max-len
                  .sort((a, b) => (a.cyberThreat.publishDate.valueOf() < b.cyberThreat.publishDate.valueOf() ? 1 : -1))
                  .slice(0, 5)
                  // eslint-disable-next-line array-callback-return
                  .map((threat) => {
                    const {
                      id,
                      cyberThreat: {
                        id: threatId,
                        title,
                        publishDate,
                        // source,
                        threatLevel,
                      },
                    } = threat;
                    if (threat) {
                      return (
                        <tr key={id}>
                          <td>
                            <img src={returnAlertIcon(threatLevel)} alt="threat level icon" />
                            {` ${threatLevel}`}
                          </td>
                          <td className="cyber-card-title">
                            <Link to={`/threat-alerts/view/${threatId}`}>
                              {title}
                            </Link>
                          </td>
                          <td>{dateOnly(publishDate)}</td>
                        </tr>
                      );
                    }
                  })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CyberThreatCard;

import React, { useState } from 'react';
import { Dropdown, DropdownButton, Toast } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Route, Switch } from 'react-router-dom';
import Layout from '../layouts/Layout';
import ClaimsList from '../shared/cyberClaims/claimsList';
import ClaimDetails from '../shared/cyberClaims/claimDetails';
import ClaimInfo from '../shared/cyberClaims/claimInfo';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import exportZipPdfs from '../shared/cyberClaims/pdfComponents/zipPdfCreator';
import exportClaim from '../../assets/icons/export.svg';
import { reSyncClaimAsync } from '../../store/irClaimSlice';

interface Props { }

const CyberClaims: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [showToast, setShowToast] = useState(false);
  const currentState = useAppSelector((state) => ({
    user: state.user,
    claims: state.irClaim,
  }));

  const { currentUser } = currentState.user;
  const { selectedClaim } = currentState.claims;
  // eslint-disable-next-line max-len
  const IRAdminRole = process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL, 10);

  const IRUserCheck = () => {
    if (currentUser?.role && IRAdminRole) {
      const check: boolean = currentUser.role >= IRAdminRole;
      return check;
    }
    return false;
  };

  const syncAllClaims = () => {
    dispatch(reSyncClaimAsync(0))
      .then(() => {
        setShowToast(true);
      });
  };

  const primaryContactCheck = currentUser?.primaryITForMembers.find((member) => member)
    || currentUser?.primaryContactForMembers.find((member) => member);
  return (
    <Layout>
      <Toast className="cyber-info-toast" show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Header className="justify-content-between">
          <strong className="bold">Primex: Notify</strong>
        </Toast.Header>
        <Toast.Body>
          A task has been initiated on the server to synchronize the claims.
        </Toast.Body>
      </Toast>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 w-100 d-flex mt-4 pb-2 justify-content-between">
            <Switch>
              <Route exact path="/cyber-claims">
                <h2 className="ml-2 align-self-center ">Cyber Claim</h2>
              </Route>
              <Route exact path={(IRUserCheck()) ? ['/cyber-claims/details', '/cyber-claims/details/*'] : ['/cyber-claims/info', '/cyber-claims/info/*']}>
                <h2 className="ml-2 align-self-center">{`Cyber Claim: ${selectedClaim?.member?.memberName}`}</h2>
              </Route>
            </Switch>
            <Switch>
              <Route exact path={(IRUserCheck()) ? ['/cyber-claims', '/cyber-claims/details', '/cyber-claims/details/*'] : ['/cyber-claims', '/cyber-claims/info', '/cyber-claims/info/*']}>
                <div className="mx-2 d-flex">
                  <DropdownButton
                    id="claims-outline-button"
                    title="References"
                    variant="clear"
                  >
                    {
                      (IRUserCheck())
                      && (
                        <>
                          <Dropdown.Item href="/files/Cyber_First_Notice_of_Loss_Fillable.pdf" download>
                            Cyber First Notice of Loss
                          </Dropdown.Item>
                          <Dropdown.Item href="/files/Cyber_Incident_Response_Process_Guide.pdf" download>
                            Cyber Incident Response Process Guide
                          </Dropdown.Item>
                        </>
                      )
                    }
                    <Dropdown.Item href="/files/Cyber_Coverage_Summary_Toolkit_Version_June 25_2021.pdf" download>
                      Cyber Coverage Summary Toolkit
                    </Dropdown.Item>
                    <Dropdown.Item href="/files/Primex_2021_Property_and_Liability_Coverage_Changes.pdf" download>
                      Property and Liability Coverage Changes and Cyber Coverage
                    </Dropdown.Item>
                  </DropdownButton>
                  <Route exact path="/cyber-claims">
                    {
                      (IRUserCheck())
                        ? (
                          <Button type="button" className="ml-2" variant="secondary"> Add New Claim </Button>
                        ) : (
                          <Button type="button" className="ml-2" variant="secondary"> Report an Incident </Button>
                        )
                    }
                  </Route>
                  <Route exact path={['/cyber-claims/details', '/cyber-claims/details', '/cyber-claims/details/*', '/cyber-claims/info', '/cyber-claims/info/*']}>
                    <Button type="button" className="ml-2" onClick={() => selectedClaim && exportZipPdfs(selectedClaim, IRUserCheck())}>
                      <img className="mr-1" src={exportClaim} alt="export icon" />
                      {' Export Claim'}
                    </Button>
                  </Route>
                  <Route exact path="/cyber-claims">
                    {
                      (IRUserCheck())
                        ? (
                          <Button onClick={() => syncAllClaims()} type="button" className="ml-2" variant="secondary"> Sync All Claims </Button>
                        ) : (
                          <></>
                        )
                    }
                  </Route>

                </div>
              </Route>
            </Switch>
          </div>
        </div>

        <div className="row row-no-gutters m-0">
          <div className="col-12">
            {(IRUserCheck()
              || primaryContactCheck)
              && (
                <Switch>
                  <Route exact path="/cyber-claims">
                    <ClaimsList IRUserCheck={IRUserCheck} />
                  </Route>
                  {IRUserCheck()
                    && (
                      <Route path="/cyber-claims/details/:id">
                        <ClaimDetails />
                      </Route>
                    )}
                </Switch>
              )}
            {primaryContactCheck
              && (
                <Switch>
                  <Route path="/cyber-claims/info/:id">
                    <ClaimInfo />
                  </Route>
                </Switch>
              )}
          </div>
        </div>
        <Switch>
          <Route
            exact
            path={IRUserCheck()
              ? [
                '/cyber-claims',
                '/cyber-claims/info',
                '/cyber-claims/details/*',
              ] : [
                '/cyber-claims',
                '/cyber-claims/info',
                '/cyber-claims/info/:id',
              ]}
          >
            <div className="row row-no-gutters m-0">
              <div className="col-12 mt-5">
                <GlobalMessage flaggedIrClaimId={selectedClaim?.id} />
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default CyberClaims;

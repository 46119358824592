import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { dateOnly } from '../../../service/timeAndDate';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { getOneClaimAsync } from '../../../store/irClaimSlice';
import FilesTab from './detailTabs/files';
import UpdateTab from './detailTabs/updates';
import ManageMemberAccess from './modals/ManageMemberAccess';

interface Props { }

const ClaimInfo: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string
  }>();
  const currentState = useAppSelector((state) => ({
    claims: state.irClaim,
    user: state.user,
  }));
  // eslint-disable-next-line max-len

  const dispatch = useAppDispatch();
  const { selectedClaim } = currentState.claims;
  const { currentUser } = currentState.user;

  useEffect(() => {
    dispatch(getOneClaimAsync(parseInt(id || '', 10)));
  }, []);
  if (!selectedClaim) {
    return (<div />);
  }
  return (
    <div>
      <Card className="cyber-claims-container">
        <div className="mb-2">
          <h2>Claim Information</h2>
        </div>
        <div>
          <p className="mb-3 claim-info-subtitle">
            {`Claim Title: ${selectedClaim?.title}`}
          </p>
          <div className="d-flex p-0 mb-2 justify-content-between">
            <div>
              <p className="claim-info-subtitle">Loss date</p>
              <p>{dateOnly(selectedClaim?.lossDate)}</p>
            </div>
            <div>
              <p className="claim-info-subtitle">Date Reported</p>
              <p>{dateOnly(selectedClaim?.employerReportDate)}</p>
            </div>
            <div>
              <p className="claim-info-subtitle">Primex Adjuster</p>
              <p>{selectedClaim?.primexAdjuster ? `${selectedClaim.primexAdjuster.firstName} ${selectedClaim.primexAdjuster.lastName}` : 'N/A'}</p>
            </div>
            <div>
              <p>
                Member users with access:
                <span className="numeric-font">
                  {` ${(selectedClaim.userAccess || []).length}`}
                </span>
              </p>
              {
                currentUser
                && currentUser.primaryContactForMembers
                && currentUser.primaryContactForMembers.length > 0
                && currentUser.primaryContactForMembers.find(
                  (mem) => mem.id === selectedClaim.member.id,
                )
                && (
                  <ManageMemberAccess
                    claim={selectedClaim}
                  />
                )
              }
            </div>
          </div>

          <p className="claim-info-subtitle mb-1"> Incident Description </p>
          <p>
            {selectedClaim?.descriptionOfIncident}
          </p>

          <p className="claim-info-subtitle mb-1">ATOM Lead</p>
          <p>{selectedClaim?.atomLead ? `${selectedClaim.atomLead.firstName} ${selectedClaim.atomLead.lastName}` : 'N/A'}</p>

          <p className="claim-info-subtitle mb-1">Resolution Description</p>
          <p>
            {selectedClaim?.resolutionDetails}
          </p>
        </div>
      </Card>
      <Card className="mt-4 cyber-claims-container">
        <FilesTab atomIrUser={false} claim={selectedClaim} />
      </Card>
      <Card className="mt-4 cyber-claims-container">
        <UpdateTab claim={selectedClaim} updates={selectedClaim.updates} />
      </Card>
    </div>
  );
};

export default ClaimInfo;

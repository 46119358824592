import React, { useEffect, useRef, useState } from 'react';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../store/hooks';
import { getOneClaimAsync, IrClaimTriageInfo, updateClaimTriageInfoAsync } from '../../../../store/irClaimSlice';

interface Props {
  triageInfo: IrClaimTriageInfo;
  claimId: number;
}

const claimAccordion: React.FC<Props> = ({ triageInfo, claimId }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [debounceHandle, setDebounceHandle] = useState<any>();
  const [additionalHeight, setAdditionalHeight] = useState<number | null>(null);
  const additionalRef = useRef<any>(null);

  const updateUnauthorizedDataAccess = async (info: string) => {
    if (claimId && info) {
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          unauthorizedMemberEmailDataAccess: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateLawEnforcement = async (info: string) => {
    if (claimId && info) {
      let check = false;
      if (info === 'true') {
        check = true;
      }
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          permissionToInvolveLawEnforcement: check,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateItemsLost = async (info: string) => {
    if (claimId && info) {
      let lostItems = [info];
      if (triageInfo.itemsLost) {
        // eslint-disable-next-line array-callback-return
        const copyCheck = triageInfo.itemsLost.find((copy) => copy === info);
        if (copyCheck && triageInfo.itemsLost) {
          lostItems = triageInfo.itemsLost.filter((copy) => copy !== info);
        } else {
          lostItems.push(...triageInfo.itemsLost);
        }
      }
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          itemsLost: JSON.stringify(lostItems),
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateHowItemsLost = async (info: string) => {
    if (claimId && info) {
      let howLost = [info];
      if (triageInfo.howItemsLost) {
        // eslint-disable-next-line array-callback-return
        const copyCheck = triageInfo.howItemsLost.find((copy) => copy === info);
        if (copyCheck && triageInfo.howItemsLost) {
          howLost = triageInfo.howItemsLost.filter((copy) => copy !== info);
        } else {
          howLost.push(...triageInfo.howItemsLost);
        }
      }
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          howItemsLost: JSON.stringify(howLost),
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateCriticalInfrastructure = async (info: string) => {
    if (claimId && info) {
      let involved = [info];
      if (triageInfo.criticalInfrastructureInvolved) {
        // eslint-disable-next-line array-callback-return
        const copyCheck = triageInfo.criticalInfrastructureInvolved.find((copy) => copy === info);
        if (copyCheck && triageInfo.criticalInfrastructureInvolved) {
          involved = triageInfo.criticalInfrastructureInvolved.filter((copy) => copy !== info);
        } else {
          involved.push(...triageInfo.criticalInfrastructureInvolved);
        }
      }
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          criticalInfrastructureInvolved: JSON.stringify(involved),
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };
  const updateAdditionalInfo = async (info: string) => {
    if (claimId && info) {
      await dispatch(updateClaimTriageInfoAsync({
        id: triageInfo.id,
        updateBody: {
          additionalInformation: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const lostItemExist = (value: string) => {
    if (triageInfo.itemsLost && triageInfo.itemsLost.find((item) => item === value)) {
      return true;
    }
    return false;
  };

  const howLostItemExist = (value: string) => {
    if (triageInfo.howItemsLost && triageInfo.howItemsLost.find((item) => item === value)) {
      return true;
    }
    return false;
  };
  const criticalInfrastructureExist = (value: string) => {
    if (triageInfo.criticalInfrastructureInvolved
      && triageInfo.criticalInfrastructureInvolved.find((item) => item === value)) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (!additionalHeight) {
      setAdditionalHeight(additionalRef?.current?.scrollHeight);
    }
  }, [additionalHeight]);

  return (
    <Accordion defaultActiveKey="0" className="mt-4 ">
      <Card>
        <Accordion.Toggle className="p-2 text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
          Triage Information
          <div>
            <FontAwesomeIcon className="ml-auto" icon={open ? faAngleUp : faAngleDown} />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p className="p-2">
              The following items should be discussed with the reporting party.
              Please indicate information collected and actions taken.
            </p>
            <Form>
              <div className="">
                <div className="row d-flex p-3">
                  <div className="col-6">
                    <Form.Group>
                      <Form.Label className="bold">What was lost?</Form.Label>
                      <Form.Check
                        label="Money"
                        value="Money"
                        type="checkbox"
                        checked={lostItemExist('Money')}
                        id="money"
                        onClick={(e) => updateItemsLost(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Physical Property"
                        value="Physical Property"
                        type="checkbox"
                        id="physicalProperty"
                        checked={lostItemExist('Physical Property')}
                        onClick={(e) => updateItemsLost(e.currentTarget.value)}

                      />
                      <Form.Check
                        label="Corporate or Personal Data"
                        value="Corporate or Personal Data"
                        type="checkbox"
                        id="corporatePersonalData"
                        checked={lostItemExist('Corporate or Personal Data')}
                        onClick={(e) => updateItemsLost(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Unknown"
                        value="Unknown"
                        type="checkbox"
                        id="lostUnknown"
                        checked={lostItemExist('Unknown')}
                        onClick={(e) => updateItemsLost(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group>
                      <Form.Label className="bold">How was it lost?</Form.Label>
                      <Form.Check
                        label="Ransomware"
                        value="Ransomware"
                        type="checkbox"
                        id="ransomeware"
                        checked={howLostItemExist('Ransomware')}
                        onClick={(e) => updateHowItemsLost(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Business Email Compromise"
                        value="Business Email Compromise"
                        type="checkbox"
                        id="emailComp"
                        checked={howLostItemExist('Business Email Compromise')}
                        onClick={(e) => updateHowItemsLost(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Unknown"
                        value="Unknown"
                        type="checkbox"
                        id="howUnknown"
                        checked={howLostItemExist('Unknown')}
                        onClick={(e) => updateHowItemsLost(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row d-flex p-3">
                  <div className="col-6">
                    <Form.Group>
                      <Form.Label className="bold"> Did the unauthorized party have access to the member&apos;s email or data at any point?</Form.Label>
                      <Form.Check
                        label="Yes"
                        value="Yes"
                        name="authEmail"
                        checked={triageInfo.unauthorizedMemberEmailDataAccess ? triageInfo.unauthorizedMemberEmailDataAccess === 'Yes' : false}
                        type="radio"
                        id="authYes"
                        onChange={(e) => updateUnauthorizedDataAccess(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="No"
                        value="No"
                        name="authEmail"
                        checked={triageInfo.unauthorizedMemberEmailDataAccess ? triageInfo.unauthorizedMemberEmailDataAccess === 'No' : false}
                        type="radio"
                        id="authNo"
                        onChange={(e) => updateUnauthorizedDataAccess(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Unknown"
                        value="Unknown"
                        checked={triageInfo.unauthorizedMemberEmailDataAccess ? triageInfo.unauthorizedMemberEmailDataAccess === 'Unknown' : false}
                        name="authEmail"
                        type="radio"
                        id="authUnknown"
                        onChange={(e) => updateUnauthorizedDataAccess(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-6">
                    <Form.Group>
                      <Form.Label className="bold"> Does the member give permission to involve law enforcement if required?</Form.Label>
                      <Form.Check
                        label="Yes"
                        type="radio"
                        value="true"
                        checked={triageInfo.permissionToInvolveLawEnforcement}
                        id="lawEnfYes"
                        onClick={(e) => updateLawEnforcement(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="No"
                        value="false"
                        type="radio"
                        checked={!triageInfo.permissionToInvolveLawEnforcement}
                        id="lawEnfNo"
                        onClick={(e) => updateLawEnforcement(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col-6">
                    <Form.Group>
                      <Form.Label className="bold"> Does the event involve critical infrastructure?</Form.Label>
                      <Form.Check
                        label="Power Distribution"
                        value="Power Distribution"
                        type="checkbox"
                        id="powerDist"
                        checked={criticalInfrastructureExist('Power Distribution')}
                        onClick={(e) => updateCriticalInfrastructure(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Water Distribution"
                        value="Water Distribution"
                        type="checkbox"
                        id="waterDist"
                        checked={criticalInfrastructureExist('Water Distribution')}
                        onClick={(e) => updateCriticalInfrastructure(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Emergency Services"
                        value="Emergency Services"
                        type="checkbox"
                        id="emergencyServices"
                        checked={criticalInfrastructureExist('Emergency Services')}
                        onClick={(e) => updateCriticalInfrastructure(e.currentTarget.value)}
                      />
                      <Form.Check
                        label="Healthcare"
                        value="Healthcare"
                        type="checkbox"
                        id="healthcare"
                        checked={criticalInfrastructureExist('Healthcare')}
                        onClick={(e) => updateCriticalInfrastructure(e.currentTarget.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <Form.Group className="col-12">
                <Form.Label className="bold">Additional information</Form.Label>
                <Form.Control
                  placeholder="Enter any additional information the reporting party provided"
                  as="textarea"
                  ref={additionalRef}
                  rows={5}
                  defaultValue={triageInfo.additionalInformation}
                  onChange={(e) => {
                    if (debounceHandle) clearTimeout(debounceHandle);
                    const { currentTarget: { value } } = e;
                    const handle = setTimeout(() => updateAdditionalInfo(value), 750);
                    setDebounceHandle(handle);
                    // eslint-disable-next-line max-len
                    setAdditionalHeight(e.currentTarget.value.length === 0 ? 100 : e.currentTarget.scrollHeight);
                    updateAdditionalInfo(e.currentTarget.value);
                  }}
                  style={{
                    minHeight: `${additionalHeight}px`,
                    overflow: 'hidden',
                  }}
                />
              </Form.Group>

            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default claimAccordion;

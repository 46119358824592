import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Member } from './memberSlice';
import { User } from './userSlice';
import {
  getAllClaims,
  getOneClaim,
  notifyAtom,
  resyncClaim,
  updateOneClaim,
} from '../api/irClaimAPI';
import updateClaimTriageInfo from '../api/irClaimTriageAPI';
import updateClaimTechnology from '../api/irClaimTechnologyAPI';
import { createContact, deleteContact, updateContact } from '../api/IrClaimContactAPI';
import { createIrClaimFile, getOneIrClaimFile } from '../api/irClaimFileAPI';
import { putImageOnS3 } from '../api/s3API';
import createUpdate from '../api/irUpdateAPI';
import createPrivateComment from '../api/irPrivateCommentAPI';

export interface commentDTO {
  irClaimId: number;
  comment: string;
}

export interface IrUpdateComment {
  id: number;
  comment: string;
  author: User;
  irClaim: IrClaim;
  createdAt: Date;
  updatedAt: Date;
}

export interface IrPrivateCommunication {
  id: number;
  comment: string;
  author: User;
  irClaim: IrClaim;
  createdAt: Date;
  updatedAt: Date;
}

export interface IrFileDTO {
  title: string;
  comments: string;
  availableDownload: boolean;
  origin: string;
  fileName: string;
  fileType: string;
  s3StorageName: string;
  s3Directory: string;
  irClaimId: number;
}

export interface IrFile {
  id: number;
  title: string;
  comments: string;
  availableDownload: boolean;
  origin: string;
  fileName: string;
  fileType: string;
  s3StorageName: string;
  s3Directory: string;
  addedBy: User;
  irClaim: IrClaim;
  preAuthPostUrl: string;
  preAuthGetUrl: string;
  createdAt: Date;
}

export interface IrContactDTO {
  contactType:
  | 'reporting-party'
  | 'backup-reporting-contact'
  | 'primary-contact'
  | 'backup-primary-contact'
  | 'it-contact'
  | 'backup-it-contact'
  | 'beazley-contact'
  | 'beazley-breach-counsel'
  | 'members-attorney'
  | 'alliant-contact'
  | 'backup-reporting-contact'
  | 'backup-primary-contact'
  | 'backup-it-contact'
  | 'other-contact';
  irClaim: IrClaim;
}
export interface IrContact {
  id: number;
  contactType:
  | 'reporting-party'
  | 'backup-reporting-contact'
  | 'primary-contact'
  | 'backup-primary-contact'
  | 'it-contact'
  | 'backup-it-contact'
  | 'beazley-contact'
  | 'beazley-breach-counsel'
  | 'members-attorney'
  | 'alliant-contact'
  | 'backup-reporting-contact'
  | 'backup-primary-contact'
  | 'backup-it-contact'
  | 'other-contact';
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  officePhone: string | null;
  cellPhone: string | null;
  jobTitle: string | null;
  category: string | null;
  notes: string | null;
  sameAsReportingPart: boolean;
  msp: boolean;
  irContactMemberUser?: User;
  irClaimId: number;
}

export interface IrClaimTechnology {
  id: number;
  equipmentCompany: string | null;
  equipmentAttention: string | null;
  equipmentAddress1: string | null;
  equipmentAddress2: string | null;
  equipmentCity: string | null;
  equipmentState: string | null;
  equipmentZipCode: number | null;
  impactedExternalIPsAndLocations: string | null;
  internalIPRanges: string | null;
  applianceVendorAndVersion: string | null;
  endpointSecuritySoftware: string | null;
  recommendedEDR: string | null;
  impactedDNSProviders: string | null;
  impactedWANProviders: string | null;
  cloudServiceProviders: string | null;
  saasServiceProviders: string | null;
  wapVendorModelRevisionNumber: string | null;
  identityManagementApplications: string | null;
  emailServerSoftware: string | null;
  hypervisorOEMVersion: string | null;
  managedSOC: string | null;
  switchingHardwareVendorType: string | null;
}

export interface IrClaimTriageInfoDTO {
  id: number;
  itemsLost: string | null;
  howItemsLost: string | null;
  unauthorizedMemberEmailDataAccess: string | null;
  permissionToInvolveLawEnforcement: boolean;
  criticalInfrastructureInvolved: string | null;
  additionalInformation: string | null;
}

export interface IrClaimDTO {
  id: number;
  closedDate: Date | null;
  resolutionDetails: string;
  triageInfo: IrClaimTriageInfoDTO;
  technology: IrClaimTechnology;
  createdAt: Date;
  updatedAt: Date | null;
  primexAdjusterId?: number;
  involvesPII: boolean;
  atomLeadId?: number;
  notAClaim?: boolean;
  newUserAccess?: number[];
}

export interface IrClaimTriageInfo {
  id: number;
  itemsLost: string[] | null;
  howItemsLost: string[] | null;
  unauthorizedMemberEmailDataAccess: string | null;
  permissionToInvolveLawEnforcement: boolean;
  criticalInfrastructureInvolved: string[] | null;
  additionalInformation: string;
  createdAt: Date;
  updatedAt: Date | null;
}

export interface IrClaim {
  id: number;
  incomingClaimId: number;
  claimNumber: string;
  title: string;
  employerReportDate: Date;
  entryDate: Date | null;
  referenceNumber: number | null;
  lossDate: Date;
  contactEmail: string | null;
  descriptionOfIncident: string | null;
  involvesPII: boolean;
  member: Member;
  closedDate: Date | null;
  incidentType: string | null;
  resolutionDetails: string;
  triageInfo: IrClaimTriageInfo;
  primexAdjuster: User | null;
  atomLead: User | null;
  technology: IrClaimTechnology;
  atomAlerted: boolean;
  notAClaim: boolean;
  irContacts: IrContact[];
  irFiles: IrFile[];
  updates: IrUpdateComment[];
  privateCommunications: IrPrivateCommunication[];
  userAccess: User[];
  createdAt: Date;
  updatedAt: Date | null;
  status: string | null;
}

export interface IrClaimState {
  irClaims: IrClaim[] | null;
  selectedClaim: IrClaim | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  irClaimcount: number | null;
}

const initialState: IrClaimState = {
  irClaims: [],
  selectedClaim: null,
  status: 'idle',
  error: null,
  irClaimcount: 0,
};

const createIrClaimFileAsync = createAsyncThunk(
  'ir-claim/createFile',
  async (arg: { irFileDto: IrFileDTO, file: File }, { rejectWithValue }) => {
    try {
      const response: any = await createIrClaimFile(arg.irFileDto);
      if (arg.file && response.data.preAuthPostUrl) {
        await putImageOnS3(response.data.preAuthPostUrl, arg.file);
        response.data.preAuthPostUrl = null;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneIrFileAsync = createAsyncThunk(
  'ir-claim/getOnefile',
  async (fileId: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneIrClaimFile(fileId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getAllClaimsAsync = createAsyncThunk(
  'ir-claim/getAll',
  async (arg: any = null, { rejectWithValue }) => {
    try {
      const response = await getAllClaims(arg as any);
      return response;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneClaimAsync = createAsyncThunk(
  'ir-claim/getOne',
  async (claimId: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneClaim(claimId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateOneClaimAsync = createAsyncThunk(
  'ir-claim/update',
  async (arg: { claimId: number, updateBody: Partial<IrClaimDTO> }, { rejectWithValue }) => {
    try {
      const response: any = await updateOneClaim(arg.claimId, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const updateClaimTriageInfoAsync = createAsyncThunk(
  'ir-claim-triage-info/update',
  async (arg: {
    id: number,
    updateBody: Partial<IrClaimTriageInfoDTO>
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateClaimTriageInfo(arg.id, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const updateClaimTechnologyAsync = createAsyncThunk(
  'ir-claim-triage-info/update',
  async (arg: {
    id: number,
    updateBody: Partial<IrClaimTechnology>
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateClaimTechnology(arg.id, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const createContactAsync = createAsyncThunk(
  'ir-contact/create',
  async (contact: Partial<IrContactDTO>, { rejectWithValue }) => {
    try {
      const response: any = await createContact(contact);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const createUpdateAsync = createAsyncThunk(
  'ir-update/create',
  async (update: Partial<IrUpdateComment>, { rejectWithValue }) => {
    try {
      const response: any = await createUpdate(update);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const createPrivateCommunicationAsync = createAsyncThunk(
  'ir-private-communication/create',
  async (comment: Partial<IrPrivateCommunication>, { rejectWithValue }) => {
    try {
      const response: any = await createPrivateComment(comment);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const updateClaimContactAsync = createAsyncThunk(
  'ir-contact/update',
  async (arg: { id: number, updateBody: Partial<IrContact> }, { rejectWithValue }) => {
    try {
      const response: any = await updateContact(arg.id, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);
const deleteContactAsync = createAsyncThunk(
  'ir-contact/delete',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await deleteContact(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const notifyAtomAsync = createAsyncThunk(
  'ir-claim/notify',
  async (claimId: number, { rejectWithValue }) => {
    try {
      const response: any = await notifyAtom(claimId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const reSyncClaimAsync = createAsyncThunk(
  'ir-claim/sync',
  async (claimId: number, { rejectWithValue }) => {
    try {
      const response: any = await resyncClaim();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const irClaimSlice = createSlice({
  name: 'irClaims',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllClaimsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllClaimsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const { data, count } = action.payload as any;
        state.irClaims = data;
        state.irClaimcount = count;
        console.log(state.irClaimcount);
        state.error = null;
      })
      .addCase(getAllClaimsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.irClaims = [];
        state.error = action.payload;
      })
      .addCase(getOneClaimAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneClaimAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedClaim = action.payload;
        state.error = null;
      })
      .addCase(getOneClaimAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateOneClaimAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOneClaimAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateOneClaimAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateClaimTriageInfoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateClaimTriageInfoAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateClaimTriageInfoAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createIrClaimFileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createIrClaimFileAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createIrClaimFileAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getOneIrFileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneIrFileAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getOneIrFileAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default irClaimSlice.reducer;

export {
  createContactAsync,
  createUpdateAsync,
  createPrivateCommunicationAsync,
  getAllClaimsAsync,
  getOneClaimAsync,
  updateOneClaimAsync,
  updateClaimTriageInfoAsync,
  updateClaimTechnologyAsync,
  updateClaimContactAsync,
  deleteContactAsync,
  notifyAtomAsync,
  createIrClaimFileAsync,
  getOneIrFileAsync,
  reSyncClaimAsync,
};

import { AxiosPromise } from 'axios';
import { QuestionnaireDistributionModel } from '../models/distributionModels';
import { QuestionnaireAssignment } from '../store/questionnaireAssignmentsSlice';
import api from './api';

export function getCurrentQuestionnaireAssignments() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/questionnaire-assignments/current')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// eslint-disable-next-line max-len
export function createQuestionnaireAssignments(questionnaireAssignmentsCriteria: QuestionnaireDistributionModel) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/questionnaire-assignments', questionnaireAssignmentsCriteria)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getAssignmentsForQuestionnaire(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/questionnaire-assignments?questionnaire=${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleAssignments(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete('/questionnaire-assignments/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateQuestionnaireAssignment(
  id: number, updateBody: Partial<QuestionnaireAssignment>,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/questionnaire-assignments/${id}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React, { useEffect } from 'react';
import {
  NavLink,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getOneMemberAsync } from '../../../../store/memberSlice';
import ProfileInfo from './ProfileInfo';
import UserDetails from './UserDetails';
import ClaimHistory from './ClaimHistory';

interface Props { }

const MemberProfileNav: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    member: state.member,
    user: state.user,
  }));

  // eslint-disable-next-line no-unused-vars
  const { member: { selectedMember }, user: { currentUser } } = currentState;

  useEffect(() => {
    dispatch(getOneMemberAsync(parseInt(id || '', 10)));
  }, []);
  return (
    <>
      <div className="row mx-0 align-items-center nav-row">
        <div className="col-12 mb-2">
          <Nav variant="tabs" className="m-0">
            <Nav.Item>
              <NavLink exact to={`/members/profile/${id}/info`}>Member Information</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to={`/members/profile/${id}/users`}>User Information</NavLink>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div className="row row-no-gutters">
        <div className="col-12">
          <Switch>
            <Route path={`/members/profile/${id}/info`}>
              {
                selectedMember
                && (
                  <ProfileInfo member={selectedMember} currentUser={currentUser} />
                )
              }
            </Route>
            <Route path={`/members/profile/${id}/users`}>
              {
                selectedMember
                && (
                  <UserDetails member={selectedMember} />
                )
              }
            </Route>
            <Route path={`/members/profile/${id}/claims`}>
              {
                selectedMember
                && (
                  <ClaimHistory member={selectedMember} />
                )
              }
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default MemberProfileNav;

/* eslint-disable quote-props */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch } from '../../../../store/hooks';
import {
  CreatePrimexSecurityAlert,
  getAllPrimexThreatAlertsAsync,
  nullSelectedPrimexAlert,
  PrimexSecurityAlert,
  updatePrimexThreatAlertAsync,
} from '../../../../store/primexAlertsSlice';
import { dateAndTime } from '../../../../service/timeAndDate';

interface Props {
  currentId: number,
  securityAlertDraft: PrimexSecurityAlert | null,
  dateConversion: Function,
  show: boolean,
  onClose: () => void,
}

const EditContentOnly: React.FC<Props> = (props) => {
  const {
    currentId,
    securityAlertDraft,
    dateConversion,
    show,
    onClose,
  } = props;

  const [headline, setHeadline] = useState('');
  const [severity, setSeverity] = useState<'Select' | 'Low' | 'Medium' | 'High' | ''>('');
  const [technologies, setTechnologies] = useState('');
  const [sourceTitle, setSourceTitle] = useState('');
  const [originalSource, setOriginalSource] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customRecommendations, setCustomRecommendations] = useState('');

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClose = () => {
    dispatch(nullSelectedPrimexAlert());
    onClose();
  };

  const resetValues = () => {
    setHeadline('');
    setSeverity('');
    setSourceTitle('');
    setTechnologies('');
    setOriginalSource('');
    setCustomDescription('');
    setCustomRecommendations('');
  };

  const updateTitle = (oldTitle: string) => {
    const cleanedTitle = oldTitle.split('-Updated');
    return `${cleanedTitle} -Updated ${dateAndTime(new Date())}`;
  };

  const publishPrimexAlert = async () => {
    if (securityAlertDraft) {
      const alertBody: Partial<CreatePrimexSecurityAlert> = {
        title: updateTitle(securityAlertDraft.title),
        threatLevel: severity || securityAlertDraft.threatLevel,
        technologiesImpacted: technologies || securityAlertDraft.technologiesImpacted,
        sourceTitle: sourceTitle || securityAlertDraft.sourceTitle,
        source: originalSource || securityAlertDraft.source,
        content: customDescription || securityAlertDraft.content,
        recommendations: customRecommendations || securityAlertDraft.recommendations,
        draft: false,
        publishDate: new Date(),
      };
      await dispatch(updatePrimexThreatAlertAsync({
        id: currentId,
        body: alertBody,
      }));
      await dispatch(getAllPrimexThreatAlertsAsync());
      dispatch(nullSelectedPrimexAlert());
      history.push('/threat-alerts/published');
      handleClose();
    }
  };
  // TODO : Make sure edit features are up to date with create component
  return (
    <>
      <Modal
        className={securityAlertDraft?.incomingAlert ? 'mega-modal' : 'not-mega-modal'}
        size="xl"
        show={show}
        onShow={resetValues}
      >
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title>
            <h4 className="ml-2 mb-0">Edit Cyber Threat Alert</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            securityAlertDraft
            && securityAlertDraft.incomingAlert
            && (
              <div className="row">
                <div className="col-6">
                  <div className="alert-box">
                    {
                      securityAlertDraft.incomingAlert.source === 'NCAS'
                      && (
                        <div>
                          <h1>
                            {securityAlertDraft.incomingAlert.title}
                          </h1>
                          <div className="d-flex flex-column">
                            <h3 className="m-0">DATE(S) ISSUED:</h3>
                            <span>{dateConversion(securityAlertDraft.incomingAlert.publishDate) || 'Unknown'}</span>
                          </div>
                        </div>
                      )
                    }
                    <div className="parsed-content">
                      {
                        parse(DOMPurify.sanitize(securityAlertDraft.incomingAlert.content))
                      }
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <Form>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="headline">
                          <Form.Label>Headline</Form.Label>
                          <Form.Control
                            type="text"
                            value={updateTitle(securityAlertDraft.title)}
                            readOnly
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group controlId="severitySelect">
                          <Form.Label>Severity</Form.Label>
                          <Form.Control
                            as="select"
                            value={severity || securityAlertDraft.threatLevel}
                            onChange={(e: any) => setSeverity(e.target.value)}
                          >
                            <option>Select</option>
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-8">
                        <Form.Group controlId="technologiesImpacted">
                          <Form.Label>Technologies Impacted</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter technologies"
                            value={technologies || securityAlertDraft.technologiesImpacted}
                            onChange={(e: any) => setTechnologies(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source name or URL"
                            value={sourceTitle || securityAlertDraft.sourceTitle}
                            onChange={(e: any) => setSourceTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source name or URL"
                            value={originalSource || securityAlertDraft.source}
                            onChange={(e: any) => setOriginalSource(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Description</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customDescription || securityAlertDraft.content}
                            onChange={setCustomDescription}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Recommendations</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customRecommendations || securityAlertDraft.recommendations}
                            onChange={setCustomRecommendations}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }
          {
            securityAlertDraft
            && !securityAlertDraft.incomingAlert
            && (
              <div className="row">
                <div className="col-12">
                  <Form>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="headline">
                          <Form.Label>Headline</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter headline"
                            value={headline || securityAlertDraft.title}
                            onChange={(e: any) => setHeadline(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group controlId="severitySelect">
                          <Form.Label>Severity</Form.Label>
                          <Form.Control
                            as="select"
                            value={severity || securityAlertDraft.threatLevel}
                            onChange={(e: any) => setSeverity(e.target.value)}
                          >
                            <option>Select</option>
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-8">
                        <Form.Group controlId="technologiesImpacted">
                          <Form.Label>Technologies Impacted</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter technologies"
                            value={technologies || securityAlertDraft.technologiesImpacted}
                            onChange={(e: any) => setTechnologies(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source name or URL"
                            value={sourceTitle || securityAlertDraft.sourceTitle}
                            onChange={(e: any) => setSourceTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source name or URL"
                            value={originalSource || securityAlertDraft.source}
                            onChange={(e: any) => setOriginalSource(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Description</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customDescription || securityAlertDraft.content}
                            onChange={setCustomDescription}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Recommendations</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customRecommendations || securityAlertDraft.recommendations}
                            onChange={setCustomRecommendations}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => publishPrimexAlert()}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditContentOnly;

import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import HowToIcon from '../../../../assets/icons/HowTo-dash.svg';

interface Props { }

const HowToCard: React.FC<Props> = () => (
  <Card>
    <Card.Body>
      <Card.Title>
        <h2 className="text-center">
          How-To Topic
        </h2>
      </Card.Title>
      <div className="d-flex justify-content-between align-items-center flex-column">
        <div className="m-3">
          <img src={HowToIcon} alt="How-To Icon" />
        </div>
        <div className="m-3">
          <p>
            Create training tips to help members use the features within the portal.
          </p>
        </div>
      </div>
      <div className="d-flex flex-column button-box">
        <Link to="/content/create/how-to">
          <Button className="m-2" variant="primary">Create</Button>
        </Link>
        <Link to="/content/manage/how-to">
          <Button className="m-2" variant="secondary">Manage</Button>
        </Link>
      </div>
    </Card.Body>
  </Card>
);

export default HowToCard;

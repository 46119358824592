/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import DeleteIcon from '../../../../../assets/icons/remove.svg';
import ViewChecklistItem from './ViewChecklistItem';
import { Checklist } from '../../../../../store/checklistSlice';
import { dateAndTime } from '../../../../../service/timeAndDate';
import {
  ChecklistAssignment,
  getChecklistAssignmentAsync,
  getCurrentChecklistAssignmentsAsync,
  updateChecklistAssignmentAsync,
} from '../../../../../store/checklistAssignmentSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Document } from '../../../../../store/documentsSlice';

interface Props {
  preview?: boolean;
  checklistAssignment?: ChecklistAssignment;
  checklist: Checklist | null;
}

const ViewChecklist: React.FC<Props> = (props) => {
  const { preview, checklist, checklistAssignment } = props;
  const dispatch = useAppDispatch();
  const [checklistError, setChecklistError] = useState(false);
  const currentState = useAppSelector((state) => ({
    user: state.user,
    // checklistAssignments: state.checklistAssignments,
  }));
  const { currentUser } = currentState.user;
  const history = useHistory();

  if (!checklist || !currentUser) {
    return (<div />);
  }

  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  const completeChecklist = async () => {
    if (
      checklistAssignment
      && checklistAssignment.responses
      && checklistAssignment.responses.length > 0
      && !checklistAssignment.responses.find((response) => !response.itemCompleted)
    ) {
      await dispatch(updateChecklistAssignmentAsync({
        id: checklistAssignment.id,
        updateBody: {
          completed: true,
          completedDate: new Date(),
        },
      }));
      setChecklistError(false);
      dispatch(getChecklistAssignmentAsync(checklistAssignment.id));
      dispatch(getCurrentChecklistAssignmentsAsync());
      history.push('/checklists');
    } else {
      setChecklistError(true);
      setTimeout(() => { setChecklistError(false); }, 500);
    }
  };

  const uploadUserDocuments = async (assignmentId: number, uploadFiles: File[]) => {
    if (uploadFiles && uploadFiles.length > 0) {
      await dispatch(updateChecklistAssignmentAsync({
        id: assignmentId,
        updateBody: {
          uploadUserDocs: uploadFiles.map((file) => ({
            title: file.name,
            origin: 'Checklist',
            fileName: file.name,
            fileType: file.type,
            s3Directory: 'memberDocs',
            members: null,
            allMembers: false,
          })),
        },
        uploads: uploadFiles,
      }));
      dispatch(getChecklistAssignmentAsync(assignmentId));
    }
  };

  const removeUserFile = async (docId: number, assignmentId: number) => {
    if (
      checklistAssignment
      && checklistAssignment.userDocuments
      && checklistAssignment.userDocuments.length > 0
    ) {
      await dispatch(updateChecklistAssignmentAsync({
        id: assignmentId,
        updateBody: {
          userDocuments:
            checklistAssignment.userDocuments.slice().filter((doc) => doc.id !== docId) || [],
        },
      }));
      dispatch(getChecklistAssignmentAsync(assignmentId));
    }
  };

  const disabledStatus = (attachment: Document) => {
    if (
      attachment
      && attachment.addedBy
      && attachment.addedBy.id === currentUser.id
    ) {
      return false;
    }
    if (
      (currentUser
        && currentUser.role
        && superAdminRole
        && (currentUser.role
        >= superAdminRole))
    ) {
      return false;
    }
    return true;
  };

  return (
    <Card className="view-checklist border-0">
      <div className="title">
        <h2>{ checklist.title || ''}</h2>
        <p>
          Created by:
          <span className="ml-2">
            {
              checklist.author
              && checklist.author.firstName
              && checklist.author.lastName
              && (
                ` ${checklist.author.firstName} ${checklist.author.lastName} `
              )
            }
          </span>
          <span className="numeric-font ml-2">
            {
              checklist.createdAt
              && dateAndTime(checklist.createdAt)
            }
          </span>
        </p>
      </div>
      <div className="summary">
        <p>{ checklist.summary || ''}</p>
      </div>
      <div className="supporting-info">
        <p>{ checklist.additionalInfo || ''}</p>
      </div>
      <div className="attachments">
        {
          checklist.documents
          && checklist.documents.length > 0
          && checklist.documents.map((doc) => (
            <p key={`checklist-doc-${doc.id}`}>
              <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
              <a target="_blank" rel="noreferrer" href={doc.docGetUrl} download>{doc.fileName}</a>
            </p>
          ))
        }
      </div>
      <div className="checklist-items">
        {
          preview
          && checklist.checklistItems
          && checklist.checklistItems.length > 0
          && checklist.checklistItems
            .slice()
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((checklistItem) => (
              <div className="checklist-item my-2" key={`checklistItem-${checklistItem.order}`}>
                <ViewChecklistItem checkComplete={checklistError} checklistItem={checklistItem} />
              </div>
            ))
        }
        {
          !preview
          && checklistAssignment
          && checklistAssignment.responses
          && checklistAssignment.responses.length > 0
          && checklistAssignment.responses
            .slice()
            .sort((a, b) => (a.checklistItem.order < b.checklistItem.order ? -1 : 1))
            .map((response) => (
              <div className="checklist-item my-2" key={`checklistItem-${response.checklistItem.order}`}>
                <ViewChecklistItem
                  checklistAssignment={checklistAssignment}
                  response={response}
                  checklistItem={response.checklistItem}
                  checkComplete={checklistError}
                />
              </div>
            ))
        }
      </div>
      <div className="footer-box d-flex justify-content-between">
        <div className="user-attachments">
          <Form.Group controlId="user-uploads">
            <Form.Label className="mt-2 mb-0">Do you want to upload any documents for reference?</Form.Label>
            <p className="text-muted">
              File types include doc, docx, pdf, jpg, jpeg, png, xls, xlsx, csv
            </p>
            <div className="d-flex">
              <Button disabled={preview || (checklistAssignment && checklistAssignment.completed)} className="upload-file-button">
                Browse
                <Form.File
                  disabled={preview || (checklistAssignment && checklistAssignment.completed)}
                  multiple
                  custom
                  type="file"
                  id="banner-file-upload"
                  accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx,.csv"
                  onChange={(e: any) => {
                    if (e.target.files) {
                      const filesList: File[] = Object.keys(e.target.files).map(
                        (key) => e.target.files[key],
                      );
                      if (
                        filesList
                        && filesList.length > 0
                        && checklistAssignment
                      ) {
                        uploadUserDocuments(checklistAssignment.id, filesList);
                      }
                    }
                  }}
                />
              </Button>
              {
                checklistAssignment
                && checklistAssignment.userDocuments
                && checklistAssignment.userDocuments.length > 0
                && checklistAssignment.userDocuments.map((attachment) => (
                  <div className="d-flex flex-row ml-2">
                    <Button
                      disabled={disabledStatus(attachment)}
                      className="button-link d-flex align-items-center"
                      onClick={() => removeUserFile(attachment.id, checklistAssignment.id)}
                    >
                      <img src={DeleteIcon} alt="Delete Icon" />
                    </Button>
                    <a target="_blank" rel="noreferrer" href={attachment.docGetUrl} download className="m-0 align-self-center">{attachment.fileName}</a>
                  </div>
                ))
              }
            </div>
          </Form.Group>
        </div>
        <div className="complete-box">
          <Button disabled={preview || (checklistAssignment && checklistAssignment.completed)} variant="primary" onClick={completeChecklist}>Complete &gt;</Button>
        </div>
      </div>
    </Card>
  );
};

export default ViewChecklist;

ViewChecklist.defaultProps = {
  preview: false,
  checklistAssignment: undefined,
};

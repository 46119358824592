import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import Delete from '../../../../../assets/icons/Delete.svg';
import { ChecklistItem } from '../../../../../models/checklistModel';

interface Props {
  item: ChecklistItem;
  removeSlide: Function;
  updateContent: Function;
}

const CreateChecklistItem: React.FC<Props> = (props: Props) => {
  const {
    item,
    removeSlide,
    updateContent,
  } = props;
  const [open, setOpen] = useState(false);

  const updateSupportInfo = (content: string) => {
    updateContent({
      ...item,
      supportingInfo: content,
    });
  };

  const updateTitle = (content: string) => {
    updateContent({
      ...item,
      itemTitle: content,
    });
  };

  return (
    <Accordion className="item-accordion">
      <Card>
        <Card.Header className="d-flex how-to-header justify-content-between">
          <Form.Group className="mb-0 input-group" controlId="title">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={item.itemTitle}
              onChange={(e: any) => updateTitle(e.target.value)}
            />
          </Form.Group>
          <Button
            className="button-link p-0"
            onClick={() => {
              // eslint-disable-next-line no-alert
              const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
              if (result) {
                removeSlide(item.order);
              }
            }}
          >
            <img src={Delete} alt="delete icon" />
          </Button>
          <Accordion.Toggle className="button-link text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <div className="p-4">
            <Form.Group controlId="post-article-textarea">
              <Form.Label>Supporting Information</Form.Label>
              <Form.Control
                value={item.supportingInfo}
                maxLength={255}
                onChange={(e) => updateSupportInfo(e.target.value)}
                as="textarea"
                rows={4}
              />
              <p className="character-count">
                {`(${item?.supportingInfo?.length} of 255 characters)`}
              </p>
              <FormControl.Feedback type="invalid">Please enter supporting information.</FormControl.Feedback>
            </Form.Group>
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CreateChecklistItem;

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from 'react-bootstrap/FormControl';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import DeleteIcon from '../../../../assets/icons/remove.svg';
import { dateYearMonthDay } from '../../../../service/timeAndDate';
import { ChecklistDistributionModel, DistributionUniversal } from '../../../../models/distributionModels';
import { getMembersAsync } from '../../../../store/memberSlice';
import { ChecklistItem, CreateChecklistModel, UploadedDocuments } from '../../../../models/checklistModel';
import CreateChecklistItem from './checklistComponents/CreateChecklistItem';
import { getAllUsersWithMembersAsync } from '../../../../store/userSlice';
import {
  Checklist,
  ChecklistDTO,
  createChecklistAsync,
  nullSelectedChecklist,
  updateChecklistAsync,
} from '../../../../store/checklistSlice';
import { createChecklistAssignmentsAsync } from '../../../../store/checklistAssignmentSlice';
import { DocumentDTO } from '../../../../store/documentsSlice';
import { populateMemberMaturityFilter, populateMemberSubTypeFilter } from '../../../../service/distributionHelpers';

interface Props {
  selectedChecklist: Checklist | null;
}

interface MemberDropdown {
  value: number;
  name: string;
}

interface SubtypeDropdown {
  value: string;
  name: string;
}

const CreateChecklist: React.FC<Props> = ({ selectedChecklist }) => {
  // eslint-disable-next-line no-unused-vars
  const [editID, setEditID] = useState<number | undefined>(selectedChecklist?.id || undefined);
  const [checklistModel, setChecklistModel] = useState<CreateChecklistModel>();
  const [dragId, setDragId] = useState<number>(0);
  const [distModel, setDistModel] = useState<DistributionUniversal>();

  const [titleError, setTitleError] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);

  const [localUserError, setLocalUserError] = useState(false);
  const [localMemberError, setLocalMemberError] = useState(false);

  const currentState = useAppSelector((state) => ({
    member: state.member,
    user: state.user,
  }));

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { members } = currentState.member;
  const { users } = currentState.user;

  useEffect(() => {
    if (!users || users.length === 0) {
      dispatch(getAllUsersWithMembersAsync());
    }
    if (!checklistModel && !editID) {
      setChecklistModel({
        title: '',
        summary: '',
        additionalInfo: '',
        checklistItems: [
          {
            order: 0,
            itemTitle: '',
            supportingInfo: '',
          },
        ],
        attachments: [],
        existingFiles: [],
        startDate: null,
        dueDate: null,
        displayWeeklyEmail: false,
        displayDashboardWidget: false,
        displayDashboardCarousel: false,
      });
    }
    if (!checklistModel && editID && selectedChecklist) {
      setChecklistModel({
        title: selectedChecklist.title,
        summary: selectedChecklist.summary,
        additionalInfo: selectedChecklist.additionalInfo,
        checklistItems: selectedChecklist.checklistItems.slice() || [],
        attachments: [],
        existingFiles:
          selectedChecklist.documents && selectedChecklist.documents.length > 0
            ? selectedChecklist.documents.slice() : [],
        startDate: selectedChecklist.startDate,
        dueDate: selectedChecklist.dueDate || null,
        displayDashboardCarousel: selectedChecklist.displayDashboardCarousel,
        displayDashboardWidget: selectedChecklist.displayDashboardWidget,
        displayWeeklyEmail: selectedChecklist.displayWeeklyEmail,
      });
    }
    if (!members || members.length === 0) {
      dispatch(getMembersAsync());
    }
    if (!distModel) {
      setDistModel({
        allMembers: false,
        userOnly: false,
        specificMemberIds: [],
        specificMemberTypes: [],
        specificMemberMaturityLevels: [],
        specificMemberSubTypes: [],
        specificUserId: null,
        primaryProgramContact: false,
        primaryIt: false,
        basicUser: false,
        specificUserJobCategories: [],
        dueDate: null,
        memberSelection: '',
        userError: false,
        dateError: false,
        memberError: false,
        selectedUserMember: null,
      });
    }
  }, []);

  if (!checklistModel) {
    return (<div />);
  }

  const addNewItem = () => {
    if (checklistModel) {
      const order = checklistModel.checklistItems.length;
      setChecklistModel({
        ...checklistModel,
        checklistItems: [
          ...checklistModel.checklistItems,
          {
            order,
            itemTitle: '',
            supportingInfo: '',
          },
        ],
      });
    }
  };

  const removeSlide = (position: number) => {
    if (checklistModel) {
      const reducedArray = checklistModel.checklistItems.filter((item) => item.order !== position);
      const newArray: ChecklistItem[] = reducedArray.map((item, index) => ({
        ...item,
        order: index,
      }));
      setChecklistModel({
        ...checklistModel,
        checklistItems: [...newArray],
      });
    }
  };

  const updateItemContent = (model: ChecklistItem) => {
    if (checklistModel) {
      const updatedSlide: ChecklistItem | undefined = checklistModel.checklistItems.find(
        (item) => item.order === model.order,
      );
      if (updatedSlide) {
        const newSlide = { ...updatedSlide };
        newSlide.itemTitle = model.itemTitle;
        newSlide.supportingInfo = model.supportingInfo;
        const newArray = checklistModel.checklistItems.slice();
        newArray.splice(model.order, 1, newSlide);
        setChecklistModel({
          ...checklistModel,
          checklistItems: [...newArray],
        });
      }
    }
  };

  const handleDrop = (e: any) => {
    if (checklistModel) {
      const dropID = parseInt(e.currentTarget.id, 10);
      const dragBox = checklistModel.checklistItems.find((box) => box.order === dragId);
      const dropBox = checklistModel.checklistItems.find((box) => box.order === dropID);

      if (dragBox && dropBox) {
        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;

        const newSlideObjects: ChecklistItem[] = checklistModel.checklistItems
          .slice()
          .map((slide) => {
            const box = { ...slide };
            if (box.order === dragId) {
              box.order = dropBoxOrder;
              return box;
            }
            if (box.order === dropID) {
              box.order = dragBoxOrder;
              return box;
            }
            return box;
          });
        const newArray = newSlideObjects.sort((a, b) => a.order - b.order);
        setChecklistModel({
          ...checklistModel,
          checklistItems: [...newArray],
        });
      }
    }
  };

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberIds: memberIds });
    }
  };

  const populateMaturityState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberMaturityLevels: subtypes });
    }
  };

  const populateSubtypeState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberSubTypes: subtypes });
    }
  };

  const submitDistribution = (checklistId: number) => {
    if (distModel && checklistId) {
      const articleDistDto: ChecklistDistributionModel = {
        checklistId,
        ...distModel,
      };
      if (distModel.memberSelection === 'specificMembers') {
        articleDistDto.allMembers = false;
        articleDistDto.specificMemberTypes = null;
        articleDistDto.specificMemberSubTypes = null;
      } else if (distModel.memberSelection === 'selectedCriteria') {
        articleDistDto.allMembers = false;
        articleDistDto.specificMemberIds = null;
      } else if (distModel.memberSelection === 'allMembers') {
        articleDistDto.allMembers = true;
        articleDistDto.specificMemberIds = null;
        articleDistDto.specificMemberTypes = null;
        articleDistDto.specificMemberSubTypes = null;
      }
      dispatch(createChecklistAssignmentsAsync(articleDistDto));
    }
  };

  const submitChecklistDraft = async () => {
    const allUploads: DocumentDTO[] = checklistModel.existingFiles || [];
    if (checklistModel.attachments && checklistModel.attachments.length > 0) {
      checklistModel.attachments.map((attachment) => {
        const newDoc: DocumentDTO = {
          title: attachment.fileName,
          origin: 'Checklist',
          fileName: attachment.fileName,
          fileType: attachment.fileType,
          s3Directory: 'portalDocs',
          members: null,
          allMembers: false,
        };
        allUploads.push(newDoc);
      });
    }
    const checklistDTO: ChecklistDTO = {
      id: editID || undefined,
      title: checklistModel.title || '',
      summary: checklistModel.summary || '',
      additionalInfo: checklistModel.additionalInfo || '',
      checklistItems:
        checklistModel.checklistItems && checklistModel.checklistItems.length > 0
          ? checklistModel.checklistItems.map((item) => ({
            id: item.id || undefined,
            order: item.order,
            itemTitle: item.itemTitle,
            supportingInfo: item.supportingInfo,
          })) : [],
      documents: allUploads,
      startDate: checklistModel.startDate,
      dueDate: checklistModel.dueDate,
      published: false,
      displayWeeklyEmail: checklistModel.displayWeeklyEmail,
      displayDashboardWidget: checklistModel.displayDashboardWidget,
      displayDashboardCarousel: checklistModel.displayDashboardCarousel,
    };
    await dispatch(createChecklistAsync({
      checklistDTO,
      uploads: checklistModel.attachments && checklistModel.attachments.length > 0
        ? checklistModel.attachments.map((att) => att.file) : undefined,
    }));
    history.push('/content/manage/checklist');
  };

  const errorCheck = () => {
    let error = false;
    if (!checklistModel.title) {
      setTitleError(true);
      error = true;
    } else {
      setTitleError(false);
    }

    if (!checklistModel.startDate) {
      setStartDateError(true);
      error = true;
    } else {
      setStartDateError(false);
    }

    if (!checklistModel.summary) {
      setSummaryError(true);
      error = true;
    } else {
      setSummaryError(false);
    }
    return error;
  };

  const distributionErrorCheck = () => {
    if (
      selectedChecklist
      && editID
      && selectedChecklist.assignments
      && selectedChecklist.assignments.length > 0
    ) {
      return false;
    }
    if (distModel) {
      let error = false;
      if (distModel.memberSelection === 'specificMembers') {
        if (distModel.specificMemberIds && distModel.specificMemberIds.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'selectedCriteria') {
        if (distModel.specificMemberTypes && distModel.specificMemberTypes.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (distModel.specificUserJobCategories && distModel.specificUserJobCategories.length > 0) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'allMembers') {
        setLocalMemberError(false);
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else {
        setLocalMemberError(true);
        error = true;
      }
      return error;
    }
    return true;
  };

  const publishChecklist = async () => {
    let publishedChecklistId: number | null = null;
    if (distributionErrorCheck() || errorCheck()) {
      return;
    }
    if (!editID) {
      const checklistDTO: ChecklistDTO = {
        title: checklistModel.title,
        summary: checklistModel.summary,
        additionalInfo: checklistModel.additionalInfo || '',
        checklistItems:
          checklistModel.checklistItems && checklistModel.checklistItems.length > 0
            ? checklistModel.checklistItems.map((item) => ({
              order: item.order,
              itemTitle: item.itemTitle,
              supportingInfo: item.supportingInfo,
            })) : [],
        documents:
          checklistModel.attachments && checklistModel.attachments.length > 0
            ? checklistModel.attachments.map((attachment) => ({
              title: attachment.fileName,
              origin: 'Checklist',
              fileName: attachment.fileName,
              fileType: attachment.fileType,
              s3Directory: 'portalDocs',
              members: null,
              allMembers: false,
            })) : [],
        startDate: checklistModel.startDate,
        dueDate: checklistModel.dueDate,
        published: true,
        displayWeeklyEmail: checklistModel.displayWeeklyEmail,
        displayDashboardWidget: checklistModel.displayDashboardWidget,
        displayDashboardCarousel: checklistModel.displayDashboardCarousel,
      };
      const res = await dispatch(createChecklistAsync({
        checklistDTO,
        uploads: checklistModel.attachments && checklistModel.attachments.length > 0
          ? checklistModel.attachments.map((att) => att.file) : undefined,
      }));
      if (res.payload.id) {
        publishedChecklistId = res.payload.id;
      }
    } else if (editID) {
      const allUploads: DocumentDTO[] = checklistModel.existingFiles || [];
      if (checklistModel.attachments && checklistModel.attachments.length > 0) {
        checklistModel.attachments.map((attachment) => {
          const newDoc: DocumentDTO = {
            title: attachment.fileName,
            origin: 'Checklist',
            fileName: attachment.fileName,
            fileType: attachment.fileType,
            s3Directory: 'portalDocs',
            members: null,
            allMembers: false,
          };
          allUploads.push(newDoc);
        });
      }
      const checklistDTO: ChecklistDTO = {
        id: editID,
        title: checklistModel.title,
        summary: checklistModel.summary,
        additionalInfo: checklistModel.additionalInfo || '',
        checklistItems:
          checklistModel.checklistItems && checklistModel.checklistItems.length > 0
            ? checklistModel.checklistItems.map((item) => ({
              id: item.id || undefined,
              order: item.order,
              itemTitle: item.itemTitle,
              supportingInfo: item.supportingInfo,
            })) : [],
        documents: allUploads,
        startDate: checklistModel.startDate,
        dueDate: checklistModel.dueDate,
        published: true,
        displayWeeklyEmail: checklistModel.displayWeeklyEmail,
        displayDashboardWidget: checklistModel.displayDashboardWidget,
        displayDashboardCarousel: checklistModel.displayDashboardCarousel,
      };
      const res = await dispatch(updateChecklistAsync({
        id: editID,
        updateBody: checklistDTO,
        uploads: checklistModel.attachments && checklistModel.attachments.length > 0
          ? checklistModel.attachments.map((att) => att.file) : undefined,
      }));
      if (res.payload.id) {
        publishedChecklistId = res.payload.id;
      }
    }
    if (publishedChecklistId) {
      submitDistribution(publishedChecklistId);
    }
    dispatch(nullSelectedChecklist());
    history.push('/content/manage/checklist');
  };

  const populateMemberTypeFilter = () => {
    const memberTypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      members
        .slice()
        .map((item) => item.entityType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull)
        // eslint-disable-next-line array-callback-return
        .map((type) => {
          const option = {
            label: type,
            value: type,
          };
          memberTypeOptions.push(option);
        });
    }
    return memberTypeOptions;
  };

  const setMemberType = (array: SubtypeDropdown[]) => {
    if (distModel) {
      const specificMemberTypes: string[] = [];
      if (array && array.length > 0) {
        // eslint-disable-next-line array-callback-return
        array.map((item) => {
          specificMemberTypes.push(item.value);
        });
      }
      setDistModel({ ...distModel, specificMemberTypes });
    }
  };

  const populateCategoryFilter = () => {
    const jobCategories: { label: string, value: string }[] = [];
    if (users) {
      const userList = users.slice();
      userList
        .filter((user) => user.jobCategory)
        .sort((a, b) => ((a.jobCategory ? a.jobCategory?.toLowerCase() : 'z') < (b.jobCategory ? b.jobCategory?.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-All`)) {
              jobCategories.push({
                label: `${user.jobCategory}-All`,
                value: `category=${user.jobCategory}&jobRole=All`,
              });
            }
          }
        });
      userList
        .filter((user) => user.jobCategory && user.jobRole)
        .sort((a, b) => ((a.jobRole ? a.jobRole.toLowerCase() : 'z') < (b.jobRole ? b.jobRole.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-${user.jobRole}`)) {
              jobCategories.push({
                label: `${user.jobCategory}-${user.jobRole}`,
                value: `category=${user.jobCategory}&jobRole=${user.jobRole}`,
              });
            }
          }
        });
      return jobCategories.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    }
    return [{
      label: 'All',
      value: 'All',
    }];
  };

  const setJobCategory = (dropDownArray: SubtypeDropdown[]) => {
    if (distModel) {
      const specificUserJobCategories: string[] = [];
      if (dropDownArray && dropDownArray.length > 0) {
        dropDownArray.map((arrayItem) => {
          specificUserJobCategories.push(arrayItem.value);
        });
      }
      setDistModel({ ...distModel, specificUserJobCategories });
    }
  };

  const removeFile = (name: string) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      const newArray = checklistModel.attachments
        .filter((attachment) => attachment.fileName !== name);
      setChecklistModel({
        ...checklistModel,
        attachments: newArray,
      });
    }
  };

  const removeExistingFile = (name: string) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      const newArray = checklistModel.existingFiles
        .filter((attachment) => attachment.fileName !== name);
      setChecklistModel({
        ...checklistModel,
        existingFiles: newArray,
      });
    }
  };

  return (
    <div className="row create-post-article">
      <div className="col-8">
        <Card className="p-3">
          <Card.Body>
            <h2>Checklist</h2>
            <Form>
              <Form.Group controlId="title">
                <Form.Label className="mt-2">Title</Form.Label>
                <Form.Control
                  isInvalid={titleError}
                  as="textarea"
                  rows={1}
                  value={checklistModel.title}
                  onChange={
                    (e: any) => setChecklistModel({ ...checklistModel, title: e.target.value })
                  }
                />
                <FormControl.Feedback type="invalid">Please enter a title.</FormControl.Feedback>
              </Form.Group>
              <Form.Group controlId="post-article-textarea">
                <Form.Label>Summary</Form.Label>
                <Form.Control
                  isInvalid={summaryError}
                  maxLength={255}
                  value={checklistModel.summary}
                  onChange={
                    (e: any) => setChecklistModel({ ...checklistModel, summary: e.target.value })
                  }
                  as="textarea"
                  rows={4}
                />
                <p className="character-count">
                  {`(${checklistModel.summary.length} of 255 characters)`}
                </p>
                <FormControl.Feedback type="invalid">Please enter a summary.</FormControl.Feedback>
              </Form.Group>
              <Form.Group controlId="post-article-textarea">
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  maxLength={255}
                  value={checklistModel.additionalInfo}
                  onChange={
                    (e: any) => setChecklistModel(
                      { ...checklistModel, additionalInfo: e.target.value },
                    )
                  }
                  as="textarea"
                  rows={4}
                />
                <p className="character-count">
                  {`(${checklistModel.additionalInfo.length} of 255 characters)`}
                </p>
              </Form.Group>
              <div className="mb-3 ml-4">
                <Button variant="primary" onClick={addNewItem}>
                  Add Checklist Item
                </Button>
              </div>
              {
                checklistModel
                && checklistModel.checklistItems.length > 0
                && checklistModel.checklistItems
                  .slice()
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <div
                      id={item.order.toString()}
                      className="create-slide-item mb-3 d-flex flex-row"
                      draggable
                      onDragOver={(e) => e.preventDefault()}
                      onDragStart={(e) => setDragId(parseInt(e.currentTarget.id, 10))}
                      onDrop={handleDrop}
                    >
                      <FontAwesomeIcon icon={faBars} className="mt-4 mr-2" />
                      <CreateChecklistItem
                        item={item}
                        updateContent={updateItemContent}
                        removeSlide={removeSlide}
                      />
                    </div>
                  ))
              }
              <div className="ml-4">
                <Form.Group controlId="doc-uploads">
                  <Form.Label className="mt-2 mb-0">Do you want to upload any documents for reference?</Form.Label>
                  <p className="text-muted">
                    File types include doc, docx, pdf, jpg, jpeg, png, xls, xlsx, csv
                  </p>
                  <div className="d-flex">
                    <Button className="upload-file-button">
                      Browse
                      <Form.File
                        multiple
                        custom
                        type="file"
                        id="banner-file-upload"
                        accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx,.csv"
                        onChange={(e: any) => {
                          // eslint-disable-next-line max-len
                          const currentFiles: UploadedDocuments[] = checklistModel.attachments && checklistModel.attachments.length > 0
                            ? checklistModel.attachments.slice()
                            : [];
                          if (e.target.files) {
                            const files = Object.keys(e.target.files).map(
                              (key) => e.target.files[key],
                            );
                            if (files && files.length > 0) {
                              files.map((f: File) => {
                                currentFiles.push({
                                  fileName: f.name,
                                  fileType: f.type,
                                  file: f,
                                });
                              });
                            }
                          }
                          setChecklistModel({
                            ...checklistModel,
                            attachments: currentFiles,
                          });
                        }}
                      />
                    </Button>
                    {
                      checklistModel.attachments
                      && checklistModel.attachments.length > 0
                      && checklistModel.attachments.map((attachment) => (
                        <div className="d-flex flex-row ml-2">
                          <Button className="button-link d-flex align-items-center" onClick={() => removeFile(attachment.fileName)}>
                            <img src={DeleteIcon} alt="Delete Icon" />
                          </Button>
                          <p className="m-0 align-self-center">{attachment.fileName}</p>
                        </div>

                      ))
                    }
                    {
                      checklistModel.existingFiles
                      && checklistModel.existingFiles.length > 0
                      && checklistModel.existingFiles.map((attachment) => (
                        <div className="d-flex flex-row ml-2">
                          <Button className="button-link d-flex align-items-center" onClick={() => removeExistingFile(attachment.fileName)}>
                            <img src={DeleteIcon} alt="Delete Icon" />
                          </Button>
                          <p className="m-0 align-self-center">{attachment.fileName}</p>
                        </div>

                      ))
                    }
                  </div>
                </Form.Group>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="col-4">
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex justify-content-between mb-2">
              <Button variant="secondary" onClick={submitChecklistDraft}>Save Draft</Button>
              <Button
                variant="primary"
                onClick={publishChecklist}
              >
                Publish
              </Button>
            </div>
            <div>
              <Form.Group controlId="memberRole">
                <Form.Label className="my-2">Display</Form.Label>
                <Form.Check className="my-2" checked={checklistModel.displayWeeklyEmail} onChange={(e) => setChecklistModel({ ...checklistModel, displayWeeklyEmail: e.target.checked })} type="checkbox" id="weeklyEmail" label="Weekly Summary Email" />
                <Form.Check className="my-2" checked={checklistModel.displayDashboardWidget} onChange={(e) => setChecklistModel({ ...checklistModel, displayDashboardWidget: e.target.checked })} type="checkbox" id="dashboardWidget" label="Dashboard Widget" />
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  isInvalid={startDateError}
                  type="date"
                  placeholder="MM/DD/YYYY"
                  defaultValue={checklistModel.startDate
                    ? dateYearMonthDay(checklistModel.startDate) : undefined}
                  onChange={(e) => setChecklistModel({ ...checklistModel, startDate: new Date((e.target.value).replace(/-/g, '/')) })}
                />
                <FormControl.Feedback type="invalid">Please enter a start date.</FormControl.Feedback>
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="MM/DD/YYYY" defaultValue={checklistModel.dueDate ? dateYearMonthDay(checklistModel.dueDate) : undefined} onChange={(e) => setChecklistModel({ ...checklistModel, dueDate: new Date((e.target.value).replace(/-/g, '/')) })} />
              </Form.Group>
            </div>
            {
              distModel
              && (
                <>
                  <div>
                    <Form.Group controlId="memberType">
                      <Form.Label>Audience</Form.Label>
                      <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'allMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="allMembers" label="Publish to all members" name="memberSelection" />
                      <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'specificMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="specificMembers" label="Publish to specific members" name="memberSelection" />
                      {
                        distModel.memberSelection === 'specificMembers'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberSelectInput">
                              <Form.Label>Select Member(s)</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={
                                  currentState.member.members
                                    .slice()
                                    .filter((member) => member.memberNumber !== 573)
                                    // eslint-disable-next-line max-len
                                    .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                                    .map((member) => (
                                      {
                                        label: member.memberName,
                                        value: member.id,
                                      }
                                    ))
                                }
                                onChange={
                                  (selectedOption: any) => populateMemberIdState(selectedOption)
                                }
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                      <Form.Check feedback="Please make a member selection" isInvalid={localMemberError} checked={distModel.memberSelection === 'selectedCriteria'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="selectedCriteria" label="Publish to members based on selected criteria" name="memberSelection" />
                      {
                        distModel.memberSelection === 'selectedCriteria'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberTypeDropdown">
                              <Form.Label>Member Type</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberTypeFilter()}
                                onChange={(newValue: any) => setMemberType(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberMaturityDropdown">
                              <Form.Label>Member Maturity Level</Form.Label>
                              <Select
                                isDisabled={
                                  !distModel.specificMemberTypes
                                  || distModel.specificMemberTypes.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberMaturityFilter(members, distModel)}
                                onChange={(newValue: any) => populateMaturityState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>Sub-Type</Form.Label>
                              <Select
                                isDisabled={
                                  (!distModel.specificMemberTypes
                                    || distModel.specificMemberTypes.length <= 0)
                                  || !distModel.specificMemberMaturityLevels
                                  || distModel.specificMemberMaturityLevels.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberSubTypeFilter(members, distModel)}
                                onChange={(newValue: any) => populateSubtypeState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>User Job Category</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={populateCategoryFilter()}
                                onChange={(newValue: any) => setJobCategory(newValue)}
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                    </Form.Group>
                  </div>
                  <div className="ml-4">
                    <Form.Group controlId="memberRole">
                      <Form.Label className="my-2">User Role</Form.Label>
                      <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryProgramContact: e.target.checked })} type="checkbox" id="primaryProgramContact" label="Primary Program Contacts" />
                      <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryIt: e.target.checked })} type="checkbox" id="primaryIT" label="Primary IT Contacts" />
                      <Form.Check feedback="Please make a user selection" isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, basicUser: e.target.checked })} type="checkbox" id="basicUser" label="Basic Users" />
                    </Form.Group>
                  </div>
                </>
              )
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CreateChecklist;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllUsersWithMembers,
  getCurrentUser,
  getOneUser,
  getImpersonateToken,
  getUsers,
  getUserFilters,
} from '../api/userAPI';
import { Member } from './memberSlice';

export interface User {
  id: number;
  email?: string;
  cyberNone: boolean;
  firstName?: string;
  lastName?: string;
  members: Member[];
  jobTitle: string;
  jobRole: string | null;
  jobCategory: string | null;
  accessLevel: number;
  accessLabel: string;
  role: 100 | 200 | 300 | 400 | null;
  phone: string;
  primaryContactForMembers: Member[];
  primaryITForMembers: Member[];
  restrictedDataAccess: boolean;
  lastActive?: Date;
}

export interface UserState {
  currentUser: User | null;
  users: User[] | null;
  viewUserProfile: User | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  impersonate?: boolean | null;
  usersPaged: User[];
  count: number;
  filters: UserFilters,
}

interface UserFilterOption {
  value: string | null;
  label: string | null;
}

interface UserFilters {
  categories: UserFilterOption[];
  memberTypes: UserFilterOption[];
  cyberMaturityRatings: UserFilterOption[];
  parentMembers: UserFilterOption[];
}

const initialState: UserState = {
  currentUser: null,
  users: [],
  viewUserProfile: null,
  status: 'idle',
  error: null,
  impersonate: false,
  usersPaged: [],
  count: 0,
  filters:
  {
    categories: [],
    memberTypes: [],
    parentMembers: [],
    cyberMaturityRatings: [],
  },
};

const getCurrentUserAsync = createAsyncThunk(
  'user/getCurrentUser',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentUser();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getAllUsersWithMembersAsync = createAsyncThunk(
  'user/getAllWithMembers',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getAllUsersWithMembers();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getUsersWithPaginationAsync = createAsyncThunk(
  'user/getUsersWithPaged',
  async (arg: any, { rejectWithValue }) => {
    try {
      return await getUsers(arg);
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getFiltersAsync = createAsyncThunk(
  'user/filters',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getUserFilters();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneUserAsync = createAsyncThunk(
  'user/getSingleUser',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneUser(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getImpersonateTokenAsync = createAsyncThunk(
  'user/getImpersonate',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getImpersonateToken(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get current user
      .addCase(getCurrentUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentUser = action.payload;
        state.error = null;
      })
      .addCase(getCurrentUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentUser = null;
        state.error = action.payload;
      })
      .addCase(getAllUsersWithMembersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllUsersWithMembersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload;
        state.error = null;
      })
      .addCase(getAllUsersWithMembersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.users = [];
        state.error = action.payload;
      })
      .addCase(getFiltersAsync.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(getUsersWithPaginationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsersWithPaginationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const { data, count } = action.payload as any;
        state.usersPaged = data;
        state.count = count;
        state.error = null;
      })
      .addCase(getUsersWithPaginationAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.usersPaged = [];
        state.error = action.payload;
      })
      .addCase(getOneUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.viewUserProfile = action.payload;
        state.error = null;
      })
      .addCase(getOneUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.viewUserProfile = null;
        state.error = action.payload;
      })
      .addCase(getImpersonateTokenAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getImpersonateTokenAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.impersonate = true;
        state.error = null;
      })
      .addCase(getImpersonateTokenAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.impersonate = null;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;

export {
  getCurrentUserAsync,
  getAllUsersWithMembersAsync,
  getOneUserAsync,
  getImpersonateTokenAsync,
  getUsersWithPaginationAsync,
  getFiltersAsync,
};

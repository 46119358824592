import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import CreateThreatAlert from './modals/createThreatAlert';
import { getAllIncomingAlertsAsync } from '../../../store/incomingAlertSlice';
import { getAllPrimexThreatAlertsAsync } from '../../../store/primexAlertsSlice';
import { dateOnly } from '../../../service/timeAndDate';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface Props { }

const CyberThreatNav: React.FC<Props> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => ({
    IncomingAlerts: state.incomingAlerts,
    primexAlerts: state.primexAlerts,
    user: state.user,
  }));

  useEffect(() => {
    dispatch(getAllIncomingAlertsAsync());
    dispatch(getAllPrimexThreatAlertsAsync());
  }, []);

  return (
    <>
      <div className="row mx-0 align-items-center nav-row">
        <div className="col-8">
          <Nav variant="tabs" className="m-0">
            <Nav.Item>
              <NavLink exact to="/threat-alerts/admin">Incoming</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to="/threat-alerts/drafts">Drafts</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to="/threat-alerts/published">Published</NavLink>
            </Nav.Item>
          </Nav>
        </div>
        <div className="col-4 d-flex justify-content-end">
          <CreateThreatAlert
            currentId={null}
            securityAlertDraft={currentState.IncomingAlerts.securityAlertDraft}
            primexAlertDraft={currentState.primexAlerts.selectedPrimexSecurityAlert}
            dateConversion={dateOnly}
            regularButton
          >
            Create
          </CreateThreatAlert>
        </div>
      </div>
      <div className="row row-no-gutters">
        <div className="col-12">
          {children}
        </div>
      </div>
    </>
  );
};

export default CyberThreatNav;

import React, { useState } from 'react';
import {
  Card,
  Form,
  Button,
  Table,
  Spinner,
  Modal,
} from 'react-bootstrap';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import upload from '../../../../assets/icons/upload-file.svg';
import deleteIcon from '../../../../assets/icons/remove.svg';
import downloadIcon from '../../../../assets/icons/DownloadBlue.svg';
import commentActive from '../../../../assets/icons/comment-active.svg';
import commentInactive from '../../../../assets/icons/comment-inactive.svg';
import {
  createIrClaimFileAsync,
  getOneClaimAsync,
  getOneIrFileAsync,
  IrClaim,
} from '../../../../store/irClaimSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { dateOnly } from '../../../../service/timeAndDate';

interface Props {
  claim: IrClaim;
  atomIrUser: boolean;
}

interface FileItem {
  fileName: string;
  fileType: string;
  file: File;
}

const Files: React.FC<Props> = ({ claim, atomIrUser }) => {
  const [files, setFiles] = useState<FileItem[]>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [show, setShow] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [sortDocument, setSortDocument] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortFile, setSortFile] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortUploaded, setSortUploaded] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortUploadedBy, setSortUploadedBy] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [loading, setLoading] = useState(false);
  const [downloading, setDownLoading] = useState(false);
  const [fileComments, setFileComments] = useState('');
  const [fileAvailableDownload, setFileAvailableDownload] = useState(false);
  const dispatch = useAppDispatch();

  const handleOpen = (incomingComment: string) => {
    setCurrentComment(incomingComment);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentComment('');
  };

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const removeExistingFile = (name: string) => {
    if (files && files.length > 0) {
      const newArray = files.filter((fileItem) => fileItem.fileName !== name);
      if (newArray && newArray.length > 0) {
        setFiles(newArray);
      } else {
        setFiles(undefined);
      }
    }
  };

  const uploadFiles = async () => {
    if (files && files.length > 0) {
      try {
        setLoading(true);
        await Promise.all(
          files.map(async (currFile) => {
            await dispatch(createIrClaimFileAsync({
              irFileDto: {
                title: currFile.fileName,
                comments: fileComments,
                availableDownload: fileAvailableDownload,
                origin: 'ir-claim-files',
                fileName: currFile.fileName,
                fileType: currFile.fileType,
                s3StorageName: currFile.fileName,
                s3Directory: `${claim.title || 'Unnamed-Claim'}-IR-Files`,
                irClaimId: claim.id,
              },
              file: currFile.file,
            }));
          }),
        );
        dispatch(getOneClaimAsync(claim.id));
        setFiles(undefined);
        setFileComments('');
        setFileAvailableDownload(false);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const downloadFiles = async () => {
    if (selectedRows && selectedRows.length > 0) {
      setDownLoading(true);
      try {
        await Promise.all(
          selectedRows.map(async (rowId) => {
            const incomingFile = await dispatch(getOneIrFileAsync(rowId));
            if (
              incomingFile
              && incomingFile.payload
              && incomingFile.payload.preAuthGetUrl
            ) {
              const aElement = document.createElement('a');
              aElement.href = incomingFile.payload.preAuthGetUrl;
              aElement.target = '_blank';
              aElement.download = incomingFile.payload.s3StorageName || 'file';
              aElement.click();
            }
          }),
        );
      } catch (e) {
        console.log(e);
      }
      setSelectedRows([]);
      setDownLoading(false);
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (selectedRows.includes(checkboxId)) {
      const newArray = selectedRows.filter((item) => item !== checkboxId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([checkboxId, ...selectedRows]);
    }
  };

  const checkArrayMatch = () => {
    let match = false;
    if (claim.irFiles && claim.irFiles.length > 0) {
      if (selectedRows.length > 0) {
        match = claim.irFiles.every((v) => selectedRows.includes(v.id));
      }
    }
    return match;
  };

  return (
    <div>
      <Card className="p-4 mt-2 align-items-center">
        <div className="col-10">
          <Card className="dashed align-items-center w-100 p-3">
            <Form.Group controlId="formFile" className="d-flex m-0 input-container-ir">
              <>
                <img src={upload} alt="Upload icon" />
                <span className="bold align-self-center mb-0 ml-2">
                  Drag and drop file here or
                  <span className="orange browse"> browse</span>
                  .
                </span>
                <Form.File
                  className="file-upload-ir"
                  type="file"
                  onChange={(e: any) => {
                    const currentFiles: FileItem[] = files && files.length > 0
                      ? files.slice()
                      : [];
                    if (e.target.files) {
                      const newFiles = Object.keys(e.target.files).map(
                        (key) => e.target.files[key],
                      );
                      if (newFiles && newFiles.length > 0) {
                        newFiles.map((file) => currentFiles.push({
                          fileName: file.name,
                          fileType: file.type,
                          file,
                        }));
                      }
                    }
                    setFiles(currentFiles);
                  }}
                />
              </>
            </Form.Group>
          </Card>
          {
            files
            && files.length > 0
            && (
              <>
                <div className="d-flex mt-4 flex-column">
                  {
                    files.map((fileItem) => (
                      <div className="d-flex flex-row">
                        <Button className="button-link d-flex align-items-center" onClick={() => removeExistingFile(fileItem.fileName)}>
                          <img src={deleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{fileItem.fileName}</p>
                      </div>
                    ))
                  }
                </div>
              </>
            )
          }
          <Form.Group className="mt-4 w-100" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              placeholder="Enter comments"
              as="textarea"
              rows={3}
              onChange={(value) => {
                setFileComments(value.currentTarget.value);
              }}
            />
          </Form.Group>
          <div className="mt-1 w-100 d-flex align-items-center justify-content-between">
            {
              atomIrUser
                ? (
                  <Form.Group>
                    <Form.Check
                      onChange={(e) => {
                        setFileAvailableDownload(e.currentTarget.checked);
                      }}
                      label="Available for download"
                    />
                  </Form.Group>
                ) : (
                  <p className="mb-0 text-left">
                    Files cannot be downloaded or removed. If a file is uploaded in error,
                    please upload the correct file and leave a comment.
                  </p>
                )
            }
            <Button onClick={uploadFiles} disabled={loading} variant="clear file-upload">
              Upload
              { loading && <Spinner animation="border" size="sm" variant="light" className="ml-1" /> }
            </Button>
          </div>
        </div>
      </Card>

      <h3 className="claim-info-subtitle mt-4">Uploaded Files</h3>
      {
        selectedRows
        && selectedRows.length > 0
        && (
          <Button onClick={downloadFiles} disabled={downloading} className="button-link no-button">
            <img src={downloadIcon} alt="download icon" />
            <span className="ml-2">Download</span>
            { downloading && <Spinner animation="border" size="sm" variant="light" className="ml-2" /> }
          </Button>
        )
      }
      <div className="file-container-table">
        <Table bordered>
          <thead>
            <tr>
              <th className="check-box">
                {
                  atomIrUser
                    ? (
                      <input
                        type="checkbox"
                        checked={checkArrayMatch()}
                        onClick={() => {
                          if (!checkArrayMatch()) {
                            setSelectedRows(
                              [...claim.irFiles.map((assign) => assign.id)],
                            );
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                      />
                    ) : (
                      <input disabled type="checkbox" />
                    )
                }
              </th>
              <th className="m-0">
                <span className="m-0">Document Name</span>
                <Button
                  className="no-button text-left"
                  onClick={() => {
                    switch (sortDocument) {
                      case 'noSort':
                        setSortDocument('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortDocument('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortDocument('noSort');
                        return;
                      default:
                        setSortDocument('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortDocument)} />
                </Button>
              </th>
              <th className="">
                <span>File Type</span>
                <Button
                  className="no-button text-left"
                  onClick={() => {
                    switch (sortFile) {
                      case 'noSort':
                        setSortFile('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortFile('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortFile('noSort');
                        return;
                      default:
                        setSortFile('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortFile)} />
                </Button>
              </th>
              <th>
                <span>Uploaded</span>
                <Button
                  className="no-button text-left"
                  onClick={() => {
                    switch (sortUploaded) {
                      case 'noSort':
                        setSortUploaded('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortUploaded('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortUploaded('noSort');
                        return;
                      default:
                        setSortUploaded('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortUploaded)} />
                </Button>
              </th>
              <th>
                <span>Uploaded By</span>
                <Button
                  className="no-button text-left"
                  onClick={() => {
                    switch (sortUploadedBy) {
                      case 'noSort':
                        setSortUploadedBy('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortUploadedBy('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortUploadedBy('noSort');
                        return;
                      default:
                        setSortUploadedBy('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortUploadedBy)} />
                </Button>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {
              claim.irFiles
              && claim.irFiles.length > 0
              && claim.irFiles
                .slice()
                .sort((a, b) => {
                  const aVar: string = a.title ? a.title.toLowerCase() : 'z';
                  const bVar: string = b.title ? b.title.toLowerCase() : 'z';
                  switch (sortDocument) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar: string = a.fileType ? a.fileType.toLowerCase() : 'z';
                  const bVar: string = b.fileType ? b.fileType.toLowerCase() : 'z';
                  switch (sortFile) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.createdAt;
                  const bVar = b.createdAt;
                  switch (sortUploaded) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.addedBy.lastName?.toLowerCase() || 'a';
                  const bVar = b.addedBy.lastName?.toLowerCase() || 'a';
                  switch (sortUploadedBy) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((irFile) => (
                  <tr>
                    <td className="check-box">
                      {
                        atomIrUser
                          ? (
                            <input
                              type="checkbox"
                              checked={
                                selectedRows
                                && selectedRows.includes(irFile.id)
                              }
                              onChange={() => onCheckboxChange(irFile.id)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              disabled={!irFile.availableDownload}
                              checked={
                                selectedRows
                                && selectedRows.includes(irFile.id)
                              }
                              onChange={() => onCheckboxChange(irFile.id)}
                            />
                          )
                      }
                    </td>
                    <td>
                      {irFile.title}
                    </td>
                    <td>
                      {irFile.fileType}
                    </td>
                    <td>
                      {irFile.createdAt && dateOnly(irFile.createdAt)}
                    </td>
                    <td>
                      {
                      irFile.addedBy
                      && (
                        `${irFile.addedBy.firstName} ${irFile.addedBy.lastName}`
                      )
                      }
                    </td>
                    <td className="file-tab-icon text-center">
                      {
                      irFile.comments
                        ? (
                          <Button className="no-button button-link p-0" onClick={() => handleOpen(irFile.comments)}>
                            <img src={commentActive} alt="comment icon" />
                          </Button>
                        ) : (
                          <img src={commentInactive} alt="comment icon" />
                        )
                      }
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            File Comment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentComment}
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Files;

import { AxiosPromise } from 'axios';
import { SystemAlertDistributionModel } from '../models/distributionModels';
import api from './api';

export function createSystemAlertAssignments(dto: SystemAlertDistributionModel) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/system-alert-assignments', dto)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getCurrentSystemAlertAssignments() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/system-alert-assignments')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable consistent-return */
import React from 'react';
import Table from 'react-bootstrap/Table';
import { Member } from '../../../../store/memberSlice';
import { dateOnly } from '../../../../service/timeAndDate';

interface Props {
  member: Member;
}

const ClaimHistory: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { member: { claims } } = props;

  return (
    <>
      <Table bordered className="mt-5">
        <thead>
          <tr>
            <th>Claim Date</th>
            <th>Claim ID</th>
            <th>Claim Title</th>
          </tr>
        </thead>
        <tbody>
          {
            claims
            && (
              claims.map((claim) => (
                <tr>
                  <td>
                    { claim.incidentDate ? dateOnly(claim.incidentDate) : ''}
                  </td>
                  <td>
                    { claim.claimNumber }
                  </td>
                  <td>
                    { claim.claimType }
                  </td>
                </tr>
              ))
            )
          }
        </tbody>
      </Table>
    </>
  );
};

export default ClaimHistory;

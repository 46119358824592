import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import pdfBackgroundLogo from '../../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../../assets/bkg-diamonds.png';
import { IrClaim } from '../../../../../store/irClaimSlice';
import { dateAndTime } from '../../../../../service/timeAndDate';

interface Props {
  irClaim: IrClaim;
}

const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 50,
    fontFamily: 'lato',
    fontSize: 12,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  comHeader: {
    fontSize: 14,
    padding: 2,
    marginBottom: 5,
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
  },
  fontBody: {
    fontFamily: 'Courier',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleFonts: {
    fontSize: 16,
  },
  contentBlock: {
    marginRight: 25,
  },
  contentBlockParent: {
    marginBottom: 10,
  },
  contentTitle: {
    textDecoration: 'underline',
  },
});

const ClaimFilesPdf: React.FC<Props> = ({ irClaim }) => {
  const { irFiles, title } = irClaim;
  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.fontBody]} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Updates
            </Text>
          </View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Claim Title:
            </Text>
            <Text>
              { title || 'No Title' }
            </Text>
          </View>
          <View>
            <View style={[styles.inline]}>
              <Text style={[{ width: '25%' }]}>Document Name</Text>
              <Text style={[{ width: '25%' }]}>File Type</Text>
              <Text style={[{ width: '25%' }]}>Upload</Text>
              <Text style={[{ width: '25%' }]}>Uploaded By</Text>
            </View>
            {
              irFiles
              && irFiles.length > 0
              && irFiles.map((update) => (
                <View key={update.id} style={[styles.inline, { marginVertical: 3 }]}>
                  <Text style={[{ width: '25%' }]}>
                    {update.fileName}
                  </Text>
                  <Text style={[{ width: '25%' }]}>
                    {update.fileType}
                  </Text>
                  <Text style={[{ width: '25%' }]}>
                    {dateAndTime(update.createdAt)}
                  </Text>
                  <Text style={[{ width: '25%' }]}>
                    {`${update.addedBy?.firstName} ${update.addedBy.lastName}`}
                  </Text>
                </View>
              ))
            }
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};
export default ClaimFilesPdf;

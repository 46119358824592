import { AxiosPromise } from 'axios';
import { HowToTopic } from '../store/howToSlice';
import api from './api';

export function getHowToTopics() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/how-to-topic')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneHowTo(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/how-to-topic/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createHowToTopic(topic: Partial<HowToTopic>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/how-to-topic', topic)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateHowToTopic(id: number, topic: Partial<HowToTopic>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/how-to-topic/${id}`, topic)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleHowTo(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/how-to-topic/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createHowToTopicAsync,
  deleteMultipleHowToAsync,
  getHowToTopicsAsync,
  getOneHowToAsync,
  HowToTopic,
  updateHowToTopicAsync,
} from '../../../../store/howToSlice';
import HowToModal from '../../howTo/modals/HowToModal';
import { dateAndTime } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';
import { HowToSlide } from '../../../../models/howToModel';

interface Props {
  deleteRows: {
    selectedRows: number[],
    setSelectedRows: Function,
  }
  howToTopics: HowToTopic[] | null;
  showArchived: boolean,
  searchString: string | null,
}

const ManageHowToTable: React.FC<Props> = (props: Props) => {
  const {
    deleteRows,
    howToTopics,
    showArchived,
    searchString,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortSlides, setSortSlides] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCreated, setSortCreated] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getHowToTopicsAsync());
  }, []);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (deleteRows.selectedRows.includes(checkboxId)) {
      const newArray = deleteRows.selectedRows.filter((item) => item !== checkboxId);
      deleteRows.setSelectedRows([...newArray]);
    } else {
      deleteRows.setSelectedRows([checkboxId, ...deleteRows.selectedRows]);
    }
  };

  const archiveHowTo = async (id: number) => {
    const archiveBody: Partial<HowToTopic> = {
      archived: true,
    };
    await dispatch(updateHowToTopicAsync(
      {
        id,
        topic: archiveBody,
      },
    ));
    dispatch(getHowToTopicsAsync());
  };

  const howToSorted = howToTopics?.slice()
    .filter((item) => (showArchived ? item : !item.archived)).filter((item) => {
      if (searchString) {
        if (item.topicTitle.toLowerCase().includes(searchString.toLowerCase())) {
          return item;
        }
        return;
      }
      return item;
    })
    .sort((a, b) => {
      const aVar = a.topicTitle.toLowerCase();
      const bVar = b.topicTitle.toLowerCase();
      switch (sortTitle) {
        case 'noSort':
          return 0;
        case 'sortAsc':
          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
        case 'sortDesc':
          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
        default:
          return 0;
      }
    })
    .sort((a, b) => {
      const aVar = a.slides.length;
      const bVar = b.slides.length;
      switch (sortSlides) {
        case 'noSort':
          return 0;
        case 'sortAsc':
          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
        case 'sortDesc':
          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
        default:
          return 0;
      }
    })
    .sort((a, b) => {
      const returnStatus = (topic: HowToTopic) => {
        if (topic.published && !topic.archived) {
          return 1;
        } if (!topic.published && !topic.archived) {
          return 2;
        }
        return 3;
      };
      const aVar = returnStatus(a);
      const bVar = returnStatus(b);
      switch (sortStatus) {
        case 'noSort':
          return 0;
        case 'sortAsc':
          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
        case 'sortDesc':
          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
        default:
          return 0;
      }
    })
    .sort((a, b) => {
      const aVar = a.createdAt;
      const bVar = b.createdAt;
      switch (sortCreated) {
        case 'noSort':
          return 0;
        case 'sortAsc':
          return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
        case 'sortDesc':
          return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
        default:
          return 0;
      }
    });

  const deleteHowTo = async (id: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultipleHowToAsync([id]));
      dispatch(getHowToTopicsAsync());
    }
  };

  const copyHowTo = async (id: number) => {
    const itemToCopy = await dispatch(getOneHowToAsync(id));
    const newHowTo: Partial<HowToTopic> = { ...itemToCopy.payload };
    newHowTo.id = undefined;
    newHowTo.published = false;
    newHowTo.author = undefined;
    newHowTo.topicTitle = `Copy of: ${newHowTo.topicTitle}`;
    newHowTo.slides = newHowTo.slides
      ? newHowTo.slides.map((slide: HowToSlide) => ({
        ...slide,
        id: undefined,
      })) : [];
    await dispatch(createHowToTopicAsync(newHowTo));
    dispatch(getHowToTopicsAsync());
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th className="check-box">
            <input type="checkbox" />
          </th>
          <th>
            <span>Title </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortTitle) {
                  case 'noSort':
                    setSortTitle('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortTitle('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortTitle('noSort');
                    return;
                  default:
                    setSortTitle('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
            </Button>
          </th>
          <th>
            <span>Slides </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortSlides) {
                  case 'noSort':
                    setSortSlides('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortSlides('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortSlides('noSort');
                    return;
                  default:
                    setSortSlides('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortSlides)} />
            </Button>
          </th>
          <th>
            <span>Status </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStatus) {
                  case 'noSort':
                    setSortStatus('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStatus('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStatus('noSort');
                    return;
                  default:
                    setSortStatus('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
            </Button>
          </th>
          <th>
            <span>Created </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortCreated) {
                  case 'noSort':
                    setSortCreated('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortCreated('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortCreated('noSort');
                    return;
                  default:
                    setSortCreated('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortCreated)} />
            </Button>
          </th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {howToSorted
          && howToSorted.length > 0
          && howToSorted
            .map((topic, order) => (
              <tr>
                <td className="check-box">
                  <input
                    type="checkbox"
                    data-threatid={topic.id}
                    onChange={() => onCheckboxChange(topic.id)}
                  />
                </td>
                <td>
                  <HowToModal
                    topic={howToSorted}
                    startingSlide={0}
                    modalIndex={order}
                  >
                    {topic.topicTitle}
                  </HowToModal>
                </td>
                <td>
                  {topic.slides ? topic.slides.length : ''}
                </td>
                <td>
                  {
                    topic.archived && 'Archived'
                  }
                  {
                    !topic.archived
                    && topic.published
                    && 'Active'
                  }
                  {
                    !topic.archived
                    && !topic.published
                    && 'Draft'
                  }
                </td>
                <td>
                  <p className="m-0">{topic.createdAt ? dateAndTime(topic.createdAt) : ''}</p>
                  <p className="m-0">{topic.author ? `${topic.author.firstName} ${topic.author.lastName}` : ''}</p>
                </td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link to={`/content/create/how-to/${topic.id}`}>
                          Edit
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => copyHowTo(topic.id)}>
                        Copy
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteHowTo(topic.id)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => archiveHowTo(topic.id)}>
                        Archive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
      </tbody>
    </Table>
  );
};
export default ManageHowToTable;

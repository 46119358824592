import { AxiosPromise } from 'axios';
import { PostArticleDistributionModel } from '../models/distributionModels';
import { PostArticleAssignment } from '../store/postArticleAssignmentSlice';
import api from './api';

export function createPostArticleAssignments(assignmentCriteria: PostArticleDistributionModel) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/post-article-assignments', assignmentCriteria)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getCurrentPostArticleAssignments() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/post-article-assignments')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultiplePostArticleAssignments(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/post-article-assignments', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// eslint-disable-next-line max-len
export function updatePostArticleAssignment(id: number, assignment: Partial<PostArticleAssignment>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/post-article-assignments/${id}`, assignment)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';
import UserInfo from '../shared/UserInfo';

const Profile: React.FC<void> = () => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  const { currentUser } = currentState.user;

  if (!currentUser) {
    return null;
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>My Profile</h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card>
              <Card.Body>
                <UserInfo user={currentUser} />
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12 mt-5">
            <GlobalMessage
              flaggedUserId={currentUser.id || undefined}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Profile;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useAppSelector } from '../../../store/hooks';
import CyberThreatRow from './NotificationTableRows/CyberThreatRow';
import QuestionnaireRow from './NotificationTableRows/QuestionnaireRow';
import TrainingRow from './NotificationTableRows/TrainingRow';
import PostArticleRow from './NotificationTableRows/PostArticleRow';
import DocumentRow from './NotificationTableRows/DocumentRow';
import ChecklistRow from './NotificationTableRows/ChecklistRow';
import IrClaimRow from './NotificationTableRows/IrClaimRow';
import IrFileRow from './NotificationTableRows/IrFileRow';
import IrPrivateMessageRow from './NotificationTableRows/PrivateMessageRow';

interface Props {

}

const NotificationsTable: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    notification: state.notification,
    user: state.user,
  }));

  const { notifications } = currentState.notification;
  const { currentUser } = currentState.user;

  const [sortReceived, setSortReceived] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortType, setSortType] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  return (
    <>
      <div className="incoming-container">
        <Table bordered>
          <thead>
            <tr>
              <th> </th>
              <th>
                <span>
                  Received
                  <Button
                    className="no-button"
                    onClick={() => {
                      switch (sortReceived) {
                        case 'noSort':
                          setSortReceived('sortAsc');
                          return;
                        case 'sortAsc':
                          setSortReceived('sortDesc');
                          return;
                        case 'sortDesc':
                          setSortReceived('noSort');
                          return;
                        default:
                          setSortReceived('noSort');
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={returnSortIcon(sortReceived)} />
                  </Button>
                </span>
              </th>
              <th>
                <span>
                  Type
                  <Button
                    className="no-button"
                    onClick={() => {
                      switch (sortType) {
                        case 'noSort':
                          setSortType('sortAsc');
                          return;
                        case 'sortAsc':
                          setSortType('sortDesc');
                          return;
                        case 'sortDesc':
                          setSortType('noSort');
                          return;
                        default:
                          setSortType('noSort');
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={returnSortIcon(sortType)} />
                  </Button>
                </span>
              </th>
              <th>
                Notification
              </th>
              <th>
                Due
              </th>
            </tr>
          </thead>
          <tbody>
            {
              notifications
              && notifications
                .slice()
                .sort((a, b) => {
                  const aVar = a.createdAt;
                  const bVar = b.createdAt;
                  switch (sortReceived) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.type.toLowerCase();
                  const bVar = b.type.toLowerCase();
                  switch (sortType) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((notice) => {
                  if (notice.type.includes('Cyber Threat Alert')) {
                    return <CyberThreatRow notification={notice} />;
                  }
                  if (notice.type.includes('Questionnaire')) {
                    return <QuestionnaireRow notification={notice} />;
                  }
                  if (notice.type.includes('Training')) {
                    return <TrainingRow notification={notice} />;
                  }
                  if (notice.type.includes('Post')) {
                    return <PostArticleRow notification={notice} />;
                  }
                  if (notice.type.includes('New Document')) {
                    return <DocumentRow notification={notice} />;
                  }
                  if (notice.type.includes('New Checklist')) {
                    return <ChecklistRow notification={notice} />;
                  }
                  if (notice.type.includes('Claim Update') && notice.irClaim && currentUser) {
                    return (
                      <IrClaimRow
                        irClaim={notice.irClaim}
                        notification={notice}
                        currentUser={currentUser}
                      />
                    );
                  }
                  if (notice.type.includes('New IR File') && notice.irFile && currentUser) {
                    return (
                      <IrFileRow
                        irClaim={notice.irFile.irClaim}
                        notification={notice}
                        currentUser={currentUser}
                        irFile={notice.irFile}
                      />
                    );
                  }
                  if (notice.type.includes('Private Comments') && notice.irFile && currentUser) {
                    return (
                      <IrPrivateMessageRow
                        irClaim={notice.irFile.irClaim}
                        notification={notice}
                        currentUser={currentUser}
                      />
                    );
                  }
                  return <tr />;
                })
            }
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default NotificationsTable;

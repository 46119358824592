import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteMultiplePrimexThreatAlertsAsync, getAllPrimexThreatAlertsAsync, PrimexSecurityAlert } from '../../../../store/primexAlertsSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { getCurrentCyberThreatAssignmentsAsync, updateCyberThreatAssignmentAsync } from '../../../../store/cyberThreatAssignmentSlice';
import { getCurrentNotificationsAsync, updateNotificationAsync } from '../../../../store/notificationSlice';
import GlobalMessage from '../../globalMessage/GlobalMessage';

interface Props {
  threatData: PrimexSecurityAlert;
  // eslint-disable-next-line react/require-default-props
  threatAssignment?: {
    id: number;
    viewed?: Date;
  };
  dateConversion: Function;
  userView?: boolean;
  notificationId?: number | null;
}

const ReadPrimexAlert: React.FC<Props> = ({
  children,
  threatAssignment,
  threatData,
  dateConversion,
  // eslint-disable-next-line no-unused-vars
  userView,
  notificationId,
}) => {
  const {
    id,
    content,
    recommendations,
    title,
    createdAt,
    threatLevel,
    source,
    sourceTitle,
    technologiesImpacted,
  } = threatData;
  const [show, setShow] = useState(false);
  const cleanRecommendations = DOMPurify.sanitize(recommendations);
  const cleanContent = DOMPurify.sanitize(content);
  const dispatch = useAppDispatch();

  const handleClose = async () => {
    if (notificationId) {
      await dispatch(updateNotificationAsync({
        id: notificationId,
        body: {
          viewed: true,
        },
      }));
      dispatch(getCurrentNotificationsAsync());
    }
    setShow(false);
  };
  const handleShow = async () => {
    setShow(true);
    if (threatAssignment && !threatAssignment.viewed) {
      await dispatch(updateCyberThreatAssignmentAsync({
        id: threatAssignment.id,
        viewed: new Date(),
      }));
      dispatch(getCurrentCyberThreatAssignmentsAsync());
    }
  };

  const deleteAlert = async () => {
    await dispatch(deleteMultiplePrimexThreatAlertsAsync([id]));
    await dispatch(getAllPrimexThreatAlertsAsync());
    handleClose();
  };

  const trackClick = (threatTitle: string) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cyberPortal',
        action: 'print',
        category: 'cyber threat alerts',
        label: 'Print Threat from Alert View',
        value: threatTitle,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <>
      <Button className="button-link px-0" onClick={handleShow}>
        {children}
      </Button>

      <Modal show={show} size="xl" className="incoming-alert-modal">
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title>
            <h4 className="ml-2 mb-0">
              Cyber Threat Alert
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5" key={id}>
          {
            userView
            && (
              <>
                <div className="row row-no-gutter">
                  <div className="col-8">
                    <p>
                      <span>Severity Level: </span>
                      <span className="bold ml-2">{threatLevel}</span>
                    </p>
                    <h1>
                      {title}
                    </h1>
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-center">
                    <div>
                      <Link to={`/threat-alerts/print/${id}`} target="_blank" onClick={() => trackClick(title)}>
                        <Button>Print</Button>
                      </Link>
                    </div>
                  </div>
                </div>
                {
                  source
                  && sourceTitle
                  && (
                    <>
                      <h3>Original Source</h3>
                      <div>
                        <p>
                          <a target="_blank" rel="noreferrer" href={source}>
                            {sourceTitle}
                          </a>
                        </p>
                      </div>
                    </>
                  )
                }
                {
                  source
                  && !sourceTitle
                  && (
                    <>
                      <h3>Original Source</h3>
                      <div>
                        <p>
                          <a target="_blank" rel="noreferrer" href={source}>
                            {source}
                          </a>
                        </p>
                      </div>
                    </>
                  )
                }
                {technologiesImpacted ? (
                  <>
                    <h3>Technologies Impacted</h3>
                    <div><p>{technologiesImpacted}</p></div>
                  </>
                ) : null}
                <h3>Description</h3>
                <div className="parsed-content quill-display">
                  {
                    parse(cleanContent)
                  }
                </div>
                <h3>Recommendations</h3>
                <div className="parsed-content quill-display">
                  {
                    parse(cleanRecommendations)
                  }
                </div>
              </>
            )
          }
          {
            !userView
            && (
              <>
                <div>
                  <h1>
                    {title}
                  </h1>
                  <div className="d-flex flex-column">
                    <h3 className="m-0">DATE CREATED:</h3>
                    <span>{dateConversion(createdAt)}</span>
                  </div>
                </div>
                <h3>Description</h3>
                <div className="parsed-content quill-display">
                  {
                    parse(cleanContent)
                  }
                </div>
                <h3>Recommendations</h3>
                <div className="parsed-content quill-display">
                  {
                    parse(cleanRecommendations)
                  }
                </div>
              </>
            )
          }
        </Modal.Body>
        <Modal.Footer className={userView ? 'message-background' : ''}>
          {
            userView
            && (
              <GlobalMessage flaggedCyberThreatId={id}>
                Need help with this alert? Send a message to Primex.
              </GlobalMessage>
            )
          }
          {
            !userView
            && (
              <>
                <Button variant="primary" onClick={deleteAlert}>
                  Delete Alert
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReadPrimexAlert;

ReadPrimexAlert.defaultProps = {
  userView: false,
  notificationId: null,
};

import React from 'react';
import { Button } from 'react-bootstrap';
import { getLogout } from '../../api/auth';

interface Props {}

const NoAccessPermitted: React.FC<Props> = () => (
  <div className="position-fixed h-100 w-100">
    <div className="p-4 h-100 d-flex flex-column justify-content-center align-items-center">
      <h4>
        <span>
          {`Uh oh!  You don't have access to the Cyberportal.
          If you think this was in error, please email `}
        </span>
        <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT_RECIPIENT}`}>us</a>
        <span> or try logging out/in</span>
      </h4>
      <Button
        onClick={async () => {
          const res = await getLogout();
          localStorage.removeItem('accessToken');
          localStorage.removeItem('email');
          localStorage.removeItem('refreshToken');
          window.location.href = res.data.url;
        }}
      >
        Logout
      </Button>
    </div>
  </div>
);

export default NoAccessPermitted;

import React from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';
import MemberProfileNav from '../shared/members/memberProfile/MemberProfileNav';
import MembersNav from '../shared/members/MembersNav';

const Members: React.FC<void> = () => {
  const currentState = useAppSelector((state) => ({
    member: state.member,
  }));
  const { selectedMember } = currentState.member;
  return (
    <Layout>
      <div className="members-dashboard">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <Switch>
              <Route exact path={['/members', '/members/users']}>
                <h2>Members</h2>
              </Route>
              <Route path="/members/profile/:id">
                <h2>
                  Members
                  {
                    selectedMember
                    && `: ${selectedMember.memberName}`
                  }
                </h2>
              </Route>
            </Switch>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="member-container">
              <Switch>
                <Route exact path={['/members', '/members/users']}>
                  <MembersNav />
                </Route>
                <Route path="/members/profile/:id">
                  <MemberProfileNav />
                </Route>
              </Switch>
            </Card>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12 mt-5">
            <GlobalMessage
              flaggedMemberId={selectedMember?.id || undefined}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Members;

import { AxiosPromise } from 'axios';
import { IrClaimDTO } from '../store/irClaimSlice';
import api, { getPaginatedCountFromResponse } from './api';

export function getAllClaims(arg: any = null) {
  return new Promise<{ data: any; count: number | null }>((resolve, reject) => {
    try {
      api.get('/ir-claim', {
        params: arg,
      })
        .then((response: any) => {
          resolve({ data: response.data, count: getPaginatedCountFromResponse(response) });
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function notifyAtom(claimId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post(`/ir-claim/notify/${claimId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneClaim(claimId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/ir-claim/${claimId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateOneClaim(
  claimId: number, updateBody: Partial<IrClaimDTO>,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/ir-claim/${claimId}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function resyncClaim() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/ir-claim/sync')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

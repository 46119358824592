/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import parse from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Footer from './Footer';
import Header from './Header';
import SideNav from './SideNav';
import HowToAutoDisplay from '../shared/howTo/modals/HowToAutoDisplay';
import { getHowToTopicsAsync } from '../../store/howToSlice';
import { getCurrentSystemAlertAssignmentsAsync } from '../../store/systemAlertAssignmentSlice';

interface Props { }

const Layout: React.FC<Props> = ({ children }) => {
  const [show, setShow] = useState<boolean>(true);
  const currentState = useAppSelector((state) => ({
    systemAlertAssignments: state.systemAlertAssignments,
    user: state.user,
    howTo: state.howTo,
  }));
  const dispatch = useAppDispatch();
  const todaysDate: Date = new Date();

  const {
    systemAlertAssignments: {
      currentSystemAlertAssignments,
    }, howTo: {
      howToTopics,
    },
  } = currentState;

  const closeAlert = (alertTitle: string) => {
    localStorage.setItem(alertTitle, 'closed');
    setShow(false);
  };

  useEffect(() => {
    dispatch(getCurrentSystemAlertAssignmentsAsync());
    dispatch(getHowToTopicsAsync());
  }, []);

  const modalClosed = (id: number) => {
    if (localStorage.getItem(`howToId-${id}`) === 'closed') {
      return false;
    }
    return true;
  };
  return (
    <>
      {
        currentSystemAlertAssignments
        && currentSystemAlertAssignments.length > 0
        && (
          currentSystemAlertAssignments
            .slice()
            .filter((item) => item.systemAlert)
            .filter((item) => !item.systemAlert.archived)
            .filter((item) => !item.systemAlert.draft)
            // eslint-disable-next-line max-len
            .filter((item) => new Date(item.systemAlert.startDate).getTime() <= new Date(todaysDate).getTime())
            // eslint-disable-next-line max-len
            .filter((item) => new Date(item.systemAlert.expirationDate).getTime() >= new Date(todaysDate).getTime())
            .map((alert) => {
              const closed = localStorage.getItem(alert.systemAlert.title);
              if (!closed) {
                return (
                  <Alert
                    key={alert.id}
                    show={show}
                    data-alert-id={alert.systemAlert.title}
                    onClose={() => closeAlert(alert.systemAlert.title)}
                    dismissible={alert.systemAlert.dismissible}
                    className="system-alert"
                  >
                    {parse(alert.systemAlert.message)}
                  </Alert>
                );
              }
            })
        )
      }
      <Header />
      <Row className="layout-row flex-nowrap">
        <div className="d-flex side-nav-container">
          <SideNav />
        </div>
        <div className="col layout-children">
          {children}
        </div>
      </Row>
      <Footer />
      {
        howToTopics
        && howToTopics.length > 0
        && (
          <HowToAutoDisplay
            topic={howToTopics
              .slice()
              .filter((item) => item.published)
              .filter((item) => !item.archived)
              .filter((item) => modalClosed(item.id))}
          />
        )
      }
    </>
  );
};

export default Layout;

import React from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';
import PostAndArticleTable from '../shared/postsAndArticles/PostAndArticleTable';
import PostAndArticleDetail from '../shared/postsAndArticles/postArticleDetail';

interface Props { }

const PostsAndArticles: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    postArticles: state.postArticles,
  }));
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>Posts / Articles</h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="posts-articles-table-container">
              <Switch>
                <Route exact path="/posts-articles">
                  <PostAndArticleTable />
                </Route>
                <Route path="/posts-articles/:id">
                  <PostAndArticleDetail />
                </Route>
              </Switch>
            </Card>
          </div>
        </div>
        <Switch>
          <Route path="/posts-articles/:id">
            <div className="mt-4">
              {
                currentState.postArticles
                && currentState.postArticles.selectedpostArticle
                && (
                <GlobalMessage
                  flaggedPostArticleId={currentState.postArticles.selectedpostArticle.id}
                >
                  Need help with this post? Send a message to Primex.
                </GlobalMessage>

                )
              }
            </div>
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default PostsAndArticles;

import React from 'react';

interface Props { }

const Footer: React.FC<Props> = () => (
  <div className="footer">
    <div className="row d-flex">
      <div className="empty-div" />
      <div className="col-12">
        <p>
          {`© ${new Date().getFullYear()} Primex`}
          <sup>3 </sup>
          Cybersecurity Portal
        </p>
        <p>
          The New Hampshire Public Risk Management Exchange (Primex
          <sup>3</sup>
          ) Cybersecurity Portal and all material therein is the exclusive property of Primex
          <sup>3</sup>
          . Members&apos;
          access to the portal and its content
          shall be at the convenience and sole discretion of Primex
          <sup>3</sup>
          .
        </p>
      </div>
      <div className="empty-div" />
    </div>
  </div>
);

export default Footer;

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getMembersAsync, Member } from '../../../../../store/memberSlice';
import { dateYearMonthDay } from '../../../../../service/timeAndDate';
import { DistributionUniversal } from '../../../../../models/distributionModels';
import { getAllUsersWithMembersAsync } from '../../../../../store/userSlice';

interface MemberDropdown {
  value: number;
  name: string;
}

interface SubtypeDropdown {
  value: string;
  name: string;
}
interface Props {
  distModel: {
    model: DistributionUniversal | undefined;
    setModel: Function;
  }
  addEndDate?: boolean;
}

const QuestionnaireDistribution: React.FC<Props> = (props: Props) => {
  const { distModel, addEndDate } = props;
  const [memberId, setMemberId] = useState();
  const [userId, setUserId] = useState(null);
  const [key, setKey] = useState('member');
  const dispatch = useAppDispatch();

  const primexMemberNumber: number | null = process.env.REACT_APP_PRIMEX_MEMBER_NUMBER ? parseInt(process.env.REACT_APP_PRIMEX_MEMBER_NUMBER, 10) : null;
  useEffect(() => {
    dispatch(getMembersAsync());
    dispatch(getAllUsersWithMembersAsync());
    if (!distModel.model) {
      distModel.setModel({
        userOnly: false,
        allMembers: false,
        specificMemberIds: [],
        specificMemberTypes: null,
        specificMemberMaturityLevels: [],
        specificMemberSubTypes: [],
        primaryProgramContact: false,
        primaryIt: false,
        basicUser: false,
        specificUserJobCategories: [],
        dueDate: null,
        memberSelection: '',
        userError: false,
        dateError: false,
        specificUserId: null,
        selectedUserMember: null,
        memberError: false,
      });
    }
  }, []);

  const currentState = useAppSelector((state) => ({
    member: state.member,
    user: state.user,
  }));
  const { users } = currentState.user;
  const { members } = currentState.member;

  const setUserOnly = (tab: string) => {
    if (tab) {
      setKey(tab);
      if (tab === 'user') {
        distModel.setModel({ ...distModel.model, userOnly: true });
      } else {
        distModel.setModel({ ...distModel.model, userOnly: false });
      }
    }
  };

  const returnUserArray = (Id: number) => {
    const selectedMember = members.find((member) => member.id === Id);
    if (selectedMember && selectedMember.users && selectedMember.users.length > 0) {
      return selectedMember.users.map((user) => ({
        value: user.id,
        label: `${user.firstName || ''} ${user.lastName || ''}`,
      }));
    }
    return [];
  };

  const returnSelectedUser = (Id: number) => {
    if (users && users.length > 0) {
      const selectedUser = users.find((user) => user.id === Id);
      if (selectedUser) {
        return (
          <Form.Group>
            <h2>{`${selectedUser.firstName} ${selectedUser.lastName}`}</h2>
            <p className="my-0">{selectedUser.jobTitle ? `Job Title: ${selectedUser.jobTitle}` : 'Job Title: N/A'}</p>
            <p className="my-0">{selectedUser.jobCategory ? `Category: ${selectedUser.jobCategory}` : 'Category: N/A'}</p>
            <p className="my-0">{selectedUser.members[0].memberName ? `Member: ${selectedUser.members[0].memberName}` : 'Member: N/A'}</p>
            <p className="my-0">{selectedUser.members[0].parentMember ? `Parent Member: ${selectedUser.members[0].parentMember.memberName}` : 'Parent Member: N/A'}</p>
            <p className="my-0">{selectedUser.phone ? `Phone: ${selectedUser.phone}` : 'Phone: N/A'}</p>
            <p className="my-0">{selectedUser.email ? `Email: ${selectedUser.email}` : 'Email: N/A'}</p>
            {
              selectedUser.primaryContactForMembers.length > 0
                && selectedUser.primaryITForMembers.length > 0
                ? <p> Contact Type: Primary Program Contact, Primary IT Contact</p>
                : (
                  <p className="my-0">
                    Contact Type:
                    {selectedUser.primaryContactForMembers.length > 0 ? ' Primary Program Contact' : null}
                    {selectedUser.primaryITForMembers.length > 0 ? ' Primary IT Contact' : null}
                  </p>
                )
            }
          </Form.Group>
        );
      }
    }
  };

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    distModel.setModel({ ...distModel.model, specificMemberIds: memberIds });
  };

  const populateSubtypeState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    distModel.setModel({ ...distModel.model, specificMemberSubTypes: subtypes });
  };

  const populateMaturityState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    distModel.setModel({ ...distModel.model, specificMemberMaturityLevels: subtypes });
  };

  const setMemberType = (array: SubtypeDropdown[]) => {
    const specificMemberTypes: string[] = [];
    if (array && array.length > 0) {
      array.map((item) => {
        specificMemberTypes.push(item.value);
      });
    }
    distModel.setModel({ ...distModel.model, specificMemberTypes });
  };

  const setMemberSelection = (memberSelection: string) => {
    distModel.setModel({ ...distModel.model, memberSelection });
  };
  const setUserSelection = (specificUserId: number) => {
    distModel.setModel({ ...distModel.model, specificUserId });
  };
  const setUserMember = (selectedUserMember: number) => {
    distModel.setModel({ ...distModel.model, selectedUserMember });
  };

  const setJobCategory = (dropDownArray: SubtypeDropdown[]) => {
    const specificUserJobCategories: string[] = [];
    if (dropDownArray && dropDownArray.length > 0) {
      dropDownArray.map((arrayItem) => {
        specificUserJobCategories.push(arrayItem.value);
      });
    }
    distModel.setModel({ ...distModel.model, specificUserJobCategories });
  };

  const populateCategoryFilter = () => {
    const jobCategories: { label: string, value: string }[] = [];
    if (users) {
      const userList = users.slice();
      userList
        .filter((user) => user.jobCategory)
        .sort((a, b) => ((a.jobCategory ? a.jobCategory?.toLowerCase() : 'z') < (b.jobCategory ? b.jobCategory?.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-All`)) {
              jobCategories.push({
                label: `${user.jobCategory}-All`,
                value: `category=${user.jobCategory}&jobRole=All`,
              });
            }
          }
        });
      userList
        .filter((user) => user.jobCategory && user.jobRole)
        .sort((a, b) => ((a.jobRole ? a.jobRole.toLowerCase() : 'z') < (b.jobRole ? b.jobRole.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-${user.jobRole}`)) {
              jobCategories.push({
                label: `${user.jobCategory}-${user.jobRole}`,
                value: `category=${user.jobCategory}&jobRole=${user.jobRole}`,
              });
            }
          }
        });
      return jobCategories.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    }
  };

  const populateMemberTypeFilter = () => {
    const memberTypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      members
        .slice()
        .map((item) => item.entityType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull)
        .map((type) => {
          const option = {
            label: type,
            value: type,
          };
          memberTypeOptions.push(option);
        });
    }
    return memberTypeOptions;
  };

  const populateMemberMaturityFilter = () => {
    const memberMaturityOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      const newMembers = members
        .slice()
        .filter((member) => {
          if (
            distModel.model
            && distModel.model.specificMemberTypes
            && distModel.model.specificMemberTypes.length > 0
            && (distModel.model.specificMemberTypes.includes(member.entityType)
              || distModel.model.specificMemberTypes.includes('All'))
          ) {
            return member;
          }
          if (
            !distModel.model
            || !distModel.model.specificMemberTypes
            || distModel.model.specificMemberTypes.length <= 0
          ) {
            return member;
          }
        })
        .map((member) => member.cyberMaturityRating)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull);

      if (newMembers && newMembers.length > 0) {
        newMembers
          .map((type) => {
            if (type) {
              const option = {
                label: type,
                value: type,
              };
              memberMaturityOptions.push(option);
            }
          });
      }
    }
    return memberMaturityOptions;
  };

  const populateMemberSubTypeFilter = () => {
    const memberSubtypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      const newMembers = members
        .slice()
        .filter((member) => {
          if (
            distModel.model
            && distModel.model.specificMemberTypes
            && distModel.model.specificMemberTypes.length > 0
            && (distModel.model.specificMemberTypes.includes(member.entityType)
              || distModel.model.specificMemberTypes.includes('All'))
          ) {
            return member;
          }
          if (
            !distModel.model
            || !distModel.model.specificMemberTypes
            || distModel.model.specificMemberTypes.length <= 0
          ) {
            return member;
          }
        })
        .filter((notNull) => notNull);

      if (newMembers && newMembers.length > 0) {
        const finalFilterMembers = newMembers
          .slice()
          .filter((member: Member) => {
            if (
              distModel.model
              && distModel.model.specificMemberSubTypes
              && distModel.model.specificMemberSubTypes.length > 0
              && (distModel.model.specificMemberSubTypes.includes(member.propertyExposureClass)
                || distModel.model.specificMemberSubTypes.includes('All'))
            ) {
              return member;
            }
            if (
              !distModel.model
              || !distModel.model.specificMemberSubTypes
              || distModel.model.specificMemberSubTypes.length <= 0
            ) {
              return member;
            }
          })
          .map((member) => member.propertyExposureClass)
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter((notNull) => notNull);

        if (finalFilterMembers && finalFilterMembers.length > 0) {
          finalFilterMembers
            .map((type) => {
              if (type) {
                const option = {
                  label: type,
                  value: type,
                };
                memberSubtypeOptions.push(option);
              }
            });
        }
      }
    }
    return memberSubtypeOptions;
  };

  return (
    <>
      {
        distModel.model
        && (
          <Tabs
            activeKey={key}
            onSelect={(k) => {
              if (k) {
                setUserOnly(k);
              }
            }}
          >
            <Tab eventKey="member" title="Member Distribution">
              <Form>
                <div className="row">
                  <div className="col-6 px-5 py-3">
                    <Form.Group controlId="memberType">
                      <Form.Check isInvalid={distModel.model.memberError} className="my-2" onChange={(e) => setMemberSelection(e.target.id)} type="radio" id="allMembers" label="Assign to all members" name="memberSelection" />
                      <Form.Check isInvalid={distModel.model.memberError} className="my-2" onChange={(e) => setMemberSelection(e.target.id)} type="radio" id="specificMembers" label="Assign to specific members" name="memberSelection" />
                      <Form.Check isInvalid={distModel.model.memberError} feedback="You must make at least one selection" className="my-2" onChange={(e) => setMemberSelection(e.target.id)} type="radio" id="selectedCriteria" label="Assign to members based on selected criteria" name="memberSelection" />
                    </Form.Group>
                    {
                      distModel.model.memberSelection === 'specificMembers'
                      && (
                        <>
                          <Form.Group controlId="memberSelectInput">
                            <Form.Label>Select Member(s)</Form.Label>
                            <Select
                              className="multi-select-dropdown"
                              isMulti
                              options={
                                currentState.member.members
                                  .slice()
                                  .filter((member) => (member.memberNumber !== primexMemberNumber) && (member.memberNumber !== 38063))
                                  .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                                  .map((member) => (
                                    {
                                      label: member.memberName,
                                      value: member.id,
                                    }
                                  ))
                              }
                              onChange={(selectedOption: any) => populateMemberIdState(selectedOption)}
                            />
                          </Form.Group>
                        </>
                      )
                    }
                    {
                      distModel.model.memberSelection === 'selectedCriteria'
                      && (
                        <>
                          <Form.Group controlId="memberTypeDropdown">
                            <Form.Label>Member Type</Form.Label>
                            <Select
                              className="multi-select-dropdown"
                              isMulti
                              options={populateMemberTypeFilter()}
                              onChange={(newValue: any) => setMemberType(newValue)}
                            />
                          </Form.Group>
                          <Form.Group controlId="memberMaturityDropdown">
                            <Form.Label>Member Maturity Level</Form.Label>
                            <Select
                              isDisabled={!distModel.model.specificMemberTypes || distModel.model.specificMemberTypes.length <= 0}
                              className="multi-select-dropdown"
                              isMulti
                              options={populateMemberMaturityFilter()}
                              onChange={(newValue: any) => populateMaturityState(newValue)}
                            />
                          </Form.Group>
                          <Form.Group controlId="memberSubtype">
                            <Form.Label>Sub-Type</Form.Label>
                            <Select
                              isDisabled={(!distModel.model.specificMemberTypes || distModel.model.specificMemberTypes.length <= 0) || !distModel.model.specificMemberMaturityLevels || distModel.model.specificMemberMaturityLevels.length <= 0}
                              className="multi-select-dropdown"
                              isMulti
                              options={populateMemberSubTypeFilter()}
                              onChange={(newValue: any) => populateSubtypeState(newValue)}
                            />
                          </Form.Group>
                        </>
                      )
                    }
                  </div>
                  <div className="col-6 px-5 py-3">
                    <Form.Group controlId="memberRole">
                      <Form.Label className="my-2">User Role</Form.Label>
                      <Form.Check isInvalid={distModel.model.userError} className="my-2" checked={distModel.model.primaryProgramContact} onChange={(e) => distModel.setModel({ ...distModel.model, primaryProgramContact: e.target.checked })} type="checkbox" id="programContact" label="Primary Program Contact" />
                      <Form.Check isInvalid={distModel.model.userError} className="my-2" checked={distModel.model.primaryIt} onChange={(e) => distModel.setModel({ ...distModel.model, primaryIt: e.target.checked })} type="checkbox" id="primaryIT" label="Primary IT" />
                      <Form.Check isInvalid={distModel.model.userError} feedback="You must make at least one selection" className="my-2" checked={distModel.model.basicUser} onChange={(e) => distModel.setModel({ ...distModel.model, basicUser: e.target.checked })} type="checkbox" id="basicUser" label="Basic User" />
                    </Form.Group>
                    {
                      distModel.model.memberSelection === 'selectedCriteria'
                      && (
                        <>
                          <Form.Group controlId="userJobCategory">
                            <Form.Label>User Job Category</Form.Label>
                            <Select
                              className="multi-select-dropdown"
                              isMulti
                              options={populateCategoryFilter()}
                              onChange={(newValue: any) => setJobCategory(newValue)}
                            />
                          </Form.Group>
                        </>
                      )
                    }
                    {
                      addEndDate
                      && (
                        <Form.Group controlId="dueDate" className="w-75">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            isInvalid={distModel.model.dateError}
                            type="date"
                            placeholder="MM/DD/YYYY"
                            defaultValue={distModel.model.dueDate ? dateYearMonthDay(distModel.model.dueDate) : undefined}
                            onChange={(e) => {
                              const newDate = new Date(e.target.value);
                              if (newDate) {
                                distModel.setModel({ ...distModel.model, dueDate: new Date((e.target.value).replace(/-/g, '/')) });
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )
                    }
                  </div>
                </div>
              </Form>
            </Tab>
            <Tab eventKey="user" title="User Distribution">
              <Form>
                <div className="row">
                  <div className="col-6 px-5 py-3">
                    <Form.Group controlId="SelectMemberUserDist">
                      <Form.Label>Select Member</Form.Label>
                      <Select
                        className="multi-select-dropdown"
                        options={
                          currentState.member.members
                            .slice()
                            .filter((member) => member.memberNumber !== primexMemberNumber)
                            .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                            .map((member) => (
                              {
                                label: member.memberName,
                                value: member.id,
                              }
                            ))
                        }
                        onChange={(selectedOption: any) => {
                          setMemberId(selectedOption.value);
                          setUserMember(selectedOption.value);
                        }}
                      />
                    </Form.Group>
                    {memberId
                      && members ? (
                        <Form.Group controlId="SelectUserDist">
                          <Form.Label>Select User</Form.Label>
                          <Select
                            className="multi-select-dropdown"
                            options={
                            returnUserArray(memberId)
                          }
                            onChange={(selectionOption: any) => {
                              setUserId(selectionOption.value);
                              setUserSelection(selectionOption.value);
                            }}
                          />
                        </Form.Group>
                      ) : null}
                  </div>
                  <div className="col-6 px-5 py-3">

                    {
                      addEndDate
                      && (
                        <Form.Group controlId="userDueDate" className="w-75">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control isInvalid={distModel.model.dateError} type="date" placeholder="MM/DD/YYYY" defaultValue={distModel.model.dueDate ? dateYearMonthDay(distModel.model.dueDate) : undefined} onChange={(e) => distModel.setModel({ ...distModel.model, dueDate: new Date((e.target.value).replace(/-/g, '/')) })} />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )
                    }
                    {users
                      && userId
                      && returnSelectedUser(userId)}
                  </div>
                </div>
              </Form>

            </Tab>
          </Tabs>
        )
      }
    </>
  );
};

QuestionnaireDistribution.defaultProps = {
  addEndDate: true,
};

export default QuestionnaireDistribution;

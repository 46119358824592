import { AxiosPromise } from 'axios';
import { PostSupportMessage } from '../store/supportMessageSlice';
import api from './api';

export function postSupportMessage(messageBody: PostSupportMessage) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/support-message', messageBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getAllSupportMessages() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/support-message')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

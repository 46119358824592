import { AxiosPromise } from 'axios';
import { Member } from '../store/memberSlice';
import api, { getPaginatedCountFromResponse } from './api';

// eslint-disable-next-line import/prefer-default-export
export function getMembers(arg: any = null) {
  return new Promise<{ data: any; count: number | any | null }>((resolve, reject) => {
    try {
      api.get('/member', { params: arg })
        .then((response: any) => {
          // eslint-disable-next-line no-unused-expressions
          arg?.pageIndex
            ? resolve({ data: response.data, count: getPaginatedCountFromResponse(response) })
            : resolve({ data: response.data, count: 0 });
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getMemberAutosuggest() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/member/member-autosuggest')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneMember(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/member/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateOneMember(id: number, memberData: Partial<Member>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/member/${id}`, memberData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getMemberFilters() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/member/member-filters')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { PostArticle } from '../../../store/postArticleSlice';
import { PostArticleAssignment } from '../../../store/postArticleAssignmentSlice';
import sliceIntoChunks from '../../../service/sliceIntoChunks';

interface Props {
  assignments: PostArticleAssignment[];
}

const PostCarousel: React.FC<Props> = ({ assignments }) => {
  const [carouselData, setCarouselData] = useState<PostArticle[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const repackage = () => {
    if (assignments.length > 0) {
      const postArticles = assignments
        .slice()
        .filter((assignment) => assignment.postArticle.displayDashboardCarousel)
        .filter((assignment) => !assignment.postArticle.archived)
        .sort((a, b) => (a.createdAt.valueOf() < b.createdAt.valueOf() ? 1 : -1))
        .map((assignment) => {
          if (assignment.postArticle && assignment.postArticle.displayDashboardCarousel) {
            return assignment.postArticle;
          }
        });
      if (postArticles && postArticles.length > 0) {
        const carouselArray = sliceIntoChunks(postArticles, 3);
        if (carouselArray.length > 4) {
          setCarouselData(carouselArray.slice(0, 4));
        } else {
          setCarouselData(carouselArray);
        }
      }
    }
  };

  useEffect(() => {
    repackage();
  }, [assignments]);

  return (
    <div className="card-section post-carousel">
      <Card>
        <Card.Body>
          <Card.Title className="d-flex justify-content-between">
            <div>
              <h2>Recent Posts / Articles</h2>
            </div>
            <div>
              <Button disabled={currentPage <= 0} onClick={() => setCurrentPage(currentPage - 1)} className="carousel-button" variant="primary">
                &lt;
              </Button>
              <Button disabled={currentPage >= (carouselData.length - 1)} onClick={() => setCurrentPage(currentPage + 1)} className="carousel-button" variant="primary">
                &gt;
              </Button>
            </div>
          </Card.Title>
          <div className="row row-no-gutters m-0">
            {
              carouselData
              && carouselData.length > 0
              && carouselData[currentPage]
                .map((article) => (
                  <div className="col-4 d-flex justify-content-center">
                    <Card className="m-1 w-100">
                      <Card.Body className="">
                        {
                          article.thumbnailFilename
                          && article.thumbnailUrl
                          && <Card.Img className="mb-2 article-thumbnail" src={article.thumbnailUrl} />
                        }
                        <Card.Subtitle className="mb-2">
                          <h4 className="bold">
                            {article.title}
                          </h4>
                        </Card.Subtitle>
                        <p>
                          {article.summary}
                        </p>
                        <p>
                          <Link to={`/posts-articles/${article.id}`}>
                            Continue reading &gt;&gt;
                          </Link>
                        </p>
                      </Card.Body>
                    </Card>
                  </div>
                ))
            }
          </div>
          <div className="d-flex justify-content-between">
            <Link to="/posts-articles">View all posts/articles &gt;&gt;</Link>
            <div>
              {
                carouselData
                && [...Array(carouselData.length)]
                  .map((item, index) => <FontAwesomeIcon className={`pagination-icon ${currentPage === index && 'active'}`} icon={faCircle} />)
              }
            </div>
            <div className="empty-div" />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default PostCarousel;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPrimexThreatAlert,
  deleteMultiplePrimexThreatAlerts,
  getAllPrimexThreatAlerts,
  getOnePrimexThreatAlert,
  updatePrimexThreatAlert,
} from '../api/primexCyberAlertsAPI';
import { IncomingSecurityAlert } from './incomingAlertSlice';
import { User } from './userSlice';

export interface PrimexSecurityAlert {
  id: number;
  title: string;
  technologiesImpacted: string;
  content: string;
  recommendations: string;
  draft: boolean;
  archived: boolean;
  publishDate: Date;
  sourceTitle: string;
  source: string;
  threatLevel: string;
  dueDate: Date;
  incomingAlert: IncomingSecurityAlert;
  author: User;
  cyberThreatAssignments?: any[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export interface CreatePrimexSecurityAlertDraft {
  id?: number;
  title: string | null;
  technologiesImpacted: string | null;
  content: string | null;
  recommendations: string | null;
  draft: boolean | null;
  archived?: boolean;
  publishDate?: Date;
  sourceTitle: string | null;
  source: string | null;
  threatLevel: string | null;
  dueDate?: Date;
  incomingAlert?: IncomingSecurityAlert;
}

export interface CreatePrimexSecurityAlert {
  id?: number;
  title: string;
  technologiesImpacted: string;
  content: string;
  recommendations: string;
  draft: boolean;
  archived?: boolean;
  publishDate?: Date;
  sourceTitle: string | null;
  source: string;
  threatLevel: string;
  dueDate?: Date;
  incomingAlert?: IncomingSecurityAlert;
}

export interface PrimexSecurityAlertState {
  primexSecurityAlerts: PrimexSecurityAlert[] | null;
  selectedPrimexSecurityAlert: PrimexSecurityAlert | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: PrimexSecurityAlertState = {
  primexSecurityAlerts: [],
  selectedPrimexSecurityAlert: null,
  status: 'idle',
  error: null,
};

const getAllPrimexThreatAlertsAsync = createAsyncThunk(
  'primexThreatAlerts/getAllPrimexAlerts',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await getAllPrimexThreatAlerts();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOnePrimexThreatAlertAsync = createAsyncThunk(
  'primexThreatAlerts/getOnePrimexAlert',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOnePrimexThreatAlert(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createPrimexThreatAlertAsync = createAsyncThunk(
  'primexThreatAlerts/createOnePrimexAlert',
  async (
    data:
    CreatePrimexSecurityAlert | CreatePrimexSecurityAlertDraft | Partial<CreatePrimexSecurityAlert>,
    { rejectWithValue },
  ) => {
    try {
      const response: any = await createPrimexThreatAlert(data);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultiplePrimexThreatAlertsAsync = createAsyncThunk(
  'primexThreatAlerts/deleteMultiplePrimexAlerts',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultiplePrimexThreatAlerts(ids);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updatePrimexThreatAlertAsync = createAsyncThunk(
  'primexThreatAlerts/update',
  async (arg: { id: number, body: Partial<CreatePrimexSecurityAlert>}, { rejectWithValue }) => {
    try {
      const response: any = await updatePrimexThreatAlert(arg.id, arg.body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const nullSelectedPrimexAlert = createAsyncThunk(
  'primexThreatAlerts/nullSelectedAlert',
  () => null,
);

export const primexAlertsSlice = createSlice({
  name: 'primexAlerts',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPrimexThreatAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllPrimexThreatAlertsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.primexSecurityAlerts = action.payload;
        state.error = null;
      })
      .addCase(getAllPrimexThreatAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.primexSecurityAlerts = [];
        state.error = action.payload;
      })
      .addCase(getOnePrimexThreatAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOnePrimexThreatAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedPrimexSecurityAlert = action.payload;
        state.error = null;
      })
      .addCase(getOnePrimexThreatAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedPrimexSecurityAlert = null;
        state.error = action.payload;
      })
      .addCase(createPrimexThreatAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPrimexThreatAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedPrimexSecurityAlert = action.payload;
        state.error = null;
      })
      .addCase(createPrimexThreatAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedPrimexSecurityAlert = null;
        state.error = action.payload;
      })
      .addCase(nullSelectedPrimexAlert.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(nullSelectedPrimexAlert.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedPrimexSecurityAlert = null;
        state.error = null;
      })
      .addCase(deleteMultiplePrimexThreatAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultiplePrimexThreatAlertsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultiplePrimexThreatAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updatePrimexThreatAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePrimexThreatAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedPrimexSecurityAlert = action.payload;
        state.error = null;
      })
      .addCase(updatePrimexThreatAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedPrimexSecurityAlert = null;
        state.error = action.payload;
      });
  },
});

export default primexAlertsSlice.reducer;

export {
  getAllPrimexThreatAlertsAsync,
  getOnePrimexThreatAlertAsync,
  createPrimexThreatAlertAsync,
  nullSelectedPrimexAlert,
  deleteMultiplePrimexThreatAlertsAsync,
  updatePrimexThreatAlertAsync,
};

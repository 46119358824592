import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import {
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import CreateSlide from './howToComponents/CreateSlide';
import { CreateHowToModel, HowToSlide } from '../../../../models/howToModel';
import { createHowToTopicAsync, getOneHowToAsync, HowToTopic } from '../../../../store/howToSlice';
import { useAppDispatch } from '../../../../store/hooks';

interface Props { }

const CreateHowToContent: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const [howToTopic, setHowToTopic] = useState<HowToTopic | CreateHowToModel>();
  const [dragId, setDragId] = useState<number>(0);
  const [specificLocation, setSpecificLocation] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [slideError, setSlideError] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getCurrentHowTo = async (reqId: number) => {
    const currentHowTo = await dispatch(getOneHowToAsync(reqId));
    setHowToTopic(currentHowTo.payload);
  };

  useEffect(() => {
    if (id && !howToTopic) {
      const parseId = parseInt(id, 10);
      getCurrentHowTo(parseId);
    } else if (!howToTopic) {
      setHowToTopic({
        topicTitle: '',
        published: false,
        archived: false,
        trigger: '',
        slides: [
          {
            order: 0,
            slideTitle: '',
            content: '',
          },
        ],
      });
    }
  }, []);

  const errorCheck = () => {
    let error = false;
    if (howToTopic) {
      if (!howToTopic.topicTitle) {
        error = true;
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (!howToTopic.slides || howToTopic.slides.length < 1) {
        error = true;
      }
      if (howToTopic.slides && howToTopic.slides.length > 0) {
        // eslint-disable-next-line array-callback-return
        howToTopic.slides.map((slide) => {
          if (!slide.slideTitle || !slide.content) {
            error = true;
            setSlideError(true);
          }
        });
      }
    } else {
      error = true;
    }
    return error;
  };

  const addNewSlide = () => {
    if (howToTopic) {
      const order = howToTopic.slides.length;
      setHowToTopic({
        ...howToTopic,
        slides: [
          ...howToTopic.slides,
          {
            order,
            slideTitle: '',
            content: '',
          },
        ],
      });
    }
  };

  const removeSlide = (position: number) => {
    if (howToTopic) {
      const reducedArray = howToTopic.slides.filter((item) => item.order !== position);
      const newArray: HowToSlide[] = reducedArray.map((item, index) => ({
        ...item,
        order: index,
      }));
      setHowToTopic({
        ...howToTopic,
        slides: [...newArray],
      });
    }
  };

  const updateSlideContent = (model: HowToSlide) => {
    if (howToTopic) {
      const updatedSlide: HowToSlide | undefined = howToTopic.slides.find(
        (item) => item.order === model.order,
      );
      if (updatedSlide) {
        const newSlide = { ...updatedSlide };
        newSlide.slideTitle = model.slideTitle;
        newSlide.content = model.content;
        const newArray = howToTopic.slides.slice();
        newArray.splice(model.order, 1, newSlide);
        setHowToTopic({
          ...howToTopic,
          slides: [...newArray],
        });
      }
    }
  };

  const handleDrop = (e: any) => {
    if (howToTopic) {
      const dropID = parseInt(e.currentTarget.id, 10);
      const dragBox = howToTopic.slides.find((box) => box.order === dragId);
      const dropBox = howToTopic.slides.find((box) => box.order === dropID);

      if (dragBox && dropBox) {
        const dragBoxOrder = dragBox.order;
        const dropBoxOrder = dropBox.order;

        const newSlideObjects: HowToSlide[] = howToTopic.slides.slice().map((slide) => {
          const box = { ...slide };
          if (box.order === dragId) {
            box.order = dropBoxOrder;
            return box;
          }
          if (box.order === dropID) {
            box.order = dragBoxOrder;
            return box;
          }
          return box;
        });
        const newArray = newSlideObjects.sort((a, b) => a.order - b.order);
        setHowToTopic({
          ...howToTopic,
          slides: [...newArray],
        });
      }
    }
  };

  const updateTitle = (title: string) => {
    if (howToTopic) {
      setHowToTopic({ ...howToTopic, topicTitle: title });
    }
  };

  const submitHowToTopic = async (published: boolean) => {
    if (published && errorCheck()) {
      return;
    }
    if (howToTopic) {
      const newTopic: CreateHowToModel = { ...howToTopic };
      if (published) {
        newTopic.published = true;
      } else {
        newTopic.published = false;
      }
      const responseTopic = await dispatch(createHowToTopicAsync(newTopic));
      localStorage.setItem(`howToId-${responseTopic.payload.id}`, 'closed');
      history.push('/content/manage/how-to');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [memberSelection, setMemberSelection] = useState('');
  return (
    <div className="row">
      <div className="col-8">
        <Card className="p-3">
          <Card.Body>
            <h2>How-To Topic</h2>
            <Form>
              <Form.Group controlId="title">
                <Form.Label className="mt-2">Topic Title</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={titleError}
                  placeholder="Enter title"
                  value={howToTopic ? howToTopic.topicTitle : ''}
                  onChange={
                    (e: any) => updateTitle(e.target.value)
                  }
                />
                <Form.Control.Feedback type="invalid">Enter a Title</Form.Control.Feedback>
              </Form.Group>
              <div className="create-slide-container">
                <div className="mb-3 ml-4">
                  <Button variant="primary" onClick={addNewSlide}>
                    Add Slide
                  </Button>
                </div>
                {
                  howToTopic
                  && howToTopic.slides.length > 0
                  && howToTopic.slides
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map((slide) => (
                      <div
                        id={slide.order.toString()}
                        className="create-slide-item mb-3 d-flex flex-row"
                        draggable
                        onDragOver={(e) => e.preventDefault()}
                        onDragStart={(e) => setDragId(parseInt(e.currentTarget.id, 10))}
                        onDrop={handleDrop}
                      >
                        <FontAwesomeIcon icon={faBars} className="mt-4 mr-2" />
                        <CreateSlide
                          slideData={slide}
                          updateContent={updateSlideContent}
                          removeSlide={removeSlide}
                        />
                      </div>
                    ))
                }
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="col-4">
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex justify-content-between mb-2">
              <Button onClick={() => submitHowToTopic(false)} variant="secondary">Save Draft</Button>
              <Button onClick={() => submitHowToTopic(true)} variant="primary">Publish</Button>
            </div>
            <div>
              <Form.Group controlId="dismissableGroup">
                <Form.Label className="my-2">Trigger</Form.Label>
                <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="login" label="Login" />
              </Form.Group>
              <Form.Group controlId="specificLoca">
                <Form.Check disabled className="my-2" onChange={(e) => setSpecificLocation(e.target.checked)} checked={specificLocation} type="checkbox" id="specific" label="Accessing a specific location" />
              </Form.Group>
              {
                specificLocation
                && (
                  <div className="ml-4">
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="dashboard" label="Dashboard" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="questionnaires" label="Questionnaires" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="alerts" label="Cyber Threat Alerts" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="training" label="Training" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="profile" label="Profile" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="postArticle" label="Post / Article" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="checklist" label="Checklist" />
                    <Form.Check className="my-2" onChange={(e) => e} type="checkbox" id="documents" label="Documents" />
                  </div>
                )
              }
            </div>
          </Card.Body>
        </Card>
        <Toast className="mt-2 create-how-to-toast" show={slideError} onClose={() => setSlideError(false)}>
          <Toast.Header>
            <strong className="bold">Primex: How-To</strong>
          </Toast.Header>
          <Toast.Body>
            Please enter content for all slides in this topic before publishing.
          </Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default CreateHowToContent;

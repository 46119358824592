/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Notification } from '../../../../store/notificationSlice';
import Read from '../../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../../assets/icons/MessageClosed.svg';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import ReadPrimexAlert from '../../cyberThreats/modals/readPrimexAlert';

interface Props {
  notification: Notification;
}

const CyberThreatRow: React.FC<Props> = ({ notification }) => {
  const {
    id,
    viewed,
    cyberThreatAssignment,
    title,
    type,
    createdAt,
  } = notification;

  if (cyberThreatAssignment) {
    return (
      <tr key={`${id}-cyberAlertNotification`}>
        <td>
          <img src={viewed ? Read : Unread} alt={viewed ? 'Read icon' : 'Unread icon'} />
        </td>
        <td>
          {dateAndTime(createdAt)}
        </td>
        <td>{type}</td>
        <td>
          <ReadPrimexAlert
            threatAssignment={{
              id: cyberThreatAssignment.id,
              viewed: cyberThreatAssignment.viewed,
            }}
            userView
            threatData={cyberThreatAssignment.cyberThreat}
            dateConversion={dateOnly}
            notificationId={id}
          >
            {title}
          </ReadPrimexAlert>
        </td>
        <td />
      </tr>
    );
  }
  return (
    <tr />
  );
};
export default CyberThreatRow;

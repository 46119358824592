import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { IrClaim } from '../../../../store/irClaimSlice';
import AdminClaimInfoPdf from './pdfPages/adminClaimInfoPdf';
import ClaimContactsPdf from './pdfPages/claimContactsPdf';
import ClaimFilesPdf from './pdfPages/claimFilesPdf';
import ClaimUpdatesPdf from './pdfPages/claimUpdatesPdf';
import ClaimPrivateMessagePdf from './pdfPages/claimPrivateMessagePdf';
import ClaimTechnologyPdf from './pdfPages/claimTechnologyPdf';
import MemberUserClaimInfoPdf from './pdfPages/memberUserClaimInfoPdf';

// eslint-disable-next-line consistent-return
const exportZipPdfs = async (irClaim: IrClaim, irUser: boolean, secondTry: boolean = false) => {
  try {
    const zip = new JSZip();
    const newClaim = zip.folder(`${irClaim.title}`);
    if (newClaim) {
      if (irUser) {
        newClaim.file('claimInfo.pdf', await pdf(<AdminClaimInfoPdf irClaim={irClaim} />).toBlob());
        newClaim.file('contacts.pdf', await pdf(<ClaimContactsPdf irClaim={irClaim} />).toBlob());
        newClaim.file('technology.pdf', await pdf(<ClaimTechnologyPdf irClaim={irClaim} />).toBlob());
        newClaim.file('files.pdf', await pdf(<ClaimFilesPdf irClaim={irClaim} />).toBlob());
        newClaim.file('updates.pdf', await pdf(<ClaimUpdatesPdf irClaim={irClaim} />).toBlob());
        newClaim.file('private-message.pdf', await pdf(<ClaimPrivateMessagePdf irClaim={irClaim} />).toBlob());
      } else {
        newClaim.file('claimInfo.pdf', await pdf(<MemberUserClaimInfoPdf irClaim={irClaim} />).toBlob());
        newClaim.file('files.pdf', await pdf(<ClaimFilesPdf irClaim={irClaim} />).toBlob());
        newClaim.file('updates.pdf', await pdf(<ClaimUpdatesPdf irClaim={irClaim} />).toBlob());
      }
    }
    return zip.generateAsync({ type: 'blob' }).then((blob) => { saveAs(blob, `${irClaim.title}.zip`); });
  } catch (e) {
    if (!secondTry) {
      exportZipPdfs(irClaim, irUser, true);
    }
  }
};

export default exportZipPdfs;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faTrashAlt,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useHistory, useParams } from 'react-router-dom';
import Complete from '../../../../assets/icons/check-circle-fill.svg';
import InProgress from '../../../../assets/icons/InProgressCheck.svg';
import NotStarted from '../../../../assets/icons/notStartedCheck.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import ViewProfileModal from '../../questionnaire/modals/ViewProfileModal';
import { getOneChecklistAsync } from '../../../../store/checklistSlice';
import {
  ChecklistAssignment,
  ChecklistAssignmentUser,
  ChecklistResponse,
  deleteMultipleChecklistAssignmentsAsync,
  getChecklistAssignmentAsync,
  updateChecklistAssignmentAsync,
  // getAssignmentsForChecklistAsync,
} from '../../../../store/checklistAssignmentSlice';
import AddChecklistAssignments from './modals/AddChecklistAssignments';

interface Props { }

const ChecklistDetailAdmin: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentState = useAppSelector((state) => ({
    checklists: state.checklists,
    // checklistAssignments: state.checklistAssignments,
  }));
  const [sortStateMember, setSortStateMember] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateUser, setSortStateUser] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateViewed, setSortStateViewed] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCompleteDate, setSortCompleteDate] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [searchCriteria, setSearchCriteria] = useState('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [allAssignments, setAllAssignments] = useState(false);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  useEffect(() => {
    dispatch(getOneChecklistAsync(parseInt(id || '', 10)));
  }, []);

  if (!currentState.checklists.selectedChecklist) {
    return (<div />);
  }

  const {
    id: currentChecklistId,
    title,
    createdAt,
    author,
    assignmentDetails,
    assignments,
    publishedDate,
    dueDate,
  } = currentState.checklists.selectedChecklist;

  const viewChecklist = async (assignmentId: number) => {
    await dispatch(getChecklistAssignmentAsync(assignmentId));
    history.push(`/checklists/${assignmentId}`);
  };

  const onCheckboxChange = (checkboxId: string) => {
    if (selectedRows.includes(checkboxId)) {
      const newArray = selectedRows.filter((item) => item !== checkboxId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([checkboxId, ...selectedRows]);
    }
  };

  const deleteSelected = async () => {
    if (
      selectedRows.length > 0
      && assignments
      && assignments.length > 0
    ) {
      const repackageAssignment: {assignmentId: number, userIds: number[]}[] = [];
      selectedRows.map((selectedRow) => {
        const split = selectedRow.split(';');
        const assignmentId = parseInt(split[0], 10);
        const userId = parseInt(split[1], 10);
        const assignIndex = repackageAssignment.findIndex(
          (assignPack) => assignPack.assignmentId === assignmentId,
        );
        if (repackageAssignment[assignIndex]) {
          if (
            repackageAssignment[assignIndex].userIds
            && repackageAssignment[assignIndex].userIds.length > 0
          ) {
            repackageAssignment[assignIndex].userIds.push(userId);
          } else {
            repackageAssignment[assignIndex].userIds = [userId];
          }
        } else {
          repackageAssignment.push({
            assignmentId,
            userIds: [userId],
          });
        }
      });
      const fullAssignDeleteIds: number[] = [];
      await Promise.all(
        repackageAssignment.map(async (assignDetails) => {
          const foundAssignment = assignments.find((a) => a.id === assignDetails.assignmentId);
          if (foundAssignment) {
            const allUsers = foundAssignment.users.every(
              (user) => assignDetails.userIds.includes(user.id),
            );
            if (allUsers) {
              fullAssignDeleteIds.push(foundAssignment.id);
            } else {
              const newUserList = foundAssignment.users.filter(
                (user) => !assignDetails.userIds.includes(user.id),
              );
              await dispatch(updateChecklistAssignmentAsync({
                id: foundAssignment.id,
                updateBody: {
                  users: newUserList,
                },
              }));
            }
          }
        }),
      );
      if (fullAssignDeleteIds.length > 0) {
        await dispatch(deleteMultipleChecklistAssignmentsAsync(fullAssignDeleteIds));
      }
      dispatch(getOneChecklistAsync(currentChecklistId));
      setSelectedRows([]);
      setAllAssignments(false);
    }
  };

  const addAllAssignments = () => {
    if (!allAssignments) {
      setAllAssignments(true);
      const allIds: string[] = [];
      if (assignments && assignments.length > 0) {
        assignments.map((assignment) => {
          if (assignment.users && assignment.users.length > 0) {
            assignment.users.map((user) => {
              // allIds.push(user.id);
              allIds.push(`${assignment.id};${user.id}`);
            });
          }
        });
      }
      setSelectedRows(allIds);
    } else {
      setAllAssignments(false);
      setSelectedRows([]);
    }
  };

  const returnAssignmentArray = () => {
    if (assignmentDetails && assignmentDetails.length > 3) {
      const detailArray: string[] = JSON.parse(assignmentDetails);
      if (detailArray && detailArray.length > 0) {
        return detailArray;
      }
    }
    return ['N/A'];
  };

  const destructureChecklistAssignmentsUsers = (
    rawQuestionnaireAssignments: ChecklistAssignment[],
  ) => {
    const destructuredAssignments: ChecklistAssignmentUser[] = [];

    rawQuestionnaireAssignments.map((checklistAssignment) => {
      checklistAssignment.users.map((user) => {
        destructuredAssignments.push({
          id: checklistAssignment.id,
          checklist: checklistAssignment.checklist,
          member: checklistAssignment.member,
          user,
          completed: checklistAssignment.completed,
          completedDate: checklistAssignment.completedDate || null,
          responses: checklistAssignment.responses || [],
        });
      });
    });

    return destructuredAssignments;
  };

  const returnLevelOfComplete = (responses: ChecklistResponse[]) => {
    let started = false;
    if (responses && responses.length > 0) {
      responses.map((response) => {
        if (response.itemCompleted) {
          started = true;
        }
      });
    }
    if (started) {
      return (
        <>
          <img className="mr-2" src={InProgress} alt="Complete Icon" />
          <p className="m-0">In Progress</p>
        </>
      );
    }
    return (
      <>
        <img className="mr-2" src={NotStarted} alt="Complete Icon" />
        <p className="m-0">Not Started</p>
      </>
    );
  };

  return (
    <div className="post-article-admin-container">
      <div className="d-flex justify-content-between">
        <div>
          <h3>{title}</h3>
          <p>
            <span>Created by: </span>
            <span>
              {
                author
                && author.firstName
                && author.lastName
                && (
                  <span className="mr-2">
                    {`${author.firstName} ${author.lastName}`}
                  </span>
                )
              }
              <span className="numeric-font">
                {dateAndTime(createdAt)}
              </span>
            </span>
            <span className="ml-4">Activated: </span>
            <span className="numeric-font">{publishedDate ? dateAndTime(publishedDate) : 'N/A'}</span>
          </p>
        </div>
        <div>
          <p>
            <span>Due: </span>
            <span className="numeric-font">{dueDate ? dateAndTime(dueDate) : 'N/A'}</span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-content-center mb-2 border-row px-2 py-3">
        <div className="d-flex flex-inline">
          <p className="m-0 d-flex align-items-center">
            <span>Distributed to: </span>
            <span className="d-flex ml-2">
              {
                returnAssignmentArray().map((assignDetail) => (
                  <p className="publish-tags ml-2">{assignDetail}</p>
                ))
              }
            </span>
          </p>
        </div>
        <div className="searchbar">
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
      <div className="mt-3 mb-2 d-flex justify-content-between align-content-center">
        <div>
          {
            selectedRows.length > 0
            && (
              <button onClick={deleteSelected} type="button" className="ml-2 button-link">
                <FontAwesomeIcon size="lg" icon={faTrashAlt} />
                <span className="ml-2">Delete Selected</span>
              </button>
            )
          }
        </div>
        <div>
          <AddChecklistAssignments currentId={currentChecklistId}>
            Add recipients
          </AddChecklistAssignments>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th className="check">
              <input checked={allAssignments} onClick={addAllAssignments} type="checkbox" />
            </th>
            <th>
              <span>Member </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateMember) {
                    case 'noSort':
                      setSortStateMember('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateMember('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateMember('noSort');
                      return;
                    default:
                      setSortStateMember('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateMember)} />
              </Button>
            </th>
            <th>
              <span>User </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateUser) {
                    case 'noSort':
                      setSortStateUser('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateUser('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateUser('noSort');
                      return;
                    default:
                      setSortStateUser('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateUser)} />
              </Button>
            </th>
            <th>
              <span>Status </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateViewed) {
                    case 'noSort':
                      setSortStateViewed('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateViewed('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateViewed('noSort');
                      return;
                    default:
                      setSortStateViewed('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateViewed)} />
              </Button>
            </th>
            <th>
              <span>Date </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortCompleteDate) {
                    case 'noSort':
                      setSortCompleteDate('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortCompleteDate('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortCompleteDate('noSort');
                      return;
                    default:
                      setSortCompleteDate('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortCompleteDate)} />
              </Button>
            </th>
            <th className="text-center align-content-center">
              <span>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            assignments
            && assignments.length > 0
            && destructureChecklistAssignmentsUsers(assignments)
              .slice()
              .filter((assignment) => assignment.user)
              .filter((assignment) => {
                if (assignment.user && assignment.user.firstName) {
                  if (
                    // eslint-disable-next-line max-len
                    assignment.user.firstName.toLowerCase().includes(searchCriteria.toLowerCase())
                  ) {
                    return assignment;
                  }
                }
                if (assignment.user && assignment.user.lastName) {
                  // eslint-disable-next-line max-len
                  if (assignment.user.lastName.toLowerCase().includes(searchCriteria.toLowerCase())) {
                    return assignment;
                  }
                }
                if (assignment.user && assignment.user.members) {
                  let memberMatch = false;
                  assignment.user.members.forEach((member: any) => {
                    if (member.memberName.toLowerCase().includes(searchCriteria.toLowerCase())) {
                      memberMatch = true;
                    }
                  });
                  if (memberMatch) {
                    return assignment;
                  }
                }
              })
              .sort((a, b) => {
                let aVar = '';
                let bVar = '';
                if (a.user.members.length > 1) {
                  aVar = 'various';
                } else if (a.user.members[0] && a.user.members[0].memberName) {
                  aVar = a.user.members[0].memberName.toLowerCase();
                }
                if (b.user.members.length > 1) {
                  bVar = 'various';
                } else if (b.user.members[0] && b.user.members[0].memberName) {
                  bVar = b.user.members[0].memberName.toLowerCase();
                }
                switch (sortStateMember) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.user.lastName ? a.user.lastName.toLowerCase() : 'a';
                const bVar = b.user.lastName ? b.user.lastName.toLowerCase() : 'a';
                switch (sortStateUser) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.completedDate || 0;
                const bVar = b.completedDate || 0;
                switch (sortCompleteDate) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((assignment) => (
                <tr>
                  <td className="check-box">
                    <input
                      type="checkbox"
                      data-userid={assignment.user.id}
                      checked={selectedRows.includes(`${assignment.id};${assignment.user.id}`)}
                      onChange={() => onCheckboxChange(`${assignment.id};${assignment.user.id}`)}
                    />
                  </td>
                  <td>
                    {
                      assignment.user.members.length > 1
                      && ('Various')
                    }
                    {
                      assignment.user.members.length === 1
                      && (
                        <Link to={`/members/profile/${assignment.user.members[0].id}/info`}>
                          {assignment.user.members[0].memberName}
                        </Link>
                      )
                    }
                    {
                      !assignment.user.members.length
                      && ('No member defined')
                    }
                  </td>
                  <td>
                    <ViewProfileModal profileId={assignment.user.id}>
                      {assignment.user.lastName}
                      <span>, </span>
                      {assignment.user.firstName}
                    </ViewProfileModal>
                  </td>
                  <td>
                    <div className="d-flex">
                      {
                        assignment.completed
                        && (
                          <>
                            <img className="mr-2" src={Complete} alt="Complete Icon" />
                            <p className="m-0">Complete</p>
                          </>
                        )
                      }
                      {
                        !assignment.completed
                        && returnLevelOfComplete(assignment.responses)
                      }
                    </div>
                  </td>
                  <td>
                    {assignment.completedDate ? dateOnly(assignment.completedDate) : ''}
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="no-underline" onClick={() => viewChecklist(assignment.id)}>
                          View Checklist
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </div>
  );
};

export default ChecklistDetailAdmin;

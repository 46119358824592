import { AxiosPromise } from 'axios';
import api from './api';

export function createDocument(documentDTO: any) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/documents', documentDTO)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getAllDocumentsList() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/documents')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneDocument(docId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/documents/${docId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteDocument(docId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete(`/documents/${docId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

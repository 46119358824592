import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch } from '../../../../store/hooks';
import Unlocked from '../../../../assets/icons/LockGray.svg';
import Locked from '../../../../assets/icons/LockBlue.svg';
import ChecklistInUseModal from './ChecklistInUseModal';
import {
  ChecklistAssignment,
  ChecklistResponse,
  createChecklistMultiMemberSessionAsync,
  getChecklistMultiMemberSessionAsync,
} from '../../../../store/checklistAssignmentSlice';
import { getChecklistSessionUpdatedDate } from '../../../../service/timeAndDate';
import { Checklist, getOneChecklistAsync } from '../../../../store/checklistSlice';

const getStatusText = (assignment: ChecklistAssignment) => {
  if (
    assignment.responses
    && !assignment.responses.find((response) => response.itemCompleted)
  ) {
    return 'Not Started';
  }
  // all answers are complete
  if (assignment.responses
    .filter((answer) => answer.itemCompleted).length
    === assignment.responses.length) {
    return 'Complete';
  }
  return 'In Progress';
};

const getCompletChecklistAssignments = (assignments: ChecklistAssignment[]) => {
  const count = assignments.filter((assignment) => assignment.responses
    && (assignment.responses || []).length
    === assignment.responses
      .filter((response: ChecklistResponse) => response.itemCompleted).length)
    .length;
  return count;
};

interface Props {
  dashboard?: boolean;
  checklistAssignments: ChecklistAssignment[];
  checklist: Checklist;
  children: string;
}

const MultiMemberChecklistModal: React.FC<Props> = (props: Props) => {
  const {
    checklistAssignments,
    children,
    checklist,
    dashboard,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState<boolean>(false);
  const [showMultipleMembers, setShowMultipleMembers] = useState<boolean>(false);
  const [showInUse, setShowInUse] = useState<boolean>(false);
  const [showInaccessibleMember, setShowInaccessibleMember] = useState<boolean>(false);
  const [
    checklistAssignmentGroups,
    setChecklistAssignmentGroups,
  ] = useState<ChecklistAssignment[][]>([]);
  const [checked, setChecked] = useState<ChecklistAssignment[]>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleShow = () => setShow(true);
  const handleShowMultipleMembers = () => setShowMultipleMembers(true);
  const handleMultipleMembersClose = () => setShowMultipleMembers(false);
  const navigateToChecklist = async (multiSessionId: number) => {
    await dispatch(getOneChecklistAsync(checklist.id));
    history.push(`/checklists/multi/${multiSessionId}`);
  };
  const continueToChecklist = async (
    startChecklistAssignments: ChecklistAssignment[],
  ) => {
    // If single checklist w/ no multi session, redirect.
    if (
      startChecklistAssignments.length === 1
      && !startChecklistAssignments[0].multiSession
    ) {
      history.push(`checklists/${startChecklistAssignments[0].id}`);
      return;
    }

    if (startChecklistAssignments.length > 1) {
      // If any have single session throw inaccessible error.
      if (startChecklistAssignments.find((assignment) => assignment.singleSessionStarted)) {
        setShowInaccessibleMember(true);
        return;
      }

      // Check if any assignments have single session, or associated multi Session
      if (!startChecklistAssignments.find((assignment) => assignment.singleSessionStarted)
        && !startChecklistAssignments.find((assignment) => assignment.multiSession)) {
        // If no single and no multi for any, then start multi session.
        const newSession = await dispatch(createChecklistMultiMemberSessionAsync(
          startChecklistAssignments.map((assign) => assign.id),
        ));
        navigateToChecklist(newSession.payload.id);
        return;
      }

      // Returns unique values for multi session ID
      const checklistMultiSessionIds = new Set(
        startChecklistAssignments.map(
          (assignment) => (assignment.multiSession ? assignment.multiSession.id : null),
        ),
      );

      // If more than 1 multisession ID exists, exit
      if (checklistMultiSessionIds.size > 1) {
        setShowInaccessibleMember(true);
        return;
      }
      const { multiSession } = startChecklistAssignments[0];
      // If multisession contains more assignments than available
      if (
        multiSession
        && !multiSession.assignments.every((sessionAssignment) => startChecklistAssignments.find(
          (startAssignment) => startAssignment.id === sessionAssignment.id,
        ))) {
        setShowInaccessibleMember(true);
        return;
      }
      const startQuestionAssignmentIds = startChecklistAssignments[0]
        .multiSession.assignments
        .map((assignmentQA: any) => assignmentQA.id);
      const currentQuestionnaireAssignmentIds = checklistAssignments
        .map((userQa) => userQa.id);
      if (
        !startQuestionAssignmentIds
          .every(
            (questionnaireSessionQa: any) => currentQuestionnaireAssignmentIds
              .indexOf(questionnaireSessionQa) !== -1,
          )
      ) {
        setShowInaccessibleMember(true);
      }
      if (
        multiSession
        && multiSession.assignments.every((sessionAssignment) => startChecklistAssignments.find(
          (startAssignment) => startAssignment.id === sessionAssignment.id,
        ))
      ) {
        const restartSession = await dispatch(getChecklistMultiMemberSessionAsync(multiSession.id));
        navigateToChecklist(restartSession.payload.id);
      }
    }
  };

  useEffect(() => {
    const groups = checklistAssignments
      .reduce((prev: any, curr: any) => {
        const checklistAssignmentId: string = (curr.id || 0).toString(10);
        (prev[checklistAssignmentId] = prev[checklistAssignmentId] || []).push(curr);
        return prev;
      }, {});
    const groupArrays = Object.keys(groups).map((key: string) => groups[key]);
    setChecklistAssignmentGroups(groupArrays);
  }, []);

  return (
    <>
      {
        dashboard
          ? (
            <Button
              variant="primary"
              onClick={checklistAssignments.length > 1 ? handleShowMultipleMembers : handleShow}
            >
              {children}
            </Button>
          ) : (
            <Button
              className="no-button button-link text-left p-0"
              onClick={checklistAssignments.length > 1 ? handleShowMultipleMembers : handleShow}
            >
              {children}
            </Button>
          )
      }

      <Modal show={showMultipleMembers} onHide={handleMultipleMembersClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Select Members</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`This checklist applies to ${checklistAssignments.length
            } of the members you are associated with.`}
          </p>
          <p>
            {`You have completed ${getCompletChecklistAssignments(checklistAssignments)
            } of the ${checklistAssignments.length
            } member checklist.`}
          </p>
          <p>
            Members can be grouped to fill out a single checklist for all
            selected members. Grouping will apply to this checklist only
            and cannot be changed once the checklist has been started
          </p>
          <h4>Which Member(s) do you want to enter answers for?</h4>
          <Table bordered className="member-select-table">
            <thead>
              <tr>
                <th> </th>
                <th>Member</th>
                <th>Status</th>
                <th>Last Update</th>
                <th>In Use</th>
              </tr>
            </thead>
            <tbody>
              {checklistAssignmentGroups.map((assignmentGroup) => (
                <>
                  {
                    assignmentGroup.map((assignment) => {
                      let rowClass = '';
                      if (assignment.multiSession) {
                        // somewhere in a group
                        rowClass = 'group-row';
                        // start of group
                        if (assignmentGroup.indexOf(assignment) === 0) {
                          rowClass += ' group-top';
                        }
                        // end of group
                        if (assignmentGroup.indexOf(assignment) === assignmentGroup.length - 1) {
                          rowClass += ' group-bottom';
                        }
                      }

                      return (
                        <tr
                          key={assignment.id}
                          className={rowClass}
                        >
                          <td>
                            <Form.Check
                              checked={
                                checked.map(
                                  (checkedAssignment) => checkedAssignment.id,
                                ).indexOf(assignment.id) !== -1
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setChecked([...checked, assignment]);
                                } else {
                                  const existingCheckedIndex = checked
                                    .map((checkedAssigment) => checkedAssigment.id)
                                    .indexOf(assignment.id);
                                  const newChecked = checked.slice();
                                  newChecked.splice(existingCheckedIndex, 1);
                                  setChecked(newChecked);
                                }
                              }}
                            />
                          </td>
                          <td>{assignment.member.memberName}</td>
                          <td>{getStatusText(assignment)}</td>
                          <td>
                            {`${assignment.lockedByUser?.firstName || ''
                            } ${assignment.lockedByUser?.lastName || ''
                            } ${getChecklistSessionUpdatedDate(
                              assignment.responses || [],
                            )
                            }`}
                          </td>
                          <td>
                            {assignment.lockedByUser
                              ? <img src={Locked} alt="Locked" />
                              : <img src={Unlocked} alt="Unlocked" />}
                          </td>
                        </tr>
                      );
                    })
                  }
                </>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => continueToChecklist(checked)}>
            Continue to Checklist
          </Button>
        </Modal.Footer>
      </Modal>
      <ChecklistInUseModal
        show={showInUse}
        id={checklistAssignments[0].multiSession && checklistAssignments[0].multiSession.id}
        name={`${
          checklistAssignments[0].lockedByUser
          && checklistAssignments[0].lockedByUser.firstName
        } ${
          checklistAssignments[0].lockedByUser
          && checklistAssignments[0].lockedByUser.lastName
        }`}
        handleClose={() => setShowInUse(false)}
        handleAccept={navigateToChecklist}
      />
      <Modal show={showInaccessibleMember} onHide={() => setShowInaccessibleMember(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Introduction</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{'This checklist assignment contains an assignment that you don\'t have access to.'}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MultiMemberChecklistModal;

MultiMemberChecklistModal.defaultProps = {
  dashboard: false,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import
{
  getMemberAutosuggest,
  getMemberFilters,
  getMembers,
  getOneMember,
  updateOneMember,
} from '../api/memberAPI';
import { User } from './userSlice';

export interface Member {
  entityType: string;
  id: number;
  cyberMaturityRating: string | null;
  memberName: string;
  memberNumber: number;
  sauNumber: number;
  memberType: string;
  subType: string;
  parentMember?: Member;
  parentMemberId?: Number;
  childMembers?: Member[];
  users?: User[];
  propertyExposureClass: string;
  payrollExposureClass: string;
  memberServicesConsultant: User;
  riskManagementConsultant: User;
  plClaimsConsultant: User;
  plClaimsRepresentative: User;
  primaryContacts?: User[];
  primaryIT?: User[];
  claims: any[];
  emailDeactivated: boolean;
}

export interface MemberState {
  selectedMember: Member | null,
  members: Member[];
  membersPaged: Member[];
  status: 'idle' | 'loading' | 'failed';
  error: any;
  count: number;
  filters: MemberFilters,
  memberAutosuggest: MemberFilterOption[];
}

interface MemberFilterOption {
  value: string | null;
  label: string | null;
}

interface MemberFilters {
  cyberMaturityRatings: MemberFilterOption[];
  memberTypes: MemberFilterOption[];
  subTypes: MemberFilterOption[];
  parentMembers: MemberFilterOption[];
}

const initialState: MemberState = {
  selectedMember: null,
  members: [],
  membersPaged: [],
  status: 'idle',
  error: null,
  count: 0,
  filters:
  {
    cyberMaturityRatings: [],
    memberTypes: [],
    parentMembers: [],
    subTypes: [],
  },
  memberAutosuggest: [],
};

const getMembersAsync = createAsyncThunk(
  'member/getMembers',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getMembers();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getMembersAutosuggestAsync = createAsyncThunk(
  'member/autosuggest',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getMemberAutosuggest();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getMembersWithPaginationAsync = createAsyncThunk(
  'member/getMembersPaginated',
  async (arg: any, { rejectWithValue }) => {
    try {
      return await getMembers(arg);
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneMemberAsync = createAsyncThunk(
  'member/getOneMember',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneMember(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateOneMemberAsync = createAsyncThunk(
  'member/updateOneMember',
  async (arg: { id: number, memberData: Partial<Member>}, { rejectWithValue }) => {
    try {
      const response: any = await updateOneMember(arg.id, arg.memberData);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getFiltersAsync = createAsyncThunk(
  'member/filters',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getMemberFilters();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get current user
      .addCase(getMembersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMembersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.members = action.payload;
        state.error = null;
      })
      .addCase(getMembersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.members = [];
        state.error = action.payload;
      })
      .addCase(getMembersAutosuggestAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMembersAutosuggestAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.memberAutosuggest = action.payload;
        state.error = null;
      })
      .addCase(getMembersAutosuggestAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.memberAutosuggest = [];
        state.error = action.payload;
      })
      .addCase(getFiltersAsync.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(getMembersWithPaginationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMembersWithPaginationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const { data, count } = action.payload as any;
        state.membersPaged = data;
        state.count = count;
        state.error = null;
      })
      .addCase(getMembersWithPaginationAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.membersPaged = [];
        state.error = action.payload;
      })
      .addCase(getOneMemberAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneMemberAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedMember = action.payload;
        state.error = null;
      })
      .addCase(getOneMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedMember = null;
        state.error = action.payload;
      })
      .addCase(updateOneMemberAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOneMemberAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedMember = action.payload;
        state.error = null;
      })
      .addCase(updateOneMemberAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedMember = null;
        state.error = action.payload;
      });
  },
});

export default memberSlice.reducer;

export {
  getMembersAsync,
  getOneMemberAsync,
  updateOneMemberAsync,
  getMembersWithPaginationAsync,
  getFiltersAsync,
  getMembersAutosuggestAsync,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createSystemAlert,
  deleteMultipleSystemAlerts,
  getOneSystemAlert,
  getSystemAlerts,
  updateSystemAlert,
} from '../api/systemAlertAPI';
import { User } from './userSlice';

export interface SystemAlert {
  id: number;
  title: string;
  message: string;
  startDate: Date;
  expirationDate: Date;
  draft: boolean;
  publishedDate?: Date;
  author: User;
  dismissible: boolean;
  archived?: boolean;
  createdAt: Date;
}

export interface SystemAlertState {
  systemAlerts: SystemAlert[] | null;
  selectedSystemAlert: SystemAlert | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: SystemAlertState = {
  systemAlerts: [],
  selectedSystemAlert: null,
  status: 'idle',
  error: null,
};

const getSystemAlertsAsync = createAsyncThunk(
  'systemAlerts/getGlobalSystemAlerts',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getSystemAlerts();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createSystemAlertAsync = createAsyncThunk(
  'systemAlerts/createSystemAlert',
  async (body: Partial<SystemAlert>, { rejectWithValue }) => {
    try {
      const response: any = await createSystemAlert(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneSystemAlertAsync = createAsyncThunk(
  'systemAlerts/getOneSystemAlert',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneSystemAlert(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const nullSelectedSystemAlert = createAsyncThunk(
  'systemAlerts/nullSelectedSystemAlert',
  () => null,
);

const updateSystemAlertAsync = createAsyncThunk(
  'systemAlerts/update',
  async (body: {id: number, updateBody: Partial<SystemAlert>}, { rejectWithValue }) => {
    try {
      const response: any = await updateSystemAlert(body.id, body.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleSystemAlertsAsync = createAsyncThunk(
  'systemAlerts/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleSystemAlerts(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const systemAlertSlice = createSlice({
  name: 'systemAlert',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get current user
      .addCase(getSystemAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSystemAlertsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.systemAlerts = action.payload;
        state.error = null;
      })
      .addCase(getSystemAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.systemAlerts = null;
        state.error = action.payload;
      })
      .addCase(createSystemAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSystemAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSystemAlert = action.payload;
        state.error = null;
      })
      .addCase(createSystemAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedSystemAlert = null;
        state.error = action.payload;
      })
      .addCase(nullSelectedSystemAlert.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(nullSelectedSystemAlert.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSystemAlert = action.payload;
        state.error = null;
      })
      .addCase(nullSelectedSystemAlert.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedSystemAlert = null;
        state.error = action.payload;
      })
      .addCase(getOneSystemAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneSystemAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSystemAlert = action.payload;
        state.error = null;
      })
      .addCase(getOneSystemAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedSystemAlert = null;
        state.error = action.payload;
      })
      .addCase(updateSystemAlertAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSystemAlertAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedSystemAlert = action.payload;
        state.error = null;
      })
      .addCase(updateSystemAlertAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedSystemAlert = null;
        state.error = action.payload;
      })
      .addCase(deleteMultipleSystemAlertsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleSystemAlertsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedSystemAlert = null;
        state.error = null;
      })
      .addCase(deleteMultipleSystemAlertsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default systemAlertSlice.reducer;

export {
  getSystemAlertsAsync,
  createSystemAlertAsync,
  nullSelectedSystemAlert,
  getOneSystemAlertAsync,
  updateSystemAlertAsync,
  deleteMultipleSystemAlertsAsync,
};

import React from 'react';
import DOMPurify from 'dompurify';
import Card from 'react-bootstrap/Card';
import { Training } from '../../../store/trainingSlice';
import MicroTrainingIcon from '../../../assets/icons/microtraining-icon.svg';
import OtherTrainingIcon from '../../../assets/icons/webinars-icon.svg';

interface Props {
  data: Training,
}

const TrainingCard: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;
  const purifiedContent = DOMPurify.sanitize(data.trainingDescription);
  const strippedHtml = purifiedContent.replace(/<[^>]+>/g, '');
  const briefDescription = strippedHtml.length > 100
    ? strippedHtml.substring(0, 100)
    : strippedHtml;

  const dateConversion = (date: Date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };
  return (
    <Card className="p-3 m-3">
      <div>
        <a target="_blank" rel="noreferrer" href={data.trainingLink}>
          <h3>
            {data.name}
          </h3>
        </a>
        {
          data.trainingDescription
          && briefDescription
          && (
          <p className="mb-0">
            {briefDescription}
            {
              strippedHtml.length > 100
              && (
                <span>...</span>
              )
            }
          </p>
          )
        }
      </div>
      <div className="d-flex flex-row mt-3 justify-content-between">
        <div>
          <p className="mb-0">
            <span>Due: </span>
            <span className="numeric-font">{dateConversion(data.dueDate)}</span>
          </p>
          <p className="mb-0">{data.status}</p>
        </div>
        <div>
          {
            data.courseType
            && data.courseType === 'micro-trainings'
              ? (
                <img src={MicroTrainingIcon} alt="training" />
              ) : (
                <img src={OtherTrainingIcon} alt="training" />
              )
          }
        </div>
      </div>
    </Card>
  );
};

export default TrainingCard;

/* eslint-disable array-callback-return */
import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { PostArticle } from '../../../../store/postArticleSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getCurrentPostArticleAssignmentsAsync, PostArticleAssignment, updatePostArticleAssignmentAsync } from '../../../../store/postArticleAssignmentSlice';
import { getCurrentNotificationsAsync, updateNotificationAsync } from '../../../../store/notificationSlice';

interface Props {
  postArticle: PostArticle;
  modalView?: boolean;
}

const PostAndArticleBody: React.FC<Props> = ({ postArticle, modalView }) => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
    postArticleAssignments: state.postArticleAssignments,
    notification: state.notification,
  }));
  const dispatch = useAppDispatch();

  const {
    user:
    {
      currentUser,
    },
    postArticleAssignments:
    {
      currentPostArticleAssignments,
    },
    notification: {
      notifications,
    },
  } = currentState;

  const {
    id: postArticleId,
    content,
    title,
    pdfUrl,
    pdfFilename,
  } = postArticle;

  const updateAssignment = async (id: number, assignmentViewed: boolean) => {
    // Update assignment
    if (!assignmentViewed) {
      const newAssignment: Partial<PostArticleAssignment> = {
        viewed: true,
        viewedDate: new Date(),
      };
      await dispatch(updatePostArticleAssignmentAsync({ id, assignment: newAssignment }));
      dispatch(getCurrentPostArticleAssignmentsAsync());
    }

    // Update notification
    if (notifications && notifications.length > 0) {
      let updateNotification = false;
      await Promise.all(
        notifications.map(async (notification) => {
          if (
            notification.postArticleAssignment
            && notification.postArticleAssignment.id === id
            && !notification.viewed
          ) {
            await dispatch(updateNotificationAsync({
              id: notification.id,
              body: {
                viewed: true,
              },
            }));
            updateNotification = true;
          }
        }),
      );
      if (updateNotification) {
        dispatch(getCurrentNotificationsAsync());
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (currentPostArticleAssignments && currentPostArticleAssignments.length > 0) {
        currentPostArticleAssignments.map((assignment) => {
          if (
            assignment.postArticle
            && assignment.postArticle.id === postArticle.id
          ) {
            updateAssignment(assignment.id, assignment.viewed);
          }
        });
      }
    }
  }, []);

  const history = useHistory();

  const clickPrint = () => {
    if (!modalView) {
      window.print();
    } else {
      history.push(`/posts-articles/${postArticleId}`);
      window.print();
    }
  };

  const cleanContent = DOMPurify.sanitize(content || '', { ADD_TAGS: ['iframe'] });

  return (
    <div className="post-article-body">
      <div className="d-flex justify-content-between">
        <div>
          <h2>{title}</h2>
        </div>
        <div>
          <Button variant="primary mr-4" onClick={clickPrint}>
            Print
          </Button>
        </div>
      </div>
      <div className="quill-display">
        <ReactQuill
          value={cleanContent}
          readOnly
          theme="bubble"
        />
      </div>
      {
        pdfFilename
        && pdfUrl
        && (
          <div>
            <a href={pdfUrl} target="_blank" rel="noreferrer" download>{pdfFilename}</a>
          </div>
        )
      }
    </div>
  );
};

PostAndArticleBody.defaultProps = {
  modalView: false,
};

export default PostAndArticleBody;

/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Toast,
} from 'react-bootstrap';
import Select from 'react-select';
import ClaimAccordion from './claimAccordion';
import {
  getOneClaimAsync,
  IrClaim,
  notifyAtomAsync,
  updateOneClaimAsync,
} from '../../../../store/irClaimSlice';
import { dateYearMonthDay } from '../../../../service/timeAndDate';
import { User } from '../../../../store/userSlice';
import { useAppDispatch } from '../../../../store/hooks';
import ManageMemberAccess from '../modals/ManageMemberAccess';
import { checkAtomIrPermission } from '../../../../service/checkIrPermission';
import ToggleEmail from '../modals/EmailToggle';

interface Props {
  claim: IrClaim | null;
  atomLead: User[];
  currentUser: User;
}
interface SelectObject {
  label: string;
  value: number;
  email?: string;
  phone?: string;
}

const ClaimInfo: React.FC<Props> = ({
  claim,
  atomLead,
  currentUser,
}) => {
  const dispatch = useAppDispatch();
  const [showToast, setShowToast] = useState(false);
  const [debounceHandle, setDebounceHandle] = useState<any>();
  const [descriptionHeight, setDescriptionHeight] = useState<number | null>(null);
  const [resolutionHeight, setResolutionHeight] = useState<number | null>(null);
  const descriptionRef = useRef<any>(null);
  const resolutionRef = useRef<any>(null);

  const updateAtomLead = (lead: SelectObject | null) => {
    if (claim && lead) {
      (dispatch(updateOneClaimAsync({
        claimId: claim.id,
        updateBody: {
          atomLeadId: lead.value,
        },
      }))).then(() => dispatch(getOneClaimAsync(claim.id)));
    }
  };
  const updateResolutionDetails = (resolution: string) => {
    if (claim && resolution) {
      (dispatch(updateOneClaimAsync({
        claimId: claim.id,
        updateBody: {
          resolutionDetails: resolution,
        },
      }))).then(() => dispatch(getOneClaimAsync(claim.id)));
    }
  };
  const updateInvolvesPII = async (check: boolean) => {
    if (claim) {
      await (dispatch(updateOneClaimAsync({
        claimId: claim.id,
        updateBody: {
          involvesPII: check,
        },
      })));
    }
  };
  const returnDate = (newDate: Date) => {
    const dateString = newDate.toString().replace(/-/g, '/').replace(/T.+/, '');
    const date = new Date(dateString);
    return dateYearMonthDay(date);
  };
  const notifyAtom = () => {
    if (claim) {
      dispatch(notifyAtomAsync(claim.id))
        .then(() => {
          dispatch(getOneClaimAsync(claim.id));
          setShowToast(true);
        });
    }
  };

  const closeClaim = (isClaim: boolean) => {
    const date = new Date();
    if (claim) {
      if (isClaim) {
        dispatch(updateOneClaimAsync({
          claimId: claim.id,
          updateBody: {
            closedDate: date,
          },
        })).then(() => dispatch(getOneClaimAsync(claim.id)));
      } else {
        dispatch(updateOneClaimAsync({
          claimId: claim.id,
          updateBody: {
            closedDate: date,
            notAClaim: true,
          },
        })).then(() => dispatch(getOneClaimAsync(claim.id)));
      }
    }
  };
  const updateClosedDate = (date: Date) => {
    if (date && claim) {
      dispatch(updateOneClaimAsync({
        claimId: claim.id,
        updateBody: {
          closedDate: date,
        },
      }))
        .then(() => dispatch(getOneClaimAsync(claim.id)));
    }
  };

  const reOpenClaim = () => {
    if (claim) {
      dispatch(updateOneClaimAsync({
        claimId: claim.id,
        updateBody: {
          closedDate: null,
        },
      })).then(() => dispatch(getOneClaimAsync(claim.id)));
    }
  };
  useEffect(() => {
    if (!descriptionHeight) {
      setDescriptionHeight(descriptionRef?.current?.scrollHeight || null);
      setResolutionHeight(resolutionRef?.current?.scrollHeight || null);
    }
  });
  return (
    <>
      <Toast className="cyber-info-toast" show={showToast} onClose={() => setShowToast(false)}>
        <Toast.Header className="justify-content-between">
          <strong className="bold">Primex: Notify</strong>
        </Toast.Header>
        <Toast.Body>
          A notification has been sent to ATOM about this claim.
        </Toast.Body>
      </Toast>
      {claim
        && (
          <div className="claim-info-container">
            <div className="d-flex justify-content-between">
              <div className="col-7">
                <div className="d-flex align-items-center header mb-1">
                  <h6 className="m-0 mr-2 bold"> Claim Title:</h6>
                  <p className="m-0">{claim?.title}</p>
                </div>
                <div className="d-flex ">
                  <div className="d-flex align-items-center mr-2">
                    <h6 className="m-0 bold mr-2">Member: </h6>
                    <p className="m-0">
                      {claim?.member?.memberName}
                    </p>
                  </div>
                  <div className="d-flex align-items-center mr-2">
                    <h6 className="m-0 bold mr-2">Claim#:</h6>
                    <a target="_blank" rel="noopener noreferrer" href={`https://live.origamirisk.com/Origami/Claims/View/${claim.incomingClaimId}`}>{claim.claimNumber}</a>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="m-0 bold mr-2">Member Type:</h6>
                    <p className="m-0">{claim.member?.entityType || 'N/A'}</p>
                  </div>
                </div>
              </div>
              <div className="col-5 d-flex align-items-end justify-content-between">
                <Button
                  className="claims-outline-button m-0"
                  variant="clear"
                  onClick={() => notifyAtom()}
                  disabled={claim.atomAlerted}
                >
                  Notify ATOM
                </Button>
                {(claim?.closedDate === null)
                  ? (
                    <DropdownButton
                      id="claims-info-button"
                      title="Close Claim"
                      variant="secondary"
                      className="m-0"
                    >
                      <Dropdown.Item onClick={() => closeClaim(true)}>
                        Cyber claim completed
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => closeClaim(false)}>
                        Not a cyber claim
                      </Dropdown.Item>
                    </DropdownButton>
                  )
                  : (
                    <Button className="button-link" onClick={() => reOpenClaim()}>
                      Reopen Claim
                    </Button>
                  )}
                <Form.Group className="m-0" controlId="startDate">
                  <Form.Label>Date Closed</Form.Label>
                  <Form.Control
                    type="date"
                    className="claim-date"
                    disabled={!claim.closedDate}
                    value={claim.closedDate ? returnDate(claim.closedDate)
                      : Date()}
                    onChange={(e) => updateClosedDate(new Date(e.currentTarget.value.replace(/-/g, '/')))}
                  />
                </Form.Group>
              </div>
            </div>
            {
              checkAtomIrPermission(currentUser)
              && <ToggleEmail claim={claim} singleClaim />
            }
            <ClaimAccordion claimId={claim.id} triageInfo={claim.triageInfo} />
            <div className="d-flex justify-content-between col-12 p-0 align-items-center mt-4">
              <div className="d-flex p-0 col-9">
                <Form.Group className="col-3 pl-0 pr-2">
                  <Form.Label className="mb-0">Loss Date</Form.Label>
                  <Form.Control
                    type="date"
                    className="claim-date"
                    disabled
                    value={claim.lossDate ? returnDate(claim.lossDate) : 'MM/DD/YYYY'}
                  />
                </Form.Group>
                <Form.Group className="col-3 px-2">
                  <Form.Label className="mb-0">Date Reported</Form.Label>
                  <Form.Control
                    type="date"
                    className="claim-date"
                    disabled
                    value={claim.employerReportDate ? returnDate(claim.employerReportDate) : 'MM/DD/YYYY'}
                  />
                </Form.Group>
                <Form.Group className="col-3 px-2">
                  <Form.Label className="mb-0">Primex Adjuster</Form.Label>
                  <Form.Control
                    type="input"
                    readOnly
                    value={claim.primexAdjuster ? `${claim.primexAdjuster.firstName} ${claim.primexAdjuster.lastName}` : undefined}
                  />
                </Form.Group>
                {claim.primexAdjuster
                  && (
                    <div className="my-auto">
                      <p className="m-0">{claim.primexAdjuster?.email ? claim.primexAdjuster.email : 'N/A'}</p>
                      <p className="m-0">{claim.primexAdjuster?.phone ? claim.primexAdjuster.phone : 'N/A'}</p>
                    </div>
                  )}

              </div>
              <div className="mb-auto">
                <p className="mb-0">
                  Member users with access:
                  <span className="numeric-font">
                    {` ${(claim.userAccess || []).length}`}
                  </span>
                </p>
                <ManageMemberAccess
                  claim={claim}
                />
              </div>
            </div>
            <Form.Group className="mt-2">
              <Form.Label className="mb-0">Incident Description</Form.Label>
              <Form.Control
                ref={descriptionRef}
                disabled
                placeholder="Enter Description of incident"
                defaultValue={claim.descriptionOfIncident || ''}
                className="incident-description"
                as="textarea"
                style={{
                  minHeight: `${descriptionHeight}px`,
                  overflow: 'hidden',
                }}
              />
              <Form.Check inline className="ml-2" label="Involves PII Information" checked={claim.involvesPII} onClick={(e) => updateInvolvesPII(e.currentTarget.checked)} type="checkbox" />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <div className="d-flex pl-0 col-6">
                <Form.Group className="w-100 col-5 pl-0 mb-0">
                  <Form.Label className="mb-0">ATOM Lead</Form.Label>
                  <Select
                    defaultValue={claim.atomLead && { label: `${claim.atomLead.firstName} ${claim.atomLead.lastName}`, value: claim.atomLead.id }}
                    options={
                      atomLead
                        .slice()
                        .map((adjuster) => (
                          {
                            label: `${adjuster.firstName} ${adjuster.lastName}`,
                            value: adjuster.id,
                            email: adjuster.email || 'N/A',
                            phone: adjuster.phone || 'N/A',
                          }
                        ))
                    }
                    onChange={(newValue: SelectObject | null) => updateAtomLead(newValue)}
                  />
                </Form.Group>
                {claim.atomLead
                  && (
                    <div className="my-auto">
                      <p className="m-0">{claim.atomLead.email ? claim.atomLead.email : 'N/A'}</p>
                      <p className="m-0">{claim.atomLead.phone ? claim.atomLead.phone : 'N/A'}</p>
                    </div>
                  )}

              </div>
              <Form.Group className="col-4">
                <Form.Label className="mb-0">Incident Type</Form.Label>
                <Form.Control
                  disabled
                  value={claim.incidentType || ''}
                />
              </Form.Group>
            </div>
            <Form.Group className="mt-2">
              <Form.Label className="mb-0">Resolution Details</Form.Label>
              <Form.Control
                ref={resolutionRef}
                placeholder="Enter description of resolution"
                as="textarea"
                defaultValue={claim.resolutionDetails && claim.resolutionDetails}
                rows={4}
                onChange={(e) => {
                  if (debounceHandle) clearTimeout(debounceHandle);
                  const { currentTarget: { value } } = e;
                  const handle = setTimeout(() => updateResolutionDetails(value), 750);
                  setDebounceHandle(handle);
                  setResolutionHeight(e.currentTarget.value.length === 0 ? 100 : e.currentTarget.scrollHeight);
                  updateResolutionDetails(e.currentTarget.value);
                }}
                style={{
                  minHeight: `${resolutionHeight}px`,
                  overflow: 'hidden',
                }}
              />
            </Form.Group>
          </div>
        )}
    </>
  );
};

export default ClaimInfo;

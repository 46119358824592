import React from 'react';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '../../assets/icons/Dashboard.svg';
import QuestionnairesIcon from '../../assets/icons/Questionnaires.svg';
import ThreatAlertsIcon from '../../assets/icons/ThreatAlerts.svg';
import TrainingIcon from '../../assets/icons/Training.svg';
import MembersIcon from '../../assets/icons/Members.svg';
import CyberClaimsIcon from '../../assets/icons/cyber-claims.svg';
import ContentIcon from '../../assets/icons/Content.svg';
import PrimexSecurityLogo from '../../assets/primex-security-logo.svg';
import ArticlesIcon from '../../assets/icons/Posts-nav.svg';
import DocumentsIcon from '../../assets/icons/Documents-nav.svg';
import ChecklistIcon from '../../assets/icons/Checklists-nav.svg';
import { useAppSelector } from '../../store/hooks';

interface Props { }

const SideNav: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  const { currentUser } = currentState.user;
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);
  // eslint-disable-next-line max-len
  const IRAdminRole = process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL, 10);
  const primexUserCheck = () => {
    if (currentUser?.role && superAdminRole && IRAdminRole) {
      const check: boolean = currentUser.role >= superAdminRole && currentUser.role < IRAdminRole;
      return check;
    }
    return null;
  };
  const IRUserCheck = () => {
    if (currentUser?.role && IRAdminRole) {
      const check: boolean = currentUser.role >= IRAdminRole;
      return check;
    }
    return null;
  };

  const primaryContactUser = currentUser?.primaryITForMembers.find((member) => member)
    || currentUser?.primaryContactForMembers.find((member) => member);

  return (
    <>
      <div className="d-flex flex-column side-nav-column justify-content-between">
        <ul className="side-nav-list">
          <li>
            <NavLink exact to="/" className="side-nav-item">
              <img src={DashboardIcon} alt="Dashboard Icon" />
              <p>Cyber Dashboard</p>
            </NavLink>
          </li>
          {
            currentUser
             && (primexUserCheck() || IRUserCheck()) ? null : (
               <li>
                 <NavLink to="/questionnaires" className="side-nav-item">
                   <img src={QuestionnairesIcon} alt="Questionnaires Icon" />
                   <p>Questionnaires</p>
                 </NavLink>
               </li>

              )
          }
          {
            currentUser
             && (primexUserCheck() || IRUserCheck()) ? null : (
               <li>
                 <NavLink to="/threat-alerts" className="side-nav-item">
                   <img src={ThreatAlertsIcon} alt="Cyber Threats Alert Icon" />
                   <p>Cyber Threat Alerts</p>
                 </NavLink>
               </li>
              )
          }
          {
            currentUser
             && (primexUserCheck() || IRUserCheck()) ? null : (
               <li>
                 <NavLink to="/training" className="side-nav-item">
                   <img src={TrainingIcon} alt="Training Icon" />
                   <p>Training</p>
                 </NavLink>
               </li>
              )
          }
          {
            currentUser
             && (primexUserCheck() || IRUserCheck()) ? null : (
               <li>
                 <NavLink to="/posts-articles" className="side-nav-item">
                   <img src={ArticlesIcon} alt="Article and Post Icon" />
                   <p>Posts / Articles</p>
                 </NavLink>
               </li>
              )
          }
          {
            currentUser
             && (primexUserCheck() || IRUserCheck()) ? null : (
               <li>
                 <NavLink to="/checklists" className="side-nav-item">
                   <img src={ChecklistIcon} alt="Checklist Icon" />
                   <p>Checklists</p>
                 </NavLink>
               </li>
              )
          }
          <li>
            <NavLink
              to="/documents/member"
              className="side-nav-item"
              isActive={(match, location) => {
                if (match) {
                  return true;
                }
                if (location.pathname.includes('/documents')) {
                  return true;
                }
                return false;
              }}
            >
              <img src={DocumentsIcon} alt="Documents Icon" />
              <p>Documents</p>
            </NavLink>
          </li>
          {currentUser
            && (primexUserCheck() || IRUserCheck()) ? (
              <>
                <li>
                  <NavLink to="/members" className="side-nav-item">
                    <img src={MembersIcon} alt="Members Icon" />
                    <p>Members</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/content" className="side-nav-item">
                    <img src={ContentIcon} alt="Content Icon" />
                    <p>Content</p>
                  </NavLink>
                </li>
              </>
            ) : null}
          {(currentUser
            && (primaryContactUser
              || IRUserCheck()))
            && (
              <li>
                <NavLink to="/cyber-claims" className="side-nav-item">
                  <img src={CyberClaimsIcon} alt="Cyber Claims Icon" />
                  <p>Cyber Claims</p>
                </NavLink>
              </li>
            )}

        </ul>
        <div className="security-logo-container">
          <img src={PrimexSecurityLogo} alt="Primex Security Logo" />
        </div>
      </div>
    </>
  );
};

export default SideNav;

/* eslint-disable consistent-return */
import React from 'react';
import Form from 'react-bootstrap/Form';
import { getOneMemberAsync, Member, updateOneMemberAsync } from '../../../../store/memberSlice';
import Bronze from '../../../../assets/icons/bronze.png';
import Silver from '../../../../assets/icons/Silver.png';
import Gold from '../../../../assets/icons/Gold.png';
import Platinum from '../../../../assets/icons/Platinum.png';
import Email from '../../../../assets/icons/Email.svg';
import { User } from '../../../../store/userSlice';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  member: Member;
  currentUser: User | null;
}

const ProfileInfo: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { member, currentUser } = props;

  const dispatch = useAppDispatch();

  const returnMaturityIcon = () => {
    switch (member.cyberMaturityRating) {
      case 'Bronze':
        return Bronze;
      case 'Silver':
        return Silver;
      case 'Gold':
        return Gold;
      case 'Platinum':
        return Platinum;
      default:
        return '';
    }
  };
  const returnMaturityExplanation = () => {
    switch (member.cyberMaturityRating) {
      case 'Bronze':
        return (
          <>
            <h3>Bronze</h3>
            <p>
              Congratulations, you have been awarded the Bronze Medallion for cybersecurity
              risk readiness. This is an outstanding achievement and symbolizes your commitment
              to protecting your organization and the trust placed in you. This level was achieved
              through completing our annual questionnaire, your commitment to training, and your
              organization&apos;s risk scoring.
              <br />
              <br />
              Note: Cybersecurity risk readiness levels are reevaluated and awarded yearly.
            </p>
          </>
        );
      case 'Silver':
        return (
          <>
            <h3>Silver</h3>
            <p>
              Congratulations on achieving the Silver Medallion for cybersecurity risk readiness.
              You have demonstrated advancements in formalized processes, administrative
              safeguards, and technical capability to reach this level.
              Your status signifies your organization is scoring better than 50% best practices
              adherence in all of our categories of cyber readiness and have an Incident Response
              Plan on file.
              <br />
              <br />
              Note: Cybersecurity risk readiness levels are reevaluated and awarded yearly.
            </p>
          </>
        );
      case 'Gold':
        return (
          <>
            <h3>Gold</h3>
            <p>
              Congratulations on achieving the Gold Medallion for cybersecurity risk readiness.
              This status is given only to organizations that have shown significant readiness for
              cybersecurity events. You have not only demonstrated advancements in formalized
              processes, administrative safeguards, and technical capability but are actively
              working through the implementation of a cybersecurity framework and training programs
              in order to deepen your “defense in depth” strategy. Gold Medallion recipients
              are recognized annually at our risk management conference.
              <br />
              <br />
              Note: Cybersecurity risk readiness levels are reevaluated and awarded yearly.
            </p>
          </>
        );
      case 'Platinum':
        return (
          <>
            <h3>Platinum</h3>
            <p>
              Congratulations on achieving the Platinum Medallion.
              Reserved for members who lead their peers in cybersecurity awareness, prevention,
              and protection, the Platinum Medallion in cybersecurity risk readiness is the highest
              award given within the Cybersecurity Portal. A member who reaches this level has
              consistently shown proactivity, leadership, and investment in keeping New Hampshire
              cyber secure. Platinum Medallion recipients are recognized annually at our risk
              management conference.
              <br />
              <br />
              Note: Cybersecurity risk readiness levels are reevaluated and awarded yearly.
            </p>
          </>
        );
      default:
        return (
          <>
            <h3>Member</h3>
            <p>
              This is the default level for members starting their cybersecurity journey. All New
              Hampshire Public Risk Management Exchange member organizations start with this level
              of cybersecurity risk rating. As you utilize the portal, take training, complete
              questionnaires, or provide data to help Primex evaluate your cybersecurity readiness
              and risk, you will be assigned new levels of cybersecurity.
              <br />
              <br />
              Note: Cybersecurity risk readiness levels are reevaluated and awarded yearly.
            </p>
          </>
        );
    }
  };

  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  const returnRoleAccess = () => {
    if (superAdminRole && currentUser && currentUser.role && (currentUser.role >= superAdminRole)) {
      return false;
    }
    return true;
  };

  const updateMaturityRating = async (id: number, maturityLevel: string) => {
    if (!returnRoleAccess()) {
      await dispatch(updateOneMemberAsync({
        id,
        memberData: {
          cyberMaturityRating: maturityLevel,
        },
      }));
      dispatch(getOneMemberAsync(id));
    }
  };
  return (
    <>
      <div className="row row-no-gutters profile-upper-row">
        <div className="col-4 top-item">
          <h1>{member.memberName || ''}</h1>
          <p>ANNUAL ASSESSMENT SCORE:</p>
          {/* <p>TODO add assessment completed date</p> */}
          <div className="maturity-dropdown">
            <Form.Label>Cyber Maturity Level</Form.Label>
            <Form.Control
              disabled={returnRoleAccess()}
              as="select"
              value={member.cyberMaturityRating || 'N/A'}
              onChange={(e) => updateMaturityRating(member.id, e.target.value)}
            >
              <option>N/A</option>
              <option>Bronze</option>
              <option>Silver</option>
              <option>Gold</option>
              <option>Platinum</option>
            </Form.Control>
          </div>
        </div>
        <div className="col-8 d-flex align-items-center text-center">
          {
            member.cyberMaturityRating && (member.cyberMaturityRating !== 'N/A')
              ? (
                <div>
                  <img src={returnMaturityIcon()} alt="Cyber Maturity Badge" />
                </div>
              ) : (
                null
              )
          }
          <div className="top-item text-left">
            {returnMaturityExplanation()}
          </div>
        </div>
      </div>
      <div className="row profile-bottom-row">
        <div className="col-4 member-item-group">
          <div className="member-item">
            <Form.Label>Member Type</Form.Label>
            <Form.Control disabled value={member.entityType} />
          </div>
          {
            !returnRoleAccess()
            && (
              <div className="member-item">
                <Form.Label>Member Sub-Type</Form.Label>
                <Form.Control disabled value={member.subType} />
              </div>
            )
          }
          <div className="member-item">
            <Form.Label>Member Exposure Size</Form.Label>
            <Form.Control disabled value={member.propertyExposureClass} />
          </div>
        </div>
        <div className="col-4 member-item-group">
          <div className="member-item d-flex flex-row justify-content-between">
            <div>
              <Form.Label>Member Number</Form.Label>
              <Form.Control className="numeric-font" disabled value={member.memberNumber} />
            </div>
            <div>
              <Form.Label>SAU Number</Form.Label>
              <Form.Control className="numeric-font" disabled value={member.sauNumber || ''} />
            </div>
          </div>
          <div className="member-item">
            <Form.Label>Parent Member</Form.Label>
            <Form.Control disabled value={member.parentMember ? member.parentMember.memberName : ''} />
          </div>
        </div>
        <div className="col-4 member-item-group">
          <div className="member-item">
            <Form.Label>Member Services Consultant</Form.Label>
            <div className="d-flex flex-row">
              <Form.Control disabled value={member.memberServicesConsultant ? (`${member.memberServicesConsultant.firstName} ${member.memberServicesConsultant.lastName}`) : ''} />
              <a className="ml-2" href={`mailto:${member.memberServicesConsultant ? member.memberServicesConsultant.email : ''}`}>
                <img src={Email} alt="Click to email" />
              </a>
            </div>
          </div>
          <div className="member-item">
            <Form.Label>Risk Management Consultant</Form.Label>
            <div className="d-flex flex-row">
              <Form.Control disabled value={member.riskManagementConsultant ? (`${member.riskManagementConsultant.firstName} ${member.riskManagementConsultant.lastName}`) : ''} />
              <a className="ml-2" href={`mailto:${member.riskManagementConsultant ? member.riskManagementConsultant.email : ''}`}>
                <img src={Email} alt="Click to email" />
              </a>
            </div>
          </div>
          <div className="member-item">
            <Form.Label>PL Claims Consultant</Form.Label>
            <div className="d-flex flex-row">
              <Form.Control disabled value={member.plClaimsConsultant ? (`${member.plClaimsConsultant.firstName} ${member.plClaimsConsultant.lastName}`) : ''} />
              <a className="ml-2" href={`mailto:${member.plClaimsConsultant ? member.plClaimsConsultant.email : ''}`}>
                <img src={Email} alt="Click to email" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;

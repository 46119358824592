import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPostArticle,
  deleteMultiplePostArticles,
  getOnePostArticle,
  getPostArticles,
  updatepostArticle,
} from '../api/postArticleAPI';
import { putImageOnS3 } from '../api/s3API';
import { PostArticleAssignment } from './postArticleAssignmentSlice';
import { User } from './userSlice';

export interface PostArticle {
  id: number;
  title: string | null;
  summary: string | null;
  content: string | null;
  thumbnailFilename: string | null;
  pdfFilename: string | null;
  displayWeeklyEmail: boolean;
  displayDashboardWidget: boolean;
  displayDashboardCarousel: boolean;
  published: boolean;
  publishedDate: Date;
  archived: boolean;
  startDate: Date | null;
  expirationDate: Date | null;
  author: User;
  imagePreAuthURL?: string | null;
  pdfPreAuthURL?: string | null;
  thumbnailUrl?: string | null;
  pdfUrl?: string | null;
  createdAt: Date;
  assignments: PostArticleAssignment[];
}

export interface CreatePostArticleDTO {
  id?: number;
  title: string | null;
  summary: string | null;
  content: string | null;
  thumbnailFilename: string | null;
  pdfFilename: string | null;
  displayWeeklyEmail: boolean;
  displayDashboardWidget: boolean;
  displayDashboardCarousel: boolean;
  published: boolean;
  publishedDate: Date | null;
  archived: boolean;
  startDate: Date | null;
  expirationDate: Date | null;
}

interface postArticleState {
  // TODO: update postArticle state
  postArticles: PostArticle[] | null;
  selectedpostArticle: PostArticle | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: postArticleState = {
  postArticles: null,
  selectedpostArticle: null,
  status: 'idle',
  error: null,
};

const getpostArticlesAsync = createAsyncThunk(
  'postArticles/getAllpostArticles',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getPostArticles();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOnePostArticleAsync = createAsyncThunk(
  'postArticles/getOne',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOnePostArticle(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

// TODO: update postArticle type
const createPostArticleAsync = createAsyncThunk(
  'postArticles/create',
  // eslint-disable-next-line max-len
  async (arg: { postArticle: CreatePostArticleDTO | Partial<PostArticle>, files: {image?: File, pdf?: File} }, { rejectWithValue }) => {
    try {
      const response: any = await createPostArticle(arg.postArticle, {
        imageType: arg.files.image ? arg.files.image.type : undefined,
        pdfType: arg.files.pdf ? arg.files.pdf.type : undefined,
      });
      if (arg.files.image && response.data.imagePreAuthURL) {
        await putImageOnS3(response.data.imagePreAuthURL, arg.files.image);
        response.data.imagePreAuthURL = null;
      }
      if (arg.files.pdf && response.data.pdfPreAuthURL) {
        await putImageOnS3(response.data.pdfPreAuthURL, arg.files.pdf);
        response.data.pdfPreAuthURL = null;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updatepostArticleAsync = createAsyncThunk(
  'postArticles/update',
  // eslint-disable-next-line max-len
  async (postArticleDTO: { id: number, postArticle: Partial<PostArticle> }, { rejectWithValue }) => {
    try {
      const response: any = await updatepostArticle(postArticleDTO.id, postArticleDTO.postArticle);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultiplepostArticlesAsync = createAsyncThunk(
  'postArticles/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultiplePostArticles(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const nullSelectedPostArticle = createAsyncThunk(
  'postArticles/nullSelected',
  () => null,
);

export const postArticlesSlice = createSlice({
  name: 'postArticles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getpostArticlesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getpostArticlesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.postArticles = action.payload;
        state.error = null;
      })
      .addCase(getpostArticlesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.postArticles = null;
        state.error = action.payload;
      })
      .addCase(createPostArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPostArticleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedpostArticle = action.payload;
        state.error = null;
      })
      .addCase(createPostArticleAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedpostArticle = null;
        state.error = action.payload;
      })
      .addCase(updatepostArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatepostArticleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedpostArticle = action.payload;
        state.error = null;
      })
      .addCase(updatepostArticleAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedpostArticle = null;
        state.error = action.payload;
      })
      .addCase(deleteMultiplepostArticlesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultiplepostArticlesAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultiplepostArticlesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(nullSelectedPostArticle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(nullSelectedPostArticle.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedpostArticle = action.payload;
        state.error = null;
      })
      .addCase(nullSelectedPostArticle.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getOnePostArticleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOnePostArticleAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedpostArticle = action.payload;
        state.error = null;
      })
      .addCase(getOnePostArticleAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedpostArticle = null;
        state.error = action.payload;
      });
  },
});

export default postArticlesSlice.reducer;

export {
  getpostArticlesAsync,
  createPostArticleAsync,
  updatepostArticleAsync,
  deleteMultiplepostArticlesAsync,
  nullSelectedPostArticle,
  getOnePostArticleAsync,
};

import { AxiosPromise } from 'axios';
import api from './api';

export function getPostArticles() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/post-article')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOnePostArticle(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/post-article/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// TODO: add type for postArticle transfer object
export function createPostArticle(postArticle: any, files?: {
  imageType?: string;
  pdfType?: string;
}) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/post-article', { createPostArticleDto: postArticle, files })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// TODO: add partial type
export function updatepostArticle(id: number, postArticle: any) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/post-article/${id}`, postArticle)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultiplePostArticles(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/post-article', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable import/prefer-default-export */
import { AxiosPromise } from 'axios';
import { Notification } from '../store/notificationSlice';
import api from './api';

export function getCurrentNotifications() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/notifications/current')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateNotification(id: number, body: Partial<Notification>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/notifications/${id}`, body)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

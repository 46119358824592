import { AxiosPromise } from 'axios';
import { Checklist, ChecklistDTO } from '../store/checklistSlice';
import api from './api';

export function createChecklist(checklistDTO: any) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/checklist', checklistDTO)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getAllChecklists() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/checklist')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneChecklist(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/checklist/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultiplechecklists(ids: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/checklist', { params: ids })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateChecklist(id: number, updateBody: Partial<Checklist> | ChecklistDTO) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/checklist/${id}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

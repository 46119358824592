import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getOneBillboardAsync, nullSelectedBillboard } from '../../../../../store/billboardSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import BillboardDisplay from '../../../dashboard/Billboard';

interface Props {
  id: number;
}

const BillboardPreviewModal: React.FC<Props> = ({ children, id }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    billboards: state.billboards,
  }));
  const billboard = currentState.billboards.selectedBillboard;

  const handleClose = () => {
    dispatch(nullSelectedBillboard());
    setShow(false);
  };
  const handleShow = async () => {
    await dispatch(getOneBillboardAsync(id));
    setShow(true);
  };
  return (
    <>
      <Button className="button-link p-0" onClick={() => handleShow()}>
        {children}
      </Button>
      {
        billboard
        && (
        <Modal show={show} size="lg" onHide={handleClose} className="how-to-modal">
          <Modal.Header closeButton className="modal-header" />
          <Modal.Body className="d-flex flex-column billboard-preview">
            <BillboardDisplay selectedBillboard={billboard} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        )
      }
    </>
  );
};

export default BillboardPreviewModal;

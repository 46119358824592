/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';
import {
  Billboard,
  createBillboardAsync,
  deleteMultipleBillboardsAsync,
  getBillboardsAsync,
  getOneBillboardAsync,
  nullSelectedBillboard,
  updateBillboardAsync,
} from '../../../../store/billboardSlice';
import BillboardPreviewModal from './modals/billboardPreviewModal';

interface Props {
  deleteRows: {
    selectedRows: number[],
    setSelectedRows: Function,
  }
  billboards: Billboard[] | null,
  showArchived: boolean,
  searchString: string | null,
}

const ManageBillboardTable: React.FC<Props> = (props: Props) => {
  const {
    deleteRows,
    showArchived,
    billboards,
    searchString,
  } = props;
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStart, setSortStart] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortEnd, setSortEnd] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCreated, setSortCreated] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBillboardsAsync());
  }, []);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (deleteRows.selectedRows.includes(checkboxId)) {
      const newArray = deleteRows.selectedRows.filter((item) => item !== checkboxId);
      deleteRows.setSelectedRows([...newArray]);
    } else {
      deleteRows.setSelectedRows([checkboxId, ...deleteRows.selectedRows]);
    }
  };

  const editBillboard = async (billboardId: number) => {
    await dispatch(getOneBillboardAsync(billboardId));
    history.push('/content/create/billboards');
  };

  const archiveBillboard = async (id: number) => {
    const archiveBody: Partial<Billboard> = {
      archived: true,
    };
    await dispatch(updateBillboardAsync({ id, billboard: archiveBody }));
    dispatch(getBillboardsAsync());
  };

  const deleteBillboard = async (id: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultipleBillboardsAsync([id]));
      dispatch(getBillboardsAsync());
    }
  };

  const copyBillboard = async (id: number) => {
    const billboardToCopy = await dispatch(getOneBillboardAsync(id));
    const newBillboard: Partial<Billboard> = { ...billboardToCopy.payload };
    newBillboard.id = undefined;
    newBillboard.published = false;
    newBillboard.author = undefined;
    newBillboard.headline = `Copy of: ${newBillboard.headline}`;
    await dispatch(createBillboardAsync({ billboard: newBillboard }));
    dispatch(getBillboardsAsync());
    dispatch(nullSelectedBillboard());
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th className="check-box">
            <input type="checkbox" />
          </th>
          <th>
            <span>Headline </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortTitle) {
                  case 'noSort':
                    setSortTitle('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortTitle('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortTitle('noSort');
                    return;
                  default:
                    setSortTitle('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
            </Button>
          </th>
          <th>
            <span>Status </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStatus) {
                  case 'noSort':
                    setSortStatus('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStatus('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStatus('noSort');
                    return;
                  default:
                    setSortStatus('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
            </Button>
          </th>
          <th>
            <span>Start Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStart) {
                  case 'noSort':
                    setSortStart('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStart('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStart('noSort');
                    return;
                  default:
                    setSortStart('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStart)} />
            </Button>
          </th>
          <th>
            <span>End Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortEnd) {
                  case 'noSort':
                    setSortEnd('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortEnd('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortEnd('noSort');
                    return;
                  default:
                    setSortEnd('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortEnd)} />
            </Button>
          </th>
          <th>
            <span>Created </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortCreated) {
                  case 'noSort':
                    setSortCreated('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortCreated('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortCreated('noSort');
                    return;
                  default:
                    setSortCreated('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortCreated)} />
            </Button>
          </th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          billboards
          && billboards
            .slice()
            .filter((item) => (showArchived ? item : !item.archived))
            .filter((item) => {
              if (searchString) {
                if (item.headline.toLowerCase().includes(searchString.toLowerCase())) {
                  return item;
                }
                return;
              }
              return item;
            })
            .sort((a, b) => {
              const aVar = a.headline.toLowerCase();
              const bVar = b.headline.toLowerCase();
              switch (sortTitle) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.startDate;
              const bVar = b.startDate;
              switch (sortStart) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.expirationDate || 0;
              const bVar = b.expirationDate || 0;
              switch (sortEnd) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const returnStatus = (billboard: Billboard) => {
                if (billboard.published && !billboard.archived) {
                  return 1;
                } if (!billboard.published && !billboard.archived) {
                  return 2;
                }
                return 3;
              };
              const aVar = returnStatus(a);
              const bVar = returnStatus(b);
              switch (sortStatus) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.createdAt;
              const bVar = b.createdAt;
              switch (sortCreated) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .map((billboard: Billboard) => (
              <tr>
                <td className="check-box">
                  <input
                    type="checkbox"
                    data-threatid={billboard.id}
                    onChange={() => onCheckboxChange(billboard.id)}
                  />
                </td>
                <td>
                  <BillboardPreviewModal id={billboard.id}>
                    {billboard.headline}
                  </BillboardPreviewModal>
                </td>
                <td>
                  {
                    billboard.archived && 'Archived'
                  }
                  {
                    !billboard.archived
                    && billboard.published
                    && 'Active'
                  }
                  {
                    !billboard.archived
                    && !billboard.published
                    && 'Draft'
                  }
                </td>
                <td>
                  {
                    dateOnly(billboard.startDate)
                  }
                </td>
                <td>
                  {
                    billboard.expirationDate
                      ? (
                        dateOnly(billboard.expirationDate)
                      ) : (
                        'N/A'
                      )
                  }
                </td>
                <td>
                  <p className="m-0">{dateAndTime(billboard.createdAt)}</p>
                  <p className="m-0">
                    {
                      billboard.author
                      && billboard.author.firstName
                    }
                    {' '}
                    {
                      billboard.author
                      && billboard.author.lastName
                    }
                  </p>
                </td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => editBillboard(billboard.id)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => copyBillboard(billboard.id)}>
                        Copy
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteBillboard(billboard.id)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => archiveBillboard(billboard.id)}>
                        Archive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};
export default ManageBillboardTable;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDebounce } from 'react-use';
import Select from 'react-select';
import ITIcon from '../../../assets/icons/PrmaryIT.svg';
import ProgramContactIcon from '../../../assets/icons/PrimaryProgramContact.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getCurrentUserAsync,
  getFiltersAsync,
  getImpersonateTokenAsync,
  getUsersWithPaginationAsync,
} from '../../../store/userSlice';
import { lastActiveDateString } from '../../../service/timeAndDate';
import CustomPagination from '../pagination/CustomPagination';
import CustomSorting from '../pagination/CustomSorting';
import MemberAutosuggest from './MemberAutosuggest';

interface Props {
  myMembers: boolean;
}

const UserSearch: React.FC<Props> = (props: Props) => {
  const { myMembers } = props;
  const [sortName, setSortName] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortAsc');
  const [categoryFilter, setCategoryFilter] = useState<{ label: string, value: string }[]>([]);
  const [memberFilter, setMemberFilter] = useState<number[]>([]);
  const [maturityFilter, setMaturityFilter] = useState<{ label: string, value: string }[]>([]);
  const [parentFilter, setParentFilter] = useState<{ label: string, value: number }[]>([]);
  const [searchCriteria, setSearchCriteria] = useState('');
  const dispatch = useAppDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');

  useDebounce(
    () => {
      setDebouncedSearchValue(searchCriteria);
    },
    1000,
    [searchCriteria],
  );

  useEffect(() => {
    dispatch(getUsersWithPaginationAsync(
      {
        pageSize,
        pageIndex,
        member: memberFilter,
        category: categoryFilter.map((v) => v.value),
        parentMember: parentFilter.map((v) => v.value),
        maturity: maturityFilter.map((v) => v.value),
        orderBy: `${sortName}_name`,
        search: debouncedSearchValue,
        myMembers,
      },
    ));
  },
  [
    pageIndex,
    pageSize,
    memberFilter,
    categoryFilter,
    parentFilter,
    maturityFilter,
    sortName,
    debouncedSearchValue,
    myMembers,
  ]);

  useEffect(() => {
    dispatch(getCurrentUserAsync());
    dispatch(getFiltersAsync());
  }, []);

  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  const
    {
      user: {
        usersPaged,
        count,
        filters,
        currentUser,
      },
    } = currentState;
  const users = usersPaged;

  const handleImpersonateToken = async (id: number) => {
    if (id) {
      await dispatch(getImpersonateTokenAsync(id));
      window.location.href = '/';
    }
  };

  return (
    <>
      <div>
        <div className="searchRow my-2">
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className="filter-row">
          <Form.Label className="row-title">Filter by:</Form.Label>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Select
              isMulti
              options={filters.categories}
              onChange={(newValue: any) => setCategoryFilter(newValue)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Member</Form.Label>
            <MemberAutosuggest memberIds={memberFilter} onSelect={setMemberFilter} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Maturity Level</Form.Label>
            <Select
              isMulti
              options={filters.cyberMaturityRatings}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setMaturityFilter(newValue)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Parent Member</Form.Label>
            <Select
              isMulti
              options={filters.parentMembers}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setParentFilter(newValue)}
            />
          </Form.Group>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>
                <CustomSorting setSort={setSortName} sort={sortName}>
                  Name
                </CustomSorting>
              </th>
              <th>Job Title</th>
              <th>Category</th>
              <th>Member</th>
              <th>Parent Member</th>
              <th>Last Active</th>
              <th>Actions</th>
              {localStorage.getItem('impersonate') !== 'admin' && currentUser && (currentUser.role === 100 || currentUser.role === 200) && (
                <th>Impersonate User</th>
              )}
            </tr>
          </thead>
          <tbody>
            {
              users
                .map((user) => (
                  <tr key={user.id}>
                    <td>
                      {user.lastName}
                      {', '}
                      {user.firstName}
                      {
                        user.primaryITForMembers
                        && user.primaryITForMembers.length > 0
                        && (
                          <img className="ml-2" src={ITIcon} alt="IT Icon" title="Primary IT Contact" />
                        )
                      }
                      {
                        user.primaryContactForMembers
                        && user.primaryContactForMembers.length > 0
                        && (
                          <img className="ml-2" src={ProgramContactIcon} alt="Primary Program Contact Icon" title="Primary Program Contact" />
                        )
                      }
                    </td>
                    <td>
                      {user.jobTitle}
                    </td>
                    <td>
                      {user.jobCategory || 'N/A'}
                    </td>
                    <td>
                      {
                        user.members[0]
                        && user.members[0].memberName
                        && user.members[0].memberName
                      }
                    </td>
                    <td>
                      {
                        user.members[0]
                        && user.members[0].parentMember
                        && user.members[0].parentMember.memberName
                        && user.members[0].parentMember.memberName
                      }
                    </td>
                    <td>
                      {user.lastActive ? lastActiveDateString(user.lastActive) : null}
                    </td>
                    <td className="text-center">
                      <Link to={`/users/detail/${user.id}`}>
                        View Profile
                      </Link>
                    </td>
                    {localStorage.getItem('impersonate') !== 'admin' && currentUser && (currentUser.role === 100 || currentUser.role === 200) && (
                      <td className="text-center">
                        {!user.cyberNone && (
                          <Button onClick={() => handleImpersonateToken(user.id)}>
                            Impersonate
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                ))
            }
          </tbody>
        </Table>
        <CustomPagination
          totalCount={count}
          pageSize={pageSize}
          currentPage={pageIndex}
          onPageChange={setPageIndex}
          onPageSizeChange={setPageSize}
        />
      </div>
    </>
  );
};

export default UserSearch;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createCyberThreatAssignments,
  getCurrentCyberThreatAssignments,
  updateCyberThreatAssignment,
  UpdateCyberThreatAssignmentModel,
} from '../api/cyberThreatAssignmentAPI';
import { CyberThreatDistributionModel } from '../models/distributionModels';
import { PrimexSecurityAlert } from './primexAlertsSlice';
import { User } from './userSlice';

export interface CyberThreatAssignment {
  id: number;
  cyberThreat: PrimexSecurityAlert;
  user: User;
  viewed?: Date;
  dueDate: Date;
  createdAt: Date;
}

interface CyberThreatAssignmentState {
  currentCyberThreatAssignments: CyberThreatAssignment[] | null; // assignments for current user
  adminDetailCyberThreatAssignments: CyberThreatAssignment[] | null; // assignments for admin detail
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: CyberThreatAssignmentState = {
  currentCyberThreatAssignments: [],
  adminDetailCyberThreatAssignments: [],
  status: 'idle',
  error: null,
};

const getCurrentCyberThreatAssignmentsAsync = createAsyncThunk(
  'cyberThreatAssignments/getCurrent',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentCyberThreatAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createCyberThreatAssignmentsAsync = createAsyncThunk(
  'cyberThreatAssignments/create',
  async (arg: CyberThreatDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createCyberThreatAssignments(arg);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateCyberThreatAssignmentAsync = createAsyncThunk(
  'cyberThreatAssignment/update',
  async (params: UpdateCyberThreatAssignmentModel, { rejectWithValue }) => {
    try {
      const response: any = await updateCyberThreatAssignment(params);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const cyberThreatAssignmentsSlice = createSlice({
  name: 'questionnaireAssignments',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get current questionnaire assignments
      .addCase(getCurrentCyberThreatAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentCyberThreatAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentCyberThreatAssignments = action.payload;
        state.error = null;
      })
      .addCase(getCurrentCyberThreatAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentCyberThreatAssignments = [];
        state.error = action.payload;
      })
      .addCase(createCyberThreatAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCyberThreatAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createCyberThreatAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCyberThreatAssignmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCyberThreatAssignmentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateCyberThreatAssignmentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default cyberThreatAssignmentsSlice.reducer;

export {
  getCurrentCyberThreatAssignmentsAsync,
  createCyberThreatAssignmentsAsync,
  updateCyberThreatAssignmentAsync,
};

import React, { useState } from 'react';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import {
  ChecklistMultiAssignmentSession,
  ChecklistResponse,
  getChecklistMultiMemberSessionAsync,
  updateChecklistMultiMemberSessionAsync,
} from '../../../../store/checklistAssignmentSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { ChecklistItem } from '../../../../store/checklistSlice';

interface Props {
  currentMultiSession: ChecklistMultiAssignmentSession;
  assignmentCompleted: boolean;
  checklistItem: ChecklistItem | null;
  response: ChecklistResponse;
  responseIds: number[];
}

const MultiSessionItem: React.FC<Props> = ({
  checklistItem,
  response,
  currentMultiSession,
  assignmentCompleted,
  responseIds,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  if (!checklistItem) {
    return (<div />);
  }

  const submitResponse = async (checked: boolean) => {
    if (
      currentMultiSession.assignments
      && currentMultiSession.assignments.length > 0
      && responseIds
      && responseIds.length > 0
    ) {
      await dispatch(updateChecklistMultiMemberSessionAsync({
        id: currentMultiSession.id,
        updateBody: {
          checklistAssignmentIds: currentMultiSession.assignments.map(
            (assignment) => assignment.id,
          ),
          responseIds,
          responseBody: {
            itemCompleted: checked,
            dateCompleted: checked ? new Date() : null,
          },
        },
      }));
      dispatch(getChecklistMultiMemberSessionAsync(currentMultiSession.id));
    }
  };

  return (
    <>
      {
        checklistItem.supportingInfo
          ? (
            <Accordion>
              <Card>
                <Card.Header className="d-flex checklist-item-header justify-content-between">
                  <div className="d-flex align-items-center">
                    {
                      response
                        && response.checklistItem.id === checklistItem.id
                        ? (
                          <input
                            disabled={assignmentCompleted}
                            type="checkbox"
                            defaultChecked={response.itemCompleted}
                            onChange={(e) => {
                              submitResponse(e.currentTarget.checked);
                            }}
                          />
                        ) : (
                          <input type="checkbox" />
                        )
                    }
                    <h3 className="mb-0 ml-3">{checklistItem.itemTitle || ''}</h3>
                  </div>
                  <Accordion.Toggle className="button-link text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <div className="p-4">
                    <p>{checklistItem.supportingInfo || ''}</p>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) : (
            <Card>
              <Card.Header className="d-flex checklist-item-header justify-content-between">
                <div className="d-flex align-items-center">
                  {
                    response
                      && response.checklistItem.id === checklistItem.id
                      ? (
                        <input
                          disabled={assignmentCompleted}
                          type="checkbox"
                          defaultChecked={response.itemCompleted}
                          onChange={(e) => {
                            submitResponse(e.currentTarget.checked);
                          }}
                        />
                      ) : (
                        <input type="checkbox" />
                      )
                  }
                  <h3 className="mb-0 ml-3">{checklistItem.itemTitle || ''}</h3>
                </div>
                <div />
              </Card.Header>
            </Card>
          )
      }
    </>
  );
};

export default MultiSessionItem;

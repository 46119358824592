import React, { useState } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import MemberSearch from './MemberSearch';
import UserSearch from './UserSearch';

interface Props { }

const MembersNav: React.FC<Props> = () => {
  const [showMyMembers, setShowMyMembers] = useState(false);
  return (
    <>
      <div className="row mx-0 align-items-center nav-row">
        <div className="col-8">
          <Nav variant="tabs" className="m-0">
            <Nav.Item>
              <NavLink exact to="/members">Members</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink exact to="/members/users">Users</NavLink>
            </Nav.Item>
          </Nav>
        </div>
        <div className="col-4 d-flex justify-content-end align-items-end">
          <p className="mb-0 mt-3">
            <input className="mr-2" type="checkbox" checked={showMyMembers} onChange={(e) => setShowMyMembers(e.target.checked)} />
            <span className="m-0">Show my members only</span>
          </p>
        </div>
      </div>
      <div className="row row-no-gutters">
        <div className="col-12">
          <Switch>
            <Route exact path="/members">
              <MemberSearch myMembers={showMyMembers} />
            </Route>
            <Route exact path="/members/users">
              <UserSearch myMembers={showMyMembers} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default MembersNav;

import { AxiosPromise } from 'axios';
import api, { getPaginatedCountFromResponse } from './api';

// eslint-disable-next-line import/prefer-default-export
export function getCurrentUser() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/user/current')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getAllUsersWithMembers() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/user')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// eslint-disable-next-line import/prefer-default-export
export function getUsers(arg: any = null) {
  return new Promise<{ data: any; count: number | any | null }>((resolve, reject) => {
    try {
      api.get('/user', { params: arg })
        .then((response: any) => {
          resolve({ data: response.data, count: getPaginatedCountFromResponse(response) });
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneUser(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/user/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getImpersonateToken(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/impersonate/token/${id}`)
        .then((response: any) => {
          resolve(response);
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('impersonate', 'admin');
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function reactiveAdmin() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/impersonate/reactive')
        .then((response: any) => {
          console.log('dddd', response);
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('impersonate', '');
          window.location.href = '/';
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getUserFilters() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/user/user-filters')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React, { useEffect, useState } from 'react';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { ChecklistItem } from '../../../../../store/checklistSlice';
import {
  ChecklistAssignment,
  ChecklistResponse,
  getChecklistAssignmentAsync,
  updateChecklistResponseAsync,
} from '../../../../../store/checklistAssignmentSlice';
import { useAppDispatch } from '../../../../../store/hooks';

interface Props {
  checklistAssignment?: ChecklistAssignment;
  checklistItem: ChecklistItem | null;
  response?: ChecklistResponse;
  checkComplete: boolean;
}

const ViewChecklistItem: React.FC<Props> = ({
  checklistItem,
  checkComplete,
  response,
  checklistAssignment,
}) => {
  const [open, setOpen] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  // const [alertChecked, setAlertChecked] = useState(false);
  const dispatch = useAppDispatch();

  if (!checklistItem) {
    return (<div />);
  }

  // eslint-disable-next-line no-unused-vars
  const submitResponse = async (responseId: number, checked: boolean) => {
    await dispatch(updateChecklistResponseAsync({
      responseId,
      updateBody: {
        itemCompleted: checked,
        dateCompleted: checked ? new Date() : null,
      },
    }));
    if (checklistAssignment && checklistAssignment.id) {
      dispatch(getChecklistAssignmentAsync(checklistAssignment.id));
      setAlertShow(false);
      // setAlertChecked(true);
    }
  };

  useEffect(() => {
    if (checkComplete && !alertShow) {
      setAlertShow(true);
    } else if (!checkComplete && alertShow) {
      setAlertShow(true);
    } else if (!checkComplete && !alertShow) {
      setAlertShow(false);
    }
  });
  return (
    <>
      {
        checklistItem.supportingInfo
          ? (
            <Accordion>
              <Card>
                <Card.Header className="d-flex checklist-item-header justify-content-between">
                  <div className="d-flex align-items-center">
                    {
                      response
                        && response.checklistItem.id === checklistItem.id
                        && checklistAssignment
                        ? (
                          <input
                            disabled={checklistAssignment.completed}
                            type="checkbox"
                            defaultChecked={response.itemCompleted}
                            onChange={(e) => {
                              submitResponse(response.id, e.currentTarget.checked);
                            }}
                          />
                        ) : (
                          <input type="checkbox" />
                        )
                    }
                    <h3 className="mb-0 ml-3">{checklistItem.itemTitle || ''}</h3>
                  </div>
                  <Accordion.Toggle className="button-link text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <div className="p-4">
                    <p>{checklistItem.supportingInfo || ''}</p>
                  </div>
                </Accordion.Collapse>
              </Card>
              <Alert variant="danger" className="m-0 p-1" show={alertShow && !response?.itemCompleted}>
                Check each item on the list before continuing.
              </Alert>
            </Accordion>
          ) : (
            <Card>
              <Card.Header className="d-flex checklist-item-header justify-content-between">
                <div className="d-flex align-items-center">
                  {
                    response
                      && response.checklistItem.id === checklistItem.id
                      && checklistAssignment
                      ? (
                        <input
                          disabled={checklistAssignment.completed}
                          type="checkbox"
                          defaultChecked={response.itemCompleted}
                          onChange={(e) => {
                            submitResponse(response.id, e.currentTarget.checked);
                          }}
                        />
                      ) : (
                        <input type="checkbox" />
                      )
                  }
                  <h3 className="mb-0 ml-3">{checklistItem.itemTitle || ''}</h3>
                </div>
                <div />
              </Card.Header>
              <Alert variant="danger" className="m-0 p-1" show={alertShow && !response?.itemCompleted}>
                Check each item on the list before continuing.
              </Alert>
            </Card>

          )
      }
    </>
  );
};

export default ViewChecklistItem;

ViewChecklistItem.defaultProps = {
  response: undefined,
  checklistAssignment: undefined,
};

import React from 'react';
import { User } from '../../store/userSlice';

interface Props {
  user: User
}

const UserInfo: React.FC<Props> = (props) => {
  const { user } = props;

  return (
    <div>
      <h1>
        {user.firstName}
        {' '}
        {user.lastName}
      </h1>
      <div>
        <span>
          Job Title:
          {' '}
          {user.jobTitle || 'N/A'}
        </span>
      </div>
      <div>
        <span>
          Category:
          {' '}
          {
            user.jobCategory
              ? user.jobCategory
              : 'N/A'
          }
        </span>
      </div>
      <div>
        <span>
          Member:
          {' '}
          {
            (user.members
              && user.members[0]
              && user.members[0].memberName) || 'N/A'
          }
        </span>
      </div>
      <div className="mb-2">
        <span>
          Parent Member:
          {' '}
          {
            (user.members
              && user.members[0]
              && user.members[0].parentMember
              && user.members[0].parentMember.memberName) || 'N/A'
          }
        </span>
      </div>
      <div>
        <span>
          Phone:
          {' '}
          {user.phone || 'N/A'}
        </span>
      </div>
      <div>
        <span>
          Email:
          {' '}
          {user.email || 'N/A'}
        </span>
      </div>
      <div>
        <span className="d-flex">
          Contact Type:
          {' '}
          <span className="ml-2">
            {
              user.primaryContactForMembers
              && user.primaryContactForMembers[0]
              && (<p>Primary Program Contact</p>)
            }
            {
              user.primaryITForMembers
              && user.primaryITForMembers[0]
              && (<p>Primary IT Contact</p>)
            }
            {
              !user.primaryContactForMembers
              && !user.primaryITForMembers
              && 'N/A'
            }
          </span>
        </span>
      </div>
    </div>
  );
};

export default UserInfo;

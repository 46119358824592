/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import DeleteIcon from '../../../../assets/icons/remove.svg';
import { dateYearMonthDay } from '../../../../service/timeAndDate';
import {
  Billboard,
  createBillboardAsync,
  CreateBillboardDTO,
  nullSelectedBillboard,
  updateBillboardAsync,
} from '../../../../store/billboardSlice';
import { BillboardDistributionModel, DistributionUniversal } from '../../../../models/distributionModels';
import { getMembersAsync } from '../../../../store/memberSlice';
import { createBillboardAssignmentsAsync } from '../../../../store/billboardAssignmentSlice';
import { getAllUsersWithMembersAsync } from '../../../../store/userSlice';
import { populateMemberMaturityFilter, populateMemberSubTypeFilter } from '../../../../service/distributionHelpers';

interface Props {
  selectedBillboard: Billboard | null;
}

interface MemberDropdown {
  value: number;
  name: string;
}

interface SubtypeDropdown {
  value: string;
  name: string;
}

const CreateBillboard: React.FC<Props> = ({ selectedBillboard }) => {
  const [file, setFile] = useState<File>();
  // eslint-disable-next-line no-unused-vars
  const [editID, setEditID] = useState<number | undefined>(selectedBillboard?.id || undefined);
  const [existingFileName, setExistingFileName] = useState(selectedBillboard?.backgroundImageFilename || '');
  const [headline, setHeadline] = useState(selectedBillboard?.headline || '');
  const [buttonText, setButtonText] = useState(selectedBillboard?.buttonText || '');
  const [buttonURL, setButtonURL] = useState(selectedBillboard?.buttonLink || '');
  const [buttonPosition, setButtonPosition] = useState<'bottom-left' | 'bottom-center' | 'bottom-right'>(selectedBillboard?.buttonPosition || 'bottom-left');
  const [headlineColor, setHeadlineColor] = useState(selectedBillboard?.color || 'white');
  // eslint-disable-next-line max-len
  const [startDate, setStartDate] = useState<Date | undefined>(selectedBillboard?.startDate);
  // eslint-disable-next-line max-len
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(selectedBillboard?.expirationDate || undefined);

  const [distModel, setDistModel] = useState<DistributionUniversal>();

  const [fileError, setFileError] = useState<boolean>(false);
  const [headlineError, setHeadlineError] = useState(false);
  const [buttonError, setButtonError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);

  const [localUserError, setLocalUserError] = useState(false);
  const [localMemberError, setLocalMemberError] = useState(false);

  const currentState = useAppSelector((state) => ({
    member: state.member,
    user: state.user,
  }));

  const { members } = currentState.member;
  const { users } = currentState.user;

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllUsersWithMembersAsync());
    dispatch(getMembersAsync());
    if (!distModel) {
      setDistModel({
        allMembers: false,
        userOnly: false,
        specificMemberIds: [],
        specificMemberTypes: null,
        specificMemberMaturityLevels: [],
        specificMemberSubTypes: [],
        primaryProgramContact: false,
        primaryIt: false,
        basicUser: false,
        specificUserJobCategories: [],
        dueDate: null,
        memberSelection: '',
        userError: false,
        dateError: false,
        memberError: false,
        specificUserId: null,
        selectedUserMember: null,

      });
    }
  }, []);

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberIds: memberIds });
    }
  };

  const populateMaturityState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberMaturityLevels: subtypes });
    }
  };

  const populateSubtypeState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberSubTypes: subtypes });
    }
  };

  const submitDistribution = async (billboardId: number) => {
    if (distModel && billboardId) {
      const billboardDistModel: BillboardDistributionModel = {
        ...distModel, billboardId,
      };
      if (distModel.memberSelection === 'specificMembers') {
        billboardDistModel.allMembers = false;
        billboardDistModel.specificMemberTypes = null;
        billboardDistModel.specificMemberSubTypes = null;
      } else if (distModel.memberSelection === 'selectedCriteria') {
        billboardDistModel.allMembers = false;
        billboardDistModel.specificMemberIds = null;
      } else if (distModel.memberSelection === 'allMembers') {
        billboardDistModel.allMembers = true;
        billboardDistModel.specificMemberIds = null;
        billboardDistModel.specificMemberTypes = null;
        billboardDistModel.specificMemberSubTypes = null;
      }
      dispatch(createBillboardAssignmentsAsync(billboardDistModel));
    }
  };

  const submitBillboardDraft = async () => {
    if (file && file.name && !editID) {
      const bbDTO: CreateBillboardDTO = {
        backgroundImageFilename: file.name,
        fileType: file.type,
        headline,
        color: headlineColor,
        buttonText,
        buttonLink: buttonURL,
        buttonPosition,
        startDate: startDate || null,
        expirationDate: expirationDate || null,
        published: false,
        archived: false,
      };
      await dispatch(createBillboardAsync({ image: file, billboard: bbDTO }));
      history.push('/content/manage/billboards');
    } else if (editID) {
      if (file && file.name) {
        const updateImageDTO: CreateBillboardDTO = {
          id: editID,
          backgroundImageFilename: file.name,
          fileType: file.type,
          headline,
          color: headlineColor,
          buttonText,
          buttonLink: buttonURL,
          buttonPosition,
          startDate: startDate || null,
          expirationDate: expirationDate || null,
          archived: false,
          published: false,
        };
        await dispatch(createBillboardAsync({ image: file, billboard: updateImageDTO }));
        dispatch(nullSelectedBillboard());
        history.push('/content/manage/billboards');
      } else if (!file && existingFileName) {
        const updateDTO: Partial<Billboard> = {
          headline,
          color: headlineColor,
          buttonText,
          buttonLink: buttonURL,
          buttonPosition,
          startDate,
          expirationDate: expirationDate || null,
          archived: false,
          published: false,
        };
        await dispatch(updateBillboardAsync({ id: editID, billboard: updateDTO }));
        dispatch(nullSelectedBillboard());
        history.push('/content/manage/billboards');
      }
    }
  };

  const errorCheck = () => {
    let error = false;
    if (!file) {
      if (existingFileName) {
        setFileError(false);
      } else {
        setFileError(true);
        error = true;
      }
    } else {
      setFileError(false);
    }
    if (!headline) {
      setHeadlineError(true);
      error = true;
    } else {
      setHeadlineError(false);
    }
    if (!startDate) {
      setStartDateError(true);
      error = true;
    } else {
      setStartDateError(false);
    }
    if (!buttonText || !buttonURL) {
      setButtonError(true);
      error = true;
    } else {
      setButtonError(false);
    }
    return error;
  };

  const distributionErrorCheck = () => {
    if (
      selectedBillboard
      && selectedBillboard.assignments
      && selectedBillboard.assignments.length > 0
    ) {
      return false;
    }
    if (distModel) {
      let error = false;
      if (distModel.memberSelection === 'specificMembers') {
        if (distModel.specificMemberIds && distModel.specificMemberIds.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'selectedCriteria') {
        if (distModel.specificMemberTypes && distModel.specificMemberTypes.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (distModel.specificUserJobCategories && distModel.specificUserJobCategories.length > 0) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'allMembers') {
        setLocalMemberError(false);
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else {
        setLocalMemberError(true);
        error = true;
      }
      return error;
    }
    return true;
  };

  // eslint-disable-next-line max-len
  const submitBillboard = async (billboard: { billboard: CreateBillboardDTO | Partial<Billboard>, image?: File }) => {
    const newBoard = await dispatch(createBillboardAsync(billboard));
    submitDistribution(newBoard.payload.id);
    dispatch(nullSelectedBillboard());
    history.push('/content/manage/billboards');
  };

  const updateBillboard = async (billboard: { id: number, billboard: Partial<Billboard> }) => {
    const newBoard = await dispatch(updateBillboardAsync(billboard));
    submitDistribution(newBoard.payload.id);
    dispatch(nullSelectedBillboard());
    history.push('/content/manage/billboards');
  };

  const publishBillboard = () => {
    if (errorCheck() || (!file && !existingFileName)) {
      return;
    }
    if (distributionErrorCheck()) {
      return;
    }
    if (file && !editID) {
      const bbDTO: CreateBillboardDTO = {
        backgroundImageFilename: file.name,
        fileType: file.type,
        headline,
        color: headlineColor,
        buttonText,
        buttonLink: buttonURL,
        buttonPosition,
        startDate: startDate || new Date(),
        expirationDate: expirationDate || null,
        published: true,
        archived: false,
      };
      submitBillboard({ image: file, billboard: bbDTO });
    } else if (editID && file) {
      const bbDTO: CreateBillboardDTO = {
        id: editID,
        backgroundImageFilename: file.name,
        fileType: file.type,
        headline,
        color: headlineColor,
        buttonText,
        buttonLink: buttonURL,
        buttonPosition,
        startDate: startDate || new Date(),
        expirationDate: expirationDate || null,
        published: true,
        archived: false,
      };
      submitBillboard({ image: file, billboard: bbDTO });
    } else if (editID && !file && existingFileName) {
      const updateDTO: Partial<Billboard> = {
        headline,
        color: headlineColor,
        buttonText,
        buttonLink: buttonURL,
        buttonPosition,
        startDate,
        expirationDate: expirationDate || null,
        archived: false,
        published: true,
      };
      updateBillboard({ id: editID, billboard: updateDTO });
    }
  };

  const populateMemberTypeFilter = () => {
    const memberTypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      members
        .slice()
        .map((item) => item.entityType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull)
        .map((type) => {
          const option = {
            label: type,
            value: type,
          };
          memberTypeOptions.push(option);
        });
    }
    return memberTypeOptions;
  };

  const setMemberType = (array: SubtypeDropdown[]) => {
    if (distModel) {
      const specificMemberTypes: string[] = [];
      if (array && array.length > 0) {
        array.map((item) => {
          specificMemberTypes.push(item.value);
        });
      }
      setDistModel({ ...distModel, specificMemberTypes });
    }
  };

  const populateCategoryFilter = () => {
    const jobCategories: { label: string, value: string }[] = [];
    if (users) {
      const userList = users.slice();
      userList
        .filter((user) => user.jobCategory)
        .sort((a, b) => ((a.jobCategory ? a.jobCategory?.toLowerCase() : 'z') < (b.jobCategory ? b.jobCategory?.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-All`)) {
              jobCategories.push({
                label: `${user.jobCategory}-All`,
                value: `category=${user.jobCategory}&jobRole=All`,
              });
            }
          }
        });
      userList
        .filter((user) => user.jobCategory && user.jobRole)
        .sort((a, b) => ((a.jobRole ? a.jobRole.toLowerCase() : 'z') < (b.jobRole ? b.jobRole.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-${user.jobRole}`)) {
              jobCategories.push({
                label: `${user.jobCategory}-${user.jobRole}`,
                value: `category=${user.jobCategory}&jobRole=${user.jobRole}`,
              });
            }
          }
        });
      return jobCategories.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    }
    return [{
      label: 'All',
      value: 'All',
    }];
  };

  const setJobCategory = (dropDownArray: SubtypeDropdown[]) => {
    if (distModel) {
      const specificUserJobCategories: string[] = [];
      if (dropDownArray && dropDownArray.length > 0) {
        dropDownArray.map((arrayItem) => {
          specificUserJobCategories.push(arrayItem.value);
        });
      }
      setDistModel({ ...distModel, specificUserJobCategories });
    }
  };

  const handleStartDateChange = (event: any) => {
    setStartDate(new Date((event.target.value).replace(/-/g, '/')));
  };

  return (
    <Form noValidate>
      <div className="row create-billboard">
        <div className="col-8">
          <Card className="p-3">
            <Card.Body>
              <h2>Billboard</h2>
              <Form.Group controlId="backgroundImage">
                <Form.Label className="mt-2 mb-0">Background Image</Form.Label>
                <Form.Text muted>
                  File types include jpg, jpeg, png. Horizontal orientation, 1200px minimum width.
                </Form.Text>
                <div className="d-flex mt-2">
                  <Button className="upload-file-button mt-2">
                    Browse
                    <Form.File
                      multiple={false}
                      custom
                      type="file"
                      id="banner-file-upload"
                      feedback="Please attach a valid jpg, jpeg, or png."
                      accept=".jpg,.jpeg,.png"
                      isInvalid={fileError}
                      onChange={(e: any) => {
                        setFile(e.target.files[0]);
                        setExistingFileName('');
                        if (e.target.files[0]) {
                          setFileError(false);
                        }
                      }}
                    />
                  </Button>
                  {
                    file
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setFile(undefined)}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{file.name}</p>
                      </div>
                    )
                  }
                  {
                    !file
                    && existingFileName
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setExistingFileName('')}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{existingFileName}</p>
                      </div>
                    )
                  }
                </div>

              </Form.Group>
              <Form.Group controlId="title">
                <Form.Label className="mt-2">Headline Text</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter banner headline"
                  isInvalid={headlineError}
                  value={headline}
                  onChange={(e: any) => {
                    if (headline.length <= 100 || e.nativeEvent.inputType === 'deleteContentBackward') {
                      setHeadline(e.target.value);
                      setHeadlineError(false);
                    } else {
                      setHeadlineError(true);
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a banner headline up to 100 characters in length.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="color">
                <Form.Label className="mt-2">Color</Form.Label>
                <div>
                  <Form.Check checked={headlineColor === 'white'} onChange={(e) => setHeadlineColor(e.target.id)} inline label="White" name="color" type="radio" id="white" />
                  <Form.Check checked={headlineColor === 'black'} onChange={(e) => setHeadlineColor(e.target.id)} inline label="Black" name="color" type="radio" id="black" />
                </div>
              </Form.Group>
              <div className="row">
                <div className="col-4">
                  <Form.Group controlId="buttonText">
                    <Form.Label className="mt-2">Button Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter button text"
                      isInvalid={buttonError}
                      value={buttonText}
                      onChange={(e: any) => {
                        if (buttonText.length <= 75 || e.nativeEvent.inputType === 'deleteContentBackward') {
                          setButtonText(e.target.value);
                          setButtonError(false);
                        } else {
                          setButtonError(true);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter button text up to 75 characters in length.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-8">
                  <Form.Group controlId="title">
                    <Form.Label className="mt-2">Button Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter URL"
                      isInvalid={buttonError}
                      value={buttonURL}
                      onChange={(e: any) => {
                        setButtonURL(e.target.value);
                        setButtonError(false);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid URL
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <Form.Group controlId="color">
                <Form.Label className="mt-2">Button Position</Form.Label>
                <div>
                  <Form.Check checked={buttonPosition === 'bottom-left'} onChange={() => setButtonPosition('bottom-left')} inline label="Bottom Left" name="position" type="radio" id="bottom-left" />
                  <Form.Check checked={buttonPosition === 'bottom-center'} onChange={() => setButtonPosition('bottom-center')} inline label="Bottom Center" name="position" type="radio" id="bottom-center" />
                  <Form.Check checked={buttonPosition === 'bottom-right'} onChange={() => setButtonPosition('bottom-right')} inline label="Bottom Right" name="position" type="radio" id="bottom-right" />
                </div>
              </Form.Group>
            </Card.Body>
          </Card>
        </div>
        <div className="col-4">
          <Card className="p-3">
            <Card.Body>
              <div className="d-flex justify-content-between mb-2">
                <Button variant="secondary" onClick={submitBillboardDraft}>Save Draft</Button>
                <Button variant="primary" onClick={publishBillboard}>Publish</Button>
              </div>
              <div>
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    isInvalid={startDateError}
                    type="date"
                    placeholder="MM/DD/YYYY"
                    defaultValue={startDate && dateYearMonthDay(startDate)}
                    onChange={handleStartDateChange}
                  />
                  <FormControl.Feedback type="invalid">Please enter a start date.</FormControl.Feedback>
                </Form.Group>
              </div>
              <div>
                <Form.Group controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="MM/DD/YYYY" defaultValue={expirationDate ? dateYearMonthDay(expirationDate) : undefined} onChange={(e) => setExpirationDate(new Date((e.target.value).replace(/-/g, '/')))} />
                </Form.Group>
              </div>
              {
                distModel
                && (
                  <>
                    <div>
                      <Form.Group controlId="memberType">
                        <Form.Label>Audience</Form.Label>
                        <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'allMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="allMembers" label="Publish to all members" name="memberSelection" />
                        <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'specificMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="specificMembers" label="Publish to specific members" name="memberSelection" />
                        {
                          distModel.memberSelection === 'specificMembers'
                          && (
                            <div className="ml-4">
                              <Form.Group controlId="memberSelectInput">
                                <Form.Label>Select Member(s)</Form.Label>
                                <Select
                                  className="multi-select-dropdown"
                                  isMulti
                                  options={
                                    currentState.member.members
                                      .slice()
                                      .filter((member) => member.memberNumber !== 573)
                                      // eslint-disable-next-line max-len
                                      .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                                      .map((member) => (
                                        {
                                          label: member.memberName,
                                          value: member.id,
                                        }
                                      ))
                                  }
                                  onChange={
                                    (selectedOption: any) => populateMemberIdState(selectedOption)
                                  }
                                />
                              </Form.Group>
                            </div>
                          )
                        }
                        <Form.Check feedback="Please make a member selection" isInvalid={localMemberError} checked={distModel.memberSelection === 'selectedCriteria'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="selectedCriteria" label="Publish to members based on selected criteria" name="memberSelection" />
                        {
                          distModel.memberSelection === 'selectedCriteria'
                          && (
                            <div className="ml-4">
                              <Form.Group controlId="memberTypeDropdown">
                                <Form.Label>Member Type</Form.Label>
                                <Select
                                  className="multi-select-dropdown"
                                  isMulti
                                  options={populateMemberTypeFilter()}
                                  onChange={(newValue: any) => setMemberType(newValue)}
                                />
                              </Form.Group>
                              <Form.Group controlId="memberMaturityDropdown">
                                <Form.Label>Member Maturity Level</Form.Label>
                                <Select
                                  isDisabled={
                                    !distModel.specificMemberTypes
                                    || distModel.specificMemberTypes.length <= 0
                                  }
                                  className="multi-select-dropdown"
                                  isMulti
                                  options={populateMemberMaturityFilter(members, distModel)}
                                  onChange={(newValue: any) => populateMaturityState(newValue)}
                                />
                              </Form.Group>
                              <Form.Group controlId="memberSubtype">
                                <Form.Label>Sub-Type</Form.Label>
                                <Select
                                  isDisabled={
                                    (!distModel.specificMemberTypes
                                      || distModel.specificMemberTypes.length <= 0)
                                    || !distModel.specificMemberMaturityLevels
                                    || distModel.specificMemberMaturityLevels.length <= 0
                                  }
                                  className="multi-select-dropdown"
                                  isMulti
                                  options={populateMemberSubTypeFilter(members, distModel)}
                                  onChange={(newValue: any) => populateSubtypeState(newValue)}
                                />
                              </Form.Group>
                              <Form.Group controlId="userJobTitle">
                                <Form.Label>User Job Category</Form.Label>
                                <Select
                                  className="multi-select-dropdown"
                                  isMulti
                                  options={populateCategoryFilter()}
                                  onChange={(newValue: any) => setJobCategory(newValue)}
                                />
                              </Form.Group>
                            </div>
                          )
                        }
                      </Form.Group>
                    </div>
                    <div className="ml-4">
                      <Form.Group controlId="memberRole">
                        <Form.Label className="my-2">User Role</Form.Label>
                        <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryProgramContact: e.target.checked })} type="checkbox" id="primaryProgramContact" label="Primary Program Contacts" />
                        <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryIt: e.target.checked })} type="checkbox" id="primaryIT" label="Primary IT Contacts" />
                        <Form.Check feedback="Please make a user selection" isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, basicUser: e.target.checked })} type="checkbox" id="basicUser" label="Basic Users" />
                      </Form.Group>
                    </div>
                  </>
                )
              }
            </Card.Body>
          </Card>
        </div>
      </div>
    </Form>
  );
};

export default CreateBillboard;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createDocument,
  deleteDocument,
  getAllDocumentsList,
  getOneDocument,
} from '../api/documentsAPI';
import { putImageOnS3 } from '../api/s3API';
import { ChecklistAssignment } from './checklistAssignmentSlice';
import { Checklist } from './checklistSlice';
import { Member } from './memberSlice';
import { User } from './userSlice';

export interface DocumentDTO {
  id?: number;
  title: string;
  origin: string;
  fileName: string;
  fileType: string;
  s3Directory: 'memberDocs' | 'portalDocs';
  members: Member[] | null;
  memberIds?: number[];
  preAuthURL?: string;
  allMembers: boolean;
}

export interface Document {
  id: number;
  title: string;
  origin: string;
  fileName: string;
  fileType: string;
  s3Directory: 'memberDocs' | 'portalDocs';
  addedBy?: User;
  members: Member[] | null;
  preAuthURL?: string;
  docGetUrl?: string;
  createdAt: Date;
  checklistAssignment?: ChecklistAssignment;
  checklist?: Checklist;
  allMembers: boolean;
}

interface DocumentState {
  documents: Document[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: DocumentState = {
  documents: null,
  status: 'idle',
  error: null,
};

const createDocumentAsync = createAsyncThunk(
  'documents/create',
  async (arg: { documentData: DocumentDTO, file: File }, { rejectWithValue }) => {
    try {
      const response: any = await createDocument(arg.documentData);
      if (arg.file && response.data.preAuthURL) {
        await putImageOnS3(response.data.preAuthURL, arg.file);
        response.data.preAuthURL = null;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getAllDocumentsListAsync = createAsyncThunk(
  'documents/getAllList',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getAllDocumentsList();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneDocumentAsync = createAsyncThunk(
  'documents/getOne',
  async (docId: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneDocument(docId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteDocumentAsync = createAsyncThunk(
  'documents/delete',
  async (docId: number, { rejectWithValue }) => {
    try {
      const response: any = await deleteDocument(docId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDocumentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDocumentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createDocumentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getAllDocumentsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllDocumentsListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.documents = action.payload;
        state.error = null;
      })
      .addCase(getAllDocumentsListAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.documents = null;
        state.error = action.payload;
      })
      .addCase(getOneDocumentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneDocumentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(getOneDocumentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteDocumentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDocumentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteDocumentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default documentsSlice.reducer;

export {
  createDocumentAsync,
  getAllDocumentsListAsync,
  getOneDocumentAsync,
  deleteDocumentAsync,
};

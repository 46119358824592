import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReportingParty from '../contactForms/reportingParty';
import PrimaryContact from '../contactForms/primaryContact';
import ITContact from '../contactForms/itContact';
import Beazley from '../contactForms/beazley';
import Additional from '../contactForms/additional';
import { IrClaim, updateClaimTechnologyAsync } from '../../../../store/irClaimSlice';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  claim: IrClaim;
  atomIrUser: boolean;

}

const Contact: React.FC<Props> = ({ claim, atomIrUser }) => {
  const {
    id,
    member: { users },
    irContacts,
    technology,
  } = claim;
  const dispatch = useAppDispatch();

  const [debounceHandle, setDebounceHandle] = useState<any>();

  const updateShippingAddress = (info: any, body: string) => {
    if (info && body === 'equipmentCompany') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentCompany: info,
        },
      }));
    }
    if (info && body === 'equipmentAttention') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentAttention: info,
        },
      }));
    }
    if (info && body === 'equipmentAddress1') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentAddress1: info,
        },
      }));
    }
    if (info && body === 'equipmentAddress2') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentAddress2: info,
        },
      }));
    }
    if (info && body === 'equipmentState') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentState: info,
        },
      }));
    }
    if (info && body === 'equipmentCity') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentCity: info,
        },
      }));
    }
    if (info && body === 'equipmentZipCode') {
      dispatch(updateClaimTechnologyAsync({
        id: technology.id,
        updateBody: {
          equipmentZipCode: info,
        },
      }));
    }
  };
  return (
    <div className="contact-container">
      <div className="p-2 mt-4">
        <h3>Equipment Shipping Address</h3>
        <div className="d-flex row">
          <div className="col-8">
            <Form.Label className="mb-0 mt-3"> Company </Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentCompany || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-company"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentCompany'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-4">
            <Form.Label className="mb-0 mt-3"> Attention</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentAttention || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-attention"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentAttention'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="d-flex row">
          <div className="col-8">
            <Form.Label className="mb-0 mt-3"> Address 1</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentAddress1 || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-address-1"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentAddress1'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-4">
            <Form.Label className="mb-0 mt-3"> Address 2</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentAddress2 || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-address-2"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentAddress2'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="d-flex row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3"> City</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentCity || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-city"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentCity'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-2">
            <Form.Label className="mb-0 mt-3"> State</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentState || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-state"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentState'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-2">
            <Form.Label className="mb-0 mt-3"> Zip Code</Form.Label>
            <Form.Control
              defaultValue={technology?.equipmentZipCode || ''}
              disabled={!atomIrUser}
              type="text"
              id="shipping-zip-code"
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => updateShippingAddress(value, 'equipmentZipCode'), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
      </div>
      {(users && irContacts && id)
        && (
          <>
            {/* reporting party accordion */}
            <ReportingParty
              claim={claim}
              users={users}
              contact={irContacts.find((contact) => contact.contactType === 'reporting-party')}
              addContact={irContacts.find((contact) => contact.contactType === 'backup-reporting-contact')}
            />
            {/* Primary contact form */}
            <PrimaryContact
              claim={claim}
              users={users}
              contact={irContacts.find((contact) => contact.contactType === 'primary-contact')}
              addContact={irContacts.find((contact) => contact.contactType === 'backup-primary-contact')}
            />
            {/* IT contact form */}
            <ITContact
              claim={claim}
              users={users}
              contact={irContacts.find((contact) => contact.contactType === 'it-contact')}
              addContact={irContacts.find((contact) => contact.contactType === 'backup-it-contact')}
            />
            {/* Beazley information */}
            <Beazley
              claim={claim}
              contact={irContacts.find((contact) => contact.contactType === 'beazley-contact')}
              counselContact={irContacts.find((contact) => contact.contactType === 'beazley-breach-counsel')}
            />
            {/* Additional contacts */}
            <Additional
              claim={claim}
              contact={irContacts.find((contact) => contact.contactType === 'members-attorney')}
              alliantContact={irContacts.find((contact) => contact.contactType === 'alliant-contact')}
              otherContact={irContacts.find((contact) => contact.contactType === 'other-contact')}
            />
          </>
        )}
    </div>
  );
};

export default Contact;

import React, { useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { dateAndTime } from '../../../../service/timeAndDate';
import {
  createPrivateCommunicationAsync,
  getOneClaimAsync,
  IrClaim,
  IrPrivateCommunication,
} from '../../../../store/irClaimSlice';

interface Props {
  claim: IrClaim;
  privateComm: IrPrivateCommunication[];
}

const PrivateComments: React.FC<Props> = ({ claim, privateComm }) => {
  const [comment, setComment] = useState('');
  const { id: claimId } = claim;
  const dispatch = useDispatch();

  const submitComment = async () => {
    const commentObj = ({
      comment,
      irClaimId: claimId,
    });
    await dispatch(createPrivateCommunicationAsync(commentObj));
    await dispatch(getOneClaimAsync(claimId));
    await setComment('');
  };

  return (
    <div className="mt-5">
      <h3>
        Comments&nbsp;
        <span className="number-font">
          (
          {privateComm.length}
          )
        </span>
      </h3>
      <div className="mt-3">
        <Form.Control
          as="textarea"
          rows={2}
          value={comment}
          placeholder="Add a comment"
          onChange={(e) => setComment(e.currentTarget.value)}
        />
        <div className="mt-3 d-flex justify-content-end">
          <Button
            variant="secondary"
            type="button"
            onClick={() => submitComment()}
            disabled={!comment}
          >
            Submit
          </Button>
        </div>
      </div>
      <div>
        <div className="updates-comments">
          {(privateComm.length > 0)
            && privateComm.map((update) => (
              <Table bordered className="col-12 mt-5">
                <thead className="updates-comment-title">
                  <tr>
                    <th className="d-flex justify-content-between">
                      <span>
                        {update.author.role === 400
                          ? (`${update.author.firstName} ${update.author.lastName} (#${update.author.id}) - ATOM`)
                          : (`${update.author.firstName} ${update.author.lastName} - Primex`)}
                      </span>
                      <span>{dateAndTime(update.createdAt)}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {update.comment}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrivateComments;

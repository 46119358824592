import React from 'react';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

interface Props { }

const DocumentNav: React.FC<Props> = ({
  children,
}) => (
  <>
    <div className="row mx-0 pb-2 align-items-center nav-row">
      <div className="col-8">
        <Nav variant="tabs" className="m-0">
          <Nav.Item>
            <NavLink exact to="/documents/member">Member Documents</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink exact to="/documents/portal">Cyber Portal Documents</NavLink>
          </Nav.Item>
        </Nav>
      </div>
    </div>
    <div className="row row-no-gutters">
      <div className="col-12">
        {children}
      </div>
    </div>
  </>
);

export default DocumentNav;

import React from 'react';
import Layout from '../layouts/Layout';

interface Props {}

const PageNotFound: React.FC<Props> = () => (
  <Layout>
    <div className="dashboard-container">
      <h1>Page Not Found</h1>
    </div>
  </Layout>
);

export default PageNotFound;

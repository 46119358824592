import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPostArticleAssignments,
  deleteMultiplePostArticleAssignments,
  getCurrentPostArticleAssignments,
  updatePostArticleAssignment,
} from '../api/postArticleAssignmentAPI';
import { PostArticleDistributionModel } from '../models/distributionModels';
import { PostArticle } from './postArticleSlice';
import { User } from './userSlice';

export interface PostArticleAssignment {
  id: number;
  viewed: boolean;
  viewedDate: Date | null;
  postArticle: PostArticle;
  user: User;
  createdAt: Date;
}

interface PostArticleAssignmentState {
  currentPostArticleAssignments: PostArticleAssignment[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: PostArticleAssignmentState = {
  currentPostArticleAssignments: null,
  status: 'idle',
  error: null,
};

const getCurrentPostArticleAssignmentsAsync = createAsyncThunk(
  'postArticleAssignments/getCurrent',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentPostArticleAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createPostArticleAssignmentsAsync = createAsyncThunk(
  'postArticleAssignments/create',
  async (arg: PostArticleDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createPostArticleAssignments(arg);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultiplePostArticleAssignmentsAsync = createAsyncThunk(
  'postArticleAssignments/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultiplePostArticleAssignments(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updatePostArticleAssignmentAsync = createAsyncThunk(
  'postArticleAssignments/update',
  async (arg: { id: number, assignment: Partial<PostArticleAssignment>}, { rejectWithValue }) => {
    try {
      const response: any = await updatePostArticleAssignment(arg.id, arg.assignment);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const postArticleAssignmentSlice = createSlice({
  name: 'postArticleAssignments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPostArticleAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPostArticleAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createPostArticleAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCurrentPostArticleAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentPostArticleAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentPostArticleAssignments = action.payload;
        state.error = null;
      })
      .addCase(getCurrentPostArticleAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteMultiplePostArticleAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultiplePostArticleAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultiplePostArticleAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updatePostArticleAssignmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePostArticleAssignmentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updatePostArticleAssignmentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default postArticleAssignmentSlice.reducer;

export {
  createPostArticleAssignmentsAsync,
  getCurrentPostArticleAssignmentsAsync,
  deleteMultiplePostArticleAssignmentsAsync,
  updatePostArticleAssignmentAsync,
};

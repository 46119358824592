import { AxiosPromise } from 'axios';
import { Questionnaire } from '../store/questionnaireSlice';
import api, { formUploadApi } from './api';

const FormData = require('form-data');

export function getAllQuestionnaires() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get('/questionnaires')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getQuestionnaire(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/questionnaires/${+id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function startQuestionnaireSession(questionnaireAssignments: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/questionnaire-sessions', { questionnaireAssignments })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getQuestionnaireSession(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/questionnaire-sessions/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateQuestionnaireSession(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/questionnaire-sessions/${id}`, {})
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function submitAnswer(answerId: number, answer: string) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/answers/${answerId}`, { answer })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// eslint-disable-next-line max-len
export function submitQuestionnaire(questionnaireId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/questionnaire-sessions/${questionnaireId}/complete`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function postQuestionnaire(questionnaire: File, title: string) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      const data = new FormData();
      data.append('template', questionnaire, questionnaire.name);
      data.append('name', title);

      formUploadApi
        .post('/questionnaires/engine', data)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteQuestionnaire(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete(`/questionnaires/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateQuestionaire(id: number, questionnaireBody: Partial<Questionnaire>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/questionnaires/${id}`, questionnaireBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

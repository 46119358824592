/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch } from '../../../../store/hooks';
import { getAllQuestionnairesAsync, updateQuestionnaireAsync } from '../../../../store/questionnaireSlice';

interface Questionnaire {
  id: number;
  name: string;
  questionnaireEngineTemplateId: number;
  questionnaireEngineName: string;
  questionnaireEngineVideo: string;
  questionnaireEngineExplanation: string;
  questionnaireSessions: any[];
  questions: any[];
}

interface Props {
  currentQuestionnaire: Questionnaire | null;
}

const UploadQuestionnaire: React.FC<Props> = (props: Props) => {
  const { currentQuestionnaire } = props;
  const [show, setShow] = useState<boolean>(false);
  const [title, setTitle] = useState<string | null>('');
  const [titleError, setTitleError] = useState<boolean>(false);
  const [successfulUpload, setSuccessfulUpload] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setTitle(null);
    setSuccessfulUpload(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const submitUpload = async (id: number) => {
    if (title && currentQuestionnaire) {
      if (currentQuestionnaire.name.toLowerCase().replace(/\s/g, '') === title.toLowerCase().replace(/\s/g, '')) {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (!titleError) {
        const response = await dispatch(
          updateQuestionnaireAsync({ id, questionnaireBody: { name: title } }),
        );
        if (response) {
          if (response.meta.requestStatus === 'rejected') {
            setUploadErrorMessage(response.payload.data || '');
          } else {
            setUploadErrorMessage('');
            setSuccessfulUpload(true);
          }
        }
        dispatch(getAllQuestionnairesAsync());
      }
    } else {
      setTitleError(true);
    }
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>
        Rename
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="mb-0">
              {!successfulUpload && 'Rename Questionnaire'}
              {successfulUpload && 'Title Uploaded'}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !successfulUpload
            && currentQuestionnaire
            && (
              <>
                <Form
                  id="file-upload-form"
                  className="d-flex flex-column"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitUpload(currentQuestionnaire.id);
                  }}
                >
                  <Form.Group controlId="fileTitle">
                    <Form.Label>Title</Form.Label>
                    <InputGroup>
                      <Form.Control type="text" placeholder={currentQuestionnaire?.name} required isInvalid={titleError} onChange={(e: any) => setTitle(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        Please enter a unique title.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </>
            )
          }
          {
            successfulUpload
            && currentQuestionnaire
            && (
              <div className="uploaded-modal p-2">
                <div>
                  <span className="ml-3">Questionnaire was successfully renamed.</span>
                </div>
                <div className="mt-3">
                  <h2>{currentQuestionnaire.name}</h2>
                </div>
              </div>
            )
          }
          {(uploadErrorMessage || '').length > 0 ? (
            <Alert variant="danger">
              {uploadErrorMessage}
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {
            !successfulUpload
            && (
              <>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" form="file-upload-form">
                  Save
                </Button>
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadQuestionnaire;

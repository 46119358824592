/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import DeleteIcon from '../../../../assets/icons/remove.svg';
import { dateYearMonthDay } from '../../../../service/timeAndDate';
import { DistributionUniversal, PostArticleDistributionModel } from '../../../../models/distributionModels';
import { getMembersAsync } from '../../../../store/memberSlice';
import {
  PostArticle,
  CreatePostArticleDTO,
  createPostArticleAsync,
  updatepostArticleAsync,
  nullSelectedPostArticle,
} from '../../../../store/postArticleSlice';
import { createPostArticleAssignmentsAsync } from '../../../../store/postArticleAssignmentSlice';
import { populateMemberMaturityFilter, populateMemberSubTypeFilter } from '../../../../service/distributionHelpers';

interface Props {
  selectedPostArticle: PostArticle | null;
}

interface MemberDropdown {
  value: number;
  name: string;
}

interface SubtypeDropdown {
  value: string;
  name: string;
}

const CreatePostArticle: React.FC<Props> = ({ selectedPostArticle }) => {
  const [editID, setEditID] = useState<number | undefined>(selectedPostArticle?.id || undefined);
  // eslint-disable-next-line max-len
  const [existingFileName, setExistingFileName] = useState(selectedPostArticle?.thumbnailFilename || '');
  const [existingPdfFileName, setExistingPdfFileName] = useState(selectedPostArticle?.pdfFilename || '');
  const [memberSelection, setMemberSelection] = useState('allMembers');

  const [file, setFile] = useState<File>();
  const [pdfFile, setPdfFile] = useState<File>();
  const [title, setTitle] = useState(selectedPostArticle?.title || '');
  const [summary, setSummary] = useState(selectedPostArticle?.summary || '');
  const [quillContent, setQuillContent] = useState(selectedPostArticle?.content || '');
  // eslint-disable-next-line max-len
  const [displayWeeklyEmail, setDisplayWeeklyEmail] = useState(selectedPostArticle?.displayWeeklyEmail || false);
  // eslint-disable-next-line max-len
  const [displayDashboardWidget, setDisplayDashboardWidget] = useState(selectedPostArticle?.displayDashboardWidget || false);
  // eslint-disable-next-line max-len
  const [displayDashboardCarousel, setDisplayDashboardCarousel] = useState(selectedPostArticle?.displayDashboardCarousel || false);
  // eslint-disable-next-line max-len
  const [startDate, setStartDate] = useState<Date | undefined>(selectedPostArticle?.startDate || undefined);
  const [distModel, setDistModel] = useState<DistributionUniversal>();
  // eslint-disable-next-line max-len
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(selectedPostArticle?.expirationDate || undefined);

  const [titleError, setTitleError] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [contentError, setContentError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);

  const [localUserError, setLocalUserError] = useState(false);
  const [localMemberError, setLocalMemberError] = useState(false);

  const currentState = useAppSelector((state) => ({
    member: state.member,
    user: state.user,
  }));

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { members } = currentState.member;
  const { users } = currentState.user;

  useEffect(() => {
    dispatch(getMembersAsync());
    if (!distModel) {
      setDistModel({
        allMembers: false,
        userOnly: false,
        specificMemberIds: [],
        specificMemberTypes: [],
        specificMemberMaturityLevels: [],
        specificMemberSubTypes: [],
        primaryProgramContact: false,
        primaryIt: false,
        basicUser: false,
        specificUserJobCategories: [],
        dueDate: null,
        memberSelection: '',
        userError: false,
        dateError: false,
        memberError: false,
        selectedUserMember: null,
      });
    }
  }, []);

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberIds: memberIds });
    }
  };

  const populateMaturityState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberMaturityLevels: subtypes });
    }
  };

  const populateSubtypeState = (array: SubtypeDropdown[]) => {
    const subtypes: string[] = [];
    array.map((memberObject) => (
      subtypes.push(memberObject.value)
    ));
    if (distModel) {
      setDistModel({ ...distModel, specificMemberSubTypes: subtypes });
    }
  };

  const submitDistribution = (articleId: number) => {
    if (distModel && articleId) {
      const articleDistDto: PostArticleDistributionModel = {
        postArticleId: articleId,
        ...distModel,
      };
      if (distModel.memberSelection === 'specificMembers') {
        articleDistDto.allMembers = false;
        articleDistDto.specificMemberTypes = null;
        articleDistDto.specificMemberSubTypes = null;
      } else if (distModel.memberSelection === 'selectedCriteria') {
        articleDistDto.allMembers = false;
        articleDistDto.specificMemberIds = null;
      } else if (distModel.memberSelection === 'allMembers') {
        articleDistDto.allMembers = true;
        articleDistDto.specificMemberIds = null;
        articleDistDto.specificMemberTypes = null;
        articleDistDto.specificMemberSubTypes = null;
      }
      dispatch(createPostArticleAssignmentsAsync(articleDistDto));
    }
  };

  const submitArticlePostDraft = async () => {
    if (!editID) {
      const articleDTO: CreatePostArticleDTO = {
        title,
        summary,
        content: quillContent,
        thumbnailFilename: file ? file.name : null,
        pdfFilename: pdfFile ? pdfFile.name : null,
        displayWeeklyEmail,
        displayDashboardWidget,
        displayDashboardCarousel,
        published: false,
        publishedDate: null,
        archived: false,
        startDate: startDate || null,
        expirationDate: expirationDate || null,
      };
      await dispatch(createPostArticleAsync({
        postArticle: articleDTO,
        files: {
          image: file,
          pdf: pdfFile,
        },
      }));
      dispatch(nullSelectedPostArticle());
      history.push('/content/manage/postarticle');
    } else if (editID) {
      const updateArticleDTO: CreatePostArticleDTO = {
        id: editID,
        title,
        summary,
        content: quillContent,
        thumbnailFilename: file ? file.name : existingFileName,
        pdfFilename: pdfFile ? pdfFile.name : existingPdfFileName,
        displayWeeklyEmail,
        displayDashboardWidget,
        displayDashboardCarousel,
        published: false,
        // eslint-disable-next-line max-len
        publishedDate: selectedPostArticle && selectedPostArticle.publishedDate ? selectedPostArticle.publishedDate : null,
        archived: false,
        startDate: startDate || null,
        expirationDate: expirationDate || null,
      };
      await dispatch(createPostArticleAsync({
        postArticle: updateArticleDTO,
        files: {
          image: file,
          pdf: pdfFile,
        },
      }));
      dispatch(nullSelectedPostArticle());
      history.push('/content/manage/postarticle');
    }
  };

  const errorCheck = () => {
    let error = false;
    if (!title) {
      setTitleError(true);
      error = true;
    } else {
      setTitleError(false);
    }

    if (!startDate) {
      setStartDateError(true);
      error = true;
    } else {
      setStartDateError(false);
    }

    if (!summary) {
      setSummaryError(true);
      error = true;
    } else {
      setSummaryError(false);
    }

    if (!quillContent) {
      setContentError(true);
      error = true;
    } else {
      setContentError(false);
    }
    return error;
  };

  const distributionErrorCheck = () => {
    if (
      selectedPostArticle
      && editID
      && selectedPostArticle.assignments
      && selectedPostArticle.assignments.length > 0
    ) {
      return false;
    }
    if (distModel) {
      let error = false;
      if (distModel.memberSelection === 'specificMembers') {
        if (distModel.specificMemberIds && distModel.specificMemberIds.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'selectedCriteria') {
        if (distModel.specificMemberTypes && distModel.specificMemberTypes.length > 0) {
          setLocalMemberError(false);
        } else {
          setLocalMemberError(true);
          error = true;
        }
        if (distModel.specificUserJobCategories && distModel.specificUserJobCategories.length > 0) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else if (distModel.memberSelection === 'allMembers') {
        setLocalMemberError(false);
        if (
          distModel.basicUser
          || distModel.primaryIt
          || distModel.primaryProgramContact
        ) {
          setLocalUserError(false);
        } else {
          setLocalUserError(true);
          error = true;
        }
      } else {
        setLocalMemberError(true);
        error = true;
      }
      return error;
    }
    return true;
  };

  const publishPostArticle = async () => {
    let publishedArtId: number | null = null;
    if (distributionErrorCheck()) {
      return;
    }
    if (!editID) {
      const articleDTO: CreatePostArticleDTO = {
        title,
        summary,
        content: quillContent,
        thumbnailFilename: file ? file.name : existingFileName,
        pdfFilename: pdfFile ? pdfFile.name : existingPdfFileName,
        displayWeeklyEmail,
        displayDashboardWidget,
        displayDashboardCarousel,
        published: true,
        publishedDate: new Date(),
        archived: false,
        startDate: startDate || null,
        expirationDate: expirationDate || null,
      };
      // eslint-disable-next-line max-len
      const res = await dispatch(createPostArticleAsync({
        postArticle: articleDTO,
        files: {
          image: file,
          pdf: pdfFile,
        },
      }));
      if (res.payload.id) {
        publishedArtId = res.payload.id;
      }
    } else if (editID) {
      const updateArticleDTO: CreatePostArticleDTO = {
        id: editID,
        title,
        summary,
        content: quillContent,
        thumbnailFilename: file ? file.name : existingFileName,
        pdfFilename: pdfFile ? pdfFile.name : existingPdfFileName,
        displayWeeklyEmail,
        displayDashboardWidget,
        displayDashboardCarousel,
        published: true,
        // eslint-disable-next-line max-len
        publishedDate: selectedPostArticle && selectedPostArticle.publishedDate ? selectedPostArticle.publishedDate : new Date(),
        archived: false,
        startDate: startDate || null,
        expirationDate: expirationDate || null,
      };
      // eslint-disable-next-line max-len
      const res = await dispatch(createPostArticleAsync({
        postArticle: updateArticleDTO,
        files: {
          image: file,
          pdf: pdfFile,
        },
      }));
      if (res.payload.id) {
        publishedArtId = res.payload.id;
      }
    }
    if (publishedArtId) {
      submitDistribution(publishedArtId);
    }
    dispatch(nullSelectedPostArticle());
    history.push('/content/manage/postarticle');
  };

  const populateMemberTypeFilter = () => {
    const memberTypeOptions: { label: string, value: string }[] = [{
      label: 'All',
      value: 'All',
    }];
    if (members) {
      members
        .slice()
        .map((item) => item.entityType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull)
        // eslint-disable-next-line array-callback-return
        .map((type) => {
          const option = {
            label: type,
            value: type,
          };
          memberTypeOptions.push(option);
        });
    }
    return memberTypeOptions;
  };

  const setMemberType = (array: SubtypeDropdown[]) => {
    if (distModel) {
      const specificMemberTypes: string[] = [];
      if (array && array.length > 0) {
        // eslint-disable-next-line array-callback-return
        array.map((item) => {
          specificMemberTypes.push(item.value);
        });
      }
      setDistModel({ ...distModel, specificMemberTypes });
    }
  };

  const populateCategoryFilter = () => {
    const jobCategories: { label: string, value: string }[] = [];
    if (users) {
      const userList = users.slice();
      userList
        .filter((user) => user.jobCategory)
        .sort((a, b) => ((a.jobCategory ? a.jobCategory?.toLowerCase() : 'z') < (b.jobCategory ? b.jobCategory?.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-All`)) {
              jobCategories.push({
                label: `${user.jobCategory}-All`,
                value: `category=${user.jobCategory}&jobRole=All`,
              });
            }
          }
        });
      userList
        .filter((user) => user.jobCategory && user.jobRole)
        .sort((a, b) => ((a.jobRole ? a.jobRole.toLowerCase() : 'z') < (b.jobRole ? b.jobRole.toLowerCase() : 'z') ? -1 : 1))
        .map((user) => {
          if (user.jobCategory) {
            if (!jobCategories.find((cat) => cat.label === `${user.jobCategory}-${user.jobRole}`)) {
              jobCategories.push({
                label: `${user.jobCategory}-${user.jobRole}`,
                value: `category=${user.jobCategory}&jobRole=${user.jobRole}`,
              });
            }
          }
        });
      return jobCategories.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
    }
    return [{
      label: 'All',
      value: 'All',
    }];
  };

  const setJobCategory = (dropDownArray: SubtypeDropdown[]) => {
    if (distModel) {
      const specificUserJobCategories: string[] = [];
      if (dropDownArray && dropDownArray.length > 0) {
        dropDownArray.map((arrayItem) => {
          specificUserJobCategories.push(arrayItem.value);
        });
      }
      setDistModel({ ...distModel, specificUserJobCategories });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align',
  ];
  return (
    <div className="row create-post-article">
      <div className="col-8">
        <Card className="p-3">
          <Card.Body>
            <h2>Post / Article</h2>
            <Form>
              <Form.Group controlId="title">
                <Form.Label className="mt-2">Title</Form.Label>
                <Form.Control
                  isInvalid={titleError}
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
                <FormControl.Feedback type="invalid">Please enter a title.</FormControl.Feedback>
              </Form.Group>
              <Form.Group controlId="post-article-textarea">
                <Form.Label>Summary</Form.Label>
                <Form.Control maxLength={255} isInvalid={summaryError} value={summary} onChange={(e) => setSummary(e.target.value)} as="textarea" rows={3} />
                <p className="character-count">
                  {`(${summary.length} of 255 characters)`}
                </p>
                <FormControl.Feedback type="invalid">Please enter a summary.</FormControl.Feedback>
              </Form.Group>
              <Form.Group controlId="message" className="quill-box-content mb-0">
                <Form.Label>Content</Form.Label>
                <ReactQuill
                  className="quill-box"
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={quillContent}
                  onChange={setQuillContent}
                />
              </Form.Group>
              <Form.Group controlId="backgroundImage">
                <Form.Label className="mt-2 mb-0">Thumbnail Image</Form.Label>
                <div className="d-flex mt-2">
                  <Button className="upload-file-button">
                    Browse
                    <Form.File
                      multiple={false}
                      custom
                      type="file"
                      id="banner-file-upload"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e: any) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  {
                    file
                    && file.name
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setFile(undefined)}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{file.name}</p>
                      </div>
                    )
                  }
                  {
                    !file
                    && existingFileName
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setExistingFileName('')}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{existingFileName}</p>
                      </div>
                    )
                  }
                </div>
              </Form.Group>
              <Form.Group controlId="attachedPdf">
                <Form.Label className="mt-2 mb-0">Attach PDF</Form.Label>
                <div className="d-flex mt-2">
                  <Button className="upload-file-button">
                    Browse
                    <Form.File
                      multiple={false}
                      custom
                      type="file"
                      id="pdf-upload"
                      accept=".pdf"
                      onChange={(e: any) => {
                        setPdfFile(e.target.files[0]);
                      }}
                    />
                  </Button>
                  {
                    pdfFile
                    && pdfFile.name
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setPdfFile(undefined)}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{pdfFile.name}</p>
                      </div>
                    )
                  }
                  {
                    !pdfFile
                    && existingPdfFileName
                    && (
                      <div className="d-flex flex-row ml-2">
                        <Button className="button-link d-flex align-items-center" onClick={() => setExistingPdfFileName('')}>
                          <img src={DeleteIcon} alt="Delete Icon" />
                        </Button>
                        <p className="m-0 align-self-center">{existingFileName}</p>
                      </div>
                    )
                  }
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <div className="col-4">
        <Card className="p-3">
          <Card.Body>
            <div className="d-flex justify-content-between mb-2">
              <Button variant="secondary" onClick={submitArticlePostDraft}>Save Draft</Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!errorCheck()) {
                    publishPostArticle();
                  }
                }}
              >
                Publish
              </Button>
            </div>
            <div>
              <Form.Group controlId="memberRole">
                <Form.Label className="my-2">Display</Form.Label>
                <Form.Check className="my-2" checked={displayWeeklyEmail} onChange={(e) => setDisplayWeeklyEmail(e.target.checked)} type="checkbox" id="weeklyEmail" label="Weekly Summary Email" />
                <Form.Check className="my-2" checked={displayDashboardWidget} onChange={(e) => setDisplayDashboardWidget(e.target.checked)} type="checkbox" id="dashboardWidget" label="Dashboard Widget" />
                <Form.Check className="my-2" checked={displayDashboardCarousel} onChange={(e) => setDisplayDashboardCarousel(e.target.checked)} type="checkbox" id="dashboardCarousel" label="Dashboard Carousel" />
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control isInvalid={startDateError} type="date" placeholder="MM/DD/YYYY" defaultValue={startDate ? dateYearMonthDay(startDate) : undefined} onChange={(e) => setStartDate(new Date((e.target.value).replace(/-/g, '/')))} />
                <FormControl.Feedback type="invalid">Please enter a start date.</FormControl.Feedback>
              </Form.Group>
            </div>
            <div>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" placeholder="MM/DD/YYYY" defaultValue={expirationDate ? dateYearMonthDay(expirationDate) : undefined} onChange={(e) => setExpirationDate(new Date((e.target.value).replace(/-/g, '/')))} />
              </Form.Group>
            </div>
            {
              distModel
              && (
                <>
                  <div>
                    <Form.Group controlId="memberType">
                      <Form.Label>Audience</Form.Label>
                      <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'allMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="allMembers" label="Publish to all members" name="memberSelection" />
                      <Form.Check isInvalid={localMemberError} checked={distModel.memberSelection === 'specificMembers'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="specificMembers" label="Publish to specific members" name="memberSelection" />
                      {
                        distModel.memberSelection === 'specificMembers'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberSelectInput">
                              <Form.Label>Select Member(s)</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={
                                  currentState.member.members
                                    .slice()
                                    .filter((member) => member.memberNumber !== 573)
                                    // eslint-disable-next-line max-len
                                    .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                                    .map((member) => (
                                      {
                                        label: member.memberName,
                                        value: member.id,
                                      }
                                    ))
                                }
                                onChange={
                                  (selectedOption: any) => populateMemberIdState(selectedOption)
                                }
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                      <Form.Check feedback="Please make a member selection" isInvalid={localMemberError} checked={distModel.memberSelection === 'selectedCriteria'} className="my-2" onChange={(e) => setDistModel({ ...distModel, memberSelection: e.target.id })} type="radio" id="selectedCriteria" label="Publish to members based on selected criteria" name="memberSelection" />
                      {
                        distModel.memberSelection === 'selectedCriteria'
                        && (
                          <div className="ml-4">
                            <Form.Group controlId="memberTypeDropdown">
                              <Form.Label>Member Type</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberTypeFilter()}
                                onChange={(newValue: any) => setMemberType(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberMaturityDropdown">
                              <Form.Label>Member Maturity Level</Form.Label>
                              <Select
                                isDisabled={
                                  !distModel.specificMemberTypes
                                  || distModel.specificMemberTypes.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberMaturityFilter(members, distModel)}
                                onChange={(newValue: any) => populateMaturityState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>Sub-Type</Form.Label>
                              <Select
                                isDisabled={
                                  (!distModel.specificMemberTypes
                                    || distModel.specificMemberTypes.length <= 0)
                                  || !distModel.specificMemberMaturityLevels
                                  || distModel.specificMemberMaturityLevels.length <= 0
                                }
                                className="multi-select-dropdown"
                                isMulti
                                options={populateMemberSubTypeFilter(members, distModel)}
                                onChange={(newValue: any) => populateSubtypeState(newValue)}
                              />
                            </Form.Group>
                            <Form.Group controlId="memberSubtype">
                              <Form.Label>User Job Category</Form.Label>
                              <Select
                                className="multi-select-dropdown"
                                isMulti
                                options={populateCategoryFilter()}
                                onChange={(newValue: any) => setJobCategory(newValue)}
                              />
                            </Form.Group>
                          </div>
                        )
                      }
                    </Form.Group>
                  </div>
                  <div className="ml-4">
                    <Form.Group controlId="memberRole">
                      <Form.Label className="my-2">User Role</Form.Label>
                      <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryProgramContact: e.target.checked })} type="checkbox" id="primaryProgramContact" label="Primary Program Contacts" />
                      <Form.Check isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, primaryIt: e.target.checked })} type="checkbox" id="primaryIT" label="Primary IT Contacts" />
                      <Form.Check feedback="Please make a user selection" isInvalid={localUserError} className="my-2" onChange={(e) => setDistModel({ ...distModel, basicUser: e.target.checked })} type="checkbox" id="basicUser" label="Basic Users" />
                    </Form.Group>
                  </div>
                </>
              )
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CreatePostArticle;

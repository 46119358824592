import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { getCurrentChecklistAssignmentsAsync } from '../../store/checklistAssignmentSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import ChecklistDetail from '../shared/checklists/ChecklistDetail';
import ChecklistTable from '../shared/checklists/ChecklistTable';
import ChecklistMultiSession from '../shared/checklists/components/ChecklistMultiSession';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';

interface Props { }

const Checklists: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCurrentChecklistAssignmentsAsync());
  }, []);
  const currentState = useAppSelector((state) => ({
    checklistAssignments: state.checklistAssignments,
  }));
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>Checklists</h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="checklists-table-container">
              <Switch>
                <Route exact path="/checklists">
                  <ChecklistTable />
                </Route>
                <Route exact path="/checklists/multi/:id">
                  <ChecklistMultiSession />
                </Route>
                <Route exact path="/checklists/:id">
                  <ChecklistDetail />
                </Route>
              </Switch>
            </Card>
          </div>
        </div>
        <Switch>
          <Route path={['/checklists/:id', '/checklists/multi/:id']}>
            <div className="mt-4">
              {
                currentState.checklistAssignments
                && currentState.checklistAssignments.selectedAssignment
                && (
                <GlobalMessage
                  flaggedChecklistAssignmentId={
                    currentState.checklistAssignments.selectedAssignment.id
                  }
                >
                  Need help with this checklist? Send a message to Primex.
                </GlobalMessage>

                )
              }
            </div>
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default Checklists;

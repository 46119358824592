import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createChecklist,
  deleteMultiplechecklists,
  getAllChecklists,
  getOneChecklist,
  updateChecklist,
} from '../api/checklistAPI';
import { putImageOnS3 } from '../api/s3API';
import { ChecklistAssignment } from './checklistAssignmentSlice';
import { Document, DocumentDTO } from './documentsSlice';
import { User } from './userSlice';

interface ChecklistItemDTO {
  id?: number;
  order: number;
  itemTitle?: string;
  supportingInfo?: string;
}

export interface ChecklistItem {
  id: number;
  order: number;
  itemTitle: string;
  supportingInfo: string;
}

export interface ChecklistDTO {
  id?: number;
  title: string;
  summary: string;
  additionalInfo: string;
  published?: boolean;
  publishedDate?: Date;
  archived?: boolean;
  startDate?: Date | null;
  dueDate?: Date | null;
  checklistItems: ChecklistItemDTO[];
  documents?: DocumentDTO[];
  displayWeeklyEmail: boolean;
  displayDashboardWidget: boolean;
  displayDashboardCarousel: boolean;
}

export interface Checklist {
  id: number;
  title: string;
  summary: string;
  additionalInfo: string;
  published: boolean;
  publishedDate: Date;
  archived: boolean;
  startDate: Date | null;
  dueDate: Date | null;
  checklistItems: ChecklistItem[];
  documents?: Document[];
  assignments?: ChecklistAssignment[];
  assignmentDetails: string | null;
  displayWeeklyEmail: boolean;
  displayDashboardWidget: boolean;
  displayDashboardCarousel: boolean;
  createdAt: Date;
  author: User;
}

interface ChecklistState {
  checklists: Checklist[] | null;
  selectedChecklist: Checklist | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: ChecklistState = {
  checklists: null,
  selectedChecklist: null,
  status: 'idle',
  error: null,
};

const getAllChecklistsAsync = createAsyncThunk(
  'checklists/getAllChecklists',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getAllChecklists();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createChecklistAsync = createAsyncThunk(
  'checklists/createChecklist',
  async (arg: {
    checklistDTO: ChecklistDTO | Partial<Checklist>, uploads?: File[]
  }, { rejectWithValue }) => {
    try {
      const response: any = await createChecklist(arg.checklistDTO);
      if (
        response.data.documents
        && response.data.documents.length > 0
      ) {
        await Promise.all(
          response.data.documents.map(async (doc: Document, index: number) => {
            if (arg.uploads && arg.uploads.length > 0) {
              const file: File | undefined = arg.uploads.find(
                (upload) => upload.name === doc.fileName,
              );
              if (file && doc.preAuthURL) {
                await putImageOnS3(doc.preAuthURL, file).catch((e) => e);
                response.data.documents[index].preAuthURL = null;
              }
            }
          }),
        );
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneChecklistAsync = createAsyncThunk(
  'checklists/getOne',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneChecklist(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleChecklistsAsync = createAsyncThunk(
  'checklists/deleteMultiple',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultiplechecklists(ids);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateChecklistAsync = createAsyncThunk(
  'checklists/update',
  async (arg: {
    id: number,
    updateBody: Partial<Checklist> | ChecklistDTO,
    uploads?: File[],
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateChecklist(arg.id, arg.updateBody);
      if (
        response.data.documents
        && response.data.documents.length > 0
      ) {
        await Promise.all(
          response.data.documents.map(async (doc: Document, index: number) => {
            if (arg.uploads && arg.uploads.length > 0) {
              const file: File | undefined = arg.uploads.find(
                (upload) => upload.name === doc.fileName,
              );
              if (file && doc.preAuthURL) {
                await putImageOnS3(doc.preAuthURL, file).catch((e) => e);
                response.data.documents[index].preAuthURL = null;
              }
            }
          }),
        );
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const checklistSlice = createSlice({
  name: 'checklists',
  initialState,
  reducers: {
    nullSelectedChecklist(state) {
      state.selectedChecklist = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChecklistsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllChecklistsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.checklists = action.payload;
        state.error = null;
      })
      .addCase(getAllChecklistsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.checklists = null;
        state.error = action.payload;
      })
      .addCase(createChecklistAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createChecklistAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedChecklist = action.payload;
        state.error = null;
      })
      .addCase(createChecklistAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedChecklist = null;
        state.error = action.payload;
      })
      .addCase(getOneChecklistAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneChecklistAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedChecklist = action.payload;
        state.error = null;
      })
      .addCase(getOneChecklistAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedChecklist = null;
        state.error = action.payload;
      })
      .addCase(deleteMultipleChecklistsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleChecklistsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultipleChecklistsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateChecklistAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChecklistAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedChecklist = action.payload;
        state.error = null;
      })
      .addCase(updateChecklistAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedChecklist = null;
        state.error = action.payload;
      });
  },
});

export const { nullSelectedChecklist } = checklistSlice.actions;
export default checklistSlice.reducer;

export {
  getAllChecklistsAsync,
  createChecklistAsync,
  getOneChecklistAsync,
  deleteMultipleChecklistsAsync,
  updateChecklistAsync,
};

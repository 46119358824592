import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getOneChecklistAsync, nullSelectedChecklist } from '../../../../../store/checklistSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import ViewChecklist from './ViewChecklist';

interface Props {
  id: number;
  preview: boolean;
  setLoading: Function;
}

const ChecklistPreviewModal: React.FC<Props> = ({
  children,
  id,
  preview,
  setLoading,
}) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    checklists: state.checklists,
  }));
  const checklist = currentState.checklists.selectedChecklist;

  const handleClose = () => {
    if (preview) {
      dispatch(nullSelectedChecklist());
    }
    setShow(false);
  };
  const handleShow = async () => {
    if (preview) {
      await dispatch(getOneChecklistAsync(id));
    }
    setShow(true);
    setLoading(false);
  };
  return (
    <>
      <Button
        className="button-link p-0 text-left"
        onClick={() => {
          setLoading(true);
          handleShow();
        }}
      >
        {children}
      </Button>
      <Modal show={show} size="lg" onHide={handleClose} className="checklist-preview-modal">
        <Modal.Header closeButton className="modal-header" />
        <Modal.Body className="d-flex flex-column checklist-preview">
          {
            checklist
            && (
              <ViewChecklist
                checklist={checklist}
                preview={preview}
              />
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChecklistPreviewModal;

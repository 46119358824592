/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  faEllipsisV,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Read from '../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../assets/icons/MessageClosed.svg';
import { dateOnly } from '../../../service/timeAndDate';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentCyberThreatAssignmentsAsync } from '../../../store/cyberThreatAssignmentSlice';
import High from '../../../assets/icons/high-risk.svg';
import Medium from '../../../assets/icons/med-risk.svg';
import Low from '../../../assets/icons/low-risk.svg';
import { PrimexSecurityAlert } from '../../../store/primexAlertsSlice';
import PdfCreator from './pdfComponents/pdfCreator';

interface Props { }

const UserThreatLanding: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    cyberThreatAssignments: state.cyberThreatAssignments,
  }));

  const { currentCyberThreatAssignments } = currentState.cyberThreatAssignments;

  useEffect(() => {
    dispatch(getCurrentCyberThreatAssignmentsAsync());
  }, []);

  const [sortReceived, setSortReceived] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');
  // const [sortSource, setSortSource] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortThreat, setSortThreat] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const returnAlertIcon = (level: string) => {
    switch (level) {
      case 'Low':
        return Low;
      case 'Medium':
        return Medium;
      case 'High':
        return High;
      default:
        return '';
    }
  };

  const trackClick = (threatTitle: string) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cyberPortal',
        action: 'view',
        category: 'cyber threat alerts',
        label: 'View Threat from Alert List View',
        value: threatTitle,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const returnDownloadLink = async (
    incomingAlert: PrimexSecurityAlert,
    retry: boolean = false,
  ) => {
    try {
      const blob = await pdf((
        <PdfCreator
          threatAlert={incomingAlert}
        />
      )).toBlob();
      saveAs(blob, incomingAlert.title);
    } catch (e) {
      if (!retry) {
        returnDownloadLink(incomingAlert, true);
      }
    }
  };

  return (
    <>
      <div className="incoming-container">
        <Table bordered>
          <thead>
            <tr>
              <th> </th>
              <th>
                <span>
                  Received
                  <Button
                    className="no-button"
                    onClick={() => {
                      switch (sortReceived) {
                        case 'noSort':
                          setSortReceived('sortAsc');
                          return;
                        case 'sortAsc':
                          setSortReceived('sortDesc');
                          return;
                        case 'sortDesc':
                          setSortReceived('noSort');
                          return;
                        default:
                          setSortReceived('noSort');
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={returnSortIcon(sortReceived)} />
                  </Button>
                </span>
              </th>
              <th>Alert</th>
              <th>
                Threat Level
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortThreat) {
                      case 'noSort':
                        setSortThreat('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortThreat('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortThreat('noSort');
                        return;
                      default:
                        setSortThreat('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortThreat)} />
                </Button>
              </th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentCyberThreatAssignments
              && currentCyberThreatAssignments
                .slice()
                .sort((a, b) => {
                  const aVar = a.cyberThreat.publishDate;
                  const bVar = b.cyberThreat.publishDate;
                  switch (sortReceived) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.cyberThreat.threatLevel;
                  const bVar = b.cyberThreat.threatLevel;
                  switch (sortThreat) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((threat) => {
                  const {
                    id,
                    cyberThreat: {
                      id: threatId,
                      title,
                      publishDate,
                      // source,
                      threatLevel,
                    },
                  } = threat;
                  return (
                    <tr key={`${id}-cyberAlert`}>
                      <td>
                        <img src={threat.viewed ? Read : Unread} alt={threat.viewed ? 'Read icon' : 'Unread icon'} />
                      </td>
                      <td>
                        {dateOnly(publishDate)}
                      </td>
                      <td>
                        <Link to={`/threat-alerts/view/${threatId}`} onClick={() => trackClick(title)}>
                          {title.includes('-Updated') ? title.split('-Updated')[0] : title}
                        </Link>
                      </td>
                      <td>
                        <img src={returnAlertIcon(threatLevel)} alt="threat level icon" />
                        {` ${threatLevel}`}
                      </td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              Email Primex
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => returnDownloadLink(threat.cyberThreat)}>
                              Download PDF
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })
            }
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default UserThreatLanding;

import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagManager from 'react-gtm-module';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import HowToModal from './modals/HowToModal';
import { HowToTopic } from '../../../store/howToSlice';

interface Props {
  topic: HowToTopic[];
}

const trackClick = (topicTracked: string) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'cyberPortal',
      action: 'view',
      category: 'how-to',
      label: 'View How-To Category',
      value: topicTracked,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

const HowToAccordion: React.FC<Props> = ({ topic }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {topic
        // eslint-disable-next-line array-callback-return
        && topic.map((item, order) => (
          <div className={`col-12 ${order > 0 && 'mt-3'}`} key={item.id}>
            <Accordion>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  className="how-to-header text-underline d-flex justify-content-between align-items-center"
                  eventKey="0"
                  onClick={() => {
                    if (open) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                      trackClick(item.topicTitle);
                    }
                  }}
                >
                  <h3 className="m-0 py-1">
                    {item.topicTitle}
                  </h3>
                  <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ul className="how-to-topic-slide-list">
                      {
                        item.slides
                        && item.slides.length > 0
                        && item.slides.map((slide, index) => (
                          <li key={slide.order}>
                            <HowToModal
                              topic={topic}
                              startingSlide={index}
                              modalIndex={order}
                            >
                              {slide.slideTitle}
                            </HowToModal>
                          </li>
                        ))
                      }
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        ))}
    </>
  );
};

export default HowToAccordion;

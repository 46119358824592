/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';
import UploadModal from './shared/UploadModal';
import {
  deleteDocumentAsync,
  Document,
  getAllDocumentsListAsync,
  getOneDocumentAsync,
} from '../../../store/documentsSlice';
import { getCurrentNotificationsAsync, updateNotificationAsync } from '../../../store/notificationSlice';
import { getMembersAsync } from '../../../store/memberSlice';

interface SelectObject {
  label: string;
  value: string;
}

interface Props { }

const CyberPortalDocuments: React.FC<Props> = () => {
  const [sortAdded, setSortAdded] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');
  const [sortAddedBy, setSortAddedBy] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortOrigin, setSortOrigin] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortMember, setSortMember] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const [memberFilter, setMemberFilter] = useState('');
  const [originFilter, setOriginFilter] = useState('');

  const currentState = useAppSelector((state) => ({
    user: state.user,
    documents: state.documents,
    member: state.member,
    notification: state.notification,
  }));
  const dispatch = useAppDispatch();
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  const { currentUser } = currentState.user;
  const { documents } = currentState.documents;
  const { members } = currentState.member;
  const { notifications } = currentState.notification;
  if (!currentUser) {
    return (<div />);
  }

  useEffect(() => {
    if (!members || members.length <= 0) {
      dispatch(getMembersAsync());
    }
  }, []);

  // eslint-disable-next-line max-len
  // const primexMemberNumber: number | null = process.env.REACT_APP_PRIMEX_MEMBER_NUMBER ? parseInt(process.env.REACT_APP_PRIMEX_MEMBER_NUMBER, 10) : null;

  const returnSortIcon = (sortState: string) => {
    switch (sortState) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const returnAdminAccess = () => {
    if (superAdminRole && currentUser && currentUser.role && (currentUser.role >= superAdminRole)) {
      return true;
    }
    return false;
  };

  const handleDocClick = async (docId: number) => {
    if (notifications && notifications.length > 0) {
      const notification = notifications.find(
        (notice) => notice.document && (notice.document.id === docId),
      );
      if (notification && !notification.viewed) {
        await dispatch(updateNotificationAsync(
          {
            id: notification.id,
            body: {
              viewed: true,
            },
          },
        ));
        dispatch(getCurrentNotificationsAsync());
      }
    }
    // TODO : if checklist redirect to checklist
    const req = await dispatch(getOneDocumentAsync(docId));
    if (req.payload && req.payload.docGetUrl) {
      const aElement = document.createElement('a');
      aElement.href = req.payload.docGetUrl;
      aElement.target = '_blank';
      aElement.download = req.payload.s3StorageName || 'file';
      aElement.click();
    }
  };

  const returnMemberFilterList = () => {
    const memberList: SelectObject[] = [];
    if (returnAdminAccess()) {
      if (members && members.length > 0) {
        members.map((member) => memberList.push({
          value: member.memberName,
          label: member.memberName,
        }));
      }
    }
    if (currentUser.members && currentUser.members.length > 0) {
      currentUser.members
        .slice()
        .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
        .map((member) => memberList.push({
          value: member.memberName,
          label: member.memberName,
        }));
    }
    return memberList
      .sort((a, b) => (a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1));
  };

  const returnOriginFilterList = () => {
    const originList: SelectObject[] = [];
    if (documents && documents.length > 0) {
      documents
        .slice()
        .map((doc) => doc.origin)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((origin) => originList.push({
          value: origin,
          label: origin,
        }));
    }
    return originList;
  };

  const deleteDocument = async (docId: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteDocumentAsync(docId));
      dispatch(getAllDocumentsListAsync());
    }
  };

  const preSelectedMember = () => {
    const selectedMemberName = memberFilter;
    if (selectedMemberName) {
      const selectedMember = members.find((member) => member.memberName === selectedMemberName);
      return selectedMember || null;
    }
    return null;
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex mt-2 filter-row w-100">
          <Form.Label className="align-self-end filter-title">Filter by:</Form.Label>
          <Form.Group controlId="userJobCategory" className="w-100 mx-4">
            <Form.Label>Member</Form.Label>
            <Select
              className="select-dropdown"
              isClearable
              options={returnMemberFilterList()}
              onChange={(newValue: SelectObject | null) => {
                if (newValue) {
                  setMemberFilter(newValue.value);
                } else {
                  setMemberFilter('');
                }
              }}
            />
          </Form.Group>
          <Form.Group controlId="userJobCategory" className="w-100 mx-4">
            <Form.Label>Origin</Form.Label>
            <Select
              className="select-dropdown"
              isClearable
              options={returnOriginFilterList()}
              onChange={(newValue: SelectObject | null) => {
                if (newValue) {
                  setOriginFilter(newValue.value);
                } else {
                  setOriginFilter('');
                }
              }}
            />
          </Form.Group>
          <div className="w-50 d-none d-lg-flex" />
          <div className="w-100 d-none d-xl-flex" />
        </div>
        <div className="align-self-center button-container">
          {
            returnAdminAccess()
            && (
              <UploadModal
                currentUser={currentUser}
                s3Directory="portalDocs"
                memberList={members}
                selectedMember={preSelectedMember()}
                memberUser={false}
              >
                Upload Document
              </UploadModal>
            )
          }
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th>
              <span>Added </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortAdded) {
                    case 'noSort':
                      setSortAdded('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortAdded('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortAdded('noSort');
                      return;
                    default:
                      setSortAdded('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortAdded)} />
              </Button>
            </th>
            <th>
              <span>Added by </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortAddedBy) {
                    case 'noSort':
                      setSortAddedBy('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortAddedBy('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortAddedBy('noSort');
                      return;
                    default:
                      setSortAddedBy('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortAddedBy)} />
              </Button>
            </th>
            <th>
              <span>Title </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortTitle) {
                    case 'noSort':
                      setSortTitle('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortTitle('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortTitle('noSort');
                      return;
                    default:
                      setSortTitle('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
              </Button>
            </th>
            <th>
              <span>Origin </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortOrigin) {
                    case 'noSort':
                      setSortOrigin('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortOrigin('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortOrigin('noSort');
                      return;
                    default:
                      setSortOrigin('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortOrigin)} />
              </Button>
            </th>
            <th>
              <span>Member </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortMember) {
                    case 'noSort':
                      setSortMember('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortMember('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortMember('noSort');
                      return;
                    default:
                      setSortMember('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortMember)} />
              </Button>
            </th>
            {
              returnAdminAccess()
              && (
                <th>
                  Actions
                </th>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            documents
            && documents.length > 0
            && documents
              .slice()
              .filter((doc) => doc.s3Directory === 'portalDocs')
              .filter((doc) => {
                if (!memberFilter) {
                  return doc;
                }
                if (doc.members && doc.members.length > 0) {
                  if (doc.members.find((mem) => mem.memberName.includes(memberFilter))) {
                    return doc;
                  }
                }
                if (returnAdminAccess()) {
                  if (
                    doc.checklist
                    && doc.checklist.assignments
                    && doc.checklist.assignments.length > 0
                  ) {
                    if (
                      doc.checklist.assignments
                      && doc.checklist.assignments.find(
                        (assignment) => assignment.member.memberName.includes(memberFilter),
                      )
                    ) {
                      return doc;
                    }
                  }
                  if (
                    doc.checklistAssignment
                    && doc.checklistAssignment.member
                    && doc.checklistAssignment.member.memberName
                    && doc.checklistAssignment.member.memberName.includes(memberFilter)
                  ) {
                    return doc;
                  }
                }
              })
              .filter((doc) => {
                if (doc.origin) {
                  if (doc.origin.includes(originFilter)) {
                    return doc;
                  }
                }
              })
              .sort((a, b) => {
                const aVar = a.createdAt ? a.createdAt.valueOf() : 0;
                const bVar = b.createdAt ? b.createdAt.valueOf() : 0;
                switch (sortAdded) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    return aVar < bVar ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.addedBy && a.addedBy.lastName ? a.addedBy.lastName.toLowerCase().valueOf() : 'z';
                const bVar = b.addedBy && b.addedBy.lastName ? b.addedBy.lastName.toLowerCase().valueOf() : 'z';
                switch (sortAddedBy) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    return aVar < bVar ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.title ? a.title.toLowerCase().valueOf() : 'z';
                const bVar = b.title ? b.title.toLowerCase().valueOf() : 'z';
                switch (sortTitle) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    return aVar < bVar ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.origin ? a.origin.toLowerCase().valueOf() : 'z';
                const bVar = b.origin ? b.origin.toLowerCase().valueOf() : 'z';
                switch (sortOrigin) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    return aVar < bVar ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const returnMemberName = (doc: Document) => {
                  if (
                    doc.members
                    && doc.members.length > 0
                    && doc.members.length === 1
                    && doc.members[0].memberName
                  ) {
                    return doc.members[0].memberName.toLowerCase();
                  }
                  if (
                    doc.members
                    && doc.members.length > 1
                  ) {
                    return 'various';
                  }
                  if (
                    doc.checklistAssignment
                    && doc.checklistAssignment.member
                    && doc.checklistAssignment.member.memberName
                  ) {
                    return doc.checklistAssignment.member.memberName.toLowerCase();
                  }
                  if (
                    doc.checklist
                    && doc.checklist.assignments
                    && doc.checklist.assignments.length === 1
                    && doc.checklist.assignments[0].member
                    && doc.checklist.assignments[0].member.memberName
                  ) {
                    return doc.checklist.assignments[0].member.memberName.toLowerCase();
                  }
                  if (
                    doc.checklist
                    && doc.checklist.assignments
                    && doc.checklist.assignments.length > 1
                  ) {
                    return 'various';
                  }
                  return '';
                };
                const aVar = returnMemberName(a);
                const bVar = returnMemberName(b);
                switch (sortMember) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    return aVar < bVar ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((doc) => (
                <tr key={`${doc.id}-${doc.title}`}>
                  <td>
                    {doc.createdAt && dateOnly(doc.createdAt)}
                  </td>
                  <td>
                    {
                      doc.addedBy
                      && doc.addedBy.firstName
                    }
                    {' '}
                    {
                      doc.addedBy
                      && doc.addedBy.lastName
                    }
                  </td>
                  <td>
                    {
                      doc.checklistAssignment
                        ? (
                          <Link to={`/checklists/${doc.checklistAssignment.id}`}>
                            {doc.title}
                          </Link>
                        ) : (
                          <Button className="button-link text-left p-0" onClick={() => handleDocClick(doc.id)}>
                            {doc.title}
                          </Button>
                        )
                    }
                  </td>
                  <td>
                    {doc.origin}
                  </td>
                  <td>
                    {
                      doc.allMembers
                      && (
                        'All Members'
                      )
                    }
                    {
                      doc.members
                      && !doc.checklistAssignment
                      && doc.members.length > 0
                      && doc.members.length === 1
                      && doc.members[0].memberName
                    }
                    {
                      doc.members
                      && !doc.checklistAssignment
                      && doc.members.length > 1
                      && ('Various')
                    }
                    {
                      (!doc.members || doc.members.length === 0)
                      && doc.checklistAssignment
                      && doc.checklistAssignment.member
                      && doc.checklistAssignment.member.memberName
                    }
                    {
                      (!doc.members || doc.members.length === 0)
                      && !doc.checklistAssignment
                      && doc.checklist
                      && doc.checklist.assignments
                      && doc.checklist.assignments.length === 1
                      && doc.checklist.assignments[0].member
                      && doc.checklist.assignments[0].member.memberName
                    }
                    {
                      (!doc.members || doc.members.length === 0)
                      && !doc.checklistAssignment
                      && doc.checklist
                      && doc.checklist.assignments
                      && doc.checklist.assignments.length > 1
                      && ('Various')
                    }
                  </td>
                  {
                    returnAdminAccess()
                    && (
                      <td>
                        {
                          doc.addedBy
                          && (doc.addedBy.id === currentUser.id)
                          && (
                            <Button className="button-link p-0" onClick={() => deleteDocument(doc.id)}>
                              Delete
                            </Button>
                          )
                        }
                      </td>
                    )
                  }
                </tr>
              ))
          }
        </tbody>
      </Table>
    </>
  );
};

export default CyberPortalDocuments;

import React from 'react';
import {
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Complete from '../../../assets/icons/check-circle-fill.svg';
import InProgress from '../../../assets/icons/InProgressCheck.svg';
import NotStarted from '../../../assets/icons/notStartedCheck.svg';
import QuestionnaireIntroModal from '../questionnaire/modals/QuestionnaireIntroModal';
import { QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import { dateOnly } from '../../../service/timeAndDate';

interface Props {
  latestQuestionnaires: QuestionnaireAssignment[]
}

const QuestionnaireDashCard: React.FC<Props> = ({ latestQuestionnaires }) => {
  const numberofQuestions: number = latestQuestionnaires[0].questionnaire.questions.length;

  const calculateQuestionsComplete = () => {
    if (!latestQuestionnaires[0].questionnaireSession) {
      return 0;
    }
    let numberComplete = 0;
    latestQuestionnaires[0].questionnaireSession.answers.forEach((answer: any) => {
      if (answer.answer) {
        numberComplete += 1;
      }
    });
    return numberComplete;
  };
  const questionsComplete: number = calculateQuestionsComplete();

  const percentageComplete = Math.round((questionsComplete / numberofQuestions) * 100);

  const returnQuestionnaireButton = () => {
    const todaysDate = new Date();
    if (
      latestQuestionnaires[0].questionnaire
      && latestQuestionnaires[0].questionnaire.dueDate
      && new Date(latestQuestionnaires[0].questionnaire.dueDate).getTime() < todaysDate.getTime()
    ) {
      return (
        <Button variant="primary" disabled>
          Questionnaire Expired
        </Button>
      );
    }
    if (latestQuestionnaires.length > 1) {
      if (latestQuestionnaires.every((questionnaire) => questionnaire.completed)) {
        return (
          <Button variant="primary" disabled>
            Questionnaire Complete
          </Button>
        );
      }
    } else if (latestQuestionnaires.length === 1 && percentageComplete === 100) {
      return (
        <Button variant="primary" disabled>
          Questionnaire Complete
        </Button>
      );
    }
    return (
      <QuestionnaireIntroModal
        id={latestQuestionnaires[0].questionnaire.id}
        questionnaire={latestQuestionnaires[0].questionnaire}
        questionnaireAssignments={latestQuestionnaires}
      />
    );
  };

  const returnLevelOfComplete = (assignment: QuestionnaireAssignment) => {
    if (assignment.completed && assignment.completedDate) {
      return (
        <>
          <img className="mr-2" src={Complete} alt="Complete Icon" />
          <p className="m-0">Complete</p>
        </>
      );
    }
    if (!assignment.questionnaireSession) {
      return (
        <>
          <img className="mr-2" src={NotStarted} alt="Complete Icon" />
          <p className="m-0">Not Started</p>
        </>
      );
    }
    return (
      <>
        <img className="mr-2" src={InProgress} alt="Complete Icon" />
        <p className="m-0">In Progress</p>
      </>
    );
  };

  if (latestQuestionnaires.length === 1) {
    return (
      <div className="card-section questionnaire-dash-card">
        <Card>
          <Card.Body>
            <Card.Title>
              <h2>Latest Questionnaires</h2>
            </Card.Title>
            <div className="d-flex flex-column align-items-center justify-content-between questionnaire-link">
              <div className="align-self-end">
                {
                  latestQuestionnaires[0]
                  && latestQuestionnaires[0].questionnaire
                  && latestQuestionnaires[0].questionnaire.dueDate
                  && (
                    <p className="mr-2">{`Due: ${dateOnly(latestQuestionnaires[0].questionnaire.dueDate)}`}</p>
                  )
                }
              </div>
              <div className="m-0">
                <h3>{latestQuestionnaires[0].questionnaire.name}</h3>
              </div>
              <div className="text-center">
                <h2 className="numeric-font">
                  {percentageComplete}
                  %
                </h2>
                <p className="mb-2 text-muted">Complete</p>
                <ProgressBar now={percentageComplete} />
                <p className="mt-2">
                  <span className="numeric-font">{numberofQuestions - questionsComplete}</span>
                  <span> questions remaining</span>
                </p>
              </div>
              <div className="mb-4">
                {returnQuestionnaireButton()}
              </div>
            </div>
            <div>
              <Link to="/questionnaires">View All Questionnaires &gt;&gt;</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  if (latestQuestionnaires.length > 1) {
    return (
      <div className="card-section">
        <Card>
          <Card.Body>
            <Card.Title>
              <h2>Latest Questionnaires</h2>
            </Card.Title>
            <div className="d-flex flex-column justify-content-between questionnaire-link">
              <div className="m-0">
                <h3>{latestQuestionnaires[0].questionnaire.name}</h3>
              </div>
              <div className="mt-2 text-left d-flex flex-row justify-content-between">
                <div>
                  <p className="mb-2">% Complete</p>
                  <p className="mt-2">questions remaining</p>
                </div>
                <Dropdown className="questionnaire-multi-dropdown align-self-center flex-grow-1 text-center">
                  <Dropdown.Toggle className="p-0 no-button">
                    <div className="d-flex">
                      <p className="m-0 mr-3 d-flex">
                        Multiple
                      </p>
                      <FontAwesomeIcon icon={faSortDown} />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      latestQuestionnaires.map((assignment) => (
                        <Dropdown.Item bsPrefix="custom-dropdown" as="div" className="no-underline d-flex justify-content-between">
                          <div className="col-8 p-0">
                            {assignment.member.memberName}
                          </div>
                          <div className="col-4 d-flex ml-3">
                            {
                              returnLevelOfComplete(assignment)
                            }
                          </div>
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="mb-4 mt-2 d-flex">
                {returnQuestionnaireButton()}
                <Link className="ml-3 align-self-end" to="/questionnaires">View All Questionnaires &gt;&gt;</Link>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div />
  );
};

export default QuestionnaireDashCard;

import React, { useEffect } from 'react';
import Router from './Router';
import { login } from './store/authSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';

interface Props {}

const App: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    auth: state.auth,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // check if user information is passed via url query params on login
    // otherwise check for access info from local storgae
    if (urlParams.get('accessToken') && urlParams.get('email') && urlParams.get('refreshToken')) {
      const accessToken = urlParams.get('accessToken')!;
      const email = urlParams.get('email')!;
      const refreshToken = urlParams.get('refreshToken')!;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('email', email);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.removeItem('impersonate');
      dispatch(login({
        email,
        accessToken,
      }));
      window.history.pushState(null, '', window.location.origin + window.location.pathname);
    } else if (localStorage.getItem('accessToken') && localStorage.getItem('email')) {
      dispatch(login({
        email: localStorage.getItem('email')!,
        accessToken: localStorage.getItem('accessToken')!,
      }));
    }
  }, []);

  return (
    <>
      {currentState?.auth.loggedIn ? (
        <Router />
      ) : (
        <div className="position-fixed h-100 w-100">
          <div className="p-4 h-100 d-flex flex-column justify-content-center align-items-center">
            <h1>Please Log In</h1>
            <a className="btn btn-secondary" href={process.env.REACT_APP_API_LOGIN_URL}>Login</a>
          </div>
        </div>
      )}
    </>
  );
};

export default App;

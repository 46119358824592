/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';
import {
  createPostArticleAsync,
  deleteMultiplepostArticlesAsync,
  getOnePostArticleAsync,
  getpostArticlesAsync,
  nullSelectedPostArticle,
  PostArticle,
  updatepostArticleAsync,
} from '../../../../store/postArticleSlice';

interface Props {
  deleteRows: {
    selectedRows: number[],
    setSelectedRows: Function,
  }
  postsAndArticles: PostArticle[] | null,
  showArchived: boolean,
  searchString: string | null,
}

const ManagePostArticleTable: React.FC<Props> = (props: Props) => {
  const {
    deleteRows,
    showArchived,
    postsAndArticles,
    searchString,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStart, setSortStart] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortEnd, setSortEnd] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCreated, setSortCreated] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getpostArticlesAsync());
  }, []);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (deleteRows.selectedRows.includes(checkboxId)) {
      const newArray = deleteRows.selectedRows.filter((item) => item !== checkboxId);
      deleteRows.setSelectedRows([...newArray]);
    } else {
      deleteRows.setSelectedRows([checkboxId, ...deleteRows.selectedRows]);
    }
  };

  const archivePostArticle = async (id: number) => {
    const archiveBody: Partial<PostArticle> = {
      archived: true,
    };
    await dispatch(updatepostArticleAsync({ id, postArticle: archiveBody }));
    dispatch(getpostArticlesAsync());
  };

  const deletePostArticle = async (id: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultiplepostArticlesAsync([id]));
      dispatch(getpostArticlesAsync());
    }
  };

  const copyPostArticle = async (id: number) => {
    const itemToCopy = await dispatch(getOnePostArticleAsync(id));
    const newPostArticle: Partial<PostArticle> = { ...itemToCopy.payload };
    newPostArticle.id = undefined;
    newPostArticle.published = false;
    newPostArticle.author = undefined;
    newPostArticle.title = `Copy of: ${newPostArticle.title}`;
    await dispatch(createPostArticleAsync({ postArticle: newPostArticle, files: {} }));
    dispatch(getpostArticlesAsync());
    dispatch(nullSelectedPostArticle());
  };

  const editPostArticle = async (id: number) => {
    await dispatch(getOnePostArticleAsync(id));
    history.push('/content/create/postarticle');
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th className="check-box">
            <input type="checkbox" />
          </th>
          <th>
            <span>Headline </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortTitle) {
                  case 'noSort':
                    setSortTitle('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortTitle('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortTitle('noSort');
                    return;
                  default:
                    setSortTitle('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
            </Button>
          </th>
          <th>
            <span>Status </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStatus) {
                  case 'noSort':
                    setSortStatus('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStatus('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStatus('noSort');
                    return;
                  default:
                    setSortStatus('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
            </Button>
          </th>
          <th>
            <span>Start Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortStart) {
                  case 'noSort':
                    setSortStart('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortStart('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortStart('noSort');
                    return;
                  default:
                    setSortStart('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortStart)} />
            </Button>
          </th>
          <th>
            <span>End Date </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortEnd) {
                  case 'noSort':
                    setSortEnd('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortEnd('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortEnd('noSort');
                    return;
                  default:
                    setSortEnd('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortEnd)} />
            </Button>
          </th>
          <th>
            <span>Created </span>
            <Button
              className="no-button text-left"
              onClick={() => {
                switch (sortCreated) {
                  case 'noSort':
                    setSortCreated('sortAsc');
                    return;
                  case 'sortAsc':
                    setSortCreated('sortDesc');
                    return;
                  case 'sortDesc':
                    setSortCreated('noSort');
                    return;
                  default:
                    setSortCreated('noSort');
                }
              }}
            >
              <FontAwesomeIcon icon={returnSortIcon(sortCreated)} />
            </Button>
          </th>
          <th className="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          postsAndArticles
          && postsAndArticles
            .slice()
            .filter((item) => (showArchived ? item : !item.archived))
            .filter((item) => {
              if (searchString) {
                if (item.title && item.title.toLowerCase().includes(searchString.toLowerCase())) {
                  return item;
                }
                return;
              }
              return item;
            })
            .sort((a, b) => {
              const aVar = a.title ? a.title.toLowerCase() : 'z';
              const bVar = b.title ? b.title.toLowerCase() : 'z';
              switch (sortTitle) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const returnStatus = (topic: PostArticle) => {
                if (topic.published && !topic.archived) {
                  return 1;
                } if (!topic.published && !topic.archived) {
                  return 2;
                }
                return 3;
              };
              const aVar = returnStatus(a);
              const bVar = returnStatus(b);
              switch (sortStatus) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.startDate || 0;
              const bVar = b.startDate || 0;
              switch (sortStart) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.expirationDate || 0;
              const bVar = b.expirationDate || 0;
              switch (sortEnd) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .sort((a, b) => {
              const aVar = a.createdAt;
              const bVar = b.createdAt;
              switch (sortCreated) {
                case 'noSort':
                  return 0;
                case 'sortAsc':
                  return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                case 'sortDesc':
                  return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                default:
                  return 0;
              }
            })
            .map((postArticle) => (
              <tr>
                <td className="check-box">
                  <input
                    type="checkbox"
                    data-threatid={postArticle.id}
                    onChange={() => onCheckboxChange(postArticle.id)}
                  />
                </td>
                <td>
                  <Link to={`/posts-articles/${postArticle.id}`}>
                    {postArticle.title || 'None'}
                  </Link>
                </td>
                <td>
                  {
                    postArticle.archived && 'Archived'
                  }
                  {
                    !postArticle.archived
                    && postArticle.published
                    && 'Active'
                  }
                  {
                    !postArticle.archived
                    && !postArticle.published
                    && 'Draft'
                  }
                </td>
                <td>
                  {
                    postArticle.startDate
                    && (
                      dateOnly(postArticle.startDate)
                    )
                  }
                </td>
                <td>
                  {
                    postArticle.expirationDate
                    && (
                      dateOnly(postArticle.expirationDate)
                    )
                  }
                </td>
                <td>
                  {
                    postArticle.createdAt
                    && (
                      <p className="m-0">{dateAndTime(postArticle.createdAt)}</p>
                    )
                  }
                  {
                    postArticle.author
                    && postArticle.author.firstName
                    && postArticle.author.lastName
                    && (
                      <p className="m-0">{`${postArticle.author.firstName} ${postArticle.author.lastName}`}</p>
                    )
                  }
                </td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        postArticle.published
                        && (
                        <Dropdown.Item className="no-underline">
                          <Link to={`/content/detail/postarticle/${postArticle.id}`} className="no-underline">
                            Details
                          </Link>
                        </Dropdown.Item>
                        )
                      }
                      <Dropdown.Item className="no-underline" onClick={() => editPostArticle(postArticle.id)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item className="no-underline" onClick={() => copyPostArticle(postArticle.id)}>
                        Copy
                      </Dropdown.Item>
                      <Dropdown.Item className="no-underline" onClick={() => deletePostArticle(postArticle.id)}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item className="no-underline" onClick={() => archivePostArticle(postArticle.id)}>
                        Archive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
};
export default ManagePostArticleTable;

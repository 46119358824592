import React, { useState } from 'react';
import {
  Accordion,
  Alert,
  Button,
  Card,
  Modal,
  Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { getOneClaimAsync, IrClaim, updateOneClaimAsync } from '../../../../store/irClaimSlice';
import members from '../../../../assets/icons/Members-Blue.svg';
import ITIcon from '../../../../assets/icons/PrmaryIT.svg';
import ProgramContactIcon from '../../../../assets/icons/PrimaryProgramContact.svg';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  claim: IrClaim;
}

const ManageMemberAccess: React.FC<Props> = ({ claim }) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const [primaryContactError, setPrimaryContactError] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>(
    claim
    && claim.userAccess
    && claim.userAccess.length > 0
      ? claim.userAccess.map((user) => user.id)
      : [],
  );

  const dispatch = useAppDispatch();

  const handleShow = () => {
    setSelectedIds(
      claim
    && claim.userAccess
    && claim.userAccess.length > 0
        ? claim.userAccess.map((user) => user.id)
        : [],
    );
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  // TODO: primary program contacts cannot be removed
  const handleSubmit = async () => {
    if (claim.member && claim.member.primaryContacts && claim.member.primaryContacts.length > 0) {
      const primaryContactIds = claim.member.primaryContacts.map((cont) => cont.id);
      if (
        primaryContactIds.every((pId) => selectedIds.includes(pId))
      ) {
        await dispatch(updateOneClaimAsync({
          claimId: claim.id,
          updateBody: {
            newUserAccess: selectedIds || [],
          },
        }));
        dispatch(getOneClaimAsync(claim.id));
        handleClose();
      } else {
        setPrimaryContactError(true);
      }
    }
  };

  return (
    <>
      <Button variant="clear" className="claims-outline-button" onClick={handleShow}>
        <img className="member-access-icon mr-2" src={members} alt="3 people icon" />
        Manage Member Access
      </Button>

      <Modal show={show} size="lg" onHide={handleClose} className="member-select-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="ml-2 mb-0 align-self-center">Member User Access</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Select user(s) allowed to access this claim</h4>
          <Accordion defaultActiveKey="0">
            <Card className="member-select-card">
              <Accordion.Toggle
                as={Card.Header}
                className="d-flex justify-content-between align-items-center"
                eventKey="0"
                onClick={() => {
                  if (open) {
                    setOpen(false);
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <h3 className="m-0 py-1">
                  {claim.member && claim.member.memberName}
                </h3>
                <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Table bordered className="member-select mb-0">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>
                        <strong>User</strong>
                      </th>
                      <th>
                        <strong>Job Title</strong>
                      </th>
                      <th>
                        <span />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      claim
                      && claim.member
                      && claim.member.users
                      && claim.member.users.length > 0
                      && claim.member.users
                        .slice()
                        .map((user) => (
                          <tr>
                            <td>
                              <input
                                disabled={
                                  !!(user.primaryContactForMembers
                                && user.primaryContactForMembers.length > 0
                                && user.primaryContactForMembers.find(
                                  (mem) => mem.id === claim.member.id,
                                ))
                                }
                                value={user.id}
                                checked={selectedIds.includes(user.id)}
                                onChange={() => {
                                  if (selectedIds.includes(user.id)) {
                                    const newArray = selectedIds.filter((id) => id !== user.id);
                                    setSelectedIds(newArray);
                                  } else {
                                    setSelectedIds([...selectedIds, user.id]);
                                  }
                                }}
                                type="checkbox"
                              />
                            </td>
                            <td>
                              {`${user.lastName || ''}, ${user.firstName || ''}`}
                            </td>
                            <td>
                              {user.jobTitle}
                            </td>
                            <td>
                              {
                                user.primaryITForMembers
                                && user.primaryITForMembers.length > 0
                                && user.primaryITForMembers.find(
                                  (mem) => mem.id === claim.member.id,
                                )
                                && (
                                  <img className="ml-2" src={ITIcon} alt="IT Icon" title="Primary IT Contact" />
                                )
                              }
                              {
                                user.primaryContactForMembers
                                && user.primaryContactForMembers.length > 0
                                && user.primaryContactForMembers.find(
                                  (mem) => mem.id === claim.member.id,
                                )
                                && (
                                  <img className="ml-2" src={ProgramContactIcon} alt="Primary Program Contact Icon" title="Primary Program Contact" />
                                )
                              }
                            </td>
                          </tr>
                        ))
                    }
                  </tbody>
                </Table>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          {
            primaryContactError
            && (
              <Alert variant="danger">
                Primary Program Contacts cannot be removed from IC Claims.
              </Alert>
            )
          }
          <div className="d-flex justify-content-end mt-3 member-access-buttons">
            <Button variant="clear" className="claims-outline-button mr-3" onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Save</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageMemberAccess;

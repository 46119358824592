import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllSupportMessages, postSupportMessage } from '../api/supportMessageAPI';
import { ChecklistAssignment } from './checklistAssignmentSlice';
import { HowToTopic } from './howToSlice';
import { IrClaim } from './irClaimSlice';
import { Member } from './memberSlice';
import { PrimexSecurityAlert } from './primexAlertsSlice';
import { QuestionnaireAssignment } from './questionnaireAssignmentsSlice';
import { SystemAlert } from './systemAlertSlice';
import { User } from './userSlice';

export interface SupportMessage {
  id: number;
  message: string;
  flaggedCyberThreat?: PrimexSecurityAlert;
  flaggedHowToTopic?: HowToTopic;
  flaggedQuestionnaireAssignment?: QuestionnaireAssignment;
  flaggedChecklistAssignment?: ChecklistAssignment;
  flaggedSystemAlert?: SystemAlert;
  flaggedMember?: Member;
  flaggedUser?: User;
  flaggedIrClaim?: IrClaim;
  fromUser: User;
}

export interface PostSupportMessage {
  message: string;
  flaggedPostArticleId?: number;
  flaggedCyberThreatId?: number;
  flaggedHowToTopicId?: number;
  flaggedQuestionnaireAssignmentId?: number;
  flaggedChecklistAssignmentId?: number;
  flaggedSystemAlertId?: number;
  flaggedMemberId?: number;
  flaggedUserId?: number;
  flaggedIrClaimId?: number;
}

interface SupportMessageState {
  supportMessages: SupportMessage[] | null;
  selectedSupportMessage: SupportMessage | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: SupportMessageState = {
  supportMessages: [],
  selectedSupportMessage: null,
  status: 'idle',
  error: null,
};

const postSupportMessageAsync = createAsyncThunk(
  'supportMessage/post',
  async (message: PostSupportMessage, { rejectWithValue }) => {
    try {
      const response: any = await postSupportMessage(message);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getAllSupportMessagesAsync = createAsyncThunk(
  'supportMessage/getAll',
  async (arg: any, { rejectWithValue }) => {
    try {
      const response: any = await getAllSupportMessages();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const supportMessageSlice = createSlice({
  name: 'supportMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSupportMessageAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSupportMessageAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(postSupportMessageAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getAllSupportMessagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllSupportMessagesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.supportMessages = action.payload;
        state.error = null;
      })
      .addCase(getAllSupportMessagesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default supportMessageSlice.reducer;

export {
  postSupportMessageAsync,
  getAllSupportMessagesAsync,
};

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  faTrashAlt,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { dateOnly } from '../../../service/timeAndDate';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteMultipleAlertsAsync, getAllIncomingAlertsAsync } from '../../../store/incomingAlertSlice';
import CreateThreatAlert from './modals/createThreatAlert';
import ReadThreatAlert from './modals/readThreatAlert';

interface Props { }

const IncomingThreats: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector((state) => ({
    IncomingAlerts: state.incomingAlerts,
    primexAlerts: state.primexAlerts,
    cyberThreatAssignments: state.cyberThreatAssignments,
    user: state.user,
  }));

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [sortState, setSortState] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');

  const onCheckboxChange = (checkboxId: number) => {
    if (selectedRows.includes(checkboxId)) {
      const newArray = selectedRows.filter((item) => item !== checkboxId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([checkboxId, ...selectedRows]);
    }
  };

  const deleteSelected = async () => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultipleAlertsAsync(selectedRows));
      setSelectedRows([]);
      dispatch(getAllIncomingAlertsAsync());
    }
  };

  const returnSortIcon = () => {
    switch (sortState) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onSortClick = () => {
    switch (sortState) {
      case 'noSort':
        setSortState('sortAsc');
        return;
      case 'sortAsc':
        setSortState('sortDesc');
        return;
      case 'sortDesc':
        setSortState('noSort');
        return;
      default:
        setSortState('noSort');
    }
  };

  return (
    <>
      <div className="incoming-container">
        <div className="my-3">
          <button type="button" className="ml-2 button-link" onClick={deleteSelected}>
            <FontAwesomeIcon icon={faTrashAlt} />
            <span className="ml-2">Delete Selected</span>
          </button>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th className="check-box">
                <input type="checkbox" />
              </th>
              <th>
                <span>Issued </span>
                <Button className="no-button" onClick={onSortClick}>
                  <FontAwesomeIcon icon={returnSortIcon()} />
                </Button>
              </th>
              <th>Alert</th>
              <th>Source</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentState.IncomingAlerts.incomingSecurityAlerts
              && currentState.IncomingAlerts.incomingSecurityAlerts
                .slice()
                .sort((a, b) => {
                  const aVar = a.publishDate ? a.publishDate : a.createdAt;
                  const bVar = b.publishDate ? b.publishDate : b.createdAt;
                  switch (sortState) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((threat) => {
                  const {
                    id,
                    guid,
                    title,
                    publishDate,
                    createdAt,
                    source,
                  } = threat;
                  return (
                    <tr key={guid}>
                      <td className="check-box">
                        <input type="checkbox" data-threatid={id} onChange={() => onCheckboxChange(id)} />
                      </td>
                      <td>
                        {publishDate ? dateOnly(publishDate) : dateOnly(createdAt)}
                      </td>
                      <td>
                        <ReadThreatAlert
                          dateConversion={dateOnly}
                          primexAlertDraft={currentState.primexAlerts.selectedPrimexSecurityAlert}
                          incomingAlertId={id}
                        >
                          {title}
                        </ReadThreatAlert>
                      </td>
                      <td>{source}</td>
                      <td>
                        <CreateThreatAlert
                          currentId={id}
                          securityAlertDraft={currentState.IncomingAlerts.securityAlertDraft}
                          primexAlertDraft={currentState.primexAlerts.selectedPrimexSecurityAlert}
                          dateConversion={dateOnly}
                        >
                          Create
                        </CreateThreatAlert>
                      </td>
                    </tr>
                  );
                })
            }
          </tbody>
        </Table>
      </div>
    </>
  );
};
export default IncomingThreats;

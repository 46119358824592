/* eslint-disable no-alert */
/* eslint-disable consistent-return */
import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QuestionInfoModal from '../QuestionInfoModal';

interface Props {
  data: {
    id: number;
    name: string;
    questionnaireEngineTemplateId: number;
    questionnaireEngineName: string;
    questionnaireEngineVideo: string;
    questionnaireEngineExplanation: string;
    questions: any[];
    questionnaireSessions: any[];
  }
}

const QuestionnairePreview: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [multiAnswer, setMultiAnswer] = useState<number[]>([]);
  const [previewMode, setPreviewMode] = useState(true);

  const answerQuestion = async (event: any) => {
    event.preventDefault();
    setQuestionNumber(questionNumber + 1);
    setCurrentAnswer('');
    setMultiAnswer([]);
  };

  const clickHandler = (event: any) => {
    setCurrentAnswer(event.target.id);
  };

  const multiClickHandler = (answerNumber: number) => {
    if (multiAnswer.includes(answerNumber)) {
      const newAnswers = multiAnswer.filter((item) => item !== answerNumber);
      setMultiAnswer([...newAnswers]);
    } else {
      setMultiAnswer([answerNumber, ...multiAnswer]);
    }
  };

  const categoryArray: any[] = [];
  return (
    <>
      <Modal className="intro-modal" show={previewMode} size="lg" onHide={() => setPreviewMode(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Introduction</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {data.questionnaireEngineExplanation}
          </p>
          {
            data.questionnaireEngineVideo
            && (
              <div className="responsive-iframe_wrapper">
                <iframe src={data.questionnaireEngineVideo.includes('https://www.youtube.com/embed/') ? data.questionnaireEngineVideo : `https://www.youtube.com/embed/${data.questionnaireEngineVideo}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setPreviewMode(false)}>
            Continue to Questionnaire
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="p-3 questionnaire-session-card">
        <div className="d-flex justify-content-between">
          <h3>{data.questionnaireEngineName}</h3>
        </div>
        <div className="d-flex justify-content-between">
          <p>
            {questionNumber}
            <span> of </span>
            {data.questions.length}
            <span> Questions Complete</span>
          </p>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-2">
            <ul className="questionnaire-categories">
              {

                data.questions.map((item) => {
                  if (!categoryArray.includes(item.category)) {
                    categoryArray.push(item.category);
                    const active = data.questions[questionNumber].category === item.category;
                    return (
                      <li className={`category-item ${active ? 'active' : ''}`}>
                        <p>{item.category}</p>
                      </li>
                    );
                  }
                  return null;
                })
              }
            </ul>
          </div>
          <div className="col-10 question-section">
            <div className="p-4">
              <h5>{data.questions[questionNumber].category}</h5>
              {
                data.questions[questionNumber].categoryExplanation
                && (
                  <p>{data.questions[questionNumber].categoryExplanation}</p>
                )
              }
              <p>
                <span>Question </span>
                {data.questions
                  .filter(
                    (item) => item.category === data.questions[questionNumber].category,
                  )
                  .indexOf(data.questions[questionNumber]) + 1}
                <span> of </span>
                {data.questions
                  .filter(
                    (item) => item.category === data.questions[questionNumber].category,
                  ).length}
              </p>
              <Card className="p-4">
                <div>
                  <h4 className="bold mb-0">{data.questions[questionNumber].question}</h4>
                  {
                    (data.questions[questionNumber].explanation
                      || data.questions[questionNumber].videoExplanation)
                    && (
                      <QuestionInfoModal data={data.questions[questionNumber]} />
                    )
                  }
                </div>
                <Form id="form-answer" onSubmit={(event) => answerQuestion(event)}>
                  {
                  data.questions[questionNumber].questionType === 'Select multiple'
                    ? (
                      <Form.Group controlId="form.answer">
                        {data.questions[questionNumber].answerOption1 && <Form.Check checked={multiAnswer.includes(1)} onChange={() => multiClickHandler(1)} type="checkbox" id="answer1" label={data.questions[questionNumber].answerOption1} />}
                        {data.questions[questionNumber].answerOption2 && <Form.Check checked={multiAnswer.includes(2)} onChange={() => multiClickHandler(2)} type="checkbox" id="answer2" label={data.questions[questionNumber].answerOption2} />}
                        {data.questions[questionNumber].answerOption3 && <Form.Check checked={multiAnswer.includes(3)} onChange={() => multiClickHandler(3)} type="checkbox" id="answer3" label={data.questions[questionNumber].answerOption3} />}
                        {data.questions[questionNumber].answerOption4 && <Form.Check checked={multiAnswer.includes(4)} onChange={() => multiClickHandler(4)} type="checkbox" id="answer4" label={data.questions[questionNumber].answerOption4} />}
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="form.answer">
                        {data.questions[questionNumber].answerOption1 && <Form.Check checked={currentAnswer === 'answer1'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer1" label={data.questions[questionNumber].answerOption1} name="question 1" />}
                        {data.questions[questionNumber].answerOption2 && <Form.Check checked={currentAnswer === 'answer2'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer2" label={data.questions[questionNumber].answerOption2} name="question 1" />}
                        {data.questions[questionNumber].answerOption3 && <Form.Check checked={currentAnswer === 'answer3'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer3" label={data.questions[questionNumber].answerOption3} name="question 1" />}
                        {data.questions[questionNumber].answerOption4 && <Form.Check checked={currentAnswer === 'answer4'} onClick={clickHandler} onChange={(e) => e} type="radio" id="answer4" label={data.questions[questionNumber].answerOption4} name="question 1" />}
                      </Form.Group>
                    )
                }
                </Form>
              </Card>
              <div className="d-flex justify-content-end">
                <div className="questionnaire-button-group">
                  <Button
                    variant="primary"
                    className="m-4"
                    disabled={questionNumber <= 0}
                    onClick={() => {
                      setCurrentAnswer('');
                      setQuestionNumber(questionNumber > 0 ? questionNumber - 1 : 0);
                    }}
                  >
                    <span className="buttonArrow">&lt;</span>
                    <span className="ml-2">Previous Question</span>
                  </Button>
                  {
                    questionNumber + 1 === data.questions.length
                      ? (
                        <Button variant="primary" className="m-4">
                          <span className="mr-2">Complete</span>
                          <span className="buttonArrow">&gt;</span>
                        </Button>
                      )
                      : (
                        <Button variant="primary" form="form-answer" type="submit" className="m-4">
                          <span className="mr-2">Next Question</span>
                          <span className="buttonArrow">&gt;</span>
                        </Button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default QuestionnairePreview;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createBillboard,
  deleteMultipleBillboards,
  getBillboards,
  getOneBillboard,
  updateBillboard,
} from '../api/billboardAPI';
import { putImageOnS3 } from '../api/s3API';
import { BillboardAssignment } from './billboardAssignmentSlice';
import { User } from './userSlice';

export interface CreateBillboardDTO {
  id?: number;
  backgroundImageFilename: string | null;
  fileType: string | null;
  headline: string | null;
  color: string | null;
  buttonText: string | null;
  buttonLink: string | null;
  buttonPosition: 'bottom-left' | 'bottom-center' | 'bottom-right';
  startDate: Date | null;
  expirationDate: Date | null;
  published: boolean;
  archived: boolean;
}

export interface Billboard {
  id: number;
  backgroundImageFilename: string;
  headline: string;
  color: string;
  buttonText: string;
  buttonLink: string;
  buttonPosition: 'bottom-left' | 'bottom-center' | 'bottom-right';
  preAuthURL: any | null;
  startDate: Date;
  expirationDate: Date | null;
  published: boolean;
  archived: boolean;
  imageGetUrl: string | null;
  author: User;
  createdAt: Date;
  assignments: BillboardAssignment[] | null;
}

interface BillboardState {
  // TODO: update billboard state
  billboards: Billboard[] | null;
  selectedBillboard: Billboard | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: BillboardState = {
  billboards: null,
  selectedBillboard: null,
  status: 'idle',
  error: null,
};

const getBillboardsAsync = createAsyncThunk(
  'billboards/getAllBillboards',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getBillboards();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneBillboardAsync = createAsyncThunk(
  'billboards/getOneBillboard',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneBillboard(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

// TODO: update billboard type
const createBillboardAsync = createAsyncThunk(
  'billboards/create',
  // eslint-disable-next-line max-len
  async (uploadPackage: {billboard: CreateBillboardDTO | Partial<Billboard>, image?: File}, { rejectWithValue }) => {
    try {
      const response: any = await createBillboard(uploadPackage.billboard);
      if (uploadPackage.image && response.data.preAuthURL) {
        await putImageOnS3(response.data.preAuthURL, uploadPackage.image);
        response.data.preAuthURL = null;
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateBillboardAsync = createAsyncThunk(
  'billboards/update',
  async (billboardDTO: { id: number, billboard: Partial<Billboard> }, { rejectWithValue }) => {
    try {
      const response: any = await updateBillboard(billboardDTO.id, billboardDTO.billboard);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteBillboard = createAsyncThunk(
  'billboards/deleteOne',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await deleteBillboard(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleBillboardsAsync = createAsyncThunk(
  'billboards/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleBillboards(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const nullSelectedBillboard = createAsyncThunk(
  'billboards/nullSelectedBillboard',
  () => null,
);

export const billboardsSlice = createSlice({
  name: 'billboards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillboardsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBillboardsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.billboards = action.payload;
        state.error = null;
      })
      .addCase(getBillboardsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.billboards = null;
        state.error = action.payload;
      })
      .addCase(createBillboardAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBillboardAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedBillboard = action.payload;
        state.error = null;
      })
      .addCase(createBillboardAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedBillboard = null;
        state.error = action.payload;
      })
      .addCase(getOneBillboardAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneBillboardAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedBillboard = action.payload;
        state.error = null;
      })
      .addCase(getOneBillboardAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedBillboard = null;
        state.error = action.payload;
      })
      .addCase(updateBillboardAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBillboardAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedBillboard = action.payload;
        state.error = null;
      })
      .addCase(updateBillboardAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedBillboard = null;
        state.error = action.payload;
      })
      .addCase(deleteBillboard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBillboard.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteBillboard.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteMultipleBillboardsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleBillboardsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultipleBillboardsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(nullSelectedBillboard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(nullSelectedBillboard.fulfilled, (state) => {
        state.status = 'idle';
        state.selectedBillboard = null;
        state.error = null;
      })
      .addCase(nullSelectedBillboard.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default billboardsSlice.reducer;

export {
  getBillboardsAsync,
  createBillboardAsync,
  updateBillboardAsync,
  deleteBillboard,
  deleteMultipleBillboardsAsync,
  getOneBillboardAsync,
  nullSelectedBillboard,
};

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getOneUserAsync } from '../../../../store/userSlice';
import UserInfo from '../../UserInfo';

interface Props {
  profileId: number;
}

const ViewProfileModal: React.FC<Props> = ({ children, profileId }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => setShow(false);
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));
  const handleShow = async () => {
    await dispatch(getOneUserAsync(profileId));
    setShow(true);
  };

  return (
    <>
      <Button className="button-link" onClick={handleShow}>
        {children}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">User Profile</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            currentState.user.viewUserProfile
            && (
              <UserInfo user={currentState.user.viewUserProfile} />
            )
          }
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewProfileModal;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Form, Modal } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';
import unfinishedCheckBox from '../../../assets/icons/InProgressCheck.svg';
import CheckBoxSuccess from '../../../assets/icons/Success.svg';
import {
  Answer,
  getQuestionnaireAsync,
  postStartQuestionnaireSessionAsync,
  Questionnaire,
  updateQuestionnaireAsync,
  updateQuestionnaireSessionAsync,
} from '../../../store/questionnaireSlice';
import QuestionnaireDistribution from './modals/modalComponents/questionnaireDistribution';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createQuestionnaireAssignmentsAsync,
  deleteMultipleQuestionnaireAssignmentsAsync,
  getAssignmentsForQuestionnaireAsync,
  QuestionnaireAssignment,
  QuestionnaireAssignmentUser,
  updateQuestionnaireAssignmentAsync,
} from '../../../store/questionnaireAssignmentsSlice';
import { dateAndTime, dateYearMonthDay, getQuestionnaireSessionUpdatedDate } from '../../../service/timeAndDate';
import { DistributionUniversal, QuestionnaireDistributionModel } from '../../../models/distributionModels';
import PreviewSessionAnswers from './modals/PreviewSessionAnswers';
import ViewProfileModal from './modals/ViewProfileModal';
import ConvertToPdf from '../../../service/pdfConverter';
import QuestionnaireIntroModal from './modals/QuestionnaireIntroModal';
import DropdownIntroModal from './modals/DropdownIntroModal';

interface Props { }

const getQuestionnaireCompletedDate:
  (questionnaireSession: any) => string = (questionnaireSession: any) => {
    // if theres no questionnaireSession
    // or no questionniareSession answers
    // or questionnaireSession answers that are empty
    // then return null
    if (!questionnaireSession
      || (questionnaireSession.answers || []).length === 0
      || questionnaireSession.answers.filter((answer: Answer) => !answer.answer).length > 0) {
      return '';
    }

    return getQuestionnaireSessionUpdatedDate(questionnaireSession.answers);
  };

const QuestionnaireAdminDetail: React.FC<Props> = () => {
  const { id: incomingId } = useParams<{
    id: string | undefined
  }>();
  const [sortStateMember, setSortStateMember] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateUser, setSortStateUser] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateStatus, setSortStateStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateCompleted, setSortStateCompleted] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateScore, setSortStateScore] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showAddRespondentsModal, setShowAddRespondentsModal] = useState<boolean>(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [expireDateError, setExpireDateError] = useState(false);

  const [model, setModel] = useState<DistributionUniversal>();
  const currentState = useAppSelector((state) => ({
    questionnaire: state.questionnaire,
    questionnaireAssignments: state.questionnaireAssignments,
  }));
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [selectModal, setSelectModal] = useState<string>('');

  useEffect(() => {
    if (incomingId) {
      dispatch(getQuestionnaireAsync(parseInt(incomingId, 10)));
      dispatch(getAssignmentsForQuestionnaireAsync(parseInt(incomingId, 10)));
    }
  }, []);

  const { questionnaire } = currentState.questionnaire;
  if (!questionnaire) {
    return (<div />);
  }

  const {
    id,
    name,
    questions,
    createdAt,
    assignmentDetails,
  } = questionnaire;

  const {
    adminCurrentAssignments: questionnaireAssignments,
  } = currentState.questionnaireAssignments;
  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  // TODO this should update with user's ID and not the assignment ID.
  const onCheckboxChange = (userId: number) => {
    if (selectedRows.includes(userId)) {
      const newArray = selectedRows.filter((item) => item !== userId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([userId, ...selectedRows]);
    }
  };

  const handleClose = () => {
    setShowAddRespondentsModal(false);
    dispatch(getAssignmentsForQuestionnaireAsync(id));
    setModel(undefined);
  };

  const dueDateCheck: number = model?.dueDate?.getTime() || 0;
  const submitDistribution = async () => {
    if (model) {
      const distModel: QuestionnaireDistributionModel = { ...model, questionnaireId: id };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        }
      } else {
        if (dueDateCheck === 0) {
          setModel({ ...model, dateError: true });
          return;
        }
        distModel.allMembers = false;
        distModel.specificMemberIds = null;
        distModel.specificMemberTypes = null;
        distModel.specificMemberMaturityLevels = null;
        distModel.specificMemberSubTypes = null;
      }

      await dispatch(createQuestionnaireAssignmentsAsync(distModel));
      handleClose();
    }
  };

  const deleteSelected = async () => {
    if (
      selectedRows.length > 0
      && questionnaireAssignments
      && questionnaireAssignments.length > 0
    ) {
      const assignment = questionnaireAssignments.find((assign) => {
        let found = false;
        if (
          assign.users
          && assign.users.length > 0
        ) {
          assign.users.map((user) => {
            if (selectedRows.includes(user.id)) {
              found = true;
            }
          });
        }
        return found;
      });
      if (assignment) {
        const newUsers = assignment.users.filter((user) => !selectedRows.includes(user.id));
        if (
          newUsers
          && newUsers.length > 0
        ) {
          await dispatch(updateQuestionnaireAssignmentAsync({
            id: assignment.id,
            updateBody: {
              users: newUsers,
            },
          }));
        } else {
          await dispatch(deleteMultipleQuestionnaireAssignmentsAsync([assignment.id]));
        }
      }
    }
    dispatch(getAssignmentsForQuestionnaireAsync(id));
    setSelectedRows([]);
  };

  const destructureQuestionnaireAssignmentsUsers = (
    rawQuestionnaireAssignments: QuestionnaireAssignment[],
  ) => {
    const assignments: QuestionnaireAssignmentUser[] = [];

    rawQuestionnaireAssignments.map((questionnaireAssignment) => {
      questionnaireAssignment.users?.map((user) => {
        assignments.push({
          id: questionnaireAssignment.id,
          questionnaire: questionnaireAssignment.questionnaire,
          member: questionnaireAssignment.member,
          user,
          questionnaireSession: questionnaireAssignment.questionnaireSession,
        });
      });
    });

    return assignments;
  };

  const activationDate = () => {
    let activatedDate: string = '';
    if (questionnaireAssignments && questionnaireAssignments.length > 0) {
      const createdDates = questionnaireAssignments
        .slice()
        .map((assignment) => assignment.createdAt);
      // eslint-disable-next-line prefer-destructuring
      activatedDate = dateAndTime(createdDates.sort(
        (a, b) => (a.valueOf() < b.valueOf() ? -1 : 1),
      )[0]);
    }
    return activatedDate;
  };

  const returnDueDate = () => {
    let dueDate = '';
    if (questionnaire && questionnaire.dueDate) {
      dueDate = dateYearMonthDay(questionnaire.dueDate);
    }
    return dueDate;
  };

  const updateDueDate = async (newDateString: string) => {
    const newDate = new Date(newDateString.replace(/-/g, '/'));
    const errorDateCheck: number = newDate.getTime() || 0;
    if (errorDateCheck === 0) {
      setExpireDateError(true);
    } else if (errorDateCheck !== 0) {
      setExpireDateError(false);
    }
    if (questionnaire && newDate.valueOf()) {
      await dispatch(updateQuestionnaireAsync({
        id,
        questionnaireBody: {
          dueDate: newDate,
        },
      }));
    }
  };

  const returnAssignmentArray = () => {
    if (assignmentDetails && assignmentDetails.length > 3) {
      const detailArray: string[] = JSON.parse(assignmentDetails);
      if (detailArray && detailArray.length > 0) {
        return detailArray;
      }
    }
    return ['N/A'];
  };

  const checkArrayMatch = () => {
    let match = false;
    if (questionnaireAssignments) {
      const allAssigns = destructureQuestionnaireAssignmentsUsers(
        questionnaireAssignments,
      ).map((assign) => assign.user.id);
      if (selectedRows.length > 0) {
        match = allAssigns.every((v) => selectedRows.includes(v));
      }
    }
    return match;
  };

  const enterQuestionnaireSession = async (userAssignment: QuestionnaireAssignmentUser) => {
    await dispatch(getQuestionnaireAsync(parseInt(incomingId || '', 10)));
    if (userAssignment.questionnaireSession) {
      await dispatch(updateQuestionnaireSessionAsync(userAssignment.questionnaireSession.id));
      history.push(`/questionnaires/begin/${userAssignment.questionnaireSession.id}`);
    } else {
      const startResponse = await dispatch(postStartQuestionnaireSessionAsync([userAssignment.id]));
      history.push(`/questionnaires/begin/${startResponse.payload.id}`);
    }
  };

  const checkForMultiMemberAssignment = (userAssignment: QuestionnaireAssignmentUser) => {
    const thisUser = userAssignment.user;
    if (questionnaireAssignments && questionnaireAssignments.length > 1) {
      const assignmentArray: QuestionnaireAssignment[] = [];
      questionnaireAssignments.map((assignment) => {
        if (assignment.users.find((user) => user.id === thisUser.id)) {
          assignmentArray.push(assignment);
        }
      });
      if (assignmentArray.length > 1) {
        return assignmentArray;
      }
    }
    return [];
  };

  const returnDownloadLink = async (
    incomingQuestionnaire: Questionnaire,
    incomingMemberName: string,
    retry: boolean = false,
  ) => {
    try {
      const blob = await pdf((
        <ConvertToPdf
          questionnaire={incomingQuestionnaire}
          specificMemberName={incomingMemberName}
        />
      )).toBlob();
      saveAs(blob, incomingQuestionnaire.name);
    } catch (e) {
      if (!retry) {
        returnDownloadLink(incomingQuestionnaire, incomingMemberName, true);
      }
    }
  };

  return (
    <div className="admin-container p-4">
      <div className="d-flex justify-content-between">
        <div>
          <h3>{name}</h3>
          <div className="d-flex">
            <p>
              <span>Created: </span>
              <span className="numeric-font">{createdAt && dateAndTime(createdAt)}</span>
            </p>
            <p className="ml-5">
              <span>Activated: </span>
              <span className="numeric-font">{activationDate()}</span>
            </p>
          </div>
        </div>
        <div>
          <p>
            <span className="numeric-font">
              <Form.Group controlId="dueDate" className="w-100">
                <Form.Label>Expires:</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="MM/DD/YYYY"
                  isInvalid={expireDateError}
                  defaultValue={returnDueDate()}
                  onChange={(e) => {
                    updateDueDate(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid date.
                </Form.Control.Feedback>
              </Form.Group>
            </span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-content-center mb-2 border-section">
        <div className="d-flex flex-inline">
          <p className="m-0 d-flex align-items-center">
            <span>Distributed to: </span>
            <span className="d-flex ml-2">
              {
                returnAssignmentArray().map((assignDetail) => (
                  <p className="publish-tags ml-2">{assignDetail}</p>
                ))
              }
            </span>
          </p>
        </div>
        <div className="searchbar">
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
      <div className="d-flex justify-content-between align-content-center mb-2">
        <div className="align-self-center">
          {
            selectedRows.length > 0
            && (
              <Button
                className="ml-2 button-link"
                onClick={() => deleteSelected()}
              >
                <FontAwesomeIcon size="lg" icon={faTrashAlt} />
                <span className="ml-2">Remove respondents</span>
              </Button>
            )
          }
        </div>
        <div className="align-self-center">
          <Button variant="primary" onClick={() => setShowAddRespondentsModal(true)}>
            Add Respondents
          </Button>
          <Modal show={showAddRespondentsModal} size="lg" onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
              <QuestionnaireDistribution
                distModel={{ model, setModel }}
              />
            </Modal.Body>
            <Modal.Footer>
              {model?.userOnly
                && (
                  <>
                    <Button disabled={!model?.dueDate && !model?.specificUserId} variant="secondary" onClick={submitDistribution}>
                      Update
                    </Button>
                  </>
                )}
              {!model?.userOnly
                && (
                  <Button variant="secondary" onClick={submitDistribution}>
                    Update
                  </Button>
                )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th className="check-box">
              <input
                type="checkbox"
                checked={checkArrayMatch()}
                onClick={() => {
                  if (!checkArrayMatch()) {
                    if (questionnaireAssignments) {
                      const allAssigns = destructureQuestionnaireAssignmentsUsers(
                        questionnaireAssignments,
                      );
                      setSelectedRows(
                        [...allAssigns.map((assign) => assign.user.id)],
                      );
                    }
                  } else {
                    setSelectedRows([]);
                  }
                }}
              />
            </th>
            <th>
              <span>Member </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateMember) {
                    case 'noSort':
                      setSortStateMember('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateMember('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateMember('noSort');
                      return;
                    default:
                      setSortStateMember('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateMember)} />
              </Button>
            </th>
            <th>
              <span>User </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateUser) {
                    case 'noSort':
                      setSortStateUser('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateUser('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateUser('noSort');
                      return;
                    default:
                      setSortStateUser('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateUser)} />
              </Button>
            </th>
            <th>
              <span>Status </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateStatus) {
                    case 'noSort':
                      setSortStateStatus('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateStatus('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateStatus('noSort');
                      return;
                    default:
                      setSortStateStatus('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateStatus)} />
              </Button>
            </th>
            <th>
              <span>Completed </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateCompleted) {
                    case 'noSort':
                      setSortStateCompleted('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateCompleted('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateCompleted('noSort');
                      return;
                    default:
                      setSortStateCompleted('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateCompleted)} />
              </Button>
            </th>
            <th>
              <span>Score </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateScore) {
                    case 'noSort':
                      setSortStateScore('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateScore('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateScore('noSort');
                      return;
                    default:
                      setSortStateScore('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateScore)} />
              </Button>
            </th>
            <th className="text-center align-content-center">
              <span>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            questionnaireAssignments
            && destructureQuestionnaireAssignmentsUsers(questionnaireAssignments)
              .slice()
              .filter((assignment) => assignment.user)
              .filter((assignment) => {
                if (
                  assignment.member.memberName.toLowerCase().includes(searchCriteria.toLowerCase())
                  || assignment.user.lastName?.toLowerCase().includes(searchCriteria.toLowerCase())
                  || assignment.user.firstName?.toLowerCase().includes(searchCriteria.toLowerCase())
                ) {
                  return assignment;
                }
              })
              .sort((a, b) => {
                const aVar = a.member.memberName.toLowerCase();
                const bVar = b.member.memberName.toLowerCase();
                switch (sortStateMember) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.user.lastName?.toLowerCase() || 'a';
                const bVar = b.user.lastName?.toLowerCase() || 'a';
                switch (sortStateUser) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a: any, b: any) => {
                // eslint-disable-next-line max-len
                const aVar = (a.questionnaireSession?.answers || []).filter((answer: any) => answer.answer).length || 0;
                // eslint-disable-next-line max-len
                const bVar = (b.questionnaireSession?.answers || []).filter((answer: any) => answer.answer).length || 0;
                switch (sortStateStatus) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a: any, b: any) => {
                const aVar = getQuestionnaireCompletedDate(a.questionnaireSession) || 0;
                const bVar = getQuestionnaireCompletedDate(b.questionnaireSession) || 0;
                switch (sortStateCompleted) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    if (aVar === 0) {
                      return 1;
                    }
                    if (bVar === 0) {
                      return -1;
                    }
                    if (aVar === bVar) {
                      return 0;
                    }
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    if (aVar === 0) {
                      return 1;
                    }
                    if (bVar === 0) {
                      return -1;
                    }
                    if (aVar === bVar) {
                      return 0;
                    }
                    return aVar > bVar ? -1 : 1;
                  default:
                    return 0;
                }
              })
              .sort((a: any, b: any) => {
                const aVar = a.questionnaireSession?.score || 0;
                const bVar = b.questionnaireSession?.score || 0;
                switch (sortStateScore) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              // TODO add sort status for status, completed, and score
              .map((assignment, index) => (
                <tr key={`${assignment.id}-${assignment.user.id}`}>
                  <td className="check-box">
                    <input
                      type="checkbox"
                      data-threatid={assignment.user.id}
                      checked={selectedRows.includes(assignment.user.id)}
                      onChange={() => onCheckboxChange(assignment.user.id)}
                    />
                  </td>
                  <td>
                    <Link to={`/members/profile/${assignment.member.id}/info`}>
                      {assignment.member.memberName}
                    </Link>
                  </td>
                  <td>
                    <ViewProfileModal profileId={assignment.user.id}>
                      {assignment.user.firstName}
                      {' '}
                      {assignment.user.lastName}
                    </ViewProfileModal>
                  </td>
                  <td>
                    {
                      getQuestionnaireCompletedDate(assignment.questionnaireSession) !== ''
                        ? (
                          <img className="finished-icon" src={CheckBoxSuccess} alt="completed icon" />
                        ) : (
                          <img className="unfinished-icon" src={unfinishedCheckBox} alt="completed icon" />
                        )
                    }
                    <span>{` ${(assignment.questionnaireSession?.answers || []).filter((answer: any) => answer.answer).length || 0} of ${questionnaire.questions.length}`}</span>
                  </td>
                  <td>
                    {getQuestionnaireCompletedDate(assignment.questionnaireSession)}
                  </td>
                  <td>
                    {
                      assignment
                      && assignment.questionnaireSession
                      && assignment.questionnaireSession.score
                      && assignment.questionnaireSession.score
                    }
                    {
                      assignment
                      && assignment.questionnaireSession
                      && assignment.questionnaireSession.rating
                      && (` (${assignment.questionnaireSession.rating})`)
                    }
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          checkForMultiMemberAssignment(assignment).length <= 0
                            ? (
                              <Dropdown.Item onClick={() => enterQuestionnaireSession(assignment)}>
                                Edit Questionnaire Answers
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item>
                                <Button
                                  className="button-link no-button p-0"
                                  onClick={() => {
                                    setSelectModal(`${assignment.id}-${index}`);
                                  }}
                                >
                                  Edit Questionnaire Answers
                                </Button>
                              </Dropdown.Item>
                            )
                        }
                        <Dropdown.Item>
                          <PreviewSessionAnswers
                            data={questionnaire}
                            assignment={assignment}
                            linkStyle="link"
                          >
                            View Questionnaire
                          </PreviewSessionAnswers>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => returnDownloadLink(
                            questionnaire, assignment.member.memberName,
                          )}
                        >
                          Download Questionnaire
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <DropdownIntroModal
                      modalKey={`${assignment.id}-${index}`}
                      openModal={selectModal || null}
                      questionnaire={questionnaire}
                      questionnaireAssignments={
                        checkForMultiMemberAssignment(assignment)
                      }
                    />
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </div>
  );
};

export default QuestionnaireAdminDetail;

/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import ITIcon from '../../../../assets/icons/PrmaryIT.svg';
import ProgramContactIcon from '../../../../assets/icons/PrimaryProgramContact.svg';
import { Member } from '../../../../store/memberSlice';
import { lastActiveDateString } from '../../../../service/timeAndDate';

interface Props {
  member: Member;
}

const UserDetails: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { member } = props;
  const { users } = member;
  const [sortState, setSortState] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const returnSortIcon = () => {
    switch (sortState) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onSortClick = () => {
    switch (sortState) {
      case 'noSort':
        setSortState('sortAsc');
        return;
      case 'sortAsc':
        setSortState('sortDesc');
        return;
      case 'sortDesc':
        setSortState('noSort');
        return;
      default:
        setSortState('noSort');
    }
  };
  return (
    <>
      <Table bordered className="mt-5">
        <thead>
          <tr>
            <th>
              <span>Name </span>
              <Button className="no-button" onClick={onSortClick}>
                <FontAwesomeIcon icon={returnSortIcon()} />
              </Button>
            </th>
            <th>Job Title</th>
            <th>Category</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Last Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            users
            && users
              .slice()
              .sort((a, b) => {
                const aVar = a.lastName ? a.lastName.toLowerCase() : 'z';
                const bVar = b.lastName ? b.lastName.toLowerCase() : 'z';
                switch (sortState) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.lastName}
                    {', '}
                    {user.firstName}
                    {
                      member.primaryIT
                      && member.primaryIT.map((contact) => {
                        if (contact.id === user.id) {
                          return <img className="ml-2" src={ITIcon} alt="IT Icon" title="Primary IT Contact" />;
                        }
                      })
                    }
                    {
                      member.primaryContacts
                      && member.primaryContacts.map((contact) => {
                        if (contact.id === user.id) {
                          return <img className="ml-2" src={ProgramContactIcon} alt="Primary Program Contact Icon" title="Primary Program Contact" />;
                        }
                      })
                    }
                  </td>
                  <td>
                    {user.jobTitle || 'N/A'}
                  </td>
                  <td>
                    {user.jobCategory || 'N/A'}
                  </td>
                  <td>
                    {user.email}
                  </td>
                  <td>
                    {user.phone}
                  </td>
                  <td>
                    {user.lastActive ? lastActiveDateString(user.lastActive) : null}
                  </td>
                  <td>
                    <Link to={`/users/detail/${user.id}`}>
                      View Profile
                    </Link>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </>
  );
};

export default UserDetails;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import pdfBackgroundLogo from '../../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../../assets/bkg-diamonds.png';
import radioOn from '../../../../../assets/icons/RadioOn.png';
import radioOff from '../../../../../assets/icons/RadioOff.png';
import { IrClaim } from '../../../../../store/irClaimSlice';
import { dateOnly } from '../../../../../service/timeAndDate';

interface Props {
  irClaim: IrClaim;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 50,
    fontFamily: 'lato',
    fontSize: 12,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  fontBody: {
    fontFamily: 'Courier',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  twoColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  halfWidth: {
    maxWidth: '50%',
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleBlockMargin: {
    marginRight: 10,
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: 5,
    height: 5,
    marginTop: 5,
    marginRight: 5,
  },
  titleFonts: {
    fontSize: 16,
  },
  subTitleFonts: {
    fontSize: 14,
  },
  spaceAfterColon: {
    marginRight: 5,
  },
  questionTitle: {
    textDecoration: 'underline',
  },
});

const AdminClaimInfoPdf: React.FC<Props> = (props: Props) => {
  const { irClaim } = props;
  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.fontBody]} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.spaceAfterColon]}>
              Claim Title:
            </Text>
            <Text>
              { irClaim.title }
            </Text>
          </View>
          <View style={[styles.inline, styles.subTitleFonts]}>
            <Text style={[styles.spaceAfterColon]}>
              Member:
            </Text>
            <Text style={[styles.titleBlockMargin]}>
              { irClaim.member && irClaim.member.memberName }
            </Text>
            <Text style={[styles.spaceAfterColon]}>
              Claim#:
            </Text>
            <Text style={[styles.titleBlockMargin]}>
              { irClaim.claimNumber || '' }
            </Text>
            <Text style={[styles.spaceAfterColon]}>
              Member Type:
            </Text>
            <Text style={[styles.titleBlockMargin]}>
              { irClaim.member && irClaim.member.entityType }
            </Text>
          </View>
          <View style={[styles.section, styles.subTitleFonts, { textDecoration: 'underline' }]}>
            <Text>Triage Information</Text>
          </View>
          <View>
            <View style={[styles.section]}>
              <Text>
                The following items should be discussed with the reporting party.
                Please indicate information collected and actions taken.
              </Text>
            </View>
            <View style={[styles.twoColumn, styles.section]}>
              <View style={[styles.listColumn, styles.halfWidth]}>
                <Text style={[styles.questionTitle]}>
                  What was lost?
                </Text>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.itemsLost
                    && irClaim.triageInfo.itemsLost.length > 1
                    && irClaim.triageInfo.itemsLost.includes('money')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Money</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.itemsLost
                    && irClaim.triageInfo.itemsLost.length > 1
                    && irClaim.triageInfo.itemsLost.includes('physical property')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Physical Property</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.itemsLost
                    && irClaim.triageInfo.itemsLost.length > 1
                    && irClaim.triageInfo.itemsLost.includes('corporate or personal data')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Corporate or Personal Data</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.itemsLost
                    && irClaim.triageInfo.itemsLost.length > 1
                    && irClaim.triageInfo.itemsLost.includes('unknown')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Unknown</Text>
                </View>
              </View>
              <View style={[styles.listColumn, styles.halfWidth]}>
                <Text style={[styles.questionTitle]}>
                  How was it lost?
                </Text>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.howItemsLost
                    && irClaim.triageInfo.howItemsLost.length > 1
                    && irClaim.triageInfo.howItemsLost.includes('ransomware')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Ransomware</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.howItemsLost
                    && irClaim.triageInfo.howItemsLost.length > 1
                    && irClaim.triageInfo.howItemsLost.includes('business email compromise')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Business Email Compromise</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.howItemsLost
                    && irClaim.triageInfo.howItemsLost.length > 1
                    && irClaim.triageInfo.howItemsLost.includes('unknown')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Unknown</Text>
                </View>
              </View>
            </View>
            <View style={[styles.twoColumn, styles.section]}>
              <View style={[styles.listColumn, styles.halfWidth]}>
                <Text style={[styles.questionTitle]}>
                  Did the unauthorized part have access to the
                  member&apos;s email or data at any point?
                </Text>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.length > 1
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.includes('yes')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Yes</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.length > 1
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.includes('no')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>No</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.length > 1
                    && irClaim.triageInfo.unauthorizedMemberEmailDataAccess.includes('unknown')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Uknown</Text>
                </View>
              </View>
              <View style={[styles.listColumn, styles.halfWidth]}>
                <Text style={[styles.questionTitle]}>
                  Does the member give permission to involve law enforcement if required?
                </Text>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.permissionToInvolveLawEnforcement
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Yes</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && !irClaim.triageInfo.permissionToInvolveLawEnforcement
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>No</Text>
                </View>
              </View>
            </View>
            <View style={[styles.twoColumn, styles.section]}>
              <View style={[styles.listColumn, styles.halfWidth]}>
                <Text style={[styles.questionTitle]}>
                  Does the event involve critical infrastructure?
                </Text>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.criticalInfrastructureInvolved
                    && irClaim.triageInfo.criticalInfrastructureInvolved.length > 1
                    && irClaim.triageInfo.criticalInfrastructureInvolved.includes('power distribution')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Power Distribution</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.criticalInfrastructureInvolved
                    && irClaim.triageInfo.criticalInfrastructureInvolved.length > 1
                    && irClaim.triageInfo.criticalInfrastructureInvolved.includes('water distribution')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Water Distribution</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.criticalInfrastructureInvolved
                    && irClaim.triageInfo.criticalInfrastructureInvolved.length > 1
                    && irClaim.triageInfo.criticalInfrastructureInvolved.includes('emergency services')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Emergency Services</Text>
                </View>
                <View style={[styles.inline]}>
                  {
                    irClaim.triageInfo
                    && irClaim.triageInfo.criticalInfrastructureInvolved
                    && irClaim.triageInfo.criticalInfrastructureInvolved.length > 1
                    && irClaim.triageInfo.criticalInfrastructureInvolved.includes('healthcare')
                      ? (
                        <Image style={[styles.icon]} src={radioOn} />
                      ) : (
                        <Image style={[styles.icon]} src={radioOff} />
                      )
                  }
                  <Text>Healthcare</Text>
                </View>
              </View>
              <View style={[styles.listColumn, styles.halfWidth]} />
            </View>
            <View>
              <Text style={[styles.questionTitle]}>
                Additional information
              </Text>
              <Text>{ irClaim.triageInfo && irClaim.triageInfo.additionalInformation }</Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inline]}>
              <Text style={[styles.spaceAfterColon]}>
                Loss Date:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                { irClaim.lossDate && dateOnly(irClaim.lossDate) }
              </Text>
              <Text style={[styles.spaceAfterColon]}>
                Date Reported:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                { irClaim.entryDate && dateOnly(irClaim.entryDate) }
              </Text>
              <Text style={[styles.spaceAfterColon]}>
                Primex Adjuster:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                {
                  irClaim.primexAdjuster
                  && irClaim.primexAdjuster.firstName
                  && irClaim.primexAdjuster.lastName
                  && `${irClaim.primexAdjuster.firstName} ${irClaim.primexAdjuster.lastName}`
                }
              </Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.questionTitle]}>
              Incident Description
            </Text>
            <Text>{ irClaim.descriptionOfIncident }</Text>
            <View style={[styles.inline]}>
              {
                irClaim.involvesPII
                  ? (
                    <Image style={[styles.icon]} src={radioOn} />
                  ) : (
                    <Image style={[styles.icon]} src={radioOff} />
                  )
              }
              <Text>Involves PII Information</Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inline]}>
              <Text style={[styles.spaceAfterColon]}>
                Atom Lead:
              </Text>
              <Text style={[styles.titleBlockMargin]}>
                {
                  irClaim.atomLead
                  && irClaim.atomLead.firstName
                  && irClaim.atomLead.lastName
                  && `${irClaim.atomLead.firstName} ${irClaim.atomLead.lastName}`
                }
              </Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <View style={[styles.inline]}>
              <Text style={[styles.spaceAfterColon]}>
                Incident Type:
              </Text>
              {
                irClaim.incidentType
                && (
                  <Text>{irClaim.incidentType}</Text>
                )
              }
            </View>
          </View>
          <View style={[styles.section]}>
            <Text style={[styles.questionTitle]}>
              Resolution Details
            </Text>
            <Text>{ irClaim.resolutionDetails }</Text>
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};
export default AdminClaimInfoPdf;

import React from 'react';
import {
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Dropdown from 'react-bootstrap/Dropdown';
import Complete from '../../../assets/icons/check-circle-fill.svg';
import InProgress from '../../../assets/icons/InProgressCheck.svg';
import NotStarted from '../../../assets/icons/notStartedCheck.svg';
import { dateOnly } from '../../../service/timeAndDate';
import { QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import { Answer } from '../../../store/questionnaireSlice';
import QuestionnaireIntroModal from './modals/QuestionnaireIntroModal';

interface Props {
  data: QuestionnaireAssignment[];
}

const QuestionnaireCard: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const numberofQuestions: number = data
    .reduce((prev, curr) => prev + (curr.questionnaire.questions || []).length, 0);

  const questionsComplete: number = data
    .reduce((prev, curr) => prev + (curr.questionnaireSession?.answers || [])
      .filter((answer: Answer) => answer.answer).length, 0);

  const percentageComplete = Math.round((questionsComplete / (numberofQuestions || 1)) * 100);

  const returnQuestionnaireButton = () => {
    const todaysDate = new Date();
    if (
      data[0].questionnaire
      && data[0].questionnaire.dueDate
      && new Date(data[0].questionnaire.dueDate).getTime() < todaysDate.getTime()
    ) {
      return (
        <Button variant="primary" disabled>
          Questionnaire Expired
        </Button>
      );
    }
    if (percentageComplete === 100) {
      return (
        <Button variant="primary" disabled>
          Questionnaire Complete
        </Button>
      );
    }
    return (
      <QuestionnaireIntroModal
        questionnaireAssignments={data}
        id={data[0].id}
        questionnaire={data[0].questionnaire}
      />
    );
  };

  const returnLevelOfComplete = (assignment: QuestionnaireAssignment) => {
    if (assignment.completed) {
      return (
        <>
          <img className="mr-2" src={Complete} alt="Complete Icon" />
          <p className="m-0">Complete</p>
        </>
      );
    }
    if (!assignment.questionnaireSession) {
      return (
        <>
          <img className="mr-2" src={NotStarted} alt="Complete Icon" />
          <p className="m-0">Not Started</p>
        </>
      );
    }
    return (
      <>
        <img className="mr-2" src={InProgress} alt="Complete Icon" />
        <p className="m-0">In Progress</p>
      </>
    );
  };

  // PLEASE LEAVE COMMENTED CODE BELOW, FOR NOW. WAITING TO SEE IF CLIENT CHANGES MIND. :)

  // const returnLevelOfCompleteForMultiple = (multiAssignments: QuestionnaireAssignment[]) => {
  //   const arrayOfStatus: string[] = multiAssignments.map((assignment) => {
  //     let status = '';
  //     if (assignment.completed && assignment.completedDate) {
  //       status = 'completed';
  //     } else if (!assignment.questionnaireSession) {
  //       status = 'notStarted';
  //     } else {
  //       status = 'inProgress';
  //     }
  //     return status;
  //   });
  //   const uniqueStatus = Array.from(new Set(arrayOfStatus));
  //   if (uniqueStatus.length > 1) {
  //     return 'Multiple';
  //   }
  //   if (uniqueStatus[0] === 'completed') {
  //     return (
  //       <>
  //         <img className="mr-2" src={Complete} alt="Complete Icon" />
  //         <p className="m-0">Complete</p>
  //       </>
  //     );
  //   }
  //   if (uniqueStatus[0] === 'notStarted') {
  //     return (
  //       <>
  //         <img className="mr-2" src={NotStarted} alt="Complete Icon" />
  //         <p className="m-0">Not Started</p>
  //       </>
  //     );
  //   }
  //   if (uniqueStatus[0] === 'inProgress') {
  //     return (
  //       <>
  //         <img className="mr-2" src={InProgress} alt="Complete Icon" />
  //         <p className="m-0">In Progress</p>
  //       </>
  //     );
  //   }
  //   return 'Multiple';
  // };

  if (data.length === 1) {
    return (
      <Card className="p-3 m-3">
        <h3>{data[0].questionnaire.name}</h3>
        <div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              <span className="numeric-font">
                {percentageComplete}
                %
              </span>
              <span> complete</span>
            </p>
            {
              data[0]
              && data[0].questionnaire
              && data[0].questionnaire.dueDate
              && (
                <p className="mb-0">
                  Due:
                  {' '}
                  <span className="numeric-font">{dateOnly(data[0].questionnaire.dueDate)}</span>
                </p>
              )
            }
          </div>
          <ProgressBar now={percentageComplete} />
        </div>
        <div className="d-flex text-right justify-content-between mt-3">
          {
            returnQuestionnaireButton()
          }
          <p>
            <span className="numeric-font">{`${numberofQuestions - questionsComplete} `}</span>
            questions remaining
          </p>
        </div>
      </Card>
    );
  }
  if (data.length > 1) {
    return (
      <Card className="p-3 m-3">
        <h3>{data[0].questionnaire.name}</h3>
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="mb-0">
                <span>% complete</span>
              </p>
              <p className="mb-0">
                questions remaining
              </p>
            </div>
            <Dropdown className="questionnaire-multi-dropdown align-self-center">
              <Dropdown.Toggle className="p-0 no-button">
                <div className="d-flex">
                  <p className="m-0 mr-3 d-flex">
                    Multiple
                  </p>
                  <FontAwesomeIcon icon={faSortDown} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  data.map((assignment) => (
                    <Dropdown.Item bsPrefix="custom-dropdown" as="div" className="no-underline d-flex justify-content-between">
                      <div className="col-8">
                        {assignment.member.memberName}
                      </div>
                      <div className="col-4 d-flex text-left ">
                        {
                          returnLevelOfComplete(assignment)
                        }
                      </div>
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="d-flex text-right justify-content-between mt-3">
          {
            returnQuestionnaireButton()
          }
          {
            data[0]
            && data[0].questionnaire
            && data[0].questionnaire.dueDate
            && (
              <p className="mb-0 align-self-end">
                Due:
                {' '}
                <span className="numeric-font">{dateOnly(data[0].questionnaire.dueDate)}</span>
              </p>
            )
          }
        </div>
      </Card>
    );
  }
  return (
    <div />
  );
};

export default QuestionnaireCard;

/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTrainingRecordsAsync } from '../../store/trainingSlice';
import Layout from '../layouts/Layout';
import TrainingCard from '../shared/training/TrainingCard';

interface Props { }

const Trainings: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line no-unused-vars
  const currentState = useAppSelector((state) => {
    return {
      primexTrainings: state.primexTrainings,
    };
  });

  useEffect(() => {
    if (
      !currentState.primexTrainings.trainings || currentState.primexTrainings.trainings.length <= 0
    ) {
      dispatch(getTrainingRecordsAsync());
    }
  }, []);
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>
              Training
            </h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="training-container">
              <div className="row row-no-gutters m-0">
                {
                  currentState.primexTrainings.trainings
                  && currentState.primexTrainings.trainings.length > 0
                  && (
                    currentState.primexTrainings.trainings.map((training) => (
                      <div className="col-12 col-lg-6 col-xl-4">
                        <TrainingCard data={training} />
                      </div>
                    ))
                  )
                }
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Trainings;

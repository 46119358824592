/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Dropdown,
  Form,
  Table,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getAllClaimsAsync, IrClaim, IrContact } from '../../../store/irClaimSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';
import counselActive from '../../../assets/icons/counsel-active.svg';
import counselInactive from '../../../assets/icons/counsel-inactive.svg';
import exportZipPdfs from './pdfComponents/zipPdfCreator';
import { getMembersAsync, Member } from '../../../store/memberSlice';
import { checkAtomIrPermission } from '../../../service/checkIrPermission';
import ToggleEmail from './modals/EmailToggle';
import CustomPagination from '../pagination/CustomPagination';
import CustomSorting from '../pagination/CustomSorting';

interface selectValue {
  label: string;
  value: string;
}
interface Props {
  IRUserCheck: Function;
}

const ClaimsList: React.FC<Props> = ({ IRUserCheck }) => {
  const currentState = useAppSelector((state: any) => ({
    claims: state.irClaim,
    user: state.user,
    member: state.member,
  }));

  const {
    claims: { irClaims },
    user: {
      currentUser,
    },
    member: { members },
  } = currentState;
  const dispatch = useAppDispatch();
  const [sortReported, setSortReported] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortClaimNumber, setSortClaimNumber] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortMemberName, setSortMemberName] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortLossDate, setSortLossDate] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortAdjuster, setSortAdjuster] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortLead, setSortLead] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [closedClaims, setClosedClaims] = useState(true);
  const [myClaims, setMyClaims] = useState(false);
  const [memberTypeFilter, setMemberTypeFilter] = useState<{ label: string, value: string }[]>([]);
  const [selectStatus, setSelectStatus] = useState<selectValue | null>(null);
  const [parentFilter, setParentFilter] = useState<{ label: string, value: string }[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getMembersAsync());
    if (!IRUserCheck()) {
      setMyClaims(false);
    }
  }, []);

  const getSort = () => [
    `${sortReported}_irClaim.employerReportDate`,
    `${sortClaimNumber}_irClaim.claimNumber`,
    `${sortMemberName}_member.memberName`,
    `${sortTitle}_irClaim.title`,
    `${sortLossDate}_irClaim.lossDate`,
    `${sortAdjuster}_irClaim.primexAdjuster`,
    `${sortLead}_atomLead.firstName`,
    `${sortStatus}_claimStatus`,
  ].join(',');

  useEffect(() => {
    dispatch(getAllClaimsAsync({
      status: selectStatus?.value,
      pageIndex,
      pageSize,
      memberType: memberTypeFilter.map((v) => v.value),
      parent: parentFilter.map((v) => v.value),
      hideclosedClaims: closedClaims,
      myClaims,
      orderBy: getSort(),
    }));
  }, [
    selectStatus,
    pageIndex,
    pageSize,
    memberTypeFilter,
    parentFilter,
    closedClaims,
    myClaims,
    sortReported,
    sortClaimNumber,
    sortMemberName,
    sortTitle,
    sortStatus,
    sortLossDate,
    sortAdjuster,
    sortLead,
  ]);

  const statusOptions: { label: string, value: string }[] = [
    {
      label: 'Open',
      value: 'Open',
    },
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Closed',
      value: 'Closed',
    },
  ];
  const returnDate = (newDate: Date) => {
    const dateString = newDate.toString().replace(/-/g, '/').replace(/T.+/, '');
    const date = new Date(dateString);
    return dateOnly(date);
  };

  // eslint-disable-next-line consistent-return
  const checkBeazleyContact = (contacts: IrContact[]) => {
    const check = contacts?.find((contact: IrContact) => contact.contactType === 'beazley-contact' || contact.contactType === 'beazley-breach-counsel')?.firstName;
    return check;
  };

  const assembleMemberType = () => [
    { value: 'School', label: 'School' },
    { value: 'Municipal', label: 'Municipality' },
    { value: 'County', label: 'County' },
    { value: 'Special Districts', label: 'Special Districts' },
    { value: 'Non-Profit Limited Cov.', label: 'Non-Profit Limited Coverage' },
  ];

  const assembleParentMembers = () => {
    const parentMemberOptions: { label: string; value: string }[] = [];
    if (members) {
      const allParents: { label: string, value: string, sauNumber: number }[] = members.map(
        (member: Member) => (member.parentMember
          ? {
            value: member.parentMember.id,
            label: member.parentMember.memberName,
            sauNumber: member.sauNumber,
          }
          : null),
      );
      allParents.filter((parent) => (parent))
        .sort((a, b) => (a.sauNumber > b.sauNumber ? 1 : -1))
        .map((parent) => {
          if (parent) {
            const existOption = parentMemberOptions.find(
              (item) => item.value === parent.value,
            );
            if (!existOption) {
              parentMemberOptions.push({
                value: parent.value,
                label: parent.label,
              });
            }
          }
        });
    }
    return parentMemberOptions;
  };

  return (
    <Card className="cyber-claims-container">
      {IRUserCheck()
        && (
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="mx-2 w-100 align-self-end">
                <p>Filter by:</p>
              </div>
              <Form.Group controlId="userJobCategory" className="w-100 mx-4">
                <Form.Label>Status</Form.Label>
                <Select
                  className="select-dropdown"
                  options={statusOptions}
                  isClearable
                  value={selectStatus}
                  onChange={(newValue: any) => {
                    if (newValue?.value === 'Closed') {
                      setSelectStatus(newValue);
                      setClosedClaims(false);
                    } else setSelectStatus(newValue);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="userJobCategory" className="w-100 mx-4">
                <Form.Label>Member Type</Form.Label>
                <Select
                  className="select-dropdown"
                  isMulti
                  options={assembleMemberType()}
                  // value={distModel.model.specificMemberSubTypes}
                  onChange={(newValue: any) => setMemberTypeFilter(newValue)}
                />
              </Form.Group>
              <Form.Group controlId="userJobCategory" className="w-100 mx-4">
                <Form.Label>Parent Member</Form.Label>
                <Select
                  isMulti
                  className="select-dropdown"
                  options={assembleParentMembers()}
                  // value={distModel.model.specificMemberSubTypes}
                  onChange={(newValue: any) => setParentFilter(newValue)}
                />
              </Form.Group>
            </div>
            <div className="align-self-end">
              <Form.Group controlId="userJobCategory" className="w-100">
                <Form.Check
                  type="checkbox"
                  label="Hide closed claims"
                  checked={closedClaims}
                  onChange={() => {
                    if (!closedClaims && selectStatus?.value === 'Closed') setSelectStatus(null);
                    setClosedClaims(!closedClaims);
                  }}
                />
                <Form.Check
                  type="checkbox"
                  label="Show my claims only"
                  defaultChecked={myClaims}
                  onChange={() => setMyClaims(!myClaims)}
                />
              </Form.Group>
            </div>
          </div>
        )}
      <div className="claim-list-table">
        <Table bordered>
          <thead>
            <tr>
              <th>
                <CustomSorting sort={sortReported} setSort={setSortReported}>
                  Reported
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortClaimNumber} setSort={setSortClaimNumber}>
                  Claim#
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortMemberName} setSort={setSortMemberName}>
                  Member
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortTitle} setSort={setSortTitle}>
                  Claim Title
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortStatus} setSort={setSortStatus}>
                  Status
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortLossDate} setSort={setSortLossDate}>
                  Loss Date
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortAdjuster} setSort={setSortAdjuster}>
                  Primex Adjuster
                </CustomSorting>
              </th>
              <th>
                <CustomSorting sort={sortLead} setSort={setSortLead}>
                  ATOM Lead
                </CustomSorting>
              </th>
              <th className="text-center">
                Actions
              </th>
              {
                checkAtomIrPermission(currentUser)
                && (
                  <th>
                    Email Status
                  </th>
                )
              }
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {irClaims
              && irClaims.filter((claim: IrClaim) => !claim.notAClaim)
                .map((claim: IrClaim) => (
                  <tr key={claim.id}>
                    <td>
                      {returnDate(claim.employerReportDate)}
                    </td>
                    <td>
                      {claim.claimNumber}
                    </td>
                    <td>
                      <Link to={`/members/profile/${claim.member?.id}/info`}>
                        {claim.member?.memberName}
                      </Link>
                    </td>
                    <td>
                      <Link to={IRUserCheck() ? `/cyber-claims/details/${claim.id}/info` : `/cyber-claims/info/${claim.id}`}>
                        {claim.title}
                      </Link>
                    </td>
                    <td>
                      {claim.status}
                    </td>
                    <td>
                      {returnDate(claim.lossDate)}
                    </td>
                    <td>
                      {claim.primexAdjuster ? `${claim.primexAdjuster.firstName} ${claim.primexAdjuster.lastName}` : ''}
                    </td>
                    <td>
                      {claim.atomLead ? `${claim.atomLead.firstName} ${claim.atomLead.lastName}` : ''}
                    </td>
                    <td className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle>
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="text-center">
                          <Dropdown.Item onClick={() => exportZipPdfs(claim, IRUserCheck())}>
                            Export Claim Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    {
                      checkAtomIrPermission(currentUser)
                      && (
                        <td className="text-left">
                          <ToggleEmail claim={claim} singleClaim={false} />
                        </td>
                      )
                    }
                    <td>
                      <img src={checkBeazleyContact(claim?.irContacts) ? counselActive : counselInactive} alt={checkBeazleyContact(claim?.irContacts) ? 'active icon' : 'inactive icon'} />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        <CustomPagination
          totalCount={currentState.claims?.irClaimcount}
          pageSize={pageSize}
          currentPage={pageIndex}
          onPageChange={(e) => setPageIndex(e)}
          onPageSizeChange={setPageSize}
        />
      </div>
    </Card>
  );
};

export default ClaimsList;

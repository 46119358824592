/* eslint-disable quote-props */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch } from '../../../../store/hooks';
import {
  CreatePrimexSecurityAlert,
  getAllPrimexThreatAlertsAsync,
  getOnePrimexThreatAlertAsync,
  nullSelectedPrimexAlert,
  PrimexSecurityAlert,
  updatePrimexThreatAlertAsync,
} from '../../../../store/primexAlertsSlice';
import { setSelectedIncomingAlertNull } from '../../../../store/incomingAlertSlice';
import { CyberThreatDistributionModel, DistributionUniversal } from '../../../../models/distributionModels';
import QuestionnaireDistribution from '../../questionnaire/modals/modalComponents/questionnaireDistribution';
import { createCyberThreatAssignmentsAsync } from '../../../../store/cyberThreatAssignmentSlice';

interface Props {
  currentId: number,
  securityAlertDraft: PrimexSecurityAlert | null,
  dateConversion: Function,
  children: string,
}

const EditPrimexAlert: React.FC<Props> = (props) => {
  const {
    currentId,
    securityAlertDraft,
    dateConversion,
    children,
  } = props;
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [createAlertStep, setCreateAlertStep] = useState(1);

  const [headline, setHeadline] = useState('');
  const [severity, setSeverity] = useState<'Select' | 'Low' | 'Medium' | 'High' | ''>('');
  const [technologies, setTechnologies] = useState('');
  const [sourceTitle, setSourceTitle] = useState('');
  const [originalSource, setOriginalSource] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customRecommendations, setCustomRecommendations] = useState('');
  const [model, setModel] = useState<DistributionUniversal>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    setModel(undefined);
  };
  const handleShow = () => setShow(true);

  const createAlert = async () => {
    await dispatch(getOnePrimexThreatAlertAsync(currentId));
    handleShow();
  };

  const editPrimexAlert = async (nextStep: boolean) => {
    // eslint-disable-next-line max-len
    if (securityAlertDraft) {
      const alertBody: Partial<CreatePrimexSecurityAlert> = {
        title: headline || securityAlertDraft.title,
        threatLevel: severity || securityAlertDraft.threatLevel,
        technologiesImpacted: technologies || securityAlertDraft.technologiesImpacted,
        sourceTitle: sourceTitle || securityAlertDraft.sourceTitle,
        source: originalSource || securityAlertDraft.source,
        content: customDescription || securityAlertDraft.content,
        recommendations: customRecommendations || securityAlertDraft.recommendations,
        draft: securityAlertDraft.draft,
        publishDate: securityAlertDraft.publishDate || null,
      };
      const results = await dispatch(updatePrimexThreatAlertAsync({
        id: currentId,
        body: alertBody,
      }));
      if (nextStep && results) {
        setCreateAlertStep(createAlertStep + 1);
      } else if (!nextStep && results) {
        await dispatch(getAllPrimexThreatAlertsAsync());
        dispatch(setSelectedIncomingAlertNull());
        dispatch(nullSelectedPrimexAlert());
        handleClose();
      }
    }
  };

  const publishPrimexAlert = async () => {
    if (securityAlertDraft) {
      const alertBody: Partial<CreatePrimexSecurityAlert> = {
        title: headline || securityAlertDraft.title,
        threatLevel: severity || securityAlertDraft.threatLevel,
        technologiesImpacted: technologies || securityAlertDraft.technologiesImpacted,
        sourceTitle: sourceTitle || securityAlertDraft.sourceTitle,
        source: originalSource || securityAlertDraft.source,
        content: customDescription || securityAlertDraft.content,
        recommendations: customRecommendations || securityAlertDraft.recommendations,
        draft: false,
        publishDate: new Date(),
      };
      const results = await dispatch(updatePrimexThreatAlertAsync({
        id: currentId,
        body: alertBody,
      }));
      if (model && currentId) {
        const distModel: CyberThreatDistributionModel = {
          ...model, cyberThreatId: currentId,
        };
        if (!model.userOnly) {
          if (
            !model.basicUser
            && !model.primaryIt
            && !model.primaryProgramContact
            && (!model.specificUserJobCategories
              || model.specificUserJobCategories.length === 0)
          ) {
            setModel({ ...model, userError: true });
            return;
          }
          if (!model.memberSelection) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (model.memberSelection === 'specificMembers') {
            if (!model.specificMemberIds
              || (model.specificMemberIds.length <= 0)
            ) {
              setModel({ ...model, memberError: true });
              return;
            }
            distModel.allMembers = false;
            distModel.specificMemberTypes = null;
            distModel.specificMemberMaturityLevels = null;
            distModel.specificMemberSubTypes = null;
          } else if (model.memberSelection === 'selectedCriteria') {
            if (!model.specificMemberTypes
              || (model.specificMemberTypes.length <= 0)) {
              setModel({ ...model, memberError: true });
              return;
            }
            if (!model.specificMemberMaturityLevels
              || model.specificMemberMaturityLevels.length <= 0) {
              setModel({ ...model, memberError: true });
              return;
            }
            if (!model.specificMemberSubTypes
              || model.specificMemberSubTypes.length <= 0) {
              setModel({ ...model, memberError: true });
              return;
            }
            distModel.allMembers = false;
            distModel.specificMemberIds = null;
          } else if (model.memberSelection === 'allMembers') {
            distModel.allMembers = true;
            distModel.specificMemberIds = null;
            distModel.specificMemberMaturityLevels = null;
            distModel.specificMemberTypes = null;
            distModel.specificMemberSubTypes = null;
          }
        } else {
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberSubTypes = null;
        }
        await dispatch(createCyberThreatAssignmentsAsync(distModel));
      }
      if (results) {
        await dispatch(getAllPrimexThreatAlertsAsync());
        dispatch(setSelectedIncomingAlertNull());
        dispatch(nullSelectedPrimexAlert());
        history.push('/threat-alerts/published');
        handleClose();
      }
    }
  };
  // TODO : Make sure edit features are up to date with create component
  return (
    <>
      <Button className="button-link p-0 m-0" onClick={createAlert}>
        {children}
      </Button>

      <Modal className={securityAlertDraft?.incomingAlert ? 'mega-modal' : 'not-mega-modal'} size="xl" show={show}>
        <Modal.Header closeButton onHide={() => editPrimexAlert(false)}>
          <Modal.Title>
            <h4 className="ml-2 mb-0">Edit Cyber Threat Alert</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            securityAlertDraft
            && securityAlertDraft.incomingAlert
            && createAlertStep === 1
            && (
              <div className="row">
                <div className="col-6">
                  <div className="alert-box">
                    {
                      securityAlertDraft.incomingAlert.source === 'NCAS'
                      && (
                        <div>
                          <h1>
                            {securityAlertDraft.incomingAlert.title}
                          </h1>
                          <div className="d-flex flex-column">
                            <h3 className="m-0">DATE(S) ISSUED:</h3>
                            <span>{dateConversion(securityAlertDraft.incomingAlert.publishDate) || 'Unknown'}</span>
                          </div>
                        </div>
                      )
                    }
                    <div className="parsed-content">
                      {
                        parse(DOMPurify.sanitize(securityAlertDraft.incomingAlert.content))
                      }
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <Form>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="headline">
                          <Form.Label>Headline</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter headline"
                            value={headline || securityAlertDraft.title}
                            onChange={(e: any) => setHeadline(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group controlId="severitySelect">
                          <Form.Label>Severity</Form.Label>
                          <Form.Control
                            as="select"
                            value={severity || securityAlertDraft.threatLevel}
                            onChange={(e: any) => setSeverity(e.target.value)}
                          >
                            <option>Select</option>
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-8">
                        <Form.Group controlId="technologiesImpacted">
                          <Form.Label>Technologies Impacted</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter technologies"
                            value={technologies || securityAlertDraft.technologiesImpacted}
                            onChange={(e: any) => setTechnologies(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source title"
                            value={sourceTitle || securityAlertDraft.sourceTitle}
                            onChange={(e: any) => setSourceTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source URL"
                            value={originalSource || securityAlertDraft.source}
                            onChange={(e: any) => setOriginalSource(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Description</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customDescription || securityAlertDraft.content}
                            onChange={setCustomDescription}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Recommendations</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customRecommendations || securityAlertDraft.recommendations}
                            onChange={setCustomRecommendations}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }
          {
            securityAlertDraft
            && !securityAlertDraft.incomingAlert
            && createAlertStep === 1
            && (
              <div className="row">
                <div className="col-12">
                  <Form>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="headline">
                          <Form.Label>Headline</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter headline"
                            value={headline || securityAlertDraft.title}
                            onChange={(e: any) => setHeadline(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Form.Group controlId="severitySelect">
                          <Form.Label>Severity</Form.Label>
                          <Form.Control
                            as="select"
                            value={severity || securityAlertDraft.threatLevel}
                            onChange={(e: any) => setSeverity(e.target.value)}
                          >
                            <option>Select</option>
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-8">
                        <Form.Group controlId="technologiesImpacted">
                          <Form.Label>Technologies Impacted</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter technologies"
                            value={technologies || securityAlertDraft.technologiesImpacted}
                            onChange={(e: any) => setTechnologies(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source title"
                            value={sourceTitle || securityAlertDraft.sourceTitle}
                            onChange={(e: any) => setSourceTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="originalSource">
                          <Form.Label>Source Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter source URL"
                            value={originalSource || securityAlertDraft.source}
                            onChange={(e: any) => setOriginalSource(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Description</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customDescription || securityAlertDraft.content}
                            onChange={setCustomDescription}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group controlId="description">
                          <Form.Label>Recommendations</Form.Label>
                          <ReactQuill
                            className="quill-box"
                            theme="snow"
                            value={customRecommendations || securityAlertDraft.recommendations}
                            onChange={setCustomRecommendations}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )
          }
          {
            securityAlertDraft
            && createAlertStep === 2
            && (
              <QuestionnaireDistribution
                distModel={{ model, setModel }}
              />
            )
          }
        </Modal.Body>
        <Modal.Footer>
          {
            createAlertStep === 1
            && (
              <>
                <Button variant="secondary" onClick={() => editPrimexAlert(false)}>
                  Save for Later
                </Button>
                <Button variant="primary" onClick={() => editPrimexAlert(true)}>
                  Next
                </Button>
              </>
            )
          }
          {
            createAlertStep === 2
            && (
              <>
                <Button variant="secondary" onClick={() => setCreateAlertStep(createAlertStep - 1)}>
                  Back
                </Button>
                {model?.userOnly
                  ? (
                    <Button disabled={!model?.specificUserId} variant="primary" onClick={() => publishPrimexAlert()}>
                      Assign
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => publishPrimexAlert()}>
                      Assign
                    </Button>
                  )}
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPrimexAlert;

import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  deleteMultipleAlertsAsync,
  getAllIncomingAlertsAsync,
  setSelectedIncomingAlertNull,
  viewIncomingAlertAsync,
} from '../../../../store/incomingAlertSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  CreatePrimexSecurityAlert,
  CreatePrimexSecurityAlertDraft,
  createPrimexThreatAlertAsync,
  getAllPrimexThreatAlertsAsync,
  nullSelectedPrimexAlert,
  PrimexSecurityAlert,
  updatePrimexThreatAlertAsync,
} from '../../../../store/primexAlertsSlice';
import CreateAlertEditor from './modalComponents/createAlertEditor';
import { CyberThreatDistributionModel, DistributionUniversal } from '../../../../models/distributionModels';
import QuestionnaireDistribution from '../../questionnaire/modals/modalComponents/questionnaireDistribution';
import { createCyberThreatAssignmentsAsync } from '../../../../store/cyberThreatAssignmentSlice';

interface Props {
  dateConversion: Function;
  primexAlertDraft: PrimexSecurityAlert | null;
  incomingAlertId: number;
}

const ReadThreatAlert: React.FC<Props> = ({
  children, dateConversion, primexAlertDraft, incomingAlertId,
}) => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    IncomingAlerts: state.incomingAlerts,
  }));
  const history = useHistory();

  const { viewIncomingAlert: threatData } = currentState.IncomingAlerts;

  const cleanContent = threatData && threatData.content ? DOMPurify.sanitize(threatData.content) : '';
  const [show, setShow] = useState(false);

  const [createAlertStep, setCreateAlertStep] = useState(0);

  const [headline, setHeadline] = useState('');
  const [severity, setSeverity] = useState<'Select' | 'Low' | 'Medium' | 'High'>('Select');
  const [technologies, setTechnologies] = useState('');
  const [sourceTitle, setSourceTitle] = useState('');
  const [originalSource, setOriginalSource] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customRecommendations, setCustomRecommendations] = useState('');
  const [model, setModel] = useState<DistributionUniversal>();

  const handleClose = () => {
    setShow(false);
    setModel(undefined);
  };
  const handleShow = async () => {
    await dispatch(viewIncomingAlertAsync(incomingAlertId));
    setShow(true);
  };

  const deleteAlert = async () => {
    if (threatData) {
      await dispatch(deleteMultipleAlertsAsync([threatData.id]));
      await dispatch(getAllIncomingAlertsAsync());
    }
    handleClose();
  };

  const submitDistribution = async () => {
    if (model && primexAlertDraft) {
      const distModel: CyberThreatDistributionModel = {
        ...model, cyberThreatId: primexAlertDraft.id,
      };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        }
      } else {
        distModel.allMembers = false;
        distModel.specificMemberIds = null;
        distModel.specificMemberTypes = null;
        distModel.specificMemberMaturityLevels = null;
        distModel.specificMemberSubTypes = null;
      }
      await dispatch(updatePrimexThreatAlertAsync({
        id: primexAlertDraft.id,
        body: {
          draft: false,
          publishDate: new Date(),
          dueDate: distModel.dueDate || undefined,
        },
      }));
      await dispatch(createCyberThreatAssignmentsAsync(distModel));
      history.push('/threat-alerts/drafts');
      await dispatch(getAllPrimexThreatAlertsAsync());
      dispatch(setSelectedIncomingAlertNull());
      dispatch(nullSelectedPrimexAlert());
      handleClose();
    }
  };

  const createPrimexAlertDraft = async () => {
    const alertBody: CreatePrimexSecurityAlertDraft = {
      id: primexAlertDraft?.id || undefined,
      title: headline || 'draft alert',
      threatLevel: severity || null,
      technologiesImpacted: technologies || null,
      sourceTitle: sourceTitle || null,
      source: originalSource || null,
      content: customDescription || null,
      recommendations: customRecommendations || null,
      incomingAlert: threatData || undefined,
      draft: true,
    };
    await dispatch(createPrimexThreatAlertAsync(alertBody));
    history.push('/threat-alerts/drafts');
    await dispatch(getAllPrimexThreatAlertsAsync());
    dispatch(setSelectedIncomingAlertNull());
    dispatch(nullSelectedPrimexAlert());
    handleClose();
  };

  const beginAlertFromDraft = () => {
    if (!sourceTitle && threatData) {
      setSourceTitle(threatData.source || '');
    }
    if (!originalSource && threatData) {
      setOriginalSource(threatData.link || '');
    }
    setCreateAlertStep(createAlertStep + 1);
  };

  const createPrimexAlert = async (nextStep: boolean) => {
    // eslint-disable-next-line max-len
    if (headline && severity && customDescription && customRecommendations) {
      const alertBody: CreatePrimexSecurityAlert = {
        id: primexAlertDraft?.id || undefined,
        title: headline,
        threatLevel: severity,
        technologiesImpacted: technologies || '',
        sourceTitle: sourceTitle || '',
        source: originalSource || '',
        content: customDescription,
        recommendations: customRecommendations,
        draft: true,
        incomingAlert: threatData || undefined,
      };
      const results = await dispatch(createPrimexThreatAlertAsync(alertBody));
      if (nextStep && results) {
        setCreateAlertStep(createAlertStep + 1);
      } else if (!nextStep && results) {
        history.push('/threat-alerts/drafts');
        await dispatch(getAllPrimexThreatAlertsAsync());
        handleClose();
      }
    }
  };

  return (
    <>
      <Button className="button-link px-0" onClick={handleShow}>
        {children}
      </Button>
      <Modal show={show} onHide={handleClose} size="xl" className={`incoming-alert-modal ${createAlertStep === 1 ? 'mega-modal' : ''}`}>
        {
          threatData
          && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  {
                    createAlertStep === 0
                    && (
                      <h4 className="ml-2 mb-0">Cyber Threat Alert</h4>
                    )
                  }
                  {
                    createAlertStep === 1
                    && (
                      <h4 className="ml-2 mb-0">Create Cyber Threat Alert</h4>
                    )
                  }
                  {
                    createAlertStep === 2
                    && (
                      <h4 className="ml-2 mb-0">Distribution</h4>
                    )
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-5">
                {
                  createAlertStep === 0
                  && (
                    <div className="d-flex justify-content-end mb-2">
                      <Button className="mr-2" variant="primary" onClick={deleteAlert}>
                        Delete Alert
                      </Button>
                      <Button variant="secondary" onClick={beginAlertFromDraft}>
                        Create Alert
                      </Button>
                    </div>
                  )
                }
                {
                  createAlertStep === 0
                  && (
                    <>
                      {
                        threatData.source === 'NCAS'
                        && (
                          <div>
                            <h1>
                              {threatData.title}
                            </h1>
                            <div className="d-flex flex-column">
                              <h3 className="m-0">DATE(S) ISSUED:</h3>
                              <span>{dateConversion(threatData.publishDate) || 'Unknown'}</span>
                            </div>
                          </div>
                        )
                      }
                      <div className="parsed-content">
                        {
                          parse(cleanContent)
                        }
                      </div>
                    </>
                  )
                }
                {
                  createAlertStep === 1
                  && threatData
                  && (
                    <CreateAlertEditor
                      dateConversion={dateConversion}
                      securityAlertDraft={threatData}
                      severity={{ severity, setSeverity }}
                      headline={{ headline, setHeadline }}
                      technologies={{ technologies, setTechnologies }}
                      sourceTitle={{ sourceTitle, setSourceTitle }}
                      originalSource={{ originalSource, setOriginalSource }}
                      customDescription={{ customDescription, setCustomDescription }}
                      customRecommendations={{ customRecommendations, setCustomRecommendations }}
                    />
                  )
                }
                {
                  createAlertStep === 2
                  && primexAlertDraft
                  && (
                    <QuestionnaireDistribution
                      distModel={{ model, setModel }}
                    />
                  )
                }
              </Modal.Body>
              <Modal.Footer>
                {
                  createAlertStep === 1
                  && (
                    <>
                      <Button variant="primary" onClick={createPrimexAlertDraft}>
                        Save for Later
                      </Button>
                      <Button variant="secondary" onClick={() => createPrimexAlert(true)}>
                        Next
                      </Button>
                    </>
                  )
                }
                {
                  createAlertStep === 2
                  && (
                    <>
                      <Button variant="primary" onClick={() => setCreateAlertStep(createAlertStep - 1)}>
                        Back
                      </Button>
                      {model?.userOnly
                        ? (
                          <Button disabled={!model?.specificUserId} variant="secondary" onClick={submitDistribution}>
                            Assign
                          </Button>
                        ) : (
                          <Button variant="secondary" onClick={submitDistribution}>
                            Assign
                          </Button>
                        )}
                    </>
                  )
                }
              </Modal.Footer>
            </>
          )
        }
      </Modal>
    </>
  );
};

export default ReadThreatAlert;

import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AttentionIcon from '../../../../assets/icons/Attention.svg';

interface Props {
  show: boolean;
  id: number;
  name: string;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleAccept: (id: number) => void;
}

const ChecklistInUseModal: React.FC<Props> = (props) => {
  const {
    show,
    id,
    name,
    handleClose,
    handleAccept,
  } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="d-flex">
            <img src={AttentionIcon} alt="exclamation icon" />
            <h5 className="ml-2 mb-0 align-self-center">Checklist In Use</h5>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`This checklist is in use by ${name}.
          If you continue, you will become the active user and
          ${name} will be bumped out of the checklist.`}
        </p>
        <p>
          Do you want to continue with this checklist?
        </p>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            handleAccept(id);
          }}
        >
          Continue to Checklist
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChecklistInUseModal;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import { getCurrentUserAsync } from '../../store/userSlice';
import BasicUser from './dashboards/basicUser';
import PrimaryUser from './dashboards/primaryUser';

interface Props { }

const Dashboard: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));

  useEffect(() => {
    dispatch(getCurrentUserAsync());
  }, []);

  const { currentUser } = currentState.user;
  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);
  // eslint-disable-next-line max-len
  const IRAdminRole = process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL, 10);
  const primexUserCheck = () => {
    if (currentUser?.role && superAdminRole && IRAdminRole) {
      const check: boolean = currentUser.role >= superAdminRole && currentUser.role <= IRAdminRole;
      return check;
    }
    return false;
  };
  const checkForPrimaryItorPrimaryContact = () => {
    if (currentUser && currentUser.members && currentUser.members.length > 0) {
      const allMembers = currentUser.members;
      if (currentUser.primaryContactForMembers
        && currentUser.primaryContactForMembers.length > 0) {
        const pCMembers = currentUser.primaryContactForMembers;
        allMembers.forEach((member) => {
          if (!pCMembers.find((pcMember) => pcMember.id === member.id)) {
            return false;
          }
        });
        return true;
      }
      if (currentUser.primaryITForMembers && currentUser.primaryITForMembers.length > 0) {
        const pCMembers = currentUser.primaryITForMembers;
        allMembers.forEach((member) => {
          if (!pCMembers.find((pcMember) => pcMember.id === member.id)) {
            return false;
          }
        });
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <Layout>
        {currentUser
          && !primexUserCheck()
          && checkForPrimaryItorPrimaryContact()
          && <PrimaryUser />}
        {currentUser
          && !primexUserCheck()
          && !checkForPrimaryItorPrimaryContact()
          && <BasicUser />}
      </Layout>
    </>
  );
};
export default Dashboard;

import React, { useState } from 'react';
import {
  Form,
  Accordion,
  Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import counselActive from '../../../../assets/icons/counsel-active.svg';
import { useDispatch } from 'react-redux';
import counselInactive from '../../../../assets/icons/counsel-inactive.svg';
import {
  IrContact,
  IrClaim,
  updateClaimContactAsync,
  getOneClaimAsync,
} from '../../../../store/irClaimSlice';

interface Props {
  contact: IrContact | undefined,
  counselContact: IrContact | undefined,
  claim: IrClaim;
}

const Beazley: React.FC<Props> = ({
  contact,
  claim,
  counselContact,
}) => {
  const [open, setOpen] = useState(true);
  const [debounceHandle, setDebounceHandle] = useState<any>();
  const dispatch = useDispatch();
  const { id: claimId } = claim;

  const updateContactInfo = async (info: any, body: string) => {
    if (info && contact?.id && body === 'firstName') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          firstName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'lastName') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          lastName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'email') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          email: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'officePhone') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          officePhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'cellPhone') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          cellPhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'jobTitle') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          jobTitle: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'category') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          category: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && contact?.id && body === 'notes') {
      await dispatch(updateClaimContactAsync({
        id: contact.id,
        updateBody: {
          notes: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };

  const updateCounselContactInfo = async (info: any, body: string) => {
    if (info && counselContact?.id && body === 'firstName') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          firstName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'lastName') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          lastName: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'email') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          email: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'officePhone') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          officePhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'cellPhone') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          cellPhone: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'jobTitle') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          jobTitle: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'category') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          category: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    } else if (info && counselContact?.id && body === 'notes') {
      await dispatch(updateClaimContactAsync({
        id: counselContact.id,
        updateBody: {
          notes: info,
        },
      }));
      await dispatch(getOneClaimAsync(claimId));
    }
  };
  return (
    <Accordion defaultActiveKey="0" className="mt-4 p-2">
      <Card>
        <Accordion.Toggle className="p-2 text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
          Beazley Information
          <div>
            {/* TODO: add function if contact exists */}
            <img className="mr-3" src={counselInactive} alt="beazley contact icon" />
            <FontAwesomeIcon className="ml-auto" icon={open ? faAngleUp : faAngleDown} />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <h5 className="bold">Beazley Contact</h5>
            <Form>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="p-0 col-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="beazley-first-name"
                    type="text"
                    defaultValue={contact?.firstName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'firstName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="beazley-last-name"
                    type="text"
                    defaultValue={contact?.lastName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'lastName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="beazley-email"
                    type="email"
                    defaultValue={contact?.email || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'email'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Office Phone</Form.Label>
                  <Form.Control
                    id="beazley-office-phone"
                    type="text"
                    defaultValue={contact?.officePhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'officePhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Cell Phone</Form.Label>
                  <Form.Control
                    id="beazley-cell-phone"
                    type="text"
                    defaultValue={contact?.cellPhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'cellPhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="pl-0 col-4">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Control
                    id="beazley-contact-type"
                    type="text"
                    defaultValue={contact?.contactType || ''}
                    disabled
                  />
                </div>
                <div className="p-0 col-4">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    id="beazley-job-title"
                    type="text"
                    defaultValue={contact?.jobTitle || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'jobTitle'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="pr-0 col-4">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    id="beazley-category"
                    type="text"
                    defaultValue={contact?.category || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateContactInfo(value, 'category'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                id="beazley-notes"
                as="textarea"
                rows={3}
                defaultValue={contact?.notes || ''}
                onChange={(e) => {
                  if (debounceHandle) clearTimeout(debounceHandle);
                  const { currentTarget: { value } } = e;
                  const handle = setTimeout(() => updateContactInfo(value, 'notes'), 750);
                  setDebounceHandle(handle);
                }}
              />

              {/* Beazley Breach Counsel */}

              <h5 className="bold my-3"> Beazley Breach Counsel</h5>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="p-0 col-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="it-second-first-name"
                    type="text"
                    defaultValue={counselContact?.firstName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'firstName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="it-second-last-name"
                    type="text"
                    defaultValue={counselContact?.lastName || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'lastName'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="it-second-email"
                    type="email"
                    defaultValue={counselContact?.email || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'email'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Office Phone</Form.Label>
                  <Form.Control
                    id="it-second-office-phone"
                    type="text"
                    defaultValue={counselContact?.officePhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'officPhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0">
                  <Form.Label>Cell Phone</Form.Label>
                  <Form.Control
                    id="it-second-cell-phone"
                    type="text"
                    defaultValue={counselContact?.cellPhone || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'cellPhone'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex mt-4 ml-0 justify-content-between">
                <div className="pl-0 col-4">
                  <Form.Label>Contact Type</Form.Label>
                  <Form.Control
                    id="it-second-contact-type"
                    type="text"
                    disabled
                    defaultValue={counselContact?.contactType || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'contactType'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="p-0 col-4">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    id="it-second-job-title"
                    type="text"
                    defaultValue={counselContact?.jobTitle || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'jobTitle'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
                <div className="pr-0 col-4">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    id="it-second-category"
                    type="text"
                    defaultValue={counselContact?.category || ''}
                    onChange={(e) => {
                      if (debounceHandle) clearTimeout(debounceHandle);
                      const { currentTarget: { value } } = e;
                      const handle = setTimeout(() => updateCounselContactInfo(value, 'category'), 750);
                      setDebounceHandle(handle);
                    }}
                  />
                </div>
              </div>
              <Form.Label className="mt-3">Notes</Form.Label>
              <Form.Control
                id="beazley-counsel-notes"
                as="textarea"
                rows={3}
                defaultValue={counselContact?.notes || ''}
                onChange={(e) => {
                  if (debounceHandle) clearTimeout(debounceHandle);
                  const { currentTarget: { value } } = e;
                  const handle = setTimeout(() => updateCounselContactInfo(value, 'notes'), 750);
                  setDebounceHandle(handle);
                }}
              />
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Beazley;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSystemAlertAssignments, getCurrentSystemAlertAssignments } from '../api/systemAlertAssignmentAPI';
import { SystemAlertDistributionModel } from '../models/distributionModels';
import { SystemAlert } from './systemAlertSlice';
import { User } from './userSlice';

export interface SystemAlertAssignment {
  id: number;
  viewed: boolean;
  viewedDate: Date | null;
  systemAlert: SystemAlert;
  user: User;
  createdAt: Date;
}

interface SystemAlertAssignmentState {
  currentSystemAlertAssignments: SystemAlertAssignment[] | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: SystemAlertAssignmentState = {
  currentSystemAlertAssignments: null,
  status: 'idle',
  error: null,
};

const getCurrentSystemAlertAssignmentsAsync = createAsyncThunk(
  'systemAlertAssignments/getCurrent',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentSystemAlertAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createSystemAlertAssignmentsAsync = createAsyncThunk(
  'systemAlertAssignments/create',
  async (dto: SystemAlertDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createSystemAlertAssignments(dto);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const systemAlertAssignmentSlice = createSlice({
  name: 'systemAlertAssignments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSystemAlertAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSystemAlertAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createSystemAlertAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCurrentSystemAlertAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentSystemAlertAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentSystemAlertAssignments = action.payload;
        state.error = null;
      })
      .addCase(getCurrentSystemAlertAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default systemAlertAssignmentSlice.reducer;

export {
  getCurrentSystemAlertAssignmentsAsync,
  createSystemAlertAssignmentsAsync,
};

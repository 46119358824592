import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createHowToTopic,
  deleteMultipleHowTo,
  getHowToTopics, getOneHowTo,
  updateHowToTopic,
} from '../api/howToTopicAPI';
import { User } from './userSlice';

interface HowToSlide {
  order: number;
  slideTitle: string;
  content: string;
}

export interface HowToTopic {
  id: number;
  topicTitle: string;
  slides: HowToSlide[];
  published: boolean;
  archived: boolean;
  trigger: string;
  createdAt: Date;
  author: User;
}

interface HowToState {
  howToTopics: HowToTopic[] | null;
  selectedHowTo: HowToTopic | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: HowToState = {
  howToTopics: [],
  selectedHowTo: null,
  status: 'idle',
  error: null,
};

const getHowToTopicsAsync = createAsyncThunk(
  'howto/getHowToTopics',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getHowToTopics();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getOneHowToAsync = createAsyncThunk(
  'howto/getOneHowTo',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getOneHowTo(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createHowToTopicAsync = createAsyncThunk(
  'howto/createHowTo',
  async (topic: Partial<HowToTopic>, { rejectWithValue }) => {
    try {
      const response: any = await createHowToTopic(topic);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateHowToTopicAsync = createAsyncThunk(
  'howto/updateHowTo',
  async (body: {id: number, topic: Partial<HowToTopic>}, { rejectWithValue }) => {
    try {
      const response: any = await updateHowToTopic(body.id, body.topic);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleHowToAsync = createAsyncThunk(
  'howto/deleteMany',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleHowTo(ids);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const howToSlice = createSlice({
  name: 'howTo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHowToTopicsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHowToTopicsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.howToTopics = action.payload;
        state.error = null;
      })
      .addCase(getHowToTopicsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.howToTopics = [];
        state.error = action.payload;
      })
      .addCase(getOneHowToAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneHowToAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedHowTo = action.payload;
        state.error = null;
      })
      .addCase(getOneHowToAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedHowTo = null;
        state.error = action.payload;
      })
      .addCase(createHowToTopicAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createHowToTopicAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selectedHowTo = action.payload;
        state.error = null;
      })
      .addCase(createHowToTopicAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.selectedHowTo = null;
        state.error = action.payload;
      })
      .addCase(updateHowToTopicAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateHowToTopicAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateHowToTopicAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteMultipleHowToAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleHowToAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultipleHowToAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default howToSlice.reducer;

export {
  getHowToTopicsAsync,
  getOneHowToAsync,
  createHowToTopicAsync,
  updateHowToTopicAsync,
  deleteMultipleHowToAsync,
};

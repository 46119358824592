import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAppDispatch } from '../../../store/hooks';
import { PostSupportMessage, postSupportMessageAsync } from '../../../store/supportMessageSlice';

interface Props {
  flaggedPostArticleId?: number;
  flaggedCyberThreatId?: number;
  flaggedHowToTopicId?: number;
  flaggedQuestionnaireAssignmentId?: number;
  flaggedChecklistAssignmentId?: number;
  flaggedSystemAlertId?: number;
  flaggedMemberId?: number;
  flaggedUserId?: number;
  flaggedIrClaimId?: number;
  children?: string;
}

const GlobalMessage: React.FC<Props> = (props: Props) => {
  const {
    flaggedPostArticleId,
    flaggedCyberThreatId,
    flaggedHowToTopicId,
    flaggedQuestionnaireAssignmentId,
    flaggedChecklistAssignmentId,
    flaggedSystemAlertId,
    flaggedMemberId,
    flaggedUserId,
    flaggedIrClaimId,
    children,
  } = props;
  const [message, setMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  const submitMessage = async () => {
    if (message) {
      const messageEntity: PostSupportMessage = {
        message,
        flaggedPostArticleId,
        flaggedCyberThreatId,
        flaggedHowToTopicId,
        flaggedQuestionnaireAssignmentId,
        flaggedChecklistAssignmentId,
        flaggedSystemAlertId,
        flaggedMemberId,
        flaggedUserId,
        flaggedIrClaimId,
      };
      const sentMessage = await dispatch(postSupportMessageAsync(messageEntity));
      if (sentMessage.meta.requestStatus === 'fulfilled') {
        setShow(true);
        setMessage('');
      }
    }
  };

  return (
    <div className="d-flex flex-column w-100 global-support-message">
      <div className="col-12">
        <h4>
          { children || "Something doesn't look right? Send a message to Primex."}
        </h4>
        <Form.Control value={message} onChange={(e) => setMessage(e.target.value)} as="textarea" rows={5} placeholder="Enter message" />
        <Toast
          onClose={() => setShow(false)}
          className="support-message-toast"
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">Message Sent</strong>
          </Toast.Header>
          <Toast.Body>Your message has been sent to Primex support.</Toast.Body>
        </Toast>
      </div>
      <div className="d-flex col-12 justify-content-end mt-3">
        <Button onClick={submitMessage}>Send</Button>
      </div>
    </div>
  );
};
export default GlobalMessage;

GlobalMessage.defaultProps = {
  flaggedPostArticleId: undefined,
  flaggedCyberThreatId: undefined,
  flaggedHowToTopicId: undefined,
  flaggedQuestionnaireAssignmentId: undefined,
  flaggedSystemAlertId: undefined,
  flaggedMemberId: undefined,
  flaggedUserId: undefined,
  flaggedIrClaimId: undefined,
  children: undefined,
  flaggedChecklistAssignmentId: undefined,
};

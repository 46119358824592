import React, { useState } from 'react';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Read from '../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../assets/icons/MessageClosed.svg';
import { useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';

interface Props { }

const PostAndArticleTable: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    postArticleAssignments: state.postArticleAssignments,
  }));

  const { currentPostArticleAssignments } = currentState.postArticleAssignments;

  const [sortReceived, setSortReceived] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');
  const [sortCreatedBy, setSortCreatedBy] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortExpire, setSortExpire] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th> </th>
            <th>
              <span>
                Received
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortReceived) {
                      case 'noSort':
                        setSortReceived('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortReceived('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortReceived('noSort');
                        return;
                      default:
                        setSortReceived('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortReceived)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Created By
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortCreatedBy) {
                      case 'noSort':
                        setSortCreatedBy('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortCreatedBy('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortCreatedBy('noSort');
                        return;
                      default:
                        setSortCreatedBy('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortCreatedBy)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Title
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortTitle) {
                      case 'noSort':
                        setSortTitle('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortTitle('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortTitle('noSort');
                        return;
                      default:
                        setSortTitle('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Expires
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortExpire) {
                      case 'noSort':
                        setSortExpire('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortExpire('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortExpire('noSort');
                        return;
                      default:
                        setSortExpire('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortExpire)} />
                </Button>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            currentPostArticleAssignments
            && currentPostArticleAssignments
              .slice()
              .sort((a, b) => {
                const aVar = a.createdAt;
                const bVar = b.createdAt;
                switch (sortReceived) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                let aVar = 'z';
                let bVar = 'z';
                if (a.postArticle && a.postArticle.author && a.postArticle.author.lastName) {
                  aVar = a.postArticle.author.lastName.toLowerCase();
                }
                if (b.postArticle && b.postArticle.author && b.postArticle.author.lastName) {
                  bVar = b.postArticle.author.lastName.toLowerCase();
                }
                switch (sortCreatedBy) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.postArticle.title ? a.postArticle.title.toLowerCase() : 'z';
                const bVar = b.postArticle.title ? b.postArticle.title.toLowerCase() : 'z';
                switch (sortTitle) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.postArticle.expirationDate ? a.postArticle.expirationDate : 0;
                const bVar = b.postArticle.expirationDate ? b.postArticle.expirationDate : 0;
                switch (sortReceived) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((assignment) => (
                <tr>
                  <td>
                    <img src={assignment.viewed ? Read : Unread} alt={assignment.viewed ? 'Read icon' : 'Unread icon'} />
                  </td>
                  <td>
                    {assignment.createdAt && dateOnly(assignment.createdAt)}
                  </td>
                  <td>
                    {
                      assignment.postArticle
                      && assignment.postArticle.author
                      && assignment.postArticle.author.firstName
                      && assignment.postArticle.author.lastName
                      && (
                        `${assignment.postArticle.author.firstName} ${assignment.postArticle.author.lastName}`
                      )
                    }
                  </td>
                  <td>
                    <Link to={`/posts-articles/${assignment.postArticle.id}`}>
                      {
                        assignment.postArticle
                        && assignment.postArticle.title
                      }
                    </Link>
                  </td>
                  <td>
                    {
                      assignment.postArticle
                      && assignment.postArticle.expirationDate
                      && dateOnly(assignment.postArticle.expirationDate)
                    }
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </>
  );
};
export default PostAndArticleTable;

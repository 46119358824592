/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { DistributionUniversal } from '../models/distributionModels';
import { Member } from '../store/memberSlice';

// eslint-disable-next-line import/prefer-default-export
export const populateMemberMaturityFilter = (
  members: Member[],
  distModel: DistributionUniversal,
) => {
  const memberMaturityOptions: { label: string, value: string }[] = [{
    label: 'All',
    value: 'All',
  }];
  if (members) {
    const newMembers = members
      .slice()
      .filter((member) => {
        if (
          distModel
          && distModel.specificMemberTypes
          && distModel.specificMemberTypes.length > 0
          && (distModel.specificMemberTypes.includes(member.entityType)
            || distModel.specificMemberTypes.includes('All'))
        ) {
          return member;
        }
        if (
          !distModel
          || !distModel.specificMemberTypes
          || distModel.specificMemberTypes.length <= 0
        ) {
          return member;
        }
      })
      .map((member) => member.cyberMaturityRating)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter((notNull) => notNull);

    if (newMembers && newMembers.length > 0) {
      newMembers
        .map((type) => {
          if (type) {
            const option = {
              label: type,
              value: type,
            };
            memberMaturityOptions.push(option);
          }
        });
    }
  }
  return memberMaturityOptions;
};

export const populateMemberSubTypeFilter = (
  members: Member[],
  distModel: DistributionUniversal,
) => {
  const memberSubtypeOptions: { label: string, value: string}[] = [{
    label: 'All',
    value: 'All',
  }];
  if (members) {
    const newMembers = members
      .slice()
      .filter((member) => {
        if (
          distModel
          && distModel.specificMemberTypes
          && distModel.specificMemberTypes.length > 0
          && (distModel.specificMemberTypes.includes(member.entityType)
          || distModel.specificMemberTypes.includes('All'))
        ) {
          return member;
        }
        if (
          !distModel
          || !distModel.specificMemberTypes
          || distModel.specificMemberTypes.length <= 0
        ) {
          return member;
        }
      })
      .filter((notNull) => notNull);

    if (newMembers && newMembers.length > 0) {
      const finalFilterMembers = newMembers
        .slice()
        .filter((member: Member) => {
          if (
            distModel
            && distModel.specificMemberSubTypes
            && distModel.specificMemberSubTypes.length > 0
            && (distModel.specificMemberSubTypes.includes(member.propertyExposureClass)
            || distModel.specificMemberSubTypes.includes('All'))
          ) {
            return member;
          }
          if (
            !distModel
            || !distModel.specificMemberSubTypes
            || distModel.specificMemberSubTypes.length <= 0
          ) {
            return member;
          }
        })
        .map((member) => member.propertyExposureClass)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((notNull) => notNull);

      if (finalFilterMembers && finalFilterMembers.length > 0) {
        finalFilterMembers
          .map((type) => {
            if (type) {
              const option = {
                label: type,
                value: type,
              };
              memberSubtypeOptions.push(option);
            }
          });
      }
    }
  }
  return memberSubtypeOptions;
};

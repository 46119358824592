import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import Delete from '../../../../../assets/icons/Delete.svg';
import { HowToSlide } from '../../../../../models/howToModel';

interface Props {
  slideData: HowToSlide;
  removeSlide: Function;
  updateContent: Function;
}

const CreateSlide: React.FC<Props> = (props: Props) => {
  const {
    slideData,
    removeSlide,
    updateContent,
  } = props;
  const [quillContent, setQuillContent] = useState(slideData.content || '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (
      quillContent !== slideData.content
    ) {
      setQuillContent(slideData.content);
    }
  }, [slideData.content]);

  const submitChanges = (content: string) => {
    setQuillContent(content);
    updateContent({
      ...slideData,
      content,
    });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'align',
  ];

  return (
    <Accordion className="item-accordion">
      <Card>
        <Card.Header className="d-flex how-to-header justify-content-between">
          <Form.Group className="mb-0 input-group" controlId="title">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={slideData.slideTitle}
              onChange={(e: any) => updateContent({ ...slideData, slideTitle: e.target.value })}
            />
          </Form.Group>
          <Button
            className="button-link p-0"
            onClick={() => {
              // eslint-disable-next-line no-alert
              const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
              if (result) {
                removeSlide(slideData.order);
              }
            }}
          >
            <img src={Delete} alt="delete icon" />
          </Button>
          <Accordion.Toggle className="button-link text-underline d-flex justify-content-between align-items-center" eventKey="0" onClick={() => (open ? setOpen(false) : setOpen(true))}>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Form.Group controlId="message" className="quill-box-content mb-0">
            <ReactQuill
              className="quill-box"
              theme="snow"
              modules={modules}
              formats={formats}
              value={quillContent}
              onChange={(content) => submitChanges(content)}
            />
          </Form.Group>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CreateSlide;

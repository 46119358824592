/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Table,
} from 'react-bootstrap';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import upload from '../../../../assets/icons/Upload.svg';
import deleteIcon from '../../../../assets/icons/Delete.svg';
import { useAppDispatch } from '../../../../store/hooks';
import { createDocumentAsync, DocumentDTO, getAllDocumentsListAsync } from '../../../../store/documentsSlice';
import { User } from '../../../../store/userSlice';
import { getMembersAsync, Member } from '../../../../store/memberSlice';

interface Props {
  currentUser: User;
  memberList: Member[] | null;
  s3Directory: 'memberDocs' | 'portalDocs';
  selectedMember: Member | null;
  memberUser: boolean;
}

interface MemberDropdown {
  value: number;
  name: string;
}

const UploadModal: React.FC<Props> = ({
  children,
  currentUser,
  s3Directory,
  memberList,
  selectedMember,
  memberUser,
}) => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File>();
  const [title, setTitle] = useState<string | null>('');
  const [selectAll, setSelectAll] = useState(false);
  // TODO - add selected member ids to state below
  // eslint-disable-next-line no-unused-vars
  const [selectedMemberId, setSelectedMemberIds] = useState<number[]>([]);
  const [memberIdStaging, setMemberIdStaging] = useState<number[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [titleError, setTitleError] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [fileError, setFileError] = useState<boolean>(false);
  const [open, setOpen] = useState(true);

  const handleShow = () => {
    setShow(true);
    if (selectedMember) {
      if (memberUser) {
        setMemberIdStaging([selectedMember.id]);
      } else {
        setSelectedMemberIds([selectedMember.id]);
      }
    }
  };
  const handleClose = () => {
    setFile(undefined);
    setTitle(null);
    setSelectedMemberIds([]);
    setMemberIdStaging([]);
    setShow(false);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (s3Directory === 'portalDocs') {
      dispatch(getMembersAsync());
    }
  }, []);

  // eslint-disable-next-line max-len
  const primexMemberNumber: number | null = process.env.REACT_APP_PRIMEX_MEMBER_NUMBER ? parseInt(process.env.REACT_APP_PRIMEX_MEMBER_NUMBER, 10) : null;

  const populateMemberIdState = (array: MemberDropdown[]) => {
    const memberIds: number[] = [];
    array.map((memberObject) => (
      memberIds.push(memberObject.value)
    ));
    setSelectedMemberIds(memberIds);
  };

  const errorCheck = () => {
    let error = false;
    if (!title) {
      setTitleError(true);
      error = true;
    }
    if (!file) {
      setFileError(true);
      error = true;
    }

    if (s3Directory === 'portalDocs' && (selectedMemberId.length <= 0 && !selectAll)) {
      error = true;
    }
    // TODO check for duplicate title.
    return error;
  };

  const submitUpload = async (e: any) => {
    e.preventDefault();
    if (!errorCheck() && title && file) {
      // TODO: for single member user, add multi
      const memberIds: number[] = [];
      if (selectedMemberId.length > 0) {
        memberIds.push(...selectedMemberId);
      } else if (currentUser.members && currentUser.members.length > 0) {
        currentUser.members.map((member) => (
          memberIds.push(member.id)
        ));
      }
      const newDocData: DocumentDTO = {
        title,
        origin: 'General Upload',
        fileName: file.name,
        fileType: file.type,
        s3Directory,
        memberIds: selectAll ? [] : memberIds,
        members: null,
        allMembers: selectAll,
      };
      await dispatch(createDocumentAsync({
        documentData: newDocData,
        file,
      }));
      dispatch(getAllDocumentsListAsync());
      handleClose();
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (memberIdStaging.includes(checkboxId)) {
      const newArray = memberIdStaging.filter((item) => item !== checkboxId);
      setMemberIdStaging([...newArray]);
    } else {
      setMemberIdStaging([checkboxId, ...memberIdStaging]);
    }
  };

  const onParentMemberCheck = (parentMemberId: number) => {
    const childIdArray: number[] = [];
    currentUser.members.slice()
      .map((member) => {
        if (member.id === parentMemberId) {
          childIdArray.push(member.id);
        } else if (member.parentMember && (member.parentMember.id === parentMemberId)) {
          childIdArray.push(member.id);
        }
      });
    // eslint-disable-next-line max-len
    const allIdsExist = memberIdStaging && memberIdStaging.length > 0 ? childIdArray.every((v) => memberIdStaging.includes(v)) : false;
    const stagingCopy: number[] = [];
    if (allIdsExist) {
      memberIdStaging.slice().map((stagingId) => {
        if (!childIdArray.includes(stagingId)) {
          stagingCopy.push(stagingId);
        }
      }).filter((e) => e);
    } else {
      stagingCopy.push(...childIdArray);
    }
    if (stagingCopy && stagingCopy.length > 0) {
      // setMemberIdStaging(stagingCopy);
      setMemberIdStaging(Array.from(new Set(stagingCopy)));
    } else {
      setMemberIdStaging([]);
    }
  };

  const allChildrenChecked = (parentMemberId: number) => {
    const childIdArray: number[] = [];
    currentUser.members.slice()
      .map((member) => {
        if (member.id === parentMemberId) {
          childIdArray.push(member.id);
        } else if (member.parentMember && (member.parentMember.id === parentMemberId)) {
          childIdArray.push(member.id);
        }
      });
    // eslint-disable-next-line max-len
    const allIdsExist = memberIdStaging && memberIdStaging.length > 0 ? childIdArray.every((v) => memberIdStaging.includes(v)) : false;
    return allIdsExist;
  };

  const submitMemberIds = () => {
    if (memberIdStaging && memberIdStaging.length > 0) {
      setSelectedMemberIds(memberIdStaging);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {children}
      </Button>

      <Modal show={show} onHide={handleClose} className="docUploadModal">
        {
          s3Directory === 'memberDocs'
            && currentUser.members
            && currentUser.members.length > 1
            && selectedMemberId.length === 0
            ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 className="mb-0">
                      Select Members
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    You are associated with
                    {' '}
                    {currentUser.members.length}
                    {' '}
                    Members.
                  </p>
                  <h4>Which Member(s) do you want to upload documents for?</h4>
                  {
                    currentUser
                    && currentUser.members
                    && currentUser.members
                      .slice()
                      .filter((member) => member.memberNumber !== primexMemberNumber)
                      .map((member) => {
                        const newParentMemberNumber = member.id;
                        if (!member.parentMember) {
                          return (
                            <Accordion defaultActiveKey="0" className="mb-2" key={newParentMemberNumber}>
                              <Card className="member-select-card">
                                <Accordion.Toggle
                                  as={Card.Header}
                                  className="d-flex justify-content-between align-items-center"
                                  eventKey="0"
                                  onClick={() => {
                                    if (open) {
                                      setOpen(false);
                                    } else {
                                      setOpen(true);
                                    }
                                  }}
                                >
                                  <h3 className="m-0 py-1">
                                    {member.memberName}
                                  </h3>
                                  <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                  <Table bordered className="member-select">
                                    <thead>
                                      <tr>
                                        <th className="check-box">
                                          <input
                                            type="checkbox"
                                            onChange={
                                              () => onParentMemberCheck(newParentMemberNumber)
                                            }
                                            checked={allChildrenChecked(newParentMemberNumber)}
                                          />
                                        </th>
                                        <th>
                                          <strong>Member</strong>
                                        </th>
                                        <th>
                                          <strong>Maturity</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        currentUser.members
                                        && currentUser.members
                                          .slice()
                                          .filter(
                                            // eslint-disable-next-line max-len
                                            (childMember) => childMember.memberNumber !== primexMemberNumber,
                                          )
                                          .filter((childMember) => {
                                            if (childMember.parentMember) {
                                              if (
                                                // eslint-disable-next-line max-len
                                                childMember.parentMember.id === newParentMemberNumber
                                              ) {
                                                return childMember;
                                              }
                                            }
                                          })
                                          .map((childMember) => (
                                            <tr>
                                              <td className="check-box">
                                                <input
                                                  type="checkbox"
                                                  onChange={() => onCheckboxChange(childMember.id)}
                                                  checked={memberIdStaging.includes(childMember.id)}
                                                />
                                              </td>
                                              <td>
                                                {childMember.memberName}
                                              </td>
                                              <td>
                                                {
                                                  childMember.cyberMaturityRating || 'N/A'
                                                }
                                              </td>
                                            </tr>
                                          ))
                                      }
                                      <tr>
                                        <td className="check-box">
                                          <input
                                            type="checkbox"
                                            onChange={() => onCheckboxChange(member.id)}
                                            checked={memberIdStaging.includes(member.id)}
                                          />
                                        </td>
                                        <td>
                                          {member.memberName}
                                        </td>
                                        <td>
                                          {member.cyberMaturityRating || 'N/A'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          );
                        }
                      })
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={memberIdStaging.length <= 0} onClick={submitMemberIds}>
                    Continue to Upload
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3 className="mb-0">
                      Upload Documents
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form id="file-upload-form" className="d-flex flex-column" onSubmit={submitUpload}>
                    <Form.Group controlId="fileTitle">
                      <Form.Label>Title</Form.Label>
                      <InputGroup>
                        <Form.Control type="text" placeholder="Enter a descriptive title for this document" required isInvalid={titleError} value={title || ''} onChange={(e: any) => setTitle(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                          Please enter a unique title.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    {
                      s3Directory === 'portalDocs'
                      && memberList
                      && (
                        <>
                          <Form.Group controlId="allMemberSelect">
                            <Form.Check type="checkbox" label="Assign to all members and users." checked={selectAll} onChange={() => setSelectAll(!selectAll)} />
                          </Form.Group>
                          <Form.Group controlId="memberSelectInput">
                            <Form.Label>Select Member(s)</Form.Label>
                            <Select
                              className="multi-select-dropdown"
                              isMulti
                              isDisabled={selectAll}
                              options={
                                memberList
                                  .slice()
                                  .filter((member) => {
                                    if (
                                      currentUser.restrictedDataAccess
                                      && currentUser.members
                                      // eslint-disable-next-line max-len
                                      && currentUser.members.find((mem) => mem.memberNumber === primexMemberNumber)
                                    ) {
                                      return member;
                                    }
                                    if (member.memberNumber !== primexMemberNumber) {
                                      return member;
                                    }
                                  })
                                  // eslint-disable-next-line max-len
                                  .sort((a, b) => (a.memberName.toLowerCase() < b.memberName.toLowerCase() ? -1 : 1))
                                  .map((member) => (
                                    {
                                      label: member.memberName,
                                      value: member.id,
                                    }
                                  ))
                              }
                              onChange={
                                (selectedOption: any) => populateMemberIdState(selectedOption)
                              }
                              defaultValue={
                                selectedMember
                                  ? { label: selectedMember.memberName, value: selectedMember.id }
                                  : undefined
                              }
                            />
                          </Form.Group>
                        </>
                      )
                    }
                    {
                      s3Directory === 'memberDocs'
                      && currentUser.members
                      && currentUser.members.length > 0
                      && currentUser.members.find(
                        (member) => member.memberNumber === primexMemberNumber,
                      )
                      && (
                        <Form.Group controlId="memberSelectInput">
                          <Form.Label>Select Member(s)</Form.Label>
                          <Select
                            className="multi-select-dropdown"
                            isDisabled
                            placeholder={currentUser.members[0].memberName}
                          />
                        </Form.Group>
                      )
                    }
                    <Form.Group controlId="formFile" className="input-container">
                      {
                        !file
                          ? (
                            <>
                              <img src={upload} alt="Upload icon" className="mb-3" />
                              <span>
                                Drag and drop file here or
                                <span className="browse"> browse</span>
                                .
                              </span>
                              <Form.File
                                className="file-upload"
                                multiple={false}
                                type="file"
                                feedback="Please attach a valid file."
                                isInvalid={fileError}
                                onChange={(e: any) => {
                                  setUploadErrorMessage('');
                                  setFile(e.target.files[0]);
                                  if (file) {
                                    setFileError(false);
                                  }
                                }}
                                accept=".xlsx,.doc,.docx,.pdf,.jpg,.jpeg,.png,.xls,.csv"
                              />
                            </>
                          )
                          : (
                            <div className="d-flex align-items-center">
                              <Button className="delete-file" onClick={() => setFile(undefined)}>
                                <img src={deleteIcon} alt="Delete uploaded Excel file Icon" />
                              </Button>
                              <div className="d-flex flex-column ml-3">
                                <span>{file.name}</span>
                                <span>
                                  {file.size}
                                  {' '}
                                  KB
                                </span>
                              </div>
                            </div>
                          )
                      }
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" form="file-upload-form">
                    Upload
                  </Button>
                </Modal.Footer>
              </>
            )
        }
      </Modal>
    </>
  );
};

export default UploadModal;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import {
  Page,
  Text,
  Image,
  Font,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import TagManager from 'react-gtm-module';
import pdfBackgroundLogo from '../assets/logo.png';
import pdfBackgroundDiamonds from '../assets/bkg-diamonds.png';
import { Member } from '../store/memberSlice';
import { User } from '../store/userSlice';
import { dateOnly } from './timeAndDate';

export interface Question {
  id?: number;
  question: string;
  category: string;
  explanation: string;
  answerOption1: string;
  answerOption2: string;
  answerOption3: string;
  answerOption4: string;
}

interface Answer {
  id: number;
  answer: string;
  question: Question;
}
interface QuestionnaireSession {
  answers: Answer[];
}

export interface QuestionnaireAssignments {
  id: number;
  member: Member;
  users: User[];
  createdAt: Date;
}

interface Questionnaire {
  id: number;
  name: string;
  dueDate: Date | null;
  questionnaireEngineTemplateId: number;
  questionnaireEngineName: string;
  questionnaireEngineVideo: string;
  questionnaireEngineExplanation: string;
  questions: Question[];
  questionnaireSessions: QuestionnaireSession[];
  questionnaireAssignments?: QuestionnaireAssignments[];
  author: User;
  createdAt: Date;
}

interface Props {
  questionnaire: Questionnaire;
  userDownload?: boolean;
  specificMemberName?: string;
  currentSession?: {
    questionnaireAssignments: QuestionnaireAssignments[];
  } | null;
}

Font.register({ family: 'lato', src: 'https://fonts.googleapis.com/css2?family=Lato&display=swap' });

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 75,
    fontFamily: 'lato',
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  questionRow: {
    marginLeft: 10,
  },
  questionItem: {
    marginBottom: 10,
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    right: 0,
    width: '110%',
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  questionnaireName: {
    fontSize: 28,
  },
  questionnaireExplanation: {
    fontSize: 20,
  },
  headerRegular: {
    fontFamily: 'lato',
  },
  fontBody: {
    fontFamily: 'lato',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
});

const ConvertToPdf: React.FC<Props> = (props: Props) => {
  const {
    questionnaire,
    userDownload,
    currentSession,
    specificMemberName,
  } = props;
  useEffect(() => {
    if (userDownload) {
      const tagManagerArgs = {
        dataLayer: {
          event: 'cyberPortal',
          action: 'print',
          category: 'questionnaires',
          label: 'Print Questionnaire from Session',
          value: questionnaire.name,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, []);
  return (
    <Document>
      <Page size="A4" style={styles.page} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.section]}>
            <View>
              <Text style={[styles.questionnaireName, styles.headerRegular]}>
                {questionnaire.name}
              </Text>
            </View>
            <View>
              <Text style={[styles.questionnaireExplanation, styles.headerRegular]}>
                {questionnaire.questionnaireEngineExplanation}
              </Text>
            </View>
          </View>
          <View style={[styles.section]}>
            <View>
              <Text style={[{ fontSize: 20, marginBottom: 2 }, styles.headerRegular]}>
                Primex Cybersecurity Portal
              </Text>
            </View>
            <View>
              <Text style={[{ fontSize: 20, marginBottom: 2 }, styles.headerRegular]}>
                <Text>For: </Text>
                {
                  !userDownload
                  && !specificMemberName
                  && questionnaire.questionnaireAssignments
                  && questionnaire.questionnaireAssignments.length > 0
                  && questionnaire.questionnaireAssignments
                    .map((assignment) => {
                      if (assignment.member) {
                        const name = assignment.member.memberName;
                        return (
                          <Text>
                            {name}
                            <Text>, </Text>
                          </Text>
                        );
                      }
                    })
                }
                {
                  !userDownload
                  && specificMemberName
                  && (
                    <Text>
                        {specificMemberName}
                    </Text>
                  )
                }
                {
                  userDownload
                  && currentSession
                  && currentSession.questionnaireAssignments
                  && currentSession.questionnaireAssignments.length > 0
                  && currentSession.questionnaireAssignments.map((assignment, index) => {
                    if (assignment.member) {
                      const name = assignment.member.memberName;
                      if (index === 0) {
                        return (
                          <Text>{name}</Text>
                        );
                      }
                      return (
                        <Text>{`, ${name}`}</Text>
                      );
                    }
                  })
                }
              </Text>
            </View>
          </View>
          <View style={[styles.section, styles.fontBody]}>
            <View style={[{ marginBottom: 2 }]}>
              <Text style={{ fontSize: 20 }}>
                {questionnaire.questions.length}
                <Text> questions</Text>
              </Text>
            </View>
            <View style={[{ marginBottom: 2 }, styles.headerRegular]}>
              <Text style={{ fontSize: 20 }}>
                <Text>Due: </Text>
                <Text>
                  {
                    questionnaire.dueDate
                    && dateOnly(questionnaire.dueDate)
                  }
                </Text>
              </Text>
            </View>
            <View>
              <Text style={[{ fontSize: 20 }, styles.headerRegular]}>
                <Text>Created by: </Text>
                <Text>
                  {
                    questionnaire.author
                      && questionnaire.author.firstName
                      && questionnaire.author.lastName
                      ? (
                        `${questionnaire.author.firstName} ${questionnaire.author.lastName}`
                      ) : (
                        'Primex Admin'
                      )
                  }
                </Text>
                <Text> on </Text>
                {
                  questionnaire.createdAt
                  && dateOnly(questionnaire.createdAt)
                }
              </Text>
            </View>
          </View>
          <View style={[styles.fontBody]}>
            {
              questionnaire.questions.map((question, index) => (
                <View key={question.id} style={[styles.section]}>
                  <View style={[styles.section]}>
                    <View style={[{ marginBottom: 2 }]}>
                      <Text style={[styles.headerRegular, { fontSize: 14 }]}>{`Question Number: ${index + 1}`}</Text>
                    </View>
                    <View style={[{ marginBottom: 2 }, styles.primexBlue, styles.fontBody]}>
                      <Text>{question.question}</Text>
                    </View>
                    <View style={[{ marginBottom: 2 }, styles.fontBody]}>
                      <Text>{question.explanation}</Text>
                    </View>
                  </View>
                  <View style={[styles.questionRow, styles.fontBody]}>
                    <Text style={[styles.questionItem, styles.fontBody]}>
                      {question.answerOption1}
                    </Text>
                    <Text style={[styles.questionItem, styles.fontBody]}>
                      {question.answerOption2}
                    </Text>
                    <Text style={[styles.questionItem, styles.fontBody]}>
                      {question.answerOption3
                        && (
                          <Text>
                            {question.answerOption3}
                          </Text>
                        )}
                    </Text>
                    <Text style={[styles.questionItem, styles.fontBody]}>
                      {question.answerOption4
                        && (
                          <Text>
                            {question.answerOption4}
                          </Text>
                        )}
                    </Text>
                  </View>
                </View>
              ))
            }
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue, styles.headerRegular]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};
export default ConvertToPdf;

ConvertToPdf.defaultProps = {
  userDownload: false,
  currentSession: null,
  specificMemberName: undefined,
};

import React from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import pdfBackgroundLogo from '../../../../../assets/logo.png';
import pdfBackgroundDiamonds from '../../../../../assets/bkg-diamonds.png';
import { IrClaim } from '../../../../../store/irClaimSlice';

interface Props {
    irClaim: IrClaim;
}

const styles = StyleSheet.create({
  page: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 75,
    paddingBottom: 50,
    fontFamily: 'lato',
    fontSize: 12,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  pageBackgroundLogo: {
    position: 'absolute',
    width: '35%',
    height: 'auto',
    left: 0,
  },
  pageBackgroundDiamond: {
    position: 'absolute',
    right: 0,
    width: '50%',
  },
  fontBody: {
    fontFamily: 'Courier',
  },
  primexBlue: {
    color: '#00263E',
  },
  footer: {
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleFonts: {
    fontSize: 16,
  },
  subTitleFonts: {
    fontSize: 14,
  },
  contentBlock: {
    marginRight: 25,
  },
  contentBlockParent: {
    marginBottom: 10,
  },
  contentTitle: {
    textDecoration: 'underline',
  },
});

const ClaimTechnologyPdf: React.FC<Props> = ({ irClaim }) => {
  if (!irClaim.technology) {
    return null;
  }
  const {
    title,
    technology: {
      equipmentAddress1,
      equipmentAddress2,
      equipmentAttention,
      equipmentCity,
      equipmentCompany,
      equipmentState,
      equipmentZipCode,
      identityManagementApplications,
      impactedDNSProviders,
      impactedExternalIPsAndLocations,
      impactedWANProviders,
      wapVendorModelRevisionNumber,
      internalIPRanges,
      applianceVendorAndVersion,
      endpointSecuritySoftware,
      recommendedEDR,
      cloudServiceProviders,
      saasServiceProviders,
      emailServerSoftware,
      hypervisorOEMVersion,
      switchingHardwareVendorType,
      managedSOC,
    },
  } = irClaim;

  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.fontBody]} fixed>
        <Image src={pdfBackgroundLogo} style={[styles.pageBackgroundLogo]} fixed />
        <Image src={pdfBackgroundDiamonds} style={[styles.pageBackgroundDiamond]} fixed />
        <View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Technology
            </Text>
          </View>
          <View style={[styles.inline, styles.section, styles.titleFonts]}>
            <Text style={[styles.contentBlock]}>
              Claim Title:
            </Text>
            <Text>
              {title || 'No Title'}
            </Text>
          </View>
          <View>
            <Text style={[styles.contentBlock]}>
              Equipment Shipping Address
            </Text>
            <View style={[styles.inline]}>
              <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
                <Text style={[styles.contentTitle]}>
                  Company
                </Text>
                <Text>
                  {
                  equipmentCompany || ''
                }
                </Text>
              </View>
              <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
                <Text style={[styles.contentTitle]}>
                  Attention
                </Text>
                <Text>
                  {
                  equipmentAttention || ''
                }
                </Text>
              </View>
            </View>
            <View style={[styles.inline]}>
              <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
                <Text style={[styles.contentTitle]}>
                  Address 1
                </Text>
                <Text>
                  {
                  equipmentAddress1 || ''
                }
                </Text>
              </View>
              <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
                <Text style={[styles.contentTitle]}>
                  Address 2
                </Text>
                <Text>
                  {
                  equipmentAddress2 || ''
                }
                </Text>
              </View>
            </View>
            <View style={[styles.inline]}>
              <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
                <Text style={[styles.contentTitle]}>
                  City
                </Text>
                <Text>
                  {
                  equipmentCity || ''
                }
                </Text>
              </View>
              <View style={[styles.column, styles.contentBlock, { width: '25%' }]}>
                <Text style={[styles.contentTitle]}>
                  State
                </Text>
                <Text>
                  {
                  equipmentState || ''
                }
                </Text>
              </View>
              <View style={[styles.column, styles.contentBlock, { width: '25%' }]}>
                <Text style={[styles.contentTitle]}>
                  Zip Code
                </Text>
                <Text>
                  {
                  equipmentZipCode || ''
                }
                </Text>
              </View>
            </View>
          </View>
          <Text style={[styles.contentBlock]}>
            Technical Scope
          </Text>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '100%' }]}>
              <Text style={[styles.contentTitle]}>
                Externally Impacted IPs and Geographic Location (City, Name, or State)
              </Text>
              <Text>
                {
                  impactedExternalIPsAndLocations || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                In Scope Internal IP Ranges (Static &amp; DHCP)
              </Text>
              <Text>
                {
                  internalIPRanges || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Perimeter Firewall/Security Appliances Vendor and Software Version Number
              </Text>
              <Text>
                {
                  applianceVendorAndVersion || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                User Endpoint Security Software
              </Text>
              <Text>
                {
                  endpointSecuritySoftware || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Recommended EDR
              </Text>
              <Text>
                {
                  recommendedEDR || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Impacted DNS Providers
              </Text>
              <Text>
                {
                  impactedDNSProviders || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Impacted WAN Providers
              </Text>
              <Text>
                {
                  impactedWANProviders || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Any Cloud Service Providers (if in scope)
              </Text>
              <Text>
                {
                  cloudServiceProviders || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Saas/IaaS/DRass Service Providers
              </Text>
              <Text>
                {
                  saasServiceProviders || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Wireless Access Point Vendor, WAP model, and Software Revision Number
              </Text>
              <Text>
                {
                  wapVendorModelRevisionNumber || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Indentity Management Applications (i.e. Active Directory, Auth0)
              </Text>
              <Text>
                {
                  identityManagementApplications || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Email Server Software (or vendor) Please not on-prem or cloud-hosted
              </Text>
              <Text>
                {
                  emailServerSoftware || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Hypervisor OEM and Version Number
              </Text>
              <Text>
                {
                  hypervisorOEMVersion || ''
                }
              </Text>
            </View>
          </View>
          <View style={[styles.inline]}>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Name of Managed SOCd
              </Text>
              <Text>
                {
                  managedSOC || ''
                }
              </Text>
            </View>
            <View style={[styles.column, styles.contentBlock, { width: '50%' }]}>
              <Text style={[styles.contentTitle]}>
                Switching Hardware Vendor and Type
              </Text>
              <Text>
                {
                  switchingHardwareVendorType || ''
                }
              </Text>
            </View>
          </View>
        </View>
        <Text style={[styles.footer, styles.primexBlue]} fixed>
          Trust. Excellence. Service.
        </Text>
      </Page>
    </Document>
  );
};

export default ClaimTechnologyPdf;

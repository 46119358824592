import React from 'react';
import { Alert } from 'react-bootstrap';
import { QuestionnaireErrors } from '../../../models/questionnaireErrorModel';

interface Props {
    errorDetails: QuestionnaireErrors;
 }

const QuestionnaireErrorDetails: React.FC<Props> = ({ errorDetails }) => {
  if (!errorDetails) {
    return <div />;
  }

  const { questionnaireEngineName, questionnaireEngineExplanation, questionErrors } = errorDetails;
  return (
    <>
      <Alert variant="danger">
        <h2>Errors in questionnaire</h2>
        {
          questionnaireEngineName
          && (<h4>The Questionnaire Name is required and must be less than 255 characters.</h4>)
        }
        {
          questionnaireEngineExplanation
          && (<h4>The Questionnaire Explanation is required.</h4>)
        }
        {
          questionErrors
          && questionErrors.length > 0
          && questionErrors.map((questionError) => (
            <>
              <h4>
                {`Question in row number: ${questionError.questionRowNumber} has the following errors:`}
              </h4>
              <ul>
                {
                  questionError.errorLocations
                  && questionError.errorLocations.length > 0
                  && questionError.errorLocations.map((location) => (
                    <li>{location}</li>
                  ))
                }
              </ul>
            </>
          ))
        }
      </Alert>
    </>
  );
};

export default QuestionnaireErrorDetails;

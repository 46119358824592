import { User } from '../store/userSlice';

// eslint-disable-next-line import/prefer-default-export
export const checkIrPermission = (user: User) => {
  const irRoleNumber = process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL
    ? parseInt(process.env.REACT_APP_PRIMEX_IR_ROLE_ACCESS_LEVEL, 10)
    : 0;
  if (irRoleNumber > 0) {
    if (user.role && (user.role >= irRoleNumber)) {
      return true;
    }
  }
  return false;
};

export const checkAtomIrPermission = (user: User) => {
  const irAtomRoleNumber = process.env.REACT_APP_PRIMEX_ATOM_IR_ACCESS
    ? parseInt(process.env.REACT_APP_PRIMEX_ATOM_IR_ACCESS, 10)
    : 0;
  if (irAtomRoleNumber > 0) {
    if (user.role && (user.role >= irAtomRoleNumber)) {
      return true;
    }
  }
  return false;
};

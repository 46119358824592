/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link } from 'react-router-dom';
import { Notification, updateNotificationAsync, getCurrentNotificationsAsync } from '../../../../store/notificationSlice';
import Read from '../../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../../assets/icons/MessageClosed.svg';
import { dateAndTime } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';

interface Props {
  notification: Notification;
}

const ChecklistRow: React.FC<Props> = ({ notification }) => {
  const {
    id,
    viewed,
    checklistAssignment,
    title,
    type,
    createdAt,
  } = notification;
  const dispatch = useAppDispatch();

  const changeViewed = async () => {
    if (checklistAssignment) {
      await dispatch(updateNotificationAsync({
        id: notification.id,
        body: {
          viewed: true,
        },
      }));
      dispatch(getCurrentNotificationsAsync());
    }
  };

  if (checklistAssignment) {
    return (
      <tr key={`${id}-postArticleNotification`}>
        <td>
          <img src={viewed ? Read : Unread} alt={viewed ? 'Read icon' : 'Unread icon'} />
        </td>
        <td>
          {dateAndTime(createdAt)}
        </td>
        <td>{type}</td>
        <td>
          <Link onClick={changeViewed} to={`/checklists/${checklistAssignment.id}`}>
            {
              checklistAssignment
              && checklistAssignment.checklist
              && checklistAssignment.checklist.title
                ? checklistAssignment.checklist.title
                : title
            }
          </Link>
        </td>
        <td />
      </tr>
    );
  }
  return (
    <tr />
  );
};
export default ChecklistRow;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getAllContacts from '../api/contactAPI';

export interface PrimexTraining {
  id: number;
  contactType: string;
}

// TODO this all needs to be redone.

export interface PrimexContactState {
  primexContacts: PrimexTraining[] | null;
  selectedContact: PrimexTraining | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: PrimexContactState = {
  primexContacts: [],
  selectedContact: null,
  status: 'idle',
  error: null,
};

const getAllContactsAsync = createAsyncThunk(
  'primexContact/getAll',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await getAllContacts();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const primexContactsSlice = createSlice({
  name: 'primexContacts',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContactsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllContactsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.primexContacts = action.payload;
        state.error = null;
      })
      .addCase(getAllContactsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.primexContacts = [];
        state.error = action.payload;
      });
  },
});

export default primexContactsSlice.reducer;

export {
  getAllContactsAsync,
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    show: boolean;
    setShow: Function;
}

const InfoModal: React.FC<Props> = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex mt-auto">
          <FontAwesomeIcon className="mr-1 modal-info-icon" icon={faInfoCircle} />
          <h3 className="mt-auto mb-0">Information</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This Area of the cyber portal
          contains confidential information and communications regarding Primex
          cyber claims. Primex cyber claims management information is exempt from
          RSA 91-A. See RSA 91-1:5, XI and RSA 5-B:7(incorporated into RSA 91-A:4,I).
          Primex cyber claims management records may also include information protected by
          the attorney-client privelege, attorney work product doctrine and/or adjuster work
          product doctrine. See RSA 91-A:5 XII. Access to Primex cyber claims management
          information is conditioned on maintaining its confidentiality. Primex cyber
          claims management information may not be disclosed to third parties without
          Primex&apos;s prior review and approval
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;

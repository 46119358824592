import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteQuestionnaire,
  getAllQuestionnaires,
  getQuestionnaire,
  getQuestionnaireSession,
  updateQuestionnaireSession,
  postQuestionnaire,
  startQuestionnaireSession,
  submitAnswer,
  submitQuestionnaire,
  updateQuestionaire,
} from '../api/questionnaireAPI';
import { downloadQuestionnaire } from '../api/questionnaireEngineAPI';
import { User } from './userSlice';

export interface SubmitQuestionnaireBody {
  question: string;
  answer: number | null;
  multiAnswer: number[] | null;
  order: number;
}

export interface Questionnaire {
  id: number;
  name: string;
  assignmentDetails: string | null;
  dueDate: Date | null;
  questionnaireEngineTemplateId: number;
  questionnaireEngineName: string;
  questionnaireEngineVideo: string;
  questionnaireEngineExplanation: string;
  questionnaireEngineType: 'Scored' | 'Question Response' | 'Annual Assessment Questionnaire';
  questions: any[];
  questionnaireSessions: any[];
  questionnaireAssignments: any[];
  author: User;
  createdAt: Date;
}

export interface Answer {
  answer: string;
  updatedAt: string;
}

export interface QuestionnaireState {
  questionnaires: Questionnaire[] | null;
  questionnaire: Questionnaire | null;
  currentQuestionnaireSession: any | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: QuestionnaireState = {
  questionnaires: [],
  questionnaire: null,
  currentQuestionnaireSession: null,
  status: 'idle',
  error: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const getAllQuestionnairesAsync = createAsyncThunk(
  'questionnaire/getAllQuestionnaires',
  async (questionnaireData, { rejectWithValue }) => {
    try {
      const response: any = await getAllQuestionnaires();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getQuestionnaireAsync = createAsyncThunk(
  'questionnaire/getQuestionnaire',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getQuestionnaire(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const postStartQuestionnaireSessionAsync = createAsyncThunk(
  'questionnaire/postQuestionnaireSession',
  async (questionnaireAssignments: number[], { rejectWithValue }) => {
    try {
      const response: any = await startQuestionnaireSession(questionnaireAssignments);

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateQuestionnaireSessionAsync = createAsyncThunk(
  'questionnaire/updateQuestionnaireSession',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await updateQuestionnaireSession(id);

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getCurrentQuestionnaireSessionAsync = createAsyncThunk(
  'questionnaire/getQuestionnaireSession',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getQuestionnaireSession(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const patchSubmitAnswer = createAsyncThunk(
  'questionnaire/submitAnswer',
  async (submission: { answerId: number, answer: string }, { rejectWithValue }) => {
    try {
      const response: any = await submitAnswer(submission.answerId, submission.answer);

      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const submitQuestionnaireAsync = createAsyncThunk(
  'questionnaire/submitQuestionnaire',
  async (sessionId: number, { rejectWithValue }) => {
    try {
      const response: any = await submitQuestionnaire(sessionId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const postQuestionnaireAsync = createAsyncThunk(
  'questionnaire/postQuestionnaire',
  async (submission: { formUpload: File, formTitle: string }, { rejectWithValue }) => {
    try {
      const response: any = await postQuestionnaire(submission.formUpload, submission.formTitle);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        data: err.response.data.message,
      });
    }
  },
);

const deleteQuestionnaireAsync = createAsyncThunk(
  'questionnaire/deleteQuestionnaire',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await deleteQuestionnaire(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const downloadQuestionnaireAsync = createAsyncThunk(
  'questionnaire/downloadQuestionnaire',
  async (engineId: number, { rejectWithValue }) => {
    try {
      const response: any = await downloadQuestionnaire(engineId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateQuestionnaireAsync = createAsyncThunk(
  'questionnaire/updateQuestionnaire',
  async (arg: {id: number, questionnaireBody: Partial<Questionnaire>}, { rejectWithValue }) => {
    try {
      const response: any = await updateQuestionaire(arg.id, arg.questionnaireBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    nullSelectedQuestionnaire(state) {
      state.questionnaire = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get all questionnaires
      .addCase(getAllQuestionnairesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllQuestionnairesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionnaires = action.payload;
        state.error = null;
      })
      .addCase(getAllQuestionnairesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.questionnaires = [];
        state.error = action.payload;
      })
      // get questionnaire
      .addCase(getQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQuestionnaireAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionnaire = action.payload;
        state.error = null;
      })
      .addCase(getQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.questionnaire = null;
        state.error = action.payload;
      })
      .addCase(postStartQuestionnaireSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postStartQuestionnaireSessionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentQuestionnaireSession = action.payload;
        state.error = null;
      })
      .addCase(postStartQuestionnaireSessionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentQuestionnaireSession = null;
        state.error = action.payload;
      })
      .addCase(updateQuestionnaireSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQuestionnaireSessionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentQuestionnaireSession = action.payload;
        state.error = null;
      })
      .addCase(updateQuestionnaireSessionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentQuestionnaireSession = null;
        state.error = action.payload;
      })
      .addCase(patchSubmitAnswer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchSubmitAnswer.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(patchSubmitAnswer.rejected, (state, action) => {
        state.status = 'failed';
        state.currentQuestionnaireSession = null;
        state.error = action.payload;
      })
      .addCase(getCurrentQuestionnaireSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentQuestionnaireSessionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentQuestionnaireSession = action.payload;
        state.error = null;
      })
      .addCase(getCurrentQuestionnaireSessionAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentQuestionnaireSession = null;
        state.error = action.payload;
      })
      .addCase(postQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postQuestionnaireAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionnaire = action.payload.error ? null : action.payload;
        state.error = null;
      })
      .addCase(postQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.questionnaire = null;
        state.error = action.payload;
      })
      .addCase(deleteQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteQuestionnaireAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.questionnaire = null;
        state.error = action.payload;
      })
      .addCase(downloadQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downloadQuestionnaireAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(downloadQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(submitQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitQuestionnaireAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(submitQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQuestionnaireAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.questionnaire = action.payload;
        state.error = null;
      })
      .addCase(updateQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { nullSelectedQuestionnaire } = questionnaireSlice.actions;
export default questionnaireSlice.reducer;

export {
  getAllQuestionnairesAsync,
  getQuestionnaireAsync,
  postStartQuestionnaireSessionAsync,
  updateQuestionnaireSessionAsync,
  patchSubmitAnswer,
  getCurrentQuestionnaireSessionAsync,
  postQuestionnaireAsync,
  deleteQuestionnaireAsync,
  downloadQuestionnaireAsync,
  submitQuestionnaireAsync,
  updateQuestionnaireAsync,
};

import React, { useEffect } from 'react';
// import MemberCard from '../shared/dashboard/MemberCard';
import CyberThreatCard from '../../shared/dashboard/CyberThreatCard';
import RecTrainingCard from '../../shared/dashboard/RecTrainingCard';
import BillboardDisplay from '../../shared/dashboard/Billboard';
import QuestionnaireDashCard from '../../shared/dashboard/QuestionnaireDashCard';
import ChecklistCard from '../../shared/dashboard/ChecklistCard';
import TodoList from '../../shared/dashboard/TodoList';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAllQuestionnairesAsync } from '../../../store/questionnaireSlice';
import { getCurrentUserAsync } from '../../../store/userSlice';
import { getCurrentQuestionnaireAssignmentsAsync, QuestionnaireAssignment } from '../../../store/questionnaireAssignmentsSlice';
import { getCurrentCyberThreatAssignmentsAsync } from '../../../store/cyberThreatAssignmentSlice';
import { getCurrentChecklistAssignmentsAsync } from '../../../store/checklistAssignmentSlice';
import { getCurrentTodoAsync } from '../../../store/todoSlice';

import LatestPost from '../../shared/dashboard/LatestPost';
import PostCarousel from '../../shared/dashboard/PostCarousel';
import AvailableTrainingCard from '../../shared/dashboard/AvailableTrainingCard';

interface Props { }

const getLatestQuestionnaire = (questionnaireAssignments: QuestionnaireAssignment[]) => {
  const latestQuestionnaire = questionnaireAssignments
    .slice().reverse()[0];
  return questionnaireAssignments
    .filter(
      (assignment: QuestionnaireAssignment) => assignment.questionnaire.id
        === latestQuestionnaire.questionnaire.id,
    );
};
const PrimaryUser: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    questionnaire: state.questionnaire,
    questionnaireAssignments: state.questionnaireAssignments,
    cyberThreatAssignments: state.cyberThreatAssignments,
    primexTrainings: state.primexTrainings,
    user: state.user,
    billboardAssignments: state.billboardAssignments,
    postArticleAssignments: state.postArticleAssignments,
    checklistAssignments: state.checklistAssignments,
    todo: state.todo,
  }));

  useEffect(() => {
    dispatch(getAllQuestionnairesAsync());
    dispatch(getCurrentQuestionnaireAssignmentsAsync());
    dispatch(getCurrentUserAsync());
    dispatch(getCurrentCyberThreatAssignmentsAsync());
    dispatch(getCurrentChecklistAssignmentsAsync());
    dispatch(getCurrentTodoAsync());
  }, []);

  const {
    user: { currentUser },
    billboardAssignments: { currentBillboardAssignments },
    postArticleAssignments: { currentPostArticleAssignments },
    questionnaireAssignments: { currentQuestionnaireAssignments },
    cyberThreatAssignments: { currentCyberThreatAssignments },
    checklistAssignments: { currentChecklistAssignments },
    todo: { todos },
  } = currentState;

  // eslint-disable-next-line consistent-return
  const checkForPostArticlePostWidgetDisplay = () => {
    if (currentPostArticleAssignments) {
      if (currentPostArticleAssignments.filter((post) => post
        .postArticle.displayDashboardWidget)[0]) {
        return true;
      }
    }
  };
  // eslint-disable-next-line consistent-return
  const checkForChecklistWidgetDisplay = () => {
    if (currentChecklistAssignments) {
      if (currentChecklistAssignments.filter((post) => post
        .checklist.displayDashboardWidget)[0]) {
        return true;
      }
    }
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title d-flex justify-content-between">
            <h2>
              {currentUser ? `Welcome ${currentUser.firstName} ${currentUser.lastName}!` : 'Welcome!'}
            </h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-4">
            {currentUser
              && todos.length > 0
              && (
                <TodoList List={todos} />
              )}
            {currentChecklistAssignments
              && currentChecklistAssignments.length > 0
              && checkForChecklistWidgetDisplay()
              && (
                <ChecklistCard
                  LatestChecklists={
                    currentChecklistAssignments
                      .slice()
                      .filter((post) => post
                        .checklist.displayDashboardWidget)
                      .sort((a, b) => (a
                        .createdAt.valueOf() < b
                        .createdAt.valueOf() ? 1 : -1))
                  }
                />
              )}
            {
              (currentQuestionnaireAssignments || []).length > 0 ? (
                <QuestionnaireDashCard
                  latestQuestionnaires={getLatestQuestionnaire(currentQuestionnaireAssignments)}
                />
              ) : null
            }
            {
              currentPostArticleAssignments
              && currentPostArticleAssignments.length > 0
              && checkForPostArticlePostWidgetDisplay()
              && (
                <LatestPost
                  latestPostArticle={
                    currentPostArticleAssignments
                      .slice()
                      .filter((post) => post.postArticle.displayDashboardWidget)
                      .sort((a, b) => (a.createdAt.valueOf() < b.createdAt.valueOf() ? 1 : -1))[0]
                      .postArticle
                  }
                />
              )
            }
          </div>
          <div className="col-8">
            {
              currentBillboardAssignments
              && currentBillboardAssignments[0]
              && currentBillboardAssignments[0].billboard
              && (
                <BillboardDisplay selectedBillboard={currentBillboardAssignments[0].billboard} />
              )

            }
            {
              currentCyberThreatAssignments
              && currentCyberThreatAssignments.length > 0
              && (
                <CyberThreatCard />
              )
            }
            <RecTrainingCard data={currentState.primexTrainings.trainings} />
            <AvailableTrainingCard />
            {
              currentPostArticleAssignments
              && currentPostArticleAssignments.length > 0
              && (
                <PostCarousel
                  assignments={currentPostArticleAssignments}
                />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimaryUser;

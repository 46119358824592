import React from 'react';
import { Button, Card } from 'react-bootstrap';
import MicroTraining from '../../../assets/icons/microtraining-icon.svg';
import Webinars from '../../../assets/icons/webinars-icon.svg';

const AvailableTrainingCard = () => (
  <div className="card-section Available-training-card">
    <Card>
      <Card.Body>
        <Card.Title>
          <h2>Available Training</h2>
        </Card.Title>
        <p>
          View our full catalog of cybersecurity training options.
          Learn valuable information in bite sized micro training videos
          or take a deeper dive with our webinars and courses.
        </p>
        <div className="d-flex pt-3">
          <a target="_blank" rel="noreferrer" href="https://nhprimex.org/account/view/training/category/cyber-micro/">
            <Button className="mr-4 p-0 pr-2" variant="primary">
              <img className="mr-3 p-0" src={MicroTraining} alt="micro training icon" />
              Browse Micro Trainings
            </Button>
          </a>
          <a target="_blank" rel="noreferrer" href="https://nhprimex.org/account/view/training/category/cyber-security/">
            <Button className="p-0 pr-2" variant="primary">
              <img className="mr-3" src={Webinars} alt="micro training icon" />
              Browse Webinars &amp; Courses
            </Button>
          </a>
        </div>
      </Card.Body>
    </Card>
  </div>
);

export default AvailableTrainingCard;

/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Complete from '../../../assets/icons/check-circle-fill.svg';
import InProgress from '../../../assets/icons/InProgressCheck.svg';
import NotStarted from '../../../assets/icons/notStartedCheck.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { dateOnly } from '../../../service/timeAndDate';
import { ChecklistAssignment, ChecklistResponse, getChecklistAssignmentAsync } from '../../../store/checklistAssignmentSlice';
import { Checklist } from '../../../store/checklistSlice';
import MultiMemberChecklistModal from './modals/MultiMemberChecklistModal';

interface GroupedChecklistAssignment {
  checklistAssignments: ChecklistAssignment[];
  checklist: Checklist;
  checklistTitle: string;
  createdAt?: Date;
}

interface Props { }

const ChecklistTable: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentState = useAppSelector((state) => ({
    checklistAssignments: state.checklistAssignments,
  }));

  const { currentChecklistAssignments } = currentState.checklistAssignments;

  const [sortReceived, setSortReceived] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');
  const [sortCreatedBy, setSortCreatedBy] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortDue, setSortDue] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCompleted, setSortCompleted] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const returnLevelOfComplete = (responses: ChecklistResponse[]) => {
    let started = false;
    if (responses && responses.length > 0) {
      responses.map((response) => {
        if (response.itemCompleted) {
          started = true;
        }
      });
    }
    if (started) {
      return (
        <>
          <img className="mr-2" src={InProgress} alt="Complete Icon" />
          <p className="m-0">In Progress</p>
        </>
      );
    }
    return (
      <>
        <img className="mr-2" src={NotStarted} alt="Complete Icon" />
        <p className="m-0">Not Started</p>
      </>
    );
  };

  const returnLevelOfCompleteForMultiple = (multiAssignments: ChecklistAssignment[]) => {
    const arrayOfStatus: string[] = multiAssignments.map((assignment) => {
      let status = '';
      if (assignment.responses.every((response) => response.itemCompleted)) {
        status = 'completed';
      } else if (assignment.responses.every((response) => !response.itemCompleted)) {
        status = 'notStarted';
      } else {
        status = 'inProgress';
      }
      return status;
    });
    const uniqueStatus = Array.from(new Set(arrayOfStatus));
    if (uniqueStatus.length > 1) {
      return 'Multiple';
    }
    if (uniqueStatus[0] === 'completed') {
      return (
        <>
          <img className="mr-2" src={Complete} alt="Complete Icon" />
          <p className="m-0">Complete</p>
        </>
      );
    }
    if (uniqueStatus[0] === 'notStarted') {
      return (
        <>
          <img className="mr-2" src={NotStarted} alt="Complete Icon" />
          <p className="m-0">Not Started</p>
        </>
      );
    }
    if (uniqueStatus[0] === 'inProgress') {
      return (
        <>
          <img className="mr-2" src={InProgress} alt="Complete Icon" />
          <p className="m-0">In Progress</p>
        </>
      );
    }
    return 'Multiple';
  };

  const viewChecklist = async (assignmentId: number) => {
    await dispatch(getChecklistAssignmentAsync(assignmentId));
    history.push(`/checklists/${assignmentId}`);
  };

  const consolidateChecklists = () => {
    const sortedChecklistArray: GroupedChecklistAssignment[] = [];
    if (currentChecklistAssignments) {
      const newChecklistArray = currentChecklistAssignments.slice();
      newChecklistArray.map((checklistAssignment) => {
        if (!sortedChecklistArray.find(
          (sortedAssignment) => sortedAssignment.checklist.id === checklistAssignment.checklist.id,
        )
        ) {
          const newStructure: GroupedChecklistAssignment = {
            checklistAssignments: newChecklistArray.filter(
              (assign) => assign.checklist.id === checklistAssignment.checklist.id,
            ),
            checklist: checklistAssignment.checklist,
            checklistTitle: checklistAssignment.checklist.title,
          };
          if (newStructure.checklistAssignments.length > 0) {
            newStructure.createdAt = newStructure
              .checklistAssignments
              .slice()
              .sort(
                (a, b) => (a.createdAt.valueOf() < b.createdAt.valueOf() ? -1 : 1),
              )[0].createdAt;
            sortedChecklistArray.push(newStructure);
          }
        }
      });
    }
    return sortedChecklistArray;
  };

  const returnCompletedDate = (assignmentGroup: GroupedChecklistAssignment) => {
    if (
      assignmentGroup.checklistAssignments
      && assignmentGroup.checklistAssignments.length > 0
      && !assignmentGroup.checklistAssignments.find(
        (assignment) => !assignment.completed && !assignment.completedDate,
      )) {
      const latestCompletedDate = assignmentGroup
        .checklistAssignments
        .slice()
        .filter((assignment) => assignment.completedDate)
        .sort(
          (a, b) => (
            (
              a.completedDate ? a.completedDate.valueOf() : NaN
            ) < (
              b.completedDate ? b.completedDate.valueOf() : NaN
            ) ? -1 : 1),
        )[0].completedDate;
      if (latestCompletedDate) {
        return dateOnly(latestCompletedDate);
      }
    }
    return '';
  };

  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th>
              <span>
                Received
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortReceived) {
                      case 'noSort':
                        setSortReceived('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortReceived('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortReceived('noSort');
                        return;
                      default:
                        setSortReceived('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortReceived)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Created By
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortCreatedBy) {
                      case 'noSort':
                        setSortCreatedBy('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortCreatedBy('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortCreatedBy('noSort');
                        return;
                      default:
                        setSortCreatedBy('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortCreatedBy)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Title
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortTitle) {
                      case 'noSort':
                        setSortTitle('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortTitle('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortTitle('noSort');
                        return;
                      default:
                        setSortTitle('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Status
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortStatus) {
                      case 'noSort':
                        setSortStatus('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortStatus('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortStatus('noSort');
                        return;
                      default:
                        setSortStatus('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Due
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortDue) {
                      case 'noSort':
                        setSortDue('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortDue('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortDue('noSort');
                        return;
                      default:
                        setSortDue('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortDue)} />
                </Button>
              </span>
            </th>
            <th>
              <span>
                Completed
                <Button
                  className="no-button"
                  onClick={() => {
                    switch (sortCompleted) {
                      case 'noSort':
                        setSortCompleted('sortAsc');
                        return;
                      case 'sortAsc':
                        setSortCompleted('sortDesc');
                        return;
                      case 'sortDesc':
                        setSortCompleted('noSort');
                        return;
                      default:
                        setSortCompleted('noSort');
                    }
                  }}
                >
                  <FontAwesomeIcon icon={returnSortIcon(sortCompleted)} />
                </Button>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            consolidateChecklists().length > 0
            && consolidateChecklists()
              .slice()
              .sort((a, b) => {
                const aVar = a.createdAt ? a.createdAt : 0;
                const bVar = b.createdAt ? b.createdAt : 0;
                switch (sortReceived) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                let aVar = 'z';
                let bVar = 'z';
                if (a.checklist && a.checklist.author && a.checklist.author.lastName) {
                  aVar = a.checklist.author.lastName.toLowerCase();
                }
                if (b.checklist && b.checklist.author && b.checklist.author.lastName) {
                  bVar = b.checklist.author.lastName.toLowerCase();
                }
                switch (sortCreatedBy) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.checklistTitle ? a.checklistTitle.toLowerCase() : 'z';
                const bVar = b.checklistTitle ? b.checklistTitle.toLowerCase() : 'z';
                switch (sortTitle) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.checklist.dueDate ? a.checklist.dueDate : 0;
                const bVar = b.checklist.dueDate ? b.checklist.dueDate : 0;
                switch (sortCompleted) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar: any = a.checklist.dueDate || 0;
                const bVar: any = b.checklist.dueDate || 0;
                switch (sortDue) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    if (aVar === 0) {
                      return 1;
                    }
                    if (bVar === 0) {
                      return -1;
                    }
                    if (aVar === bVar) {
                      return 0;
                    }
                    return aVar > bVar ? 1 : -1;
                  case 'sortDesc':
                    if (aVar === 0) {
                      return 1;
                    }
                    if (bVar === 0) {
                      return -1;
                    }
                    if (aVar === bVar) {
                      return 0;
                    }
                    return aVar > bVar ? -1 : 1;
                  default:
                    return 0;
                }
              })
              .map((assignmentGroup) => {
                if (assignmentGroup.checklistAssignments.length === 1) {
                  return (
                    <tr>
                      <td>
                        {
                          assignmentGroup.createdAt
                          && dateOnly(assignmentGroup.createdAt)
                        }
                      </td>
                      <td>
                        {
                          assignmentGroup.checklist
                          && assignmentGroup.checklist.author
                          && assignmentGroup.checklist.author.firstName
                          && assignmentGroup.checklist.author.lastName
                          && (
                            `${assignmentGroup.checklist.author.firstName} ${assignmentGroup.checklist.author.lastName}`
                          )
                        }
                      </td>
                      <td>
                        <Button className="button-link text-left p-0" onClick={() => viewChecklist(assignmentGroup.checklistAssignments[0].id)}>
                          {
                            assignmentGroup.checklist
                            && assignmentGroup.checklist.title
                          }
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex">
                          {
                            assignmentGroup.checklistAssignments[0].completed
                            && (
                              <>
                                <img className="mr-2" src={Complete} alt="Complete Icon" />
                                <p className="m-0">Complete</p>
                              </>
                            )
                          }
                          {
                            !assignmentGroup.checklistAssignments[0].completed
                            && returnLevelOfComplete(
                              assignmentGroup.checklistAssignments[0].responses,
                            )
                          }
                        </div>
                      </td>
                      <td>
                        {
                          assignmentGroup.checklist.dueDate
                          && dateOnly(assignmentGroup.checklist.dueDate)
                        }
                      </td>
                      <td>
                        {
                          assignmentGroup.checklistAssignments[0].completedDate
                          && dateOnly(assignmentGroup.checklistAssignments[0].completedDate)
                        }
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr>
                    <td>
                      {
                        assignmentGroup.createdAt
                        && dateOnly(assignmentGroup.createdAt)
                      }
                    </td>
                    <td>
                      {
                        assignmentGroup.checklist
                        && assignmentGroup.checklist.author
                        && assignmentGroup.checklist.author.firstName
                        && assignmentGroup.checklist.author.lastName
                        && (
                          `${assignmentGroup.checklist.author.firstName} ${assignmentGroup.checklist.author.lastName}`
                        )
                      }
                    </td>
                    <td>
                      <MultiMemberChecklistModal
                        checklistAssignments={assignmentGroup.checklistAssignments}
                        checklist={assignmentGroup.checklist}
                      >
                        {
                          assignmentGroup.checklist
                          && assignmentGroup.checklist.title
                        }
                      </MultiMemberChecklistModal>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle className="p-0">
                          <div className="d-flex">
                            <p className="m-0 mr-3 d-flex">
                              {
                                returnLevelOfCompleteForMultiple(
                                  assignmentGroup.checklistAssignments,
                                )
                              }
                            </p>
                            <FontAwesomeIcon icon={faSortDown} />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            assignmentGroup.checklistAssignments.map((assignment) => {
                              if (assignment.completed) {
                                return (
                                  <Dropdown.Item bsPrefix="custom-dropdown" as="div" className="no-underline d-flex justify-content-between">
                                    <div className="col-7 p-0">
                                      <p className="m-0">{assignment.member.memberName}</p>
                                    </div>
                                    <div className="col-5 d-flex">
                                      <img className="mr-2" src={Complete} alt="Complete Icon" />
                                      <p className="m-0">Complete</p>
                                    </div>
                                  </Dropdown.Item>
                                );
                              }
                              return (
                                <Dropdown.Item bsPrefix="custom-dropdown" as="div" className="no-underline d-flex justify-content-between">
                                  <div className="col-7 p-0">
                                    {assignment.member.memberName}
                                  </div>
                                  <div className="col-5 text-left d-flex">
                                    {
                                      returnLevelOfComplete(
                                        assignment.responses,
                                      )
                                    }
                                  </div>
                                </Dropdown.Item>
                              );
                            })
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      {
                        assignmentGroup.checklist.dueDate
                        && dateOnly(assignmentGroup.checklist.dueDate)
                      }
                    </td>
                    <td>
                      {
                        returnCompletedDate(assignmentGroup) || null
                      }
                    </td>
                  </tr>
                );
              })
          }
        </tbody>
      </Table>
    </>
  );
};
export default ChecklistTable;

import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import QuestionnaireAdminDetail from '../shared/questionnaire/QuestionnaireAdminDetail';

import QuestionnaireSession from '../shared/questionnaire/QuestionnaireSession';
import MemberQuestionnairesList from './questionnaires/MemberQuestionnairesList';
import QuestionnairesAdmin from './questionnaires/QuestionnairesAdmin';

interface Props { }

const Questionnaires: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
    questionnaire: state.questionnaire,
    questionnaireAssignments: state.questionnaireAssignments,
  }));
  const [toastShow, setToastShow] = useState(false);
  const { currentUser } = currentState.user;

  // eslint-disable-next-line max-len
  const superAdminRole = process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL && parseInt(process.env.REACT_APP_PRIMEX_SUPER_ADMIN_ROLE_ACCESS_LEVEL, 10);

  return (
    <>
      <Toast className="questionnaire-toast" show={toastShow} onClose={() => setToastShow(false)}>
        <Toast.Header>
          <strong className="me-auto">Primex</strong>
        </Toast.Header>
        <Toast.Body>Questionnaire submitted!</Toast.Body>
      </Toast>
      <Layout>
        <Switch>
          <Route exact path="/questionnaires">
            <MemberQuestionnairesList />
          </Route>
          <Route exact path="/questionnaires/begin/*">
            <div className="dashboard-container">
              {
                currentState?.questionnaire?.questionnaire
                && currentState.questionnaire.currentQuestionnaireSession
                && (
                  <QuestionnaireSession
                    data={currentState?.questionnaire?.questionnaire}
                    currentSession={currentState.questionnaire.currentQuestionnaireSession}
                    toast={{ setToastShow }}
                  />
                )
              }
            </div>
          </Route>
          {
            currentUser
              && currentUser.role
              && superAdminRole
              && (currentUser.role
                >= superAdminRole) ? (
                  <>
                    <Route exact path="/questionnaires/admin">
                      <QuestionnairesAdmin />
                    </Route>
                    <Route exact path="/questionnaires/admin/detail/:id">
                      <div className="dashboard-container">
                        <div className="row row-no-gutters m-0">
                          <div className="col-12 dashboard-title">
                            <h2>
                              Response Collection
                            </h2>
                          </div>
                        </div>
                        <div className="row row-no-gutters m-0">
                          <div className="col-12">
                            <Card className="questionnaire-container">
                              <QuestionnaireAdminDetail />
                            </Card>
                          </div>
                        </div>
                      </div>
                    </Route>
                  </>
              ) : null
          }
        </Switch>
      </Layout>
    </>
  );
};

export default Questionnaires;

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDebounce } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getFiltersAsync, getMembersWithPaginationAsync } from '../../../store/memberSlice';
import CustomPagination from '../pagination/CustomPagination';
import CustomSorting from '../pagination/CustomSorting';

interface Props {
  myMembers: boolean;
}

const MemberSearch: React.FC<Props> = (props: Props) => {
  const { myMembers } = props;
  const [sortName, setSortName] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortAsc');
  const [maturityFilter, setMaturityFilter] = useState<{ label: string, value: string }[]>([]);
  const [memberTypeFilter, setMemberTypeFilter] = useState<{ label: string, value: string }[]>([]);
  const [subtypeFilter, setSubtypeFilter] = useState<{ label: string, value: string }[]>([]);
  const [parentFilter, setParentFilter] = useState<{ label: string, value: number }[]>([]);
  const [searchCriteria, setSearchCriteria] = useState('');
  const dispatch = useAppDispatch();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [debouncedSearchValue, setDebouncedSearchValue] = React.useState('');

  useDebounce(
    () => {
      setDebouncedSearchValue(searchCriteria);
    },
    1000,
    [searchCriteria],
  );

  useEffect(() => {
    dispatch(getMembersWithPaginationAsync(
      {
        pageSize,
        pageIndex,
        memberType: memberTypeFilter.map((v) => v.value),
        subType: subtypeFilter.map((v) => v.value),
        parentMember: parentFilter.map((v) => v.value),
        maturity: maturityFilter.map((v) => v.value),
        orderBy: `${sortName}_memberName`,
        search: debouncedSearchValue,
        myMembers,
      },
    ));
  },
  [
    pageIndex,
    pageSize,
    memberTypeFilter,
    subtypeFilter,
    parentFilter,
    maturityFilter,
    sortName,
    debouncedSearchValue,
    myMembers,
  ]);

  useEffect(() => {
    dispatch(getFiltersAsync());
  }, []);

  const currentState = useAppSelector((state) => ({
    member: state.member,
  }));
  const { member: { membersPaged, filters, count } } = currentState;
  const members = membersPaged;
  console.log(count);
  // eslint-disable-next-line max-len

  return (
    <>
      <div>
        <div className="searchRow my-2">
          <InputGroup>
            <Form.Control
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className="filter-row">
          <Form.Label className="row-title">Filter by:</Form.Label>
          <Form.Group>
            <Form.Label>Maturity Level</Form.Label>
            <Select
              isMulti
              options={filters.cyberMaturityRatings}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setMaturityFilter(newValue)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Member Type</Form.Label>
            <Select
              isMulti
              options={filters.memberTypes}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setMemberTypeFilter(newValue)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Member Sub-Type</Form.Label>
            <Select
              isMulti
              options={filters.subTypes}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setSubtypeFilter(newValue)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Parent Member</Form.Label>
            <Select
              isMulti
              options={filters.parentMembers}
              // value={distModel.model.specificMemberSubTypes}
              onChange={(newValue: any) => setParentFilter(newValue)}
            />
          </Form.Group>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>
                <CustomSorting setSort={setSortName} sort={sortName}>
                  Member
                </CustomSorting>
              </th>
              <th>Maturity</th>
              <th>Type</th>
              <th>Sub-Type</th>
              <th>Parent Member</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              members
                .map((member) => (
                  <tr key={member.id}>
                    <td>{member.memberName}</td>
                    <td>{member.cyberMaturityRating || 'N/A'}</td>
                    <td>{member.entityType}</td>
                    <td>{member.propertyExposureClass || ''}</td>
                    <td>{member.parentMember ? member.parentMember.memberName : '-'}</td>
                    <td className="text-center">
                      <Link to={`/members/profile/${member.id}/info`}>
                        View Profile
                      </Link>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </Table>
        <CustomPagination
          totalCount={count}
          pageSize={pageSize}
          currentPage={pageIndex}
          onPageChange={setPageIndex}
          onPageSizeChange={setPageSize}
        />
      </div>
    </>
  );
};

export default MemberSearch;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createQuestionnaireAssignments,
  getCurrentQuestionnaireAssignments,
  deleteMultipleAssignments,
  getAssignmentsForQuestionnaire,
  updateQuestionnaireAssignment,
} from '../api/questionnaireAssignmentsAPI';
import { QuestionnaireDistributionModel } from '../models/distributionModels';
import { Member } from './memberSlice';
import { Questionnaire } from './questionnaireSlice';
import { User } from './userSlice';

export interface QuestionnaireAssignment {
  id: number;
  questionnaire: Questionnaire;
  completed: boolean;
  completedDate: Date | null;
  member: Member;
  users: User[];
  questionnaireSession: any;
  createdAt: Date;
}
export interface QuestionnaireAssignmentUser {
    id: number;
    questionnaire: Questionnaire;
    member: Member;
    user: User;
    questionnaireSession: any;
}
export interface QuestionnaireAssignmentsState {
  adminCurrentAssignments: QuestionnaireAssignment[] | null,
  status: 'idle' | 'loading' | 'failed';
  currentQuestionnaireAssignments: QuestionnaireAssignment[]
  error: any;
}

const initialState: QuestionnaireAssignmentsState = {
  adminCurrentAssignments: [],
  status: 'idle',
  error: null,
  currentQuestionnaireAssignments: [],
};

const getCurrentQuestionnaireAssignmentsAsync = createAsyncThunk(
  'questionnaireAssignments/getCurrentQuestionnaireAssignments',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentQuestionnaireAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createQuestionnaireAssignmentsAsync = createAsyncThunk(
  'questionnaireAssignments/createQuestionnaireAssignments',
  async (arg: QuestionnaireDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createQuestionnaireAssignments(arg);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getAssignmentsForQuestionnaireAsync = createAsyncThunk(
  'questionnaireAssignments/getAssignmentForQuestionnaire',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getAssignmentsForQuestionnaire(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleQuestionnaireAssignmentsAsync = createAsyncThunk(
  'questionnaireAssignments/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleAssignments(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateQuestionnaireAssignmentAsync = createAsyncThunk(
  'questionnaireAssignment/update',
  async (arg: {id: number, updateBody: Partial<QuestionnaireAssignment>}, { rejectWithValue }) => {
    try {
      const response: any = await updateQuestionnaireAssignment(arg.id, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const questionnaireAssignmentsSlice = createSlice({
  name: 'questionnaireAssignments',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get current questionnaire assignments
      .addCase(getCurrentQuestionnaireAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentQuestionnaireAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentQuestionnaireAssignments = action.payload;
        state.error = null;
      })
      .addCase(getCurrentQuestionnaireAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.currentQuestionnaireAssignments = [];
        state.error = action.payload;
      })
      // get current user
      .addCase(createQuestionnaireAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createQuestionnaireAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.adminCurrentAssignments = action.payload;
        state.error = null;
      })
      .addCase(createQuestionnaireAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.adminCurrentAssignments = [];
        state.error = action.payload;
      })
      .addCase(getAssignmentsForQuestionnaireAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAssignmentsForQuestionnaireAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.adminCurrentAssignments = action.payload;
        state.error = null;
      })
      .addCase(getAssignmentsForQuestionnaireAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.adminCurrentAssignments = [];
        state.error = action.payload;
      })
      .addCase(deleteMultipleQuestionnaireAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleQuestionnaireAssignmentsAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteMultipleQuestionnaireAssignmentsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateQuestionnaireAssignmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateQuestionnaireAssignmentAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateQuestionnaireAssignmentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default questionnaireAssignmentsSlice.reducer;

export {
  getCurrentQuestionnaireAssignmentsAsync,
  createQuestionnaireAssignmentsAsync,
  getAssignmentsForQuestionnaireAsync,
  deleteMultipleQuestionnaireAssignmentsAsync,
  updateQuestionnaireAssignmentAsync,
};

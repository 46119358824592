/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getHowToTopicsAsync } from '../../store/howToSlice';
import Layout from '../layouts/Layout';
import HowToAccordion from '../shared/howTo/HowToAccordion';

interface Props { }

const HowTo: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line no-unused-vars
  const currentState = useAppSelector((state) => {
    return {
      howTo: state.howTo,
    };
  });

  useEffect(() => {
    dispatch(getHowToTopicsAsync());
  }, []);

  const { howTo: { howToTopics } } = currentState;

  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>
              How-To Topics
            </h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="how-to-container">
              <div className="row row-no-gutters m-0">
                {
                  howToTopics
                    && howToTopics.length > 0
                    ? (
                      <HowToAccordion topic={howToTopics
                        .slice()
                        .filter((topic) => topic.published)
                        .filter((topic) => !topic.archived)}
                      />
                    ) : null
                }
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default HowTo;

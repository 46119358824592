import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Card from 'react-bootstrap/Card';
import TagManager from 'react-gtm-module';
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import { getOnePrimexThreatAlertAsync, PrimexSecurityAlert } from '../../../store/primexAlertsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// import { isFunctionTypeNode } from 'typescript';

interface Props {
}

const PrintCyberThreat: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    primexAlerts: state.primexAlerts,
  }));

  useEffect(() => {
    dispatch(getOnePrimexThreatAlertAsync(parseInt(id || '', 10)));
  }, []);

  if (!currentState.primexAlerts.selectedPrimexSecurityAlert) {
    return (<div />);
  }
  const threatData: PrimexSecurityAlert = currentState.primexAlerts.selectedPrimexSecurityAlert;
  const {
    content,
    recommendations,
    title,
    threatLevel,
    source,
    technologiesImpacted,
  } = threatData;

  const cleanRecommendations = DOMPurify.sanitize(recommendations);
  const cleanContent = DOMPurify.sanitize(content);

  const clickPrint = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'cyberPortal',
        action: 'print',
        category: 'cyber threat alerts',
        label: 'Print Threat from List',
        value: title || 'No title',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    window.print();
  };

  return (
    <>
      <Card className="incoming-alert-modal">
        <Card.Header>
          <Card.Title>
            <h4 className="ml-2 mb-0">
              Cyber Threat Alert
            </h4>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-5">
          <>
            <div className="row row-no-gutter">
              <div className="col-8">
                <p>
                  <span>Severity Level: </span>
                  <span className="bold ml-2">{threatLevel}</span>
                </p>
                <h1>
                  {title.includes('-Updated') ? title.split('-Updated')[0] : title}
                </h1>
              </div>
              <div className="col-4 d-flex justify-content-end align-items-center">
                <div>
                  <Button variant="primary" onClick={clickPrint}>Print</Button>
                </div>
              </div>
            </div>
            {source ? (
              <>
                <h3>Original Source</h3>
                <div><p>{source}</p></div>
              </>
            ) : null}
            {technologiesImpacted ? (
              <>
                <h3>Technologies Impacted</h3>
                <div><p>{technologiesImpacted}</p></div>
              </>
            ) : null}
            <h3>Description</h3>
            <ReactQuill
              value={cleanContent}
              readOnly
              theme="bubble"
            />
            <h3>Recommendations</h3>
            <ReactQuill
              value={cleanRecommendations}
              readOnly
              theme="bubble"
            />
          </>
        </Card.Body>
      </Card>
    </>
  );
};

export default PrintCyberThreat;

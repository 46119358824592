import axios, { AxiosPromise } from 'axios';

const s3Api = axios.create();
// TODO probably have to use s3client to send object.

// eslint-disable-next-line import/prefer-default-export
export function putImageOnS3(preAuthURL: any, image: File) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      s3Api.defaults.headers = {
        'Content-Type': `${image.type}`,
      };
      s3Api.put(preAuthURL, image)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

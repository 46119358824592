import { AxiosPromise } from 'axios';
import api from './api';

export default function getAllContacts() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/contact')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

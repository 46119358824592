/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import {
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteIcon from '../../../../assets/icons/remove.svg';
import { dateAndTime } from '../../../../service/timeAndDate';
import {
  getChecklistMultiMemberSessionAsync,
  getCurrentChecklistAssignmentsAsync,
  updateChecklistMultiMemberSessionAsync,
} from '../../../../store/checklistAssignmentSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import MultiSessionItem from './MultiSessionItem';
import { getOneChecklistAsync } from '../../../../store/checklistSlice';

interface Props { }

const ChecklistMultiSession: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const currentState = useAppSelector((state) => ({
    checklistAssignments: state.checklistAssignments,
    checklist: state.checklists,
    user: state.user,
  }));

  useEffect(() => {
    dispatch(getChecklistMultiMemberSessionAsync(parseInt(id || '', 10)));
  }, []);

  const { currentChecklistMultiMemberSession } = currentState.checklistAssignments;
  const { selectedChecklist: checklist } = currentState.checklist;

  useEffect(() => {
    if (
      !checklist
      && currentChecklistMultiMemberSession
      && currentChecklistMultiMemberSession.assignments
      && currentChecklistMultiMemberSession.assignments.length > 0
    ) {
      dispatch(
        getOneChecklistAsync(currentChecklistMultiMemberSession.assignments[0].checklist.id),
      );
    }
  }, []);
  if (!currentChecklistMultiMemberSession || !checklist) {
    return (<div />);
  }

  const checklistAssignment = currentChecklistMultiMemberSession.assignments[0];
  const { currentUser } = currentState.user;

  if (!checklistAssignment || !currentUser) {
    return (<div />);
  }

  const completeChecklist = async () => {
    if (
      currentChecklistMultiMemberSession.assignments
      && currentChecklistMultiMemberSession.assignments.length > 0
    ) {
      await dispatch(updateChecklistMultiMemberSessionAsync({
        id: currentChecklistMultiMemberSession.id,
        updateBody: {
          checklistAssignmentIds: currentChecklistMultiMemberSession.assignments.map(
            (assignment) => assignment.id,
          ),
          checklistAssignmentBody: {
            completed: true,
            completedDate: new Date(),
          },
        },
      }));
      await dispatch(getChecklistMultiMemberSessionAsync(currentChecklistMultiMemberSession.id));
      dispatch(getCurrentChecklistAssignmentsAsync());
      history.push('/checklists');
    }
  };

  const uploadUserDocuments = async (uploadFiles: File[]) => {
    if (
      currentChecklistMultiMemberSession.assignments
      && currentChecklistMultiMemberSession.assignments.length > 0
    ) {
      await dispatch(updateChecklistMultiMemberSessionAsync({
        id: currentChecklistMultiMemberSession.id,
        updateBody: {
          checklistAssignmentIds: currentChecklistMultiMemberSession.assignments.map(
            (assignment) => assignment.id,
          ),
          checklistAssignmentBody: {
            uploadUserDocs: uploadFiles.map((file) => ({
              title: file.name,
              origin: 'Checklist',
              fileName: file.name,
              fileType: file.type,
              s3Directory: 'memberDocs',
              members: null,
              allMembers: false,
            })),
          },
        },
        uploads: uploadFiles,
      }));
      dispatch(getChecklistMultiMemberSessionAsync(currentChecklistMultiMemberSession.id));
    }
  };

  const removeUserFile = async (docId: number) => {
    if (
      currentChecklistMultiMemberSession.assignments
      && currentChecklistMultiMemberSession.assignments.length > 0
    ) {
      await dispatch(updateChecklistMultiMemberSessionAsync({
        id: currentChecklistMultiMemberSession.id,
        updateBody: {
          checklistAssignmentIds: currentChecklistMultiMemberSession.assignments.map(
            (assignment) => assignment.id,
          ),
          checklistAssignmentBody: {
            userDocuments:
            currentChecklistMultiMemberSession
              .assignments[0]
              .userDocuments
              .slice()
              .filter((doc) => doc.id !== docId) || [],
          },
        },
      }));
      dispatch(getChecklistMultiMemberSessionAsync(currentChecklistMultiMemberSession.id));
    }
  };

  return (
    <Card className="view-checklist border-0">
      <div className="title">
        <h2>{checklist.title || ''}</h2>
        <p>
          Created by:
          <span className="ml-2">
            {
              checklist.author
              && checklist.author.firstName
              && checklist.author.lastName
              && (
                ` ${checklist.author.firstName} ${checklist.author.lastName} `
              )
            }
          </span>
          <span className="numeric-font ml-2">
            {
              checklist.createdAt
              && dateAndTime(checklist.createdAt)
            }
          </span>
        </p>
      </div>
      <div className="summary">
        <p>{checklist.summary || ''}</p>
      </div>
      <div className="supporting-info">
        <p>{checklist.additionalInfo || ''}</p>
      </div>
      <div className="attachments">
        {
          checklist.documents
          && checklist.documents.length > 0
          && checklist.documents.map((doc) => (
            <p key={`checklist-doc-${doc.id}`}>
              <FontAwesomeIcon icon={faPaperclip} className="mr-2" />
              <a target="_blank" rel="noreferrer" href={doc.docGetUrl} download>{doc.fileName}</a>
            </p>
          ))
        }
      </div>
      <div className="checklist-items">
        {
          currentChecklistMultiMemberSession
          && currentChecklistMultiMemberSession.assignments
          && currentChecklistMultiMemberSession.assignments.length > 0
          && currentChecklistMultiMemberSession.assignments[0].responses
          && currentChecklistMultiMemberSession.assignments[0].responses.length > 0
          && currentChecklistMultiMemberSession.assignments[0].responses
            .slice()
            .sort((a, b) => (a.checklistItem.order < b.checklistItem.order ? -1 : 1))
            .map((response) => {
              const responses: number[] = currentChecklistMultiMemberSession.assignments.map(
                (assignment) => {
                  const filteredResponse = assignment.responses.find(
                    (res) => res.checklistItem.id === response.checklistItem.id,
                  );
                  if (filteredResponse) {
                    return filteredResponse.id;
                  }
                  return NaN;
                },
              );
              return (
                <div className="checklist-item my-2" key={`checklistItem-${response.checklistItem.order}`}>
                  <MultiSessionItem
                    currentMultiSession={currentChecklistMultiMemberSession}
                    response={response}
                    checklistItem={response.checklistItem}
                    responseIds={responses}
                    assignmentCompleted={
                      currentChecklistMultiMemberSession.assignments[0].completed
                    }
                  />
                </div>
              );
            })
        }
      </div>
      <div className="footer-box d-flex justify-content-between">
        <div className="user-attachments">
          <Form.Group controlId="user-uploads">
            <Form.Label className="mt-2 mb-0">Do you want to upload any documents for reference?</Form.Label>
            <p className="text-muted">
              File types include doc, docx, pdf, jpg, jpeg, png, xls, xlsx, csv
            </p>
            <div className="d-flex">
              <Button disabled={(checklistAssignment && checklistAssignment.completed)} className="upload-file-button">
                Browse
                <Form.File
                  disabled={(checklistAssignment.completed)}
                  multiple
                  custom
                  type="file"
                  id="banner-file-upload"
                  accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx,.csv"
                  onChange={(e: any) => {
                    if (e.target.files) {
                      const filesList: File[] = Object.keys(e.target.files).map(
                        (key) => e.target.files[key],
                      );
                      if (
                        filesList
                        && filesList.length > 0
                      ) {
                        uploadUserDocuments(filesList);
                      }
                    }
                  }}
                />
              </Button>
              {
                checklistAssignment
                && checklistAssignment.userDocuments
                && checklistAssignment.userDocuments.length > 0
                && checklistAssignment.userDocuments.map((attachment) => (
                  <div className="d-flex flex-row ml-2">
                    <Button
                      disabled={attachment.addedBy && (attachment.addedBy.id !== currentUser.id)}
                      className="button-link d-flex align-items-center"
                      onClick={() => removeUserFile(attachment.id)}
                    >
                      <img src={DeleteIcon} alt="Delete Icon" />
                    </Button>
                    <a href={attachment.docGetUrl} download className="m-0 align-self-center">{attachment.fileName}</a>
                  </div>
                ))
              }
            </div>
          </Form.Group>
        </div>
        <div className="complete-box">
          <Button disabled={(checklistAssignment && checklistAssignment.completed)} variant="primary" onClick={completeChecklist}>Complete &gt;</Button>
        </div>
      </div>
    </Card>
  );
};
export default ChecklistMultiSession;

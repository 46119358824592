import React from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import Layout from '../layouts/Layout';
import GlobalMessage from '../shared/globalMessage/GlobalMessage';
import UserProfile from '../shared/members/UserProfile';

const Users: React.FC<void> = () => {
  const currentState = useAppSelector((state) => ({
    user: state.user,
  }));
  const { user: { viewUserProfile } } = currentState;
  return (
    <Layout>
      <div className="members-dashboard">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>Users</h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="member-container">
              <Switch>
                <Route path="/users/detail/:id">
                  <UserProfile />
                </Route>
              </Switch>
            </Card>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12 mt-5">
            <GlobalMessage
              flaggedUserId={viewUserProfile?.id || undefined}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Users;

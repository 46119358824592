import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createChecklistAssignments,
  createChecklistMultiMemberSession,
  deleteMultipleChecklistAssignments,
  getChecklistAssignment,
  getChecklistMultiMemberSession,
  getCurrentChecklistAssignments,
  updateChecklistAssignment,
  updateChecklistMultiMemberSession,
  updateChecklistResponse,
} from '../api/checklistAssignmentAPI';
import { putImageOnS3 } from '../api/s3API';
import { ChecklistDistributionModel } from '../models/distributionModels';
import { Checklist, ChecklistItem } from './checklistSlice';
import { Document, DocumentDTO } from './documentsSlice';
import { Member } from './memberSlice';
import { User } from './userSlice';

export interface UpdateChecklistMultiAssignmentDto {
  responseIds?: number[];
  responseBody?: Partial<ChecklistResponse>;
  checklistAssignmentIds: number[];
  checklistAssignmentBody?: Partial<ChecklistAssignment>;
}

export interface ChecklistMultiAssignmentSession {
  id: number;
  assignments: ChecklistAssignment[];
  createdAt: Date;
}

export interface ChecklistResponse {
  id: number;
  itemCompleted: boolean;
  dateCompleted: Date | null;
  checklistItem: ChecklistItem;
  modifiedBy: User | null;
  updatedAt: Date;
}

export interface ChecklistAssignmentUser {
  id: number;
  checklist: Checklist;
  member: Member;
  user: User;
  completed: boolean;
  completedDate: Date | null;
  responses: ChecklistResponse[];
}

export interface ChecklistAssignment {
  id: number;
  checklist: Checklist;
  member: Member;
  completed: boolean;
  completedDate: Date | null;
  users: User[];
  userDocuments: Document[];
  uploadUserDocs: | DocumentDTO[];
  responses: ChecklistResponse[];
  multiSession: ChecklistMultiAssignmentSession;
  singleSessionStarted: boolean;
  createdAt: Date;
  lockedByUser: User | null;
}

interface ChecklistAssignmentState {
  adminCurrentAssignments: ChecklistAssignment[] | null;
  currentChecklistAssignments: ChecklistAssignment[] | null;
  currentChecklistMultiMemberSession: ChecklistMultiAssignmentSession | null;
  selectedAssignment: ChecklistAssignment | null;
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: ChecklistAssignmentState = {
  adminCurrentAssignments: [],
  currentChecklistAssignments: [],
  currentChecklistMultiMemberSession: null,
  selectedAssignment: null,
  status: 'idle',
  error: null,
};

const getCurrentChecklistAssignmentsAsync = createAsyncThunk(
  'checklistAssignments/getCurrentAssignments',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentChecklistAssignments();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createChecklistAssignmentsAsync = createAsyncThunk(
  'checklistAssignments/createAssignments',
  async (arg: ChecklistDistributionModel, { rejectWithValue }) => {
    try {
      const response: any = await createChecklistAssignments(arg);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getChecklistAssignmentAsync = createAsyncThunk(
  'checklistAssignments/getAssignmentsForChecklist',
  async (checklistId: number, { rejectWithValue }) => {
    try {
      const response: any = await getChecklistAssignment(checklistId);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateChecklistAssignmentAsync = createAsyncThunk(
  'checklistAssignments/updateAssignment',
  async (arg: {
    id: number,
    updateBody: Partial<ChecklistAssignment>,
    uploads?: File[],
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateChecklistAssignment(arg.id, arg.updateBody);
      if (
        response.data.userDocuments
        && response.data.userDocuments.length > 0
        && arg.uploads
        && arg.uploads.length > 0
      ) {
        await Promise.all(
          response.data.userDocuments.map(async (doc: Document, index: number) => {
            if (arg.uploads && arg.uploads.length > 0) {
              const file: File | undefined = arg.uploads.find(
                (upload) => upload.name === doc.fileName,
              );
              if (file && doc.preAuthURL) {
                await putImageOnS3(doc.preAuthURL, file).catch((e) => e);
                response.data.userDocuments[index].preAuthURL = null;
              }
            }
          }),
        );
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateChecklistResponseAsync = createAsyncThunk(
  'checklistAssignments/updateResponse',
  async (
    arg: { responseId: number, updateBody: Partial<ChecklistResponse> }, { rejectWithValue },
  ) => {
    try {
      const response: any = await updateChecklistResponse(arg.responseId, arg.updateBody);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteMultipleChecklistAssignmentsAsync = createAsyncThunk(
  'checklistAssignments/deleteMultiple',
  async (idArray: number[], { rejectWithValue }) => {
    try {
      const response: any = await deleteMultipleChecklistAssignments(idArray);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const createChecklistMultiMemberSessionAsync = createAsyncThunk(
  'checklistMultiMemberSession/create',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const response: any = await createChecklistMultiMemberSession(ids);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const getChecklistMultiMemberSessionAsync = createAsyncThunk(
  'checklistMultiMemberSession/get',
  async (id: number, { rejectWithValue }) => {
    try {
      const response: any = await getChecklistMultiMemberSession(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateChecklistMultiMemberSessionAsync = createAsyncThunk(
  'checklistMultiMemberSession/updateAssignment',
  async (arg: {
    id: number,
    updateBody: UpdateChecklistMultiAssignmentDto,
    uploads?: File[],
  }, { rejectWithValue }) => {
    try {
      const response: any = await updateChecklistMultiMemberSession(arg.id, arg.updateBody);
      if (
        response.data
        && response.data.length > 0
        && arg.uploads
        && arg.uploads.length > 0
      ) {
        await Promise.all(
          response.data.map(async (uploadDoc: any, docIndex: number) => {
            if (uploadDoc && uploadDoc.userDocuments && uploadDoc.userDocuments.length > 0) {
              await Promise.all(
                uploadDoc.userDocuments.map(async (doc: Document, index: number) => {
                  if (arg.uploads && arg.uploads.length > 0) {
                    const file: File | undefined = arg.uploads.find(
                      (upload) => upload.name === doc.fileName,
                    );
                    if (file && doc.preAuthURL) {
                      await putImageOnS3(doc.preAuthURL, file).catch((e) => e);
                      response.data[docIndex].userDocuments[index].preAuthURL = null;
                    }
                  }
                }),
              );
            }
          }),
        );
      }
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const checklistAssignmentSlice = createSlice({
  name: 'checklistAssignments',
  initialState,
  reducers: {
    nullChecklistAdminAssignments(state) {
      state.adminCurrentAssignments = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentChecklistAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentChecklistAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.currentChecklistAssignments = action.payload;
        state.error = null;
      })
      .addCase(getCurrentChecklistAssignmentsAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.currentChecklistAssignments = [];
        state.error = action.payload;
      })
      .addCase(createChecklistAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createChecklistAssignmentsAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.adminCurrentAssignments = action.payload;
        state.error = null;
      })
      .addCase(createChecklistAssignmentsAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.adminCurrentAssignments = [];
        state.error = action.payload;
      })
      .addCase(getChecklistAssignmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChecklistAssignmentAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.selectedAssignment = action.payload;
        state.error = null;
      })
      .addCase(getChecklistAssignmentAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.selectedAssignment = null;
        state.error = action.payload;
      })
      .addCase(updateChecklistAssignmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChecklistAssignmentAsync.fulfilled, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateChecklistAssignmentAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.adminCurrentAssignments = [];
        state.error = action.payload;
      })
      .addCase(deleteMultipleChecklistAssignmentsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleChecklistAssignmentsAsync.fulfilled, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteMultipleChecklistAssignmentsAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.adminCurrentAssignments = [];
        state.error = action.payload;
      })
      .addCase(updateChecklistResponseAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChecklistResponseAsync.fulfilled, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateChecklistResponseAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.error = action.payload;
      })
      .addCase(createChecklistMultiMemberSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createChecklistMultiMemberSessionAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.currentChecklistMultiMemberSession = action.payload;
        state.error = null;
      })
      .addCase(createChecklistMultiMemberSessionAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.currentChecklistMultiMemberSession = null;
        state.error = action.payload;
      })
      .addCase(getChecklistMultiMemberSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChecklistMultiMemberSessionAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.currentChecklistMultiMemberSession = action.payload;
        state.error = null;
      })
      .addCase(getChecklistMultiMemberSessionAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.currentChecklistMultiMemberSession = null;
        state.error = action.payload;
      })
      .addCase(updateChecklistMultiMemberSessionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateChecklistMultiMemberSessionAsync.fulfilled, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateChecklistMultiMemberSessionAsync.rejected, (state, action) => {
        state.status = 'loading';
        state.currentChecklistMultiMemberSession = null;
        state.error = action.payload;
      });
  },
});

export const { nullChecklistAdminAssignments } = checklistAssignmentSlice.actions;
export default checklistAssignmentSlice.reducer;

export {
  getCurrentChecklistAssignmentsAsync,
  createChecklistAssignmentsAsync,
  getChecklistAssignmentAsync,
  updateChecklistAssignmentAsync,
  deleteMultipleChecklistAssignmentsAsync,
  updateChecklistResponseAsync,
  createChecklistMultiMemberSessionAsync,
  getChecklistMultiMemberSessionAsync,
  updateChecklistMultiMemberSessionAsync,
};

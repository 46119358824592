/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  faSort,
  faEllipsisV,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import { useAppDispatch } from '../../../../store/hooks';
import {
  Checklist,
  createChecklistAsync,
  deleteMultipleChecklistsAsync,
  getAllChecklistsAsync,
  getOneChecklistAsync,
  nullSelectedChecklist,
  updateChecklistAsync,
} from '../../../../store/checklistSlice';
import ChecklistPreviewModal from './checklistComponents/PreviewModal';
import LoadingModal from '../../loadingModal';

interface Props {
  deleteRows: {
    selectedRows: number[],
    setSelectedRows: Function,
  }
  checklists: Checklist[] | null,
  showArchived: boolean,
  searchString: string | null,
}

const ManageChecklistTable: React.FC<Props> = (props: Props) => {
  const {
    deleteRows,
    showArchived,
    checklists,
    searchString,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [sortTitle, setSortTitle] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStart, setSortStart] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortEnd, setSortEnd] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStatus, setSortStatus] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortCreated, setSortCreated] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllChecklistsAsync());
  }, []);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (deleteRows.selectedRows.includes(checkboxId)) {
      const newArray = deleteRows.selectedRows.filter((item) => item !== checkboxId);
      deleteRows.setSelectedRows([...newArray]);
    } else {
      deleteRows.setSelectedRows([checkboxId, ...deleteRows.selectedRows]);
    }
  };

  const deleteChecklist = async (id: number) => {
    const result = confirm('You may be deleting active content. Are you sure you want to delete this content?');
    if (result) {
      await dispatch(deleteMultipleChecklistsAsync([id]));
      dispatch(getAllChecklistsAsync());
    }
  };

  const archiveChecklist = async (id: number) => {
    const archiveBody: Partial<Checklist> = {
      archived: true,
    };
    await dispatch(updateChecklistAsync({ id, updateBody: archiveBody }));
    dispatch(nullSelectedChecklist());
    dispatch(getAllChecklistsAsync());
  };

  const editChecklist = async (id: number) => {
    await dispatch(getOneChecklistAsync(id));
    history.push('/content/create/checklist');
  };

  const copyChecklist = async (id: number) => {
    const itemTocopy = await dispatch(getOneChecklistAsync(id));
    const newChecklist: Partial<Checklist> = { ...itemTocopy.payload };
    newChecklist.id = undefined;
    newChecklist.published = false;
    newChecklist.author = undefined;
    newChecklist.title = `Copy of: ${newChecklist.title}`;
    await dispatch(createChecklistAsync({ checklistDTO: newChecklist }));
    dispatch(nullSelectedChecklist());
    dispatch(getAllChecklistsAsync());
  };

  return (
    <>
      <Table bordered>
        <thead>
          <tr>
            <th className="check-box">
              <input type="checkbox" />
            </th>
            <th>
              <span>Title </span>
              <Button
                className="no-button text-left"
                onClick={() => {
                  switch (sortTitle) {
                    case 'noSort':
                      setSortTitle('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortTitle('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortTitle('noSort');
                      return;
                    default:
                      setSortTitle('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortTitle)} />
              </Button>
            </th>
            <th>
              <span>Status </span>
              <Button
                className="no-button text-left"
                onClick={() => {
                  switch (sortStatus) {
                    case 'noSort':
                      setSortStatus('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStatus('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStatus('noSort');
                      return;
                    default:
                      setSortStatus('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStatus)} />
              </Button>
            </th>
            <th>
              <span>Start Date </span>
              <Button
                className="no-button text-left"
                onClick={() => {
                  switch (sortStart) {
                    case 'noSort':
                      setSortStart('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStart('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStart('noSort');
                      return;
                    default:
                      setSortStart('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStart)} />
              </Button>
            </th>
            <th>
              <span>Due Date </span>
              <Button
                className="no-button text-left"
                onClick={() => {
                  switch (sortEnd) {
                    case 'noSort':
                      setSortEnd('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortEnd('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortEnd('noSort');
                      return;
                    default:
                      setSortEnd('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortEnd)} />
              </Button>
            </th>
            <th>
              <span>Created </span>
              <Button
                className="no-button text-left"
                onClick={() => {
                  switch (sortCreated) {
                    case 'noSort':
                      setSortCreated('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortCreated('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortCreated('noSort');
                      return;
                    default:
                      setSortCreated('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortCreated)} />
              </Button>
            </th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            checklists
            && checklists
              .slice()
              .filter((item) => (showArchived ? item : !item.archived))
              .filter((item) => {
                if (searchString) {
                  if (item.title && item.title.toLowerCase().includes(searchString.toLowerCase())) {
                    return item;
                  }
                  return;
                }
                return item;
              })
              .sort((a, b) => {
                const aVar = a.title ? a.title.toLowerCase() : 'z';
                const bVar = b.title ? b.title.toLowerCase() : 'z';
                switch (sortTitle) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const returnStatus = (topic: Checklist) => {
                  if (topic.published && !topic.archived) {
                    return 1;
                  } if (!topic.published && !topic.archived) {
                    return 2;
                  }
                  return 3;
                };
                const aVar = returnStatus(a);
                const bVar = returnStatus(b);
                switch (sortStatus) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.startDate || 0;
                const bVar = b.startDate || 0;
                switch (sortStart) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.dueDate || 0;
                const bVar = b.dueDate || 0;
                switch (sortEnd) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .sort((a, b) => {
                const aVar = a.createdAt;
                const bVar = b.createdAt;
                switch (sortCreated) {
                  case 'noSort':
                    return 0;
                  case 'sortAsc':
                    return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                  case 'sortDesc':
                    return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                  default:
                    return 0;
                }
              })
              .map((checklist) => (
                <tr>
                  <td className="check-box">
                    <input
                      type="checkbox"
                      data-threatid={checklist.id}
                      onChange={() => onCheckboxChange(checklist.id)}
                    />
                  </td>
                  <td>
                    <ChecklistPreviewModal setLoading={setLoading} id={checklist.id} preview>
                      {checklist.title || 'None'}
                    </ChecklistPreviewModal>
                  </td>
                  <td>
                    {
                      checklist.archived && 'Archived'
                    }
                    {
                      !checklist.archived
                      && checklist.published
                      && 'Active'
                    }
                    {
                      !checklist.archived
                      && !checklist.published
                      && 'Draft'
                    }
                  </td>
                  <td>
                    {
                      checklist.startDate
                      && (
                        dateOnly(checklist.startDate)
                      )
                    }
                  </td>
                  <td>
                    {
                      checklist.dueDate
                      && (
                        dateOnly(checklist.dueDate)
                      )
                    }
                  </td>
                  <td>
                    {
                      checklist.createdAt
                      && (
                        <p className="m-0">{dateAndTime(checklist.createdAt)}</p>
                      )
                    }
                    {
                      checklist.author
                      && checklist.author.firstName
                      && checklist.author.lastName
                      && (
                        <p className="m-0">{`${checklist.author.firstName} ${checklist.author.lastName}`}</p>
                      )
                    }
                  </td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          checklist.published
                          && (
                            <Dropdown.Item className="no-underline">
                              <Link to={`/content/detail/checklist/${checklist.id}`} className="no-underline">
                                Details
                              </Link>
                            </Dropdown.Item>
                          )
                        }
                        <Dropdown.Item className="no-underline" onClick={() => editChecklist(checklist.id)}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="no-underline" onClick={() => copyChecklist(checklist.id)}>
                          Copy
                        </Dropdown.Item>
                        <Dropdown.Item className="no-underline" onClick={() => deleteChecklist(checklist.id)}>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item className="no-underline" onClick={() => archiveChecklist(checklist.id)}>
                          Archive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
      <LoadingModal show={loading} />
    </>
  );
};
export default ManageChecklistTable;

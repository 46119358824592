import { AxiosPromise } from 'axios';
import { IrFileDTO } from '../store/irClaimSlice';
import api from './api';

export function createIrClaimFile(fileDTO: IrFileDTO) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/ir-files', fileDTO)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneIrClaimFile(fileId: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/ir-files/${fileId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Route, Switch } from 'react-router-dom';
import { getAllDocumentsListAsync } from '../../store/documentsSlice';
import { useAppDispatch } from '../../store/hooks';
import Layout from '../layouts/Layout';
import CyberPortalDocuments from '../shared/documents/CyberPortalDocuments';
import DocumentNav from '../shared/documents/DocumentNav';
import MemberDocuments from '../shared/documents/MemberDocuments';

interface Props { }

const Documents: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllDocumentsListAsync());
  }, []);
  return (
    <Layout>
      <div className="dashboard-container">
        <div className="row row-no-gutters m-0">
          <div className="col-12 dashboard-title">
            <h2>Documents</h2>
          </div>
        </div>
        <div className="row row-no-gutters m-0">
          <div className="col-12">
            <Card className="documents-container">
              <DocumentNav>
                <Switch>
                  <Route path="/documents/member">
                    <MemberDocuments />
                  </Route>
                  <Route path="/documents/portal">
                    <CyberPortalDocuments />
                  </Route>
                </Switch>
              </DocumentNav>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Documents;

import {
  configureStore, ThunkAction, Action, combineReducers,
} from '@reduxjs/toolkit';
import authSlice from './authSlice';
import billboardAssignmentsSlice from './billboardAssignmentSlice';
import billboardsSlice from './billboardSlice';
import checklistAssignmentSlice from './checklistAssignmentSlice';
import checklistSlice from './checklistSlice';
import primexContactsSlice from './contactsSlice';
import cyberThreatAssignmentsSlice from './cyberThreatAssignmentSlice';
import documentsSlice from './documentsSlice';
import howToSlice from './howToSlice';
import incomingAlertsSlice from './incomingAlertSlice';
import memberSlice from './memberSlice';
import notificationSlice from './notificationSlice';
import postArticleAssignmentSlice from './postArticleAssignmentSlice';
import postArticlesSlice from './postArticleSlice';
import primexAlertsSlice from './primexAlertsSlice';
import questionnaireAssignmentsSlice from './questionnaireAssignmentsSlice';
import questionnaireSlice from './questionnaireSlice';
import systemAlertAssignmentSlice from './systemAlertAssignmentSlice';
import systemAlertSlice from './systemAlertSlice';
import primexTrainingSlice from './trainingSlice';
import todoSlice from './todoSlice';
import userSlice from './userSlice';
import irClaimSlice from './irClaimSlice';

const reducer = combineReducers({
  questionnaire: questionnaireSlice,
  incomingAlerts: incomingAlertsSlice,
  primexAlerts: primexAlertsSlice,
  auth: authSlice,
  user: userSlice,
  primexContacts: primexContactsSlice,
  primexTrainings: primexTrainingSlice,
  systemAlerts: systemAlertSlice,
  member: memberSlice,
  questionnaireAssignments: questionnaireAssignmentsSlice,
  cyberThreatAssignments: cyberThreatAssignmentsSlice,
  howTo: howToSlice,
  notification: notificationSlice,
  billboards: billboardsSlice,
  postArticles: postArticlesSlice,
  billboardAssignments: billboardAssignmentsSlice,
  postArticleAssignments: postArticleAssignmentSlice,
  systemAlertAssignments: systemAlertAssignmentSlice,
  documents: documentsSlice,
  checklists: checklistSlice,
  checklistAssignments: checklistAssignmentSlice,
  todo: todoSlice,
  irClaim: irClaimSlice,
});

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

/* eslint-disable jsx-a11y/control-has-associated-label */import React from 'react';
import { Link } from 'react-router-dom';
import { Notification } from '../../../../store/notificationSlice';
import Read from '../../../../assets/icons/noun_message open_2493714.svg';
import Unread from '../../../../assets/icons/MessageClosed.svg';
import { dateAndTime } from '../../../../service/timeAndDate';
import { IrClaim } from '../../../../store/irClaimSlice';
import { User } from '../../../../store/userSlice';
import { checkIrPermission } from '../../../../service/checkIrPermission';

interface Props {
  irClaim: IrClaim;
  notification: Notification;
  currentUser: User;
}

const IrPrivateMessageRow: React.FC<Props> = ({
  irClaim,
  notification,
  currentUser,
}) => {
  if (irClaim) {
    return (
      <tr key={`${irClaim.id}-claimNotification`}>
        <td>
          <img src={notification.viewed ? Read : Unread} alt={notification.viewed ? 'Read icon' : 'Unread icon'} />
        </td>
        <td>
          {dateAndTime(notification.createdAt)}
        </td>
        <td>{notification.type}</td>
        <td>
          {
            currentUser
              && checkIrPermission(currentUser)
              && (
                <Link to={`/cyber-claims/details/${irClaim.id}/private-comments`}>
                  {notification.title}
                </Link>
              )
          }
        </td>
        <td />
      </tr>
    );
  }
  return (
    <tr />
  );
};
export default IrPrivateMessageRow;

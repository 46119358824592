import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DistributionUniversal, ChecklistDistributionModel } from '../../../../../models/distributionModels';
import { useAppDispatch } from '../../../../../store/hooks';
import QuestionnaireDistribution from '../../../questionnaire/modals/modalComponents/questionnaireDistribution';
import { createChecklistAssignmentsAsync } from '../../../../../store/checklistAssignmentSlice';
import { getOneChecklistAsync } from '../../../../../store/checklistSlice';

interface Props {
  currentId: number,
  children: string,
}

const AddChecklistAssignments: React.FC<Props> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { currentId, children } = props;
  const [show, setShow] = useState(false);
  const [model, setModel] = useState<DistributionUniversal>();

  const dispatch = useAppDispatch();

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setModel(undefined);
  };

  const submitDistribution = async () => {
    if (model && currentId) {
      const distModel: ChecklistDistributionModel = {
        ...model, checklistId: currentId,
      };
      if (!model.userOnly) {
        if (
          !model.basicUser
          && !model.primaryIt
          && !model.primaryProgramContact
          && (!model.specificUserJobCategories
            || model.specificUserJobCategories.length === 0)
        ) {
          setModel({ ...model, userError: true });
          return;
        }
        if (!model.memberSelection) {
          setModel({ ...model, memberError: true });
          return;
        }
        if (model.memberSelection === 'specificMembers') {
          if (!model.specificMemberIds
            || (model.specificMemberIds.length <= 0)
          ) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        } else if (model.memberSelection === 'selectedCriteria') {
          if (!model.specificMemberTypes
            || (model.specificMemberTypes.length <= 0)) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberMaturityLevels
            || model.specificMemberMaturityLevels.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          if (!model.specificMemberSubTypes
            || model.specificMemberSubTypes.length <= 0) {
            setModel({ ...model, memberError: true });
            return;
          }
          distModel.allMembers = false;
          distModel.specificMemberIds = null;
        } else if (model.memberSelection === 'allMembers') {
          distModel.allMembers = true;
          distModel.specificMemberIds = null;
          distModel.specificMemberTypes = null;
          distModel.specificMemberMaturityLevels = null;
          distModel.specificMemberSubTypes = null;
        }
      } else {
        distModel.allMembers = false;
        distModel.specificMemberIds = null;
        distModel.specificMemberTypes = null;
        distModel.specificMemberMaturityLevels = null;
        distModel.specificMemberSubTypes = null;
      }
      await dispatch(createChecklistAssignmentsAsync(distModel));
      await dispatch(getOneChecklistAsync(currentId));
      handleClose();
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {children}
      </Button>

      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="ml-2 mb-0 align-self-center">{children}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestionnaireDistribution
            distModel={{ model, setModel }}
            addEndDate={false}
          />
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          {model?.userOnly
            ? (
              <Button disabled={!model?.specificUserId} variant="secondary" onClick={submitDistribution}>
                {children}
              </Button>
            ) : (
              <Button variant="secondary" onClick={submitDistribution}>
                {children}
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddChecklistAssignments;

import { AxiosPromise } from 'axios';
import { ChecklistDistributionModel } from '../models/distributionModels';
import { ChecklistAssignment, ChecklistResponse, UpdateChecklistMultiAssignmentDto } from '../store/checklistAssignmentSlice';
import api from './api';

export function getCurrentChecklistAssignments() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/checklist-assignment')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createChecklistAssignments(assignmentDTO: ChecklistDistributionModel) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/checklist-assignment', assignmentDTO)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getChecklistAssignment(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/checklist-assignment/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleChecklistAssignments(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .delete('/checklist-assignment/batch', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateChecklistAssignment(id: number, updateBody: Partial<ChecklistAssignment>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/checklist-assignment/${id}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateChecklistResponse(
  responseId: number, updateBody: Partial<ChecklistResponse>,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/checklist-response/${responseId}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createChecklistMultiMemberSession(ids: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .post('/checklist-multi-assignment-session', { assignmentIds: ids })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getChecklistMultiMemberSession(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .get(`/checklist-multi-assignment-session/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function updateChecklistMultiMemberSession(
  sessionId: number,
  updateDto: UpdateChecklistMultiAssignmentDto,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`/checklist-multi-assignment-session/${sessionId}`, updateDto)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../store/hooks';
import { IrClaimTechnology, updateClaimTechnologyAsync } from '../../../../store/irClaimSlice';

interface Props {
  technologyInfo: IrClaimTechnology;
  atomIrUser: boolean;
}

const Technology: React.FC<Props> = ({ technologyInfo, atomIrUser }) => {
  const dispatch = useAppDispatch();
  const [debounceHandle, setDebounceHandle] = useState<any>();

  return (
    <>
      {/* technical scope container */}

      <div className="mt-5 p-2">
        <h3>Technical Scope</h3>
        <div className="row">
          <div className="col-12">
            <Form.Label className="mb-0 mt-3">
              Externally Impacted IPs and Geographic
              Location (City, Name, or State)
            </Form.Label>
            <Form.Control
              defaultValue={technologyInfo?.impactedExternalIPsAndLocations || ''}
              as="textarea"
              id="external-ips"
              rows={3}
              disabled={!atomIrUser}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { impactedExternalIPsAndLocations: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              In Scope Internal IP Ranges (Static &amp; DHCP)
            </Form.Label>
            <Form.Control
              as="textarea"
              id="scope-internal-ips"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.internalIPRanges || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { internalIPRanges: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Perimeter Firewall/Security Appliances Vendor and Software Version Number
            </Form.Label>
            <Form.Control
              as="textarea"
              id="firewall-security"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.applianceVendorAndVersion || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { applianceVendorAndVersion: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              User Endpoint Security Software
            </Form.Label>
            <Form.Control
              as="textarea"
              id="user-security-software"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.endpointSecuritySoftware || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { endpointSecuritySoftware: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Recommended EDR
            </Form.Label>
            <Form.Control
              as="textarea"
              id="rec-edr"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.recommendedEDR || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { recommendedEDR: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Impacted DNS Providers
            </Form.Label>
            <Form.Control
              as="textarea"
              id="dns-providers"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.impactedDNSProviders || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { impactedDNSProviders: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Impacted WAN Providers
            </Form.Label>
            <Form.Control
              as="textarea"
              id="wan-providers"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.impactedWANProviders || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { impactedWANProviders: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Any Cloud Service Providers (if in scope)
            </Form.Label>
            <Form.Control
              as="textarea"
              id="any-cloud-service"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.cloudServiceProviders || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { cloudServiceProviders: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Saas/IaaS/DRass Service Providers
            </Form.Label>
            <Form.Control
              as="textarea"
              id="aaS-cloud-service"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.saasServiceProviders || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { saasServiceProviders: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Wireless Access Point Vendor, WAP model, and Software Revision Number
            </Form.Label>
            <Form.Control
              as="textarea"
              id="wap-vendor"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.wapVendorModelRevisionNumber || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { wapVendorModelRevisionNumber: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Identity Management Applications (i.e., Active Directory, Auth0)
            </Form.Label>
            <Form.Control
              as="textarea"
              id="identity-managements"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.identityManagementApplications || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { identityManagementApplications: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Email Server Software (or vendor) please note on-prem or cloud-hosted
            </Form.Label>
            <Form.Control
              as="textarea"
              id="email-server-software"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.emailServerSoftware || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { emailServerSoftware: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Hypervisor OEM and Version Number
            </Form.Label>
            <Form.Control
              as="textarea"
              id="oem-version-number"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.hypervisorOEMVersion || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { hypervisorOEMVersion: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Name of Managed SOC
            </Form.Label>
            <Form.Control
              as="textarea"
              id="managed-soc"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.managedSOC || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { managedSOC: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
          <div className="col-6">
            <Form.Label className="mb-0 mt-3">
              Switching Hardware Vendor and Type
            </Form.Label>
            <Form.Control
              as="textarea"
              id="switching-hardware"
              rows={3}
              disabled={!atomIrUser}
              defaultValue={technologyInfo?.switchingHardwareVendorType || ''}
              onChange={(e) => {
                if (debounceHandle) clearTimeout(debounceHandle);
                const { currentTarget: { value } } = e;
                const handle = setTimeout(() => dispatch(updateClaimTechnologyAsync(
                  {
                    id: technologyInfo.id,
                    updateBody: { switchingHardwareVendorType: value },
                  },
                )), 750);
                setDebounceHandle(handle);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Technology;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentNotifications, updateNotification } from '../api/notificationAPI';
import { CyberThreatAssignment } from './cyberThreatAssignmentSlice';
import { ChecklistAssignment } from './checklistAssignmentSlice';
import { Document } from './documentsSlice';
import { PostArticleAssignment } from './postArticleAssignmentSlice';
import { QuestionnaireAssignment } from './questionnaireAssignmentsSlice';
import { User } from './userSlice';
import { IrClaim, IrFile } from './irClaimSlice';

export interface Notification {
  id: number;
  type:
    | 'Cyber Threat Alert'
    | 'Cyber Threat Alert Reminder'
    | 'New Questionnaire'
    | 'Questionnaire Reminder'
    | 'Training'
    | 'Training Reminder'
    | 'Post or Article'
    | 'New Document'
    | 'New Checklist'
    | 'Updated Checklist'
    | 'Checklist Reminder'
    | 'New IR Claim Added'
    | 'New IR File Added'
    | 'Private Comments'
    | 'Claim Update'
    | 'Claim Update Message'
    | 'Updated Questionnaire';
  title: string;
  dueDate: Date;
  viewed: boolean;
  user: User;
  questionnaireAssignment?: QuestionnaireAssignment;
  cyberThreatAssignment?: CyberThreatAssignment;
  postArticleAssignment?: PostArticleAssignment;
  checklistAssignment?: ChecklistAssignment;
  document?: Document;
  trainingId?: number;
  irClaim?: IrClaim;
  irFile?: IrFile;
  createdAt: Date;
}

export interface NotificationState {
  notifications: Notification[] | null,
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: NotificationState = {
  notifications: [],
  status: 'idle',
  error: null,
};

const getCurrentNotificationsAsync = createAsyncThunk(
  'notifications/getCurrentNotifications',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentNotifications();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const updateNotificationAsync = createAsyncThunk(
  'notifications/updateNotification',
  async (arg: { id: number, body: Partial<Notification> }, { rejectWithValue }) => {
    try {
      const response: any = await updateNotification(arg.id, arg.body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentNotificationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentNotificationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.notifications = action.payload;
        state.error = null;
      })
      .addCase(getCurrentNotificationsAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.notifications = [];
        state.error = action.payload;
      })
      .addCase(updateNotificationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNotificationAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(updateNotificationAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.notifications = [];
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;

export {
  getCurrentNotificationsAsync,
  updateNotificationAsync,
};

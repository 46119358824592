import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import Button from 'react-bootstrap/Button';
import TagManager from 'react-gtm-module';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { HowToTopic } from '../../../../store/howToSlice';

interface Props {
  startingSlide: number;
  topic: HowToTopic[];
  modalIndex: number;
}

const HowToModal: React.FC<Props> = ({
  children,
  startingSlide,
  topic,
  modalIndex,
}) => {
  const [show, setShow] = useState(false);
  const [slidePosition, setSlidePosition] = useState(0);
  const [modalNumber, setModalNumber] = useState(0);

  useEffect(() => {
    setSlidePosition(startingSlide);
    setModalNumber(modalIndex);
    const tagManagerArgs = {
      dataLayer: {
        event: 'cyberPortal',
        action: 'view',
        category: 'how-to',
        label: 'View How-To Slide',
        value: topic[modalNumber].slides[startingSlide]?.slideTitle,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const handleClose = () => {
    setShow(false);
    setSlidePosition(0);
    setModalNumber(0);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <Button className="button-link p-0" onClick={handleShow}>
        {children}
      </Button>
      {
        topic.length > 0
        && (
          <Modal show={show} size="xl" onHide={handleClose} className="how-to-modal">
            <Modal.Header closeButton className="modal-header">
              <h2 className="how-to-modal-title m-0">
                How To:&nbsp;
                {topic[modalNumber].topicTitle}
              </h2>
              {topic.length > 1
                ? (
                  <Button
                    className="d-flex align-items-center
               no-button button-link text-left p-0 ml-auto"
                    onClick={() => {
                      setModalNumber(modalNumber < topic.length - 1 ? modalNumber + 1 : 0);
                      setSlidePosition(0);
                    }}
                  >
                    next category
                    <FontAwesomeIcon className="ml-1 next-cat-icon" icon={faAngleRight} />
                    <FontAwesomeIcon className="next-cat-icon" icon={faAngleRight} />
                  </Button>
                ) : null }
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <h2>
                  {topic[modalNumber].slides[slidePosition].slideTitle}
                </h2>
              </div>
              <div className="modal-info">
                {
                  parse(DOMPurify.sanitize(topic[modalNumber].slides[slidePosition].content))
                }
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer d-flex justify-content-between">
              <Button variant="primary" disabled={slidePosition <= 0} onClick={() => setSlidePosition(slidePosition - 1)}>
                Previous
              </Button>
              <div>
                {
                  topic[modalNumber].slides.map((item, index) => <FontAwesomeIcon key={item.order} className={`pagination-icon ${slidePosition === index && 'active'}`} icon={faCircle} />)
                }
              </div>
              <Button variant="primary" onClick={() => setSlidePosition(topic[modalNumber].slides.length - 1 <= slidePosition ? 0 : slidePosition + 1)}>
                Next
              </Button>
            </Modal.Footer>
          </Modal>
        )
      }
    </>
  );
};

export default HowToModal;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from '../layouts/Layout';
import SystemAlertCard from '../shared/content/SystemAlerts/SystemAlertCard';
import HowToCard from '../shared/content/HowTo/HowToCard';
import ManageContent from '../shared/content/ManageContent';
import CreateAlertContent from '../shared/content/SystemAlerts/CreateAlertContent';
import CreateHowToContent from '../shared/content/HowTo/CreateHowToContent';
import { useAppSelector } from '../../store/hooks';
import BillboardCard from '../shared/content/Billboards/BillboardCard';
import CreateBillboard from '../shared/content/Billboards/CreateBillboard';
import PostArticleCard from '../shared/content/PostArticle/PostArticleCard';
import CreatePostArticle from '../shared/content/PostArticle/CreatePostArticle';
import QuestionnaireCard from '../shared/content/Questionnaire/QuestionnaireCard';
import DetailContent from '../shared/content/DetailContent';
import CyberThreatCard from '../shared/content/CyberAlerts/CyberThreatCard';
import ChecklistCard from '../shared/content/Checklists/ChecklistCard';
import CreateChecklist from '../shared/content/Checklists/CreateChecklist';
import TrainingCard from '../shared/content/Training/TrainingCard';

interface Props { }

const ContentCreation: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    systemAlerts: state.systemAlerts,
    user: state.user,
    howTo: state.howTo,
    billboards: state.billboards,
    postArticles: state.postArticles,
    checklists: state.checklists,
  }));
  return (
    <>
      <Layout>
        <div className="dashboard-container">
          <div className="row row-no-gutters m-0">
            <div className="col-12 dashboard-title">
              <Switch>
                <Route exact path={['/content', '/content/create/*']}>
                  <h2>
                    Content Creation
                  </h2>
                </Route>
                <Route exact path={['/content/manage/how-to']}>
                  <h2>Manage How-To Topics</h2>
                </Route>
                <Route exact path={['/content/manage/system-alerts']}>
                  <h2>Manage System Alerts</h2>
                </Route>
                <Route exact path={['/content/manage/billboards']}>
                  <h2>Manage Billboards</h2>
                </Route>
                <Route exact path={['/content/manage/postarticle']}>
                  <h2>Manage Posts / Articles</h2>
                </Route>
                <Route exact path={['/content/manage/checklist']}>
                  <h2>Manage Checklists</h2>
                </Route>
                <Route exact path={['/content/detail/checklist/:id']}>
                  <h2>Checklist Detail</h2>
                </Route>
                <Route exact path={['/content/detail/postarticle/:id']}>
                  <h2>Post / Article Detail</h2>
                </Route>
              </Switch>
            </div>
          </div>
          <Switch>
            <Route exact path="/content">
              <div className="row content-creation-container">
                <div className="col-6 col-md-4 pb-4">
                  <CyberThreatCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <QuestionnaireCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <PostArticleCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <SystemAlertCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <HowToCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <ChecklistCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <BillboardCard />
                </div>
                <div className="col-6 col-md-4 pb-4">
                  <TrainingCard />
                </div>
              </div>
            </Route>
            <Route exact path={['/content/detail/postarticle/:id', '/content/detail/checklist/:id']}>
              <div className="row row-no-gutters m-0 content-creation-container">
                <div className="col-12">
                  <DetailContent />
                </div>
              </div>
            </Route>
            <Route exact path={['/content/manage/system-alerts', '/content/manage/how-to', '/content/manage/billboards', '/content/manage/postarticle', '/content/manage/checklist']}>
              <div className="row row-no-gutters m-0 content-creation-container">
                <div className="col-12">
                  <ManageContent
                    systemAlerts={currentState.systemAlerts}
                    howToTopics={currentState.howTo.howToTopics}
                    billboards={currentState.billboards.billboards}
                    postsAndArticles={currentState.postArticles.postArticles}
                    checklists={currentState.checklists.checklists}
                  />
                </div>
              </div>
            </Route>
            <Route exact path={['/content/create/system-alerts', '/content/create/system-alerts/:id']}>
              <div>
                <CreateAlertContent />
              </div>
            </Route>
            <Route exact path={['/content/create/how-to', '/content/create/how-to/:id']}>
              <div className="how-to-creation-container">
                <CreateHowToContent />
              </div>
            </Route>
            <Route exact path={['/content/create/billboards']}>
              <div className="how-to-creation-container">
                <CreateBillboard
                  selectedBillboard={currentState.billboards.selectedBillboard}
                />
              </div>
            </Route>
            <Route exact path={['/content/create/postarticle', '/content/create/postarticle/:id']}>
              <div className="post-article-creation-container">
                <CreatePostArticle
                  selectedPostArticle={currentState.postArticles.selectedpostArticle}
                />
              </div>
            </Route>
            <Route exact path={['/content/create/checklist', '/content/create/checklist/:id']}>
              <div className="checklist-creation-container">
                <CreateChecklist
                  selectedChecklist={currentState.checklists.selectedChecklist}
                />
              </div>
            </Route>
          </Switch>
        </div>
      </Layout>
    </>
  );
};

export default ContentCreation;

import { AxiosPromise } from 'axios';
import { Billboard } from '../store/billboardSlice';
import api from './api';

export function getBillboards() {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get('/billboard')
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOneBillboard(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.get(`/billboard/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// TODO: add type for billboard transfer object
export function createBillboard(billboard: any) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/billboard', billboard)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

// TODO: add partial type
export function updateBillboard(id: number, billboard: Partial<Billboard>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.patch(`/billboard/${id}`, billboard)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function deleteMultipleBillboards(idArray: number[]) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete('/billboard', { params: idArray })
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useParams } from 'react-router-dom';
import Read from '../../../../assets/icons/check-circle-fill.svg';
import Unread from '../../../../assets/icons/InProgressCheck.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getOnePostArticleAsync } from '../../../../store/postArticleSlice';
import { dateAndTime, dateOnly } from '../../../../service/timeAndDate';
import ViewProfileModal from '../../questionnaire/modals/ViewProfileModal';
import { deleteMultiplePostArticleAssignmentsAsync } from '../../../../store/postArticleAssignmentSlice';
import AddArticleRecipients from './modals/AddArticleRecipients';

interface Props { }

const PostArticleDetailAdmin: React.FC<Props> = () => {
  const { id } = useParams<{
    id: string | undefined
  }>();
  const dispatch = useAppDispatch();
  const currentState = useAppSelector((state) => ({
    postArticles: state.postArticles,
  }));
  const [sortStateMember, setSortStateMember] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateUser, setSortStateUser] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortStateViewed, setSortStateViewed] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [sortAssignDate, setSortAssignDate] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('noSort');
  const [searchCriteria, setSearchCriteria] = useState('');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [allAssignments, setAllAssignments] = useState(false);

  const returnSortIcon = (state: string) => {
    switch (state) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  useEffect(() => {
    // dispatch(getOnePrimexThreatAlertAsync(parseInt(id || '', 10)));
    dispatch(getOnePostArticleAsync(parseInt(id || '', 10)));
  }, []);

  // if there is not an active security alert, then just show an empty div
  if (!currentState.postArticles.selectedpostArticle) {
    return (<div />);
  }

  const {
    id: currentArticleId,
    title,
    publishedDate,
    createdAt,
    assignments,
    expirationDate,
  } = currentState.postArticles.selectedpostArticle;

  const sliceIntoChunks = (arr: number[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const onCheckboxChange = (checkboxId: number) => {
    if (selectedRows.includes(checkboxId)) {
      const newArray = selectedRows.filter((item) => item !== checkboxId);
      setSelectedRows([...newArray]);
    } else {
      setSelectedRows([checkboxId, ...selectedRows]);
    }
  };

  const deleteSelected = async () => {
    if (selectedRows.length > 0 && selectedRows.length < 10) {
      await dispatch(deleteMultiplePostArticleAssignmentsAsync(selectedRows));
      await dispatch(getOnePostArticleAsync(parseInt(id || '', 10)));
      setSelectedRows([]);
      setAllAssignments(false);
    } else if (selectedRows.length > 10) {
      const chunkedArrays = sliceIntoChunks(selectedRows, 10);
      await Promise.all(
        chunkedArrays.map(async (array) => {
          const deletedItem = await dispatch(deleteMultiplePostArticleAssignmentsAsync(array));
          return deletedItem;
        }),
      );
      await dispatch(getOnePostArticleAsync(parseInt(id || '', 10)));
      setSelectedRows([]);
      setAllAssignments(false);
    }
  };

  const addAllAssignments = () => {
    if (!allAssignments) {
      setAllAssignments(true);
      const allIds: number[] = [];
      assignments.map((assignment) => {
        allIds.push(assignment.id);
      });
      setSelectedRows(allIds);
    } else {
      setAllAssignments(false);
      setSelectedRows([]);
    }
  };

  return (
    <div className="post-article-admin-container">
      <div className="d-flex justify-content-between">
        <div>
          <h3>{title}</h3>
          <p>
            <span>Created: </span>
            <span className="numeric-font">{dateAndTime(createdAt)}</span>
            <span className="ml-4">Activated: </span>
            <span className="numeric-font">{publishedDate ? dateAndTime(publishedDate) : 'N/A'}</span>
          </p>
        </div>
        <div>
          <p>
            <span>Expires: </span>
            <span className="numeric-font">{expirationDate ? dateAndTime(expirationDate) : 'N/A'}</span>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between align-content-center mb-2 border-row px-2 py-3">
        <div className="d-flex flex-inline">
          <p className="m-0 d-flex align-items-center">
            <span>Distributed to: </span>
            <span className="d-flex ml-2">
              <p className="publish-tags ml-2">N/A</p>
            </span>
          </p>
        </div>
        <div className="searchbar">
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Enter search criteria"
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
      <div className="mt-3 mb-2 d-flex justify-content-between align-content-center">
        <div>
          {
            selectedRows.length > 0
            && (
              <button onClick={deleteSelected} type="button" className="ml-2 button-link">
                <FontAwesomeIcon size="lg" icon={faTrashAlt} />
                <span className="ml-2">Delete Selected</span>
              </button>
            )
          }
        </div>
        <div>
          <AddArticleRecipients
            currentId={currentArticleId}
          >
            Add Recipients
          </AddArticleRecipients>
        </div>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th className="check">
              <input checked={allAssignments} onClick={addAllAssignments} type="checkbox" />
            </th>
            <th>
              <span>Member </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateMember) {
                    case 'noSort':
                      setSortStateMember('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateMember('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateMember('noSort');
                      return;
                    default:
                      setSortStateMember('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateMember)} />
              </Button>
            </th>
            <th>
              <span>User </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateUser) {
                    case 'noSort':
                      setSortStateUser('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateUser('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateUser('noSort');
                      return;
                    default:
                      setSortStateUser('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateUser)} />
              </Button>
            </th>
            <th>
              <span>Status </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortStateViewed) {
                    case 'noSort':
                      setSortStateViewed('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortStateViewed('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortStateViewed('noSort');
                      return;
                    default:
                      setSortStateViewed('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortStateViewed)} />
              </Button>
            </th>
            <th>
              <span>Date </span>
              <Button
                className="no-button"
                onClick={() => {
                  switch (sortAssignDate) {
                    case 'noSort':
                      setSortAssignDate('sortAsc');
                      return;
                    case 'sortAsc':
                      setSortAssignDate('sortDesc');
                      return;
                    case 'sortDesc':
                      setSortAssignDate('noSort');
                      return;
                    default:
                      setSortAssignDate('noSort');
                  }
                }}
              >
                <FontAwesomeIcon icon={returnSortIcon(sortAssignDate)} />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            assignments
            && (
              assignments
                .slice()
                .filter((assignment) => assignment.user)
                .filter((assignment) => {
                  if (assignment.user.firstName) {
                    if (
                      // eslint-disable-next-line max-len
                      assignment.user.firstName.toLowerCase().includes(searchCriteria.toLowerCase())
                    ) {
                      return assignment;
                    }
                  }
                  if (assignment.user.lastName) {
                    // eslint-disable-next-line max-len
                    if (assignment.user.lastName.toLowerCase().includes(searchCriteria.toLowerCase())) {
                      return assignment;
                    }
                  }
                  if (assignment.user.members) {
                    let memberMatch = false;
                    assignment.user.members.forEach((member: any) => {
                      if (member.memberName.toLowerCase().includes(searchCriteria.toLowerCase())) {
                        memberMatch = true;
                      }
                    });
                    if (memberMatch) {
                      return assignment;
                    }
                  }
                })
                .sort((a, b) => {
                  let aVar = '';
                  let bVar = '';
                  if (a.user.members.length > 1) {
                    aVar = 'various';
                  } else if (a.user.members[0] && a.user.members[0].memberName) {
                    aVar = a.user.members[0].memberName.toLowerCase();
                  }
                  if (b.user.members.length > 1) {
                    bVar = 'various';
                  } else if (b.user.members[0] && b.user.members[0].memberName) {
                    bVar = b.user.members[0].memberName.toLowerCase();
                  }
                  switch (sortStateMember) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.user.lastName ? a.user.lastName.toLowerCase() : 'a';
                  const bVar = b.user.lastName ? b.user.lastName.toLowerCase() : 'a';
                  switch (sortStateUser) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.viewedDate || 0;
                  const bVar = b.viewedDate || 0;
                  switch (sortAssignDate) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((assignment) => (
                  <tr>
                    <td className="check">
                      <input
                        type="checkbox"
                        data-threatid={assignment.id}
                        checked={selectedRows.includes(assignment.id)}
                        onChange={() => onCheckboxChange(assignment.id)}
                      />
                    </td>
                    <td>
                      {
                        assignment.user.members.length > 1
                        && ('Various')
                      }
                      {
                        assignment.user.members.length === 1
                        && (
                          <Link to={`/members/profile/${assignment.user.members[0].id}/info`}>
                            {assignment.user.members[0].memberName}
                          </Link>
                        )
                      }
                      {
                        !assignment.user.members.length
                        && ('No member defined')
                      }
                    </td>
                    <td>
                      <ViewProfileModal profileId={assignment.user.id}>
                        {assignment.user.lastName}
                        <span>, </span>
                        {assignment.user.firstName}
                      </ViewProfileModal>
                    </td>
                    <td>
                      <div className="d-flex">
                        <img className="mr-2" src={assignment.viewed ? Read : Unread} alt={assignment.viewed ? 'Read icon' : 'Unread icon'} />
                        {
                          assignment.viewed
                            ? (
                              <p className="m-0">Read</p>
                            ) : (
                              <p className="m-0">Unread</p>
                            )
                        }
                      </div>
                    </td>
                    <td>
                      {assignment.viewedDate ? dateOnly(assignment.viewedDate) : ''}
                    </td>
                  </tr>
                ))
            )
          }
        </tbody>
      </Table>
    </div>
  );
};

export default PostArticleDetailAdmin;

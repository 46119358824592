import { AxiosPromise } from 'axios';
import { IrContact } from '../store/irClaimSlice';
import api from './api';

export function updateContact(
  id: number, updateBody: Partial<IrContact>,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api
        .patch(`ir-contact/${id}`, updateBody)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

export function createContact(contact: Partial<IrContact>) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.post('/ir-contact', contact)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
export function deleteContact(id: number) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      api.delete(`/ir-contact/${id}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  faEllipsisV,
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import Table from 'react-bootstrap/Table';
import FormControl from 'react-bootstrap/FormControl';
import {
  CreatePrimexSecurityAlert,
  createPrimexThreatAlertAsync,
  getAllPrimexThreatAlertsAsync,
  getOnePrimexThreatAlertAsync,
  nullSelectedPrimexAlert,
  PrimexSecurityAlert,
} from '../../../store/primexAlertsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import AddRecipients from './modals/addRecipients';
import EditContentOnly from './modals/editContentOnly';
import { dateOnly } from '../../../service/timeAndDate';
import PdfCreator from './pdfComponents/pdfCreator';

interface Props { }

const PublishedThreats: React.FC<Props> = () => {
  const currentState = useAppSelector((state) => ({
    IncomingAlerts: state.incomingAlerts,
    primexAlerts: state.primexAlerts,
    cyberThreatAssignments: state.cyberThreatAssignments,
    user: state.user,
  }));

  const [sortState, setSortState] = useState<'noSort' | 'sortAsc' | 'sortDesc'>('sortDesc');
  const [showArchived, setShowArchived] = useState(false);
  const [cyberThreatId, setCyberThreatId] = useState<number>(0);
  const [editAlertId, setEditAlertId] = useState<number | null>(null);
  const [searchCriteria, setSearchCriteria] = useState('');

  const dispatch = useAppDispatch();
  const history = useHistory();

  const returnSortIcon = () => {
    switch (sortState) {
      case 'noSort':
        return faSort;
      case 'sortAsc':
        return faSortUp;
      case 'sortDesc':
        return faSortDown;
      default:
        return faSort;
    }
  };

  const onSortClick = () => {
    switch (sortState) {
      case 'noSort':
        setSortState('sortAsc');
        return;
      case 'sortAsc':
        setSortState('sortDesc');
        return;
      case 'sortDesc':
        setSortState('noSort');
        return;
      default:
        setSortState('noSort');
    }
  };

  const copyPrimexAlert = async (threatId: number) => {
    const selectedAlert = await dispatch(getOnePrimexThreatAlertAsync(threatId));
    if (selectedAlert) {
      const newThreatCopy: CreatePrimexSecurityAlert = { ...selectedAlert.payload };
      newThreatCopy.title = `Copy of: ${newThreatCopy.title}`;
      newThreatCopy.id = undefined;
      newThreatCopy.draft = true;
      newThreatCopy.publishDate = undefined;
      newThreatCopy.archived = false;
      await dispatch(createPrimexThreatAlertAsync(newThreatCopy));
      dispatch(nullSelectedPrimexAlert());
      dispatch(getAllPrimexThreatAlertsAsync());
      history.push('/threat-alerts/drafts');
    }
  };

  const archivePrimexAlert = async (threatId: number) => {
    // eslint-disable-next-line no-alert
    const result = confirm('You may be archiving active content. Are you sure you want to archive this content?');
    if (result) {
      await dispatch(createPrimexThreatAlertAsync({ id: threatId, archived: true }));
      dispatch(nullSelectedPrimexAlert());
      dispatch(getAllPrimexThreatAlertsAsync());
    }
  };

  const viewAlertDetail = async (threatId: number) => {
    await dispatch(getOnePrimexThreatAlertAsync(threatId));
    history.push(`/threat-alerts/published/${threatId}`);
  };

  const editAlert = async (id: number) => {
    await dispatch(getOnePrimexThreatAlertAsync(id));
    setEditAlertId(id);
  };

  const returnDownloadLink = async (
    incomingAlert: PrimexSecurityAlert,
    retry: boolean = false,
  ) => {
    try {
      const blob = await pdf((
        <PdfCreator
          threatAlert={incomingAlert}
        />
      )).toBlob();
      saveAs(blob, incomingAlert.title);
    } catch (e) {
      if (!retry) {
        returnDownloadLink(incomingAlert, true);
      }
    }
  };

  return (
    <>
      <div className="published-container">
        <div className="my-3 mx-2 d-flex justify-content-between align-items-center">
          <div>
            <input
              type="checkbox"
              checked={showArchived}
              onChange={() => (showArchived ? setShowArchived(false) : setShowArchived(true))}
            />
            <span className="ml-2">Show Archived</span>
          </div>
          <div className="searchbar">
            <InputGroup>
              <FormControl
                placeholder="Search"
                aria-label="Enter search criteria"
                value={searchCriteria}
                onChange={(e) => setSearchCriteria(e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <Table bordered>
          <thead>
            <tr>
              <th>
                <span>Published </span>
                <Button className="no-button" onClick={onSortClick}>
                  <FontAwesomeIcon icon={returnSortIcon()} />
                </Button>
              </th>
              <th>Alert</th>
              <th>Source</th>
              <th>Created</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              currentState.primexAlerts.primexSecurityAlerts
              && currentState.primexAlerts.primexSecurityAlerts
                .slice()
                .filter((item) => item.draft === false)
                .filter((item) => (showArchived ? item : !item.archived))
                .filter((item) => {
                  if (
                    item.title
                    && item.title.toLowerCase().includes(searchCriteria.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .sort((a, b) => {
                  const aVar = a.createdAt;
                  const bVar = b.createdAt;
                  switch (sortState) {
                    case 'noSort':
                      return 0;
                    case 'sortAsc':
                      return aVar.valueOf() > bVar.valueOf() ? 1 : -1;
                    case 'sortDesc':
                      return aVar.valueOf() < bVar.valueOf() ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                .map((threat) => {
                  const {
                    id,
                    title,
                    publishDate,
                    createdAt,
                    sourceTitle,
                    author,
                  } = threat;
                  return (
                    <tr key={id}>
                      <td>
                        {dateOnly(publishDate)}
                      </td>
                      <td>
                        <Link to={`/threat-alerts/view/${id}`}>
                          {title}
                        </Link>
                      </td>
                      <td>{sourceTitle}</td>
                      <td>
                        {
                          author
                          && (
                            <>
                              {author.firstName || ''}
                              {' '}
                              {author.lastName || ''}
                            </>
                          )
                        }
                        {' '}
                        {dateOnly(createdAt)}
                      </td>
                      <td className="text-center">
                        <Dropdown>
                          <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => viewAlertDetail(id)}
                            >
                              Details
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Button className="button-link p-0 m-0" onClick={() => editAlert(id)}>
                                Edit
                              </Button>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setCyberThreatId(id)}
                            >
                              Add Recipients
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => copyPrimexAlert(id)}
                            >
                              Copy
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => archivePrimexAlert(id)}
                            >
                              Archive
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => returnDownloadLink(threat)}>
                              Download PDF
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })
            }
          </tbody>
        </Table>
      </div>
      <AddRecipients
        currentId={cyberThreatId}
        securityAlertDraft={currentState.primexAlerts.selectedPrimexSecurityAlert}
      >
        Add Recipients
      </AddRecipients>
      <EditContentOnly
        currentId={editAlertId || 0}
        securityAlertDraft={currentState.primexAlerts.selectedPrimexSecurityAlert}
        dateConversion={dateOnly}
        show={!!editAlertId}
        onClose={() => setEditAlertId(null)}
      />
    </>
  );
};

export default PublishedThreats;

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { getOnePostArticleAsync, PostArticle } from '../../../store/postArticleSlice';

interface Props {
  latestPostArticle: PostArticle;
}

const LatestPost: React.FC<Props> = ({ latestPostArticle }) => {
  const [thumbNail, setThumbnail] = useState('');
  const dispatch = useAppDispatch();

  const getImage = async (id: number) => {
    const updateArticleImage = await dispatch(getOnePostArticleAsync(id));
    if (updateArticleImage.payload.thumbnailUrl) {
      setThumbnail(updateArticleImage.payload.thumbnailUrl);
    }
  };

  useEffect(() => {
    if (latestPostArticle.thumbnailFilename) {
      getImage(latestPostArticle.id);
    }
  }, []);
  return (
    <div className="card-section latest-post">
      <Card>
        <Card.Body>
          <Card.Title>
            <h2>Latest Post / Article</h2>
          </Card.Title>
          {
            thumbNail
            && (
              <Card.Img className="mb-2 article-thumbnail" src={thumbNail} />
            )
          }
          <Card.Subtitle className="mb-2">
            <h4 className="bold">
              {latestPostArticle.title}
            </h4>
          </Card.Subtitle>
          <p>
            {latestPostArticle.summary}
          </p>
          <div className="d-flex flex-column">
            <Link to={`/posts-articles/${latestPostArticle.id}`}>
              <Button variant="primary">
                Continue Reading
              </Button>
            </Link>
            <Link className="mt-2" to="/posts-articles">
              View all posts and articles  &gt;&gt;
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LatestPost;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentTodo } from '../api/todoAPI';
import { ChecklistAssignment } from './checklistAssignmentSlice';
import { Checklist } from './checklistSlice';
import { QuestionnaireAssignment } from './questionnaireAssignmentsSlice';
import { Questionnaire } from './questionnaireSlice';
import { User } from './userSlice';

export interface Todo {
  id: number;
  type:
    | 'checklist'
    | 'training'
    | 'questionnaire';
  title: string;
  dueDate: Date;
  user: User;
  questionnaireAssignments: QuestionnaireAssignment;
  questionnaire: Questionnaire;
  checklistAssignment?: ChecklistAssignment;
  checklist: Checklist;
  trainingId?: number;
  createdAt: Date;
}

export interface TodoState {
  todos: Todo[] | [],
  status: 'idle' | 'loading' | 'failed';
  error: any;
}

const initialState: TodoState = {
  todos: [],
  status: 'idle',
  error: null,
};

const getCurrentTodoAsync = createAsyncThunk(
  'todo/getCurrentTodo',
  async (arg, { rejectWithValue }) => {
    try {
      const response: any = await getCurrentTodo();
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentTodoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentTodoAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.todos = action.payload;
        state.error = null;
      })
      .addCase(getCurrentTodoAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.todos = [];
        state.error = action.payload;
      });
  },
});

export default todoSlice.reducer;

export {
  getCurrentTodoAsync,
};
